import {
    motion,
    AnimatePresence,
} from "framer-motion";
import { IoIosClose } from "react-icons/io";

const PopupCloseDrawer = ({ open, setOpen, children }:{
    open: boolean,
    setOpen: (value: boolean) => void,
    children: React.ReactNode
}) => {
    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setOpen(false)}
                    className="backdrop-blur fixed inset-0 z-50 grid place-items-center cursor-pointer"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "0deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="flex flex-col justify-center items-center bg-white text-black p-2 rounded-lg shadow-xl cursor-default relative overflow-hidden"
                    >
                        <div className="flex flex-row w-full justify-end">
                            <div onClick={() => setOpen(false)}>
                                <IoIosClose className="text-3xl text-grigio cursor-pointer" />
                            </div>
                        </div>
                        <div className="w-full p-4 flex flex-col gap-5">
                            {children}
                        </div>

                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default PopupCloseDrawer;