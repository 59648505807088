import React from "react";

function Icon({ empty=false, yellow=false, gray=false }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Livello_2"
			data-name="Livello 2"
			viewBox="0 0 51.22 49.06"
		>
			<path
				stroke={empty ? "#000" : "none"}
				id="Livello_1-2"
				fill={empty ? "none" : yellow ? "#f9d71c" : gray ? "#d0d0d0" : "#fff"}
				d="m28.8 1.98 5.99 12.13 13.38 1.94c2.92.42 4.08 4.01 1.97 6.07l-9.69 9.44 2.29 13.33c.5 2.91-2.55 5.12-5.16 3.75l-11.97-6.29-11.97 6.29c-2.61 1.37-5.66-.84-5.16-3.75l2.29-13.33-9.69-9.44c-2.11-2.06-.95-5.64 1.97-6.07l13.38-1.94 5.99-12.13c1.3-2.64 5.07-2.64 6.38 0"
				data-name="Livello 1"
			></path>
		</svg>
	);
}

export default Icon;
