import React from "react";

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Livello 2"
			viewBox="0 0 111.6 111.6"
            className="w-20 h-20"
		>
			<g data-name="Livello 1">
				<circle
					cx="55.8"
					cy="55.8"
					r="55.8"
					className="cls-1 fill-[#2c302b]"
				></circle>
				<circle
					cx="55.8"
					cy="10.45"
					r="4.03"
					className="cls-2 fill-[#8fbb3b]"
				></circle>
				<circle
					cx="55.8"
					cy="101.14"
					r="4.03"
					className="cls-2 fill-[#8fbb3b]"
				></circle>
				<circle
					cx="101.14"
					cy="55.8"
					r="4.03"
					className="cls-2 fill-[#8fbb3b]"
				></circle>
				<circle
					cx="10.45"
					cy="55.8"
					r="4.03"
					className="cls-2 fill-[#8fbb3b]"
				></circle>
				<g>
					<path
						d="M55.8 89.74c-18.71 0-33.94-15.22-33.94-33.94S37.08 21.86 55.8 21.86 89.74 37.08 89.74 55.8 74.52 89.74 55.8 89.74zm0-62.65c-15.83 0-28.71 12.88-28.71 28.71S39.97 84.51 55.8 84.51 84.51 71.63 84.51 55.8 71.63 27.09 55.8 27.09z"
						className="cls-3 fill-[#fff]"
					></path>
					<path
						d="M79.51 47.11c-3.37 6.36-12.8 6.96-21.06 2.58s-12.23-12.08-8.87-18.44c3.37-6.36 12.8-7.96 21.06-3.58 8.27 4.38 12.23 13.08 8.87 19.44z"
						className="cls-3 fill-[#fff]"
					></path>
					<path
						d="M55.77 75.63c-10.57 0-19.17-8.6-19.17-19.17s8.6-19.17 19.17-19.17 19.17 8.6 19.17 19.17-8.6 19.17-19.17 19.17zm0-35.2c-8.84 0-16.03 7.19-16.03 16.03s7.19 16.03 16.03 16.03S71.8 65.3 71.8 56.46s-7.19-16.03-16.03-16.03z"
						className="cls-3 fill-[#fff]"
					></path>
				</g>
			</g>
		</svg>
	);
}

export default Icon;
