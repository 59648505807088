import React from 'react'
import InfoIcon from '../../../assets/PNG/information_7087065.png';

const Tooltip = ({ children, className }:
    {
        children: React.ReactNode,
        className?: string
    }
) => {
    return (
        <div className={className}>
            <div className='flex items-center space-x-2 p-2 bg-grigino rounded-lg'>
                <div className='w-6 aspect-square'>
                    <img src={InfoIcon} />
                </div>
                <div className='flex-1'>
                    {children}
                </div>
            </div>
        </div>

    )
}

export default Tooltip