import * as React from "react";

const Favouritesgray = () => (
	<svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_2"
    data-name="Livello 2"
    viewBox="0 0 384.31 342.51"
  >
    <g id="Livello_1-2" data-name="Livello 1">
      <path
        d="M322.44 201.96c29.58-30.28 44.63-60.69 44.63-90.25s-8.41-52.77-25.01-69.4c-16.61-16.65-39.83-25.09-69.03-25.09-8.1 0-16.51 1.47-25.01 4.37a97.2 97.2 0 0 0-23.56 11.64c-6.82 4.59-13.07 9.21-18.61 13.76-2.75 2.17-5.36 4.51-7.87 6.81a8.63 8.63 0 0 1-11.64.03c-2.75-2.51-5.16-4.72-7.68-6.68-5.41-4.49-11.66-9.1-18.8-13.9-7.24-4.91-15.15-8.81-23.54-11.65-8.53-2.91-16.94-4.38-25.04-4.38-29.17 0-52.39 8.44-68.99 25.09-16.63 16.64-25.06 39.99-25.06 69.4 0 9 1.55 18.38 4.6 27.85 3.05 9.52 6.58 17.67 10.48 24.24 4.06 6.97 8.5 13.53 13.27 19.56 5.94 7.69 9.39 11.6 10.92 13.25 2.03 2.14 3.7 3.78 4.45 4.38.23.18.61.51.82.71l120.65 119.36c5.48 5.71 14.04 5.55 19.28.25z"
        className="fill-[#c6c6c6]"
      ></path>
      <path
        d="M354.26 30.15C334.3 10.14 306.98 0 273.03 0c-9.99 0-20.28 1.77-30.53 5.27-9.81 3.29-19.13 7.9-27.67 13.68-7.25 4.87-13.96 9.83-19.78 14.62-.96.76-1.92 1.55-2.88 2.36q-1.38-1.17-2.73-2.22c-5.63-4.67-12.35-9.63-19.94-14.75C161 13.2 151.69 8.6 141.85 5.27c-10.29-3.51-20.58-5.28-30.57-5.28C77.35 0 50.04 10.14 30.09 30.14 10.12 50.13 0 77.57 0 111.71c0 10.8 1.83 21.95 5.43 33.12 3.45 10.75 7.51 20.09 12.03 27.69 4.42 7.6 9.33 14.84 14.54 21.44 4.67 6.05 8.9 11.16 11.94 14.43.95 1.01 4 4.19 6.04 5.87l120.18 118.89c5.8 6.04 13.61 9.36 21.98 9.36s16.01-3.22 21.74-9.02L334.66 214.1c32.94-33.72 49.64-68.17 49.64-102.4s-10.11-61.59-30.05-81.56ZM182.43 321.06 61.78 201.7c-.21-.2-.6-.53-.82-.71-.75-.61-2.42-2.24-4.45-4.38-1.53-1.65-4.98-5.56-10.92-13.25-4.76-6.03-9.21-12.59-13.27-19.56-3.9-6.57-7.43-14.72-10.48-24.24-3.05-9.47-4.6-18.85-4.6-27.85 0-29.41 8.43-52.76 25.06-69.4 16.6-16.65 39.81-25.09 68.99-25.09 8.09 0 16.51 1.47 25.04 4.38 8.39 2.83 16.3 6.74 23.54 11.65 7.14 4.8 13.39 9.41 18.8 13.9 2.53 1.96 4.94 4.17 7.68 6.68 3.29 3 8.35 2.99 11.64-.03 2.51-2.31 5.12-4.64 7.87-6.81 5.54-4.55 11.79-9.17 18.61-13.76 7.27-4.92 15.19-8.82 23.56-11.64 8.5-2.9 16.91-4.37 25.01-4.37 29.2 0 52.43 8.44 69.03 25.09 16.6 16.63 25.01 39.98 25.01 69.4s-15.05 59.97-44.63 90.25L201.71 321.31c-5.24 5.3-13.8 5.46-19.28-.25"
        className="fill-[#8e8e8e]"
      ></path>
      <path
        d="M293.75 72.69c8.94 4.98 19.45 3.16 23.51-4.11s.07-17.15-8.81-22.17c-8.94-4.98-19.45-3.16-23.51 4.11-4.05 7.22-.07 17.15 8.81 22.17M224.68 183.01h-64.36c-5.03 0-9.1 4.07-9.1 9.1s4.07 9.1 9.1 9.1h64.36c5.03 0 9.1-4.07 9.1-9.1s-4.07-9.1-9.1-9.1M278.34 129.05c0-.34 0-.69-.08-.99-.04-.27-.11-.53-.23-.91-.11-.42-.23-.84-.38-1.26l-.23-.5c-.23-.5-.46-.99-.72-1.37l-.27-.38c-.34-.5-.72-.95-1.18-1.45l-.23-.19c-.42-.42-.91-.8-1.41-1.18-1.22-.84-2.59-1.45-4.42-1.79-.61-.11-1.22-.19-1.87-.19h-1.37c-4.23 0-7.77 3.16-8.34 7.31-.08.42-.15.8-.23 1.33-.5 5.83-2.93 10.51-7.01 13.56l-.88.65c-.3.19-.65.42-.84.57-.19.11-.38.27-.53.34-.69.34-1.37.69-2.13.95s-1.52.5-2.28.69l-.72.15c-.88.19-1.6.27-2.32.27h-.27c-.72 0-1.45-.08-2.36-.27l-.65-.15q-1.14-.285-2.28-.69c-.76-.27-1.45-.57-2.09-.91-.19-.11-.38-.23-.46-.27-.3-.23-.65-.46-1.1-.76l-.69-.53c-4.11-3.05-6.55-7.73-7.05-13.63 0-.46-.11-.88-.19-1.03-.61-4.34-4.11-7.5-8.38-7.5h-1.37c-.65 0-1.26.08-1.71.15l-.57.08c-1.45.3-2.82.88-4.04 1.71-.46.3-.88.65-1.37 1.14l-.34.3c-.42.46-.8.91-.99 1.22l-.38.5c-.27.46-.53.91-.69 1.33l-.3.61c-.15.38-.27.76-.42 1.41l-.19.76c-.08.34-.08.65-.11 1.29 0 .3-.04.61-.04.91v.42c.57 12.53 7.16 23.34 16.87 29.82 6.02 4 13.21 6.32 20.98 6.32 17.4 0 32.03-11.65 36.44-27.61.76-2.7 1.22-5.48 1.37-8.42v-.57s.04-.19.04-.3v-.15c0-.19-.04-.42-.08-.72l.08-.08ZM175.52 150.6c4-5.75 6.47-12.64 6.82-20.18.11-3.05-.95-5.94-3.08-8.15s-4.99-3.43-8.04-3.43h-1.37c-2.25 0-4.34.88-5.9 2.44-1.64 1.68-2.59 4-2.59 6.47 0 5.6-2.89 10.66-8 13.94-.23.15-.46.34-.69.5-.15.11-.3.23-.46.3-.76.42-1.52.72-2.32 1.03q-1.14.405-2.28.69l-.72.15c-.88.19-1.64.27-2.32.27h-.65c-1.56-.04-3.2-.42-5.1-1.14-.61-.23-1.18-.5-1.79-.76-.72-.38-1.33-.84-1.98-1.33l-.46-.34c-4.11-3.05-6.55-7.73-7.05-13.63-.42-4.87-4.42-8.53-9.33-8.53h-.57c-3.05 0-5.86 1.22-8 3.39s-3.24 5.06-3.12 8.11c.69 19.99 16.57 35.72 36.41 36.44.5 0 .95.08 1.45.08 12.83 0 24.18-6.4 31.04-16.22l.11-.08Z"
        className="fill-[#fff]"
      ></path>
    </g>
  </svg>
);

export default Favouritesgray;
