import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
     fill="CurrentColor"
      viewBox="0 0 109.2 111.85"
    >
      <g data-name="layer 1">
        <path
          d="M97.35 0H11.87C5.31 0 0 5.31 0 11.86v94.03c0 1.83 1.48 3.31 3.31 3.31a2.65 2.65 0 002.64 2.64h13.3a2.65 2.65 0 002.64-2.64H87.3a2.65 2.65 0 002.64 2.64h13.3a2.65 2.65 0 002.64-2.64c1.83 0 3.31-1.48 3.31-3.31V11.86C109.19 5.31 103.88 0 97.33 0zm8.55 52.4c-2.22-3.1-5.83-5.15-9.93-5.15H84.81c-5.74-14.13-19.32-21-30.92-21s-21.82 5.98-27.77 15.02l-1.9 3.31c-2.26 4.49-3.57 9.54-3.57 14.9s1.3 10.4 3.57 14.89l1.9 3.3c5.94 9.04 16.17 15.35 27.76 15.03 24.06-.67 30.63-20.51 30.83-21.01h11.26c4.1 0 7.71-2.04 9.93-5.15v39.35H3.31V77.67h.02v-3.3h-.02V44.58h.02v-3.31h-.02V11.86c0-4.72 3.83-8.55 8.55-8.55h85.49c4.72 0 8.55 3.83 8.55 8.55V52.4zM53.88 38.22c-11.72 0-21.25 9.54-21.25 21.26s9.53 21.25 21.25 21.25c7.86 0 14.66-4.34 18.33-10.69 1.79 1.03 3.83 1.66 6.04 1.66h2.91c-4.68 10.42-15.14 17.7-27.28 17.7-16.5 0-29.92-13.42-29.92-29.91s13.42-29.92 29.92-29.92c12.14 0 22.59 7.28 27.28 17.7h-2.91c-2.21 0-4.25.63-6.04 1.66-3.68-6.36-10.47-10.69-18.33-10.69zm15.67 12.7c-2.17 2.21-3.52 5.23-3.52 8.56s1.35 6.35 3.52 8.56c-3.05 5.56-8.89 9.38-15.67 9.38-9.89 0-17.94-8.04-17.94-17.93s8.05-17.94 17.94-17.94c6.78 0 12.62 3.82 15.67 9.38zm8.7 17.61c-4.99 0-9.05-4.06-9.05-9.05s4.06-9.05 9.05-9.05h17.72c4.99 0 9.05 4.06 9.05 9.05s-4.06 9.05-9.05 9.05H78.25z"
          className="cls-1"
        ></path>
        <path
          d="M31.86 24.29c3.34 0 6.04-2.71 6.04-6.04s-2.71-6.04-6.04-6.04-6.04 2.71-6.04 6.04 2.71 6.04 6.04 6.04zm0-9.13c1.7 0 3.09 1.38 3.09 3.09s-1.38 3.09-3.09 3.09-3.09-1.39-3.09-3.09 1.39-3.09 3.09-3.09zM23.08 18.25c0-3.34-2.71-6.04-6.05-6.04s-6.04 2.71-6.04 6.04 2.71 6.04 6.04 6.04 6.05-2.71 6.05-6.04zm-6.05 3.09c-1.7 0-3.09-1.39-3.09-3.09s1.39-3.09 3.09-3.09 3.09 1.38 3.09 3.09-1.38 3.09-3.09 3.09zM91.7 21.03c1.67 0 3.02-1.35 3.02-3.02s-1.35-3.02-3.02-3.02-3.02 1.35-3.02 3.02 1.36 3.02 3.02 3.02z"
          className="cls-1"
        ></path>
        <path
          d="M73.67 33.45H107.45V36.720000000000006H73.67z"
          className="cls-1"
        ></path>
        <path d="M73.67 82.15H107.45V85.42H73.67z" className="cls-1"></path>
      </g>
    </svg>
  );
}

export default Icon;