import React, { useContext } from "react";
import Profilo from "../../../assets/sidebar/Profilo";
import useIsStandalone from "../../../utils/Standalone";
import { ReducerContext } from "../../../rootReducer";
import Trattative from "../../../assets/sidebar/Trattative";
import { useNavigate } from "react-router-dom";
import Search from "../../../assets/sidebar/Search";
import Notifiche from "../../../assets/sidebar/Notifiche";
import useUserStore from "../../../stores/userStore";
import ChatIcon from "../../../assets/sidebar/ChatIcon";
import InfoIcon from "../../../assets/Icons/InfoIcon";

function SidebarPhone() {
	const url = window.location.pathname.split("/")[1];
	const { user } = useUserStore();
	const logged = useUserStore((state) => state.isLogged);

	const isStandalone = useIsStandalone();
	const { state } = useContext(ReducerContext);
	const navigate = useNavigate();

	return (
		state.showSidebarPhone && (
			<div className="fixed md:hidden  block z-50 w-full bottom-0 left-0">
				<div
					className={`fixed ${
						isStandalone ? "pb-5" : "pb-0"
					} z-50 upshadow h-bottombar-phone text-xs inset-x-0 bottom-0 bg-white flex justify-between shadow-md items-end  `}
				>
					<div
						onClick={() => navigate("/")}
						className="flex flex-1 py-2 flex-col gap-1 items-center justify-center"
					>
						<Search selected={url === ""} />
						<p className={`${url === "" ? "text-verde" : "text-verdeScuro"}`}>
							Esplora
						</p>
					</div>
					{logged && (
						<div
							onClick={() => navigate("/trattative")}
							className="flex flex-1 py-2 flex-col  gap-1 items-center justify-center"
						>
							<Trattative selected={url === "trattative"} />
							<p
								className={`${
									url === "trattative" ? "text-verde" : "text-verdeScuro"
								}`}
							>
								Trattative
							</p>
						</div>
					)}
					{logged && (
						<div
							onClick={() => navigate("/chat")}
							className="flex flex-1 py-2 flex-col  gap-1 items-center justify-center"
						>
							<ChatIcon selected={url === "chat"} />
							<p
								className={`${
									url === "chat" ? "text-verde" : "text-verdeScuro"
								}`}
							>
								Chat
							</p>
						</div>
					)}
					{logged && (
						<div
							onClick={() => navigate("/notifications")}
							className="flex flex-1 py-2  flex-col gap-1 items-center justify-center"
						>
							<div className="relative">
								{user?.hasNotifications && (
									<div className="absolute h-3 w-3 rounded-full bg-rosso -top-1 right-0 "></div>
								)}
								<Notifiche selected={url === "notifications"} />
							</div>
							<p
								className={`${
									url === "notifications" ? "text-verde" : "text-verdeScuro"
								}`}
							>
								Notifiche
							</p>
						</div>
					)}
					{!logged && (
					<div
						onClick={() => navigate("/faq")}
						className="flex flex-1 py-2 flex-col gap-1 items-center justify-center"
					>
						<InfoIcon selected={url === "faq"} />
						<p
							className={`${url === "faq" ? "text-verde" : "text-verdeScuro"}`}
						>
							Come funziona
						</p>
					</div>
)}
					<div
						onClick={() => {
							logged ? navigate("/profilo") : navigate("/login");
						}}
						className="flex flex-1 py-2 flex-col gap-1 items-center justify-center"
					>
						<Profilo selected={url === "profilo" || url === "login"} />
						<p
							className={`${
								url === "profilo" || url === "login"
									? "text-verde"
									: "text-verdeScuro"
							}`}
						>
							Profilo
						</p>
					</div>
				</div>
			</div>
		)
	);
}

export default SidebarPhone;
