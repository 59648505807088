import {
    FiChevronDown,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Location from "./Location";

const DropdownLocation = ({
    isOpen,
    setIsOpen,
    opennth,
    setCoordinate,
    coordinate,
    cancellaFiltro,
    selectCity,
    setSelectCity,
}) => {
    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState({ ...coordinate });
    useEffect(() => {
        for (let e of document.querySelectorAll(
            'input[type="range"].slider-progress'
        )) {
            e.style.setProperty("--value", e.value);
            e.style.setProperty("--min", e.min === "" ? "0" : e.min);
            e.style.setProperty("--max", e.max === "" ? "100" : e.max);
            e.addEventListener("input", () =>
                e.style.setProperty("--value", e.value)
            );
        }
    }, []);
    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        setLocation(coordinate);
    }, [coordinate]);

    const handleClick = () => {
        setIsOpen(opennth);
    };

    const conferma = () => {
        setCoordinate(location);
        handleClick();
    };

    const deleteFiltro = () => {
        cancellaFiltro();
        handleClick();
    }

    return (
        <div className="flex items-center md:h-12 h-10 md:w-auto w-full ">
            <motion.div
                animate={open ? "open" : "closed"}
                className=" relative h-full md:w-auto w-full"
            >
                <button
                    onClick={() => handleClick()}
                    className="h-full md:min-w-40 w-full px-2 border border-grigio flex items-center md:justify-evenly justify-between  cursor-pointer rounded-md text-verdeScuro bg-white "
                >
                    <div className="flex">
                        <span className="font-medium text-sm">
                            {selectCity && (
                                <nobr>
                                    {location.city &&
                                    location.city.length > 12
                                        ? location.city.slice(0, 12) + "..."
                                        : location.city}
                                </nobr>
                            )}
                        </span>
                        <span className="font-medium pl-2 text-sm">
                            {selectCity ? (
                                location.city ? (
                                    <nobr> {location.range} km</nobr>
                                ) : (
                                    "Scegli una località"
                                )
                            ) : (
                                "Tutte le città"
                            )}
                        </span>
                    </div>
                    <motion.span
                        className="md:text-base text-2xl"
                        variants={iconVariants}
                    >
                        <FiChevronDown />
                    </motion.span>
                </button>

                <div className="md:flex hidden">
                    <motion.div
                        initial={wrapperVariants.closed}
                        variants={wrapperVariants}
                        transition={{ duration: 0.001 }}
                        style={{ originY: "top", translateX: "0%" }}
                        className="flex left-0 md:w-96 w-[90vw] z-50 flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] md:left-0 md:translate-x-0  overflow-hidden"
                    >
                        <Location
                            coordinate={location}
                            setCoordinate={setLocation}
                            cancellaFiltro={deleteFiltro}
                            setSelectCity={setSelectCity}
                            selectCity={selectCity}
                            conferma={conferma}
                        />
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
};



export default DropdownLocation;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};
