import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";

const Question = ({ title, children, index, openQuestion, handleToggle }) => {
    const isOpen = openQuestion === index;

    return (
        <motion.div
            animate={isOpen ? "open" : "closed"}
        >
            <button
                onClick={() => handleToggle(index)}
                className={`p-4 w-full flex items-center justify-between gap-4 rounded-lg ${isOpen ? "bg-verdeScuro" : ""}`}
            >
                <motion.span
                    variants={{
                        open: {
                            color: "#fdfdfd",
                        },
                        closed: {
                            color: "rgba(3, 6, 23, 1)",
                        },
                    }}
                    className="text-lg font-bold text-left"
                >
                    {title}
                </motion.span>
                <motion.span
                    variants={{
                        open: {
                            rotate: "180deg",
                            color: "#fdfdfd",
                        },
                        closed: {
                            rotate: "0deg",
                            color: "#030617",
                        },
                    }}
                >
                    <FiChevronDown className="text-2xl" />
                </motion.span>
            </button>
            <motion.div
                initial={false}
                animate={{
                    height: isOpen ? "fit-content" : "0px",
                    marginBottom: isOpen ? "24px" : "0px",
                }}
                className="overflow-hidden text-slate-600 px-4 mt-2"
            >
                {children}
            </motion.div>
        </motion.div>
    );
};

export default Question;