import ProfilePic from "../../../Components/Atoms/ProfilePic/ProfilePic";
import FadeInComponent from "../../../Components/Atoms/Transitions/FadeInComponent";
import { auth } from "../../../firebase";
import React from "react";
import useBookingStore from "../../../stores/bookingStore";
import { useNavigate } from "react-router-dom";

/**
 * 
 * @param {Object} props
 * @param {string} props.bookingId
 * @returns 
 */
function ContactTile({ bookingId }) {
    const navigate = useNavigate();

    /**
     * @type {string}
     */
    const selectedBookingId = useBookingStore((state) => state.selectedBooking ? state.selectedBooking._id : null);
    const setSelectedBooking = useBookingStore((state) => state.setSelectedBooking);

    /**
     * @type {import("../../../utils/API/BookingAPI").Booking}
     */
    const booking = useBookingStore((state) => state.bookings ? state.bookings[bookingId] : {});

    if (!booking) {
        return <div/>;
    }

    return (
        <FadeInComponent>
            <div className={`flex py-4 px-4 justify-start items-stretch rounded-sm ${selectedBookingId !== booking._id ? "border-2 border-transparent hover:border-2 hover:border-grigio hover:border-opacity-40" : "border-2 border-transparent md:border-grigio"} transition duration-300 cursor-pointer`}
                onClick={() => {
                    navigate('/chat/' + booking._id);
                    setSelectedBooking(booking);
                }}>
                <div className="flex flex-col justify-center w-2 mr-2">
                    {
                        booking.lastMessage.sendBy !== auth.currentUser.uid && !booking.isRead ?
                        <div className="bg-red-500 rounded-full w-2 h-2"/>
                        :
                        <div/>
                    }
                </div>
                <div className="flex flex-col justify-center">
                    <ProfilePic w={12} key={booking.otherUser.uid} hiddenLvl={true} image={booking.otherUser.image}/>
                </div>
                <div className="w-4"></div>
                <div className="flex flex-col w-3/5 justify-between pb-2">
                    <div className="text-lg font-medium">{booking.otherUser.completeName}</div>
                    <div className="text-sm">{booking.gift.name}</div>
                    <div className="text-gray-600 text-xs break-words overflow-hidden overflow-ellipsis h-4">{booking.lastMessage.content}</div>
                </div>
            </div>
        </FadeInComponent>
  );
}

export default ContactTile;