import React from "react";
import Mission from "../../assets/Icons/Mission";
import Vision from "../../assets/Icons/Vision";

function Chisiamo() {
    return (
        <>
            <div className="w-full relative  flex justify-center ">
                <div className=" md:max-w-[50vw] w-full py-10 md:px-16 px-6 pr-7">
                    <h1 className="md:text-6xl text-5xl mb-7 font-malik text-center font-bold text-verdeScuro">
                        Chi siamo
                    </h1>

                    <p className=" md:text-xl text-center text-md font-light text-black">
                        <br />
                        <span className="font-bold">
                            {" "}
                            Benvenuti su togiftit! :)
                        </span>{" "}
                        <br />
                        <br />
                        <span className="font-semibold">Togiftit</span> nasce
                        per semplificare e promuovere l'
                        <span className="font-semibold">
                            economia circolare
                        </span>
                        , facilitando la pratica del "
                        <span className="italic">
                            te lo regalo se lo vieni a prendere
                        </span>
                        ". <br />
                        <br />
                        Crediamo che{" "}
                        <span className="font-semibold">ogni oggetto</span>{" "}
                        possa avere una{" "}
                        <span className="font-semibold">seconda vita</span>, con
                        l’obbiettivo di{" "}
                        <span className="font-semibold">
                            ridurre gli sprechi
                        </span>{" "}
                        e contribuire a un futuro più sostenibile.
                    </p>
                </div>
            </div>
            <div className="w-full mt-16">
                <div className="flex items-start text-justify flex-col md:px-12 px-6 -mt-20">
                    <div className="grid md:grid-cols-2 md:gap-12 gap-12 mt-12 ">
                        <div className=" md:p-10 flex flex-col justify-between items-center">
                            <Vision/>
                            <p className="text-verdeScuro text-center text-3xl">
                                {" "}
                                La Nostra Visione
                            </p>
                            <p className="mt-4 text-lg text-center font-light">
                                Immaginiamo un mondo dove{" "}
                                <span className="font-semibold">
                                    il riuso è la norma
                                </span>
                                , gli oggetti trovano{" "}
                                <span className="font-semibold">
                                    una nuova casa
                                </span>{" "}
                                invece di finire in discarica e ogni gesto di
                                condivisione crea un impatto positivo{" "}
                                <span className="font-semibold">
                                    sulla comunità e sull'ambiente.
                                </span>
                            </p>
                        </div>
                        <div className=" md:p-10 flex flex-col justify-between items-center">
                            <Mission />
                            <p className="text-verdeScuro text-center text-3xl">
                                {" "}
                                La Nostra Missione
                            </p>
                            <p className="mt-4 text-center text-lg font-light">
                                Facilitare la{" "}
                                <span className="font-semibold">
                                    donazione e l'adozione di oggetti
                                </span>{" "}
                                attraverso una piattaforma semplice e
                                organizzata, che mette in contatto chi desidera
                                donare liberandosi di oggetti inutilizzati, con
                                chi preferisce il riutilizzo ad un nuovo
                                acquisto. Vogliamo costruire una community
                                basata sulla fiducia e la generosità.
                            </p>
                        </div>
                        <div className=" md:p-10 col-span-full flex flex-col ">
                            <p className="mt-4 text-lg text-center font-light">
                                <span className="font-bold">
                                    Unisciti a noi
                                </span>{" "}
                                e diventa parte di un movimento che fa la
                                differenza, un regalo alla volta, perché ogni
                                gesto conta. <br />
                                <br />
                                Questo è solo l'inizio. Il nostro obiettivo è
                                abbracciare tutte le pratiche correlate
                                all'economia circolare, creando un impatto
                                sempre più significativo per un futuro
                                sostenibile. <br />
                                <br />
                                Insieme possiamo fare la differenza.
                                <a href="/register" className="font-semibold pl-1">
                                    Iscriviti oggi!{" "}
                                </a>
                                <span className="text-sm">
                                    (Se no poi te ne scordi...)
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chisiamo;
