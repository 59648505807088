import React, { useRef } from 'react'

interface RadioButtonProps {
    label: string
    value: string
    icon: React.ReactNode
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    radioGroup: string
    isSelected?: boolean
    toggle?: boolean
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, value, icon, onChange, radioGroup, isSelected = false, toggle }) => {
    const radioInput = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        // e.preventDefault();
        radioInput.current!.click();
    }

    return (
        <div
            className='w-full flex items-center rounded-md text-verdeScuro md:hover:bg-grigino p-1'
            onClick={handleClick}
        >
            {icon && <div className='w-6 mr-4'>{icon}</div>}
            <p className='flex-1 px-2'>{label}</p>
            <input
                ref={radioInput}
                className='text-verde rounded-sm'
                type={toggle ? 'checkbox' : 'radio'}
                value={value}
                checked={isSelected}
                onChange={onChange}
                radioGroup={radioGroup}
            />
        </div>
    )
}

export default RadioButton