import React from "react";

function ChatFilter({text, chatsFilter, setChatsFilter, index}) {
    const isSelected = chatsFilter === index;

    return (
        <div className={`bg-grigio ${isSelected ? "bg-opacity-100" : "bg-opacity-20"} text-${isSelected ? "white" : "black"} rounded-md px-2 py-2 md:text-sm text-[0.80rem]  cursor-pointer select-none`}
        onClick={() => {
            setChatsFilter(index);
            if (chatsFilter === index) {
                setChatsFilter(0);
            }
        }}>
            {text}
        </div>
    );
}


export default ChatFilter;