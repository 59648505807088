import React, { useEffect, useRef, useState } from "react";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import GiftAPI from "../../utils/API/GiftAPI";
import useUserStore from "../../stores/userStore";
import Favouritesgray from "../../assets/Icons/Favouritesgray";
import FavouritesColored from "../../assets/Icons/FavouritesColored";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import useNavbarStore from "../../stores/navbarStore";
import CardContainer from "../../Components/Molecules/Container/CardContainer/CardContainer";

function Preferiti() {
	/**
	 * @type {[import("../../utils/API/GiftAPI").Gift[], function]}
	 */
	const user = useUserStore((state) => state.user);
	const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
	const setFootbar = useNavbarStore((state) => state.setFootbarVisible);

	const [preferiti, setPreferiti] = useState([]);
	const [favourites] = useState(user.favourites);
	const [loaded, setLoaded] = useState(false);
	const fetchCalled = useRef(false);

	const fetchLiked = async () => {
		if (fetchCalled.current) return;
		fetchCalled.current = true;

		for (let i = 0; i < favourites.length; i++) {
			const gift = await GiftAPI.getById(favourites[i]);
			setPreferiti((prev) => [...prev, gift]);
		}

		setLoaded(true);
	};

	useEffect(() => {
		fetchLiked();
		setNavbar(false);
		setFootbar(false);
	}, []);

	return (
		<div className="min-h-[90vh]">
			<div className="w-full px-4   gap-3">
				<div className="flex col-span-full flex-col items-center my-5">
					<div className="flex justify-between items-center w-full mb-5 col-span-full">
						<div className="absolute left-0">
							<Indietro />
						</div>
						<h1 className="text-3xl text-center mx-auto font-semibold">
							Preferiti
						</h1>
					</div>
					{loaded && favourites.length ? (
						<div className="w-40">
							<FavouritesColored />
						</div>
					) : (
						<span></span>
					)}
				</div>
				{loaded ? (
					preferiti.length ? (
						<CardContainer>
							{preferiti.map((item, i) => {
								return (
									<div className="" key={i}>
										<CardPublic user={user} gift={item} key={i} />
									</div>
								);
							})}
						</CardContainer>
					) : (
						<div className="flex flex-col gap-12 items-center w-full col-span-full text-center">
							<p className="text-xl text-grigio">
								Non hai ancora aggiunto nessun prodotto alla lista preferiti{" "}
							</p>
							<div className="w-40">
								<Favouritesgray />
							</div>
							<PrimaryButton
								href="/"
								bgColor="bg-verdeScuro"
								textStyle="text-white"
							>
								Esplora
							</PrimaryButton>
						</div>
					)
				) : (
					<Caricamento />
				)}
			</div>
		</div>
	);
}

export default Preferiti;
