import { create } from "zustand";
import { auth } from "../firebase";

const useBookingStore = create((set) => ({
    /**
     * @type {Record<string, import("../utils/API/BookingAPI").Booking> | null}
     */
    bookings: null,

    selectedBooking: {},

    displayedMessages: [],

    setBookings: (bookings) => set({bookings: bookings}),

    updateBookings: (booking) => set((state) => {
        if (!state.bookings) {
            return {};
        }

        if (state.bookings[booking._id]) {
            const update = {};

            if (state.selectedBooking && state.selectedBooking._id === booking._id) {
                update.selectedBooking = {...state.selectedBooking, ...booking};
            }

            const bookings = state.bookings;
            bookings[booking._id] = {...bookings[booking._id], ...booking};

            update[bookings] = {...bookings};

            return update;
        }
        else if (booking.receiver && booking.owner) {
            const bookings = state.bookings;
            bookings[booking._id] = booking;
            return {bookings: {...bookings}};
        }

        return {};
    }),

    setSelectedBooking: (booking) => set({selectedBooking: booking}),

    setDisplayedMessages: (messages) => set({displayedMessages: messages}),

    appendMessage: (newMessage) => set((state) => {
        state.updateBookings({_id: newMessage.bookingId, lastMessage: newMessage});

        if (state.selectedBooking._id !== newMessage.bookingId) {
            if (newMessage.sendBy !== auth.currentUser.uid && state.bookings[newMessage.bookingId]) {
                const bookings = state.bookings;
                bookings[newMessage.bookingId] = {...bookings[newMessage.bookingId], isRead: false};

                return {
                    bookings: {...bookings}
                };
            }

            return {};
        }

        const messageIndex = newMessage.id ? state.displayedMessages.findIndex((message) => message.id === newMessage.id && message.sendBy === newMessage.sendBy) : -1;

        if (messageIndex !== -1) {
            const newDisplayedMessages = state.displayedMessages;
            if (!newMessage.isLoading) {
                newDisplayedMessages[messageIndex].isLoading = false;
            }
            return {
                displayedMessages: newDisplayedMessages
            };
        }

        const messages = [newMessage, ...state.displayedMessages];
        return {
            displayedMessages: messages
        };
    })
}));

export default useBookingStore;