import React, { useState, useRef, useEffect } from 'react'
import { IoMdClose } from "react-icons/io";
import { RiImageAddLine } from "react-icons/ri";

import { addNewItem } from '../regalaHelper';
import { toast } from "sonner";

interface DraggableImage {
    id: number;
    image: string;
    imageFile: File;
}

export interface DraggableListProps {
    images: DraggableImage[];
    setImages: (images: DraggableImage[]) => void;
    maxImagesNumber: number;
}

export const DraggableList: React.FC<DraggableListProps> = ({ images, setImages, maxImagesNumber }) => {
    // const reloadedImages = images ? images.map(val => addNewItem(URL.createObjectURL(val.imageFile), val.imageFile, val.id)) : [];
    const [items, setItems] = useState(images);
    const [draggingItem, setDraggingItem] = useState<DraggableImage | null>(null);
    const fileButton = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setImages(items);
    }, [items]);

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: DraggableImage) => {
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setDraggingItem(null);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetItem: DraggableImage) => {
        if (!draggingItem) return;

        const currentIndex = items.indexOf(draggingItem);
        const targetIndex = items.indexOf(targetItem);

        if (currentIndex !== -1 && targetIndex !== -1) {
            items.splice(currentIndex, 1);
            items.splice(targetIndex, 0, draggingItem);
            setItems([...items]);
        }
    };

    const handleDelete = (item: DraggableImage) => {
        setItems(items.filter((i) => i !== item));
    };

    const onAdditionalPhotoChoose = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        if (e.target.files.length + items.length > maxImagesNumber) {
            toast.error(`Non puoi aggiungere piu' di ${maxImagesNumber} immagini`);
            return;
        }

        const newItems = [];
        let index = items.length === 0 ? 0 : Math.max(...items.map((item) => item.id)) + 1;
        for (const file of e.target.files) {
            newItems.push(addNewItem(URL.createObjectURL(file), file, index++));
        }

        setItems([...items, ...newItems]);
    };

    return (
        <div className="flex flex-wrap items-center justify-start gap-4 p-2 md:p-4">
            {items.map((item, index) => (
                <div
                    key={item.id}
                    className=
                    {`rounded-md relative
                        ${item === draggingItem ? 'dragging' : ''} ${index === 0 && 'scale-125 border-4 border-grigino shadow-lg mr-2'}`}
                    draggable="true"
                    onDragStart={(e) =>
                        handleDragStart(e, item)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, item)}
                >
                    <button
                        className='absolute text-base -right-2 -top-2 text-white bg-verdeScuro/80 rounded-full hover:bg-verdeScuro hover:text-rosso p-1'
                        onClick={() => handleDelete(item)}
                    >
                        <IoMdClose />
                    </button>
                    <img className='h-16 w-16 md:w-20 md:h-20 pointer-events-none object-cover rounded-sm' src={item.image} alt={item.imageFile?.name ?? "null"} />
                </div>
            ))}
            <div className='flex gap-2 justify-center pl-2'>
                {items.length < 5 && (
                    <div
                        onClick={() => fileButton.current!.click()}
                        className="w-12 h-12 md:w-16 md:h-16 text-xl rounded-md bg-grigino flex items-center justify-center cursor-pointer"
                        draggable="false"
                    >
                        <input
                            type="file"
                            ref={fileButton}
                            className='hidden'
                            accept="image/*"
                            multiple
                            onChange={(e) =>
                                onAdditionalPhotoChoose(e)
                            }
                        // id={`additionalImage${index}`}
                        />
                        <RiImageAddLine />
                    </div>
                )}
            </div>
        </div>
    )
}

export default DraggableList