import React, { useEffect, useState } from "react";
import ChipTabs from "../../Components/Atoms/Tabs/ChipTabs";
import Donazioni from "../../assets/Illustrazioni/Donazioni";
import useUserStore from "../../stores/userStore";
import Adozioni from "../../assets/Illustrazioni/Adozioni";
import Richieste from "../../assets/Illustrazioni/Richieste";
import GiftAPI from "../../utils/API/GiftAPI";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import CardContainer from "../../Components/Molecules/Container/CardContainer/CardContainer";
import { useNavigate } from "react-router-dom";

function Trattative() {
    const user = useUserStore((state) => state.user);
    const tabs = ["Donazioni", "Adozioni", "Richieste"];
    const [selected, setSelected] = useState(tabs[0]);
    const [donazioni, setDonazioni] = useState([]);
    const [adozioni, setAdozioni] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    const getDonations = async () => {
        try {
            const donations = await GiftAPI.getByOwner(user.uid);
            console.log(donations);
            setDonazioni(donations);
            getAdoptions();
        } catch (e) {
            console.log(e);
        }
    };

    const getAdoptions = async () => {
        try {
            const adoptions = await GiftAPI.getBooked();
            setAdozioni(adoptions);
            setLoaded(true);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getDonations();
    }, []);

    return (
        <div className="flex flex-col jsutify-center items-center gap-[2vh] min-h-[80vh] px-4 mt-[2vh]">
            {/*<div className="flex justify-between items-center w-full my-[1vh]">
				<div className="absolute left-0">
					<Indietro />
				</div>
                </div>*/}
            <h1 className="text-3xl text-center mx-auto font-bold">
                Trattative
            </h1>

            {selected === "Donazioni" && (
                <div className="w-full flex flex-col justify-center items-center gap-[2vh]">
                    <Donazioni />
                    <h2 className="text-lg font-semibold">
                        {user.donations} Donazioni
                    </h2>
                    <div className="w-full md:w-auto">
                        <ChipTabs
                            selected={selected}
                            setSelected={setSelected}
                            tabs={tabs}
                        />
                    </div>
                    <CardContainer>
                        {loaded ? (
                            donazioni.length > 0 ? (
                                donazioni.map((item, index) => (
                                    <CardPublic
                                        chiave={index}
                                        gift={item}
                                        dash={true}
                                    />
                                ))
                            ) : (
                                <div className="min-h-[30vh] w-full flex flex-col justify-center items-center col-span-full gap-12">
                                    <p className="text-center text-lg text-grigio">
                                        Non hai ancora pubblicato nessun regalo,
                                        qui potrai gestire tutte le tue
                                        donazioni
                                    </p>
                                    <PrimaryButton
                                        onClick={() => {
                                            navigate("/regala");
                                        }}
                                    >
                                        <div className="flex items-center ">
                                            <span className="text-lg font-bold mr-2">
                                                +
                                            </span>
                                            Fai un regalo
                                        </div>
                                    </PrimaryButton>
                                </div>
                            )
                        ) : (
                            <Caricamento />
                        )}
                    </CardContainer>
                </div>
            )}
            {selected === "Adozioni" && (
                <div className="w-full flex flex-col justify-center items-center gap-[2vh]">
                    <Adozioni />
                    <h2 className="text-lg font-semibold">
                        {adozioni.length} Adozioni
                    </h2>
                    <div className="w-full md:w-auto">
                        <ChipTabs
                            selected={selected}
                            setSelected={setSelected}
                            tabs={tabs}
                        />
                    </div>
                    <CardContainer>
                        {loaded &&
                            (adozioni.length > 0 ? (
                                adozioni.map((item, index) => (
                                    <CardPublic chiave={index} gift={item} />
                                ))
                            ) : (
                                <div className="h-[30vh] w-full flex flex-col justify-center items-center col-span-full">
                                    <p className="text-center text-lg text-grigio">
                                        Non hai mai richiesto un prodotto, qui
                                        potrai gestire tutte le prenotazioni e i
                                        prodotti su cui hai richiesto
                                        informazioni
                                    </p>
                                </div>
                            ))}
                    </CardContainer>
                </div>
            )}
            {selected === "Richieste" && (
                <div className="w-full  flex flex-col justify-center items-center gap-[2vh]">
                    <Richieste w="90" />
                    <h2 className="text-lg font-semibold">
                        PRESTO DISPONIBILE
                    </h2>
                    <div className="w-full md:w-auto">
                        <ChipTabs
                            selected={selected}
                            setSelected={setSelected}
                            tabs={tabs}
                        />
                    </div>
                    <div className="min-h-[40vh] flex flex-col justify-center items-center px-4 gap-8">
                        <h2 className="text-2xl font-semibold text-center">
                            A breve sarà disponibile <br /> una nuova funzione!{" "}
                        </h2>
                        <p className="text-lg font-semibold">
                            Se avrai bisogno di qualcosa nello specifico, potrai
                            richiederlo direttamente facendo una richiesta
                            visibile agli altri utenti :)
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Trattative;
