import PullToRefresh from "react-simple-pull-to-refresh";
import useDeviceDetection from "../../utils/useDeviceDetection";
import React from "react";

function RefreshOnPull({children, mobileOnly=true}) {
    const isMobile = useDeviceDetection() === "Mobile";

    return (
        <PullToRefresh onRefresh={async () => window.location.reload()} isPullable={mobileOnly ? isMobile : true}>
            {children}
        </PullToRefresh>
    );
}

export default RefreshOnPull;