import React from 'react'
import RadioButton from './RadioButton';

interface RadioButtonItem {
    label: string
    value: string
    icon?: React.ReactNode
}

interface RadioGroupProps {
    containerClass?: string
    items: RadioButtonItem[]
    radioGroup: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectedItem?: string
    toggle?: boolean
}

const RadioGroup: React.FC<RadioGroupProps> = ({containerClass, items, radioGroup, onChange, selectedItem = null,  toggle = false }) => {

    const renderRadioButtons = (item: RadioButtonItem) => (
        <RadioButton
            toggle={toggle}
            key={item.value}
            label={item.label}
            icon={item.icon}
            value={item.value}
            onChange={onChange}
            radioGroup={radioGroup}
            isSelected={item.value === selectedItem}
        />
    )

    return (
        <div className={containerClass || ''}>
            {items.map(renderRadioButtons)}
        </div>
    )
}

export default RadioGroup