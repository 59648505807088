import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import Icon from "../../../assets/Illustrazioni/welcome.svg";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import useUserStore from '../../../stores/userStore'
import DragCloseDrawer from "../Drawers/DragCloseDrawer";
import PrimaryButton from "../Bottoni/PrimaryButton";

const Benvenuto = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(true);
    const device = useDeviceDetection();
    const nomeUtente = useUserStore((state) => state.user.name)

    const handleClose = async (e) => {
        console.log("Benvenuto: handleClose");
        
        setIsOpen(false);
        onClose(e);
    };

    const welcomeMessage = (
        <div className="w-full flex px-4 flex-col items-center text-center gap-5">
            <h1 className="md:text-4xl text-4xl font-malik text-center text-verdeScuro font-bold px-4">
                Ciao {nomeUtente}!
            </h1>
            <p className="text-xl">Complimenti, ora fai parte della comunità dei <span className="font-bold">Gifter</span></p>
            <div className="w-full my-5">
                <img src={Icon} alt="Benvenuto" />
            </div>
            <p className="md:text-base md:px-12">Prima di iniziare, avremo bisogno di alcune informazioni.</p>
        </div>
    )
    const welcomeButton = (
        <div className="flex justify-center mt-8">
            <div className="w-full md:w-auto">
                <PrimaryButton fullWidth onClick={(e)=>handleClose(e)}>Prosegui</PrimaryButton>
            </div>
        </div>
    )
    const mobileBody = (
        <DragCloseDrawer hideCloseButton open={isOpen}>
            <div className="flex flex-col relative h-full max-w-xl bg-white rounded-lg overflow-hidden justify-around">
                {welcomeMessage}
                {welcomeButton}
            </div>
        </DragCloseDrawer>
    )
    const desktopBody = (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // onClick={() => handleClose(false)}
            className="bg-slate-900/20 text-xs backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
        >
            <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden"
            >
                <div className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden">
                    {welcomeMessage}
                    {welcomeButton}
                </div>
            </motion.div>
        </motion.div>
    )
    const body = device === "Mobile" ? mobileBody : desktopBody

    return (
        <AnimatePresence>
            {isOpen && body}
        </AnimatePresence>
    );
};

export default Benvenuto;
