import React from "react";
import ProfilePic from "../ProfilePic/ProfilePic";
import ProfilePicName from "../ProfilePic/ProfilePicName";
import Recensione from "../../../assets/Icons/Recensione";
import { Review } from "../../../types/Review";
/**
 * @param {Object} props
 * @param {import("../../../utils/API/UserAPI").Review} props.review
 * @returns
 */
function CardRecensioni({ review }:{review: Review}) {
    //TODO: review.content se è troppo lungo non va a capo se non ha spazi
    return (
        <div className="bg-white p-3 md:max-w-[14rem] md:min-w-[13rem] w-[42vw] flex flex-col gap-2 rounded-lg shadow-md">
            <div className="flex gap-2">
                <ProfilePic userId={review.author} />
                <ProfilePicName userId={review.author} />
            </div>
            <div className="flex gap-2 text-lg items-center font-semibold">
            
                <div className="flex gap-1">
                    {Array.from({ length: 5 }).map((_, index) => {
                        return (
                            <div
                                key={index}
                                className={`${
                                    index < review.rating
                                        ? "text-verde"
                                        : "text-grigino"
                                } w-6 mr-0 scale-x-[-1]`}
                            >
                                <Recensione yellow={index<review.rating} gray={index>=review.rating} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="text-sm font-light ">
                <p>{review.content}</p>
            </div>
        </div>
    );
}

export default CardRecensioni;
