import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../Atoms/Bottoni/PrimaryButton";
import SecondaryButton from "../../Atoms/Bottoni/SecondaryButton";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { AnimatePresence, motion } from "framer-motion";
import useUserStore from "../../../stores/userStore";
import { FiX } from "react-icons/fi";

function Location({
	setCoordinate,
	cancellaFiltro,
	coordinate,
	setSelectCity,
	selectCity,
	conferma,
}) {
	const radioSelectRef = useRef(null);
	const radioAllRef = useRef(null);
	const inputRangeRef = useRef(null);
	const user = useUserStore((state) => state.user);
	const [inputValue, setInputValue] = useState(user?.location?.city);

	const setCoordinates = async (place) => {
		try {
			const lat = await place.geometry.location.lat();
			const lng = await place.geometry.location.lng();
			console.log(lat, lng);
			console.log(inputRangeRef.current.value);
			setCoordinate({
				...coordinate,
				lat: lat,
				lng: lng,
				city: place.address_components[0].long_name,
				range: inputRangeRef.current.value,
			});
			setInputValue(place.formatted_address);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const inputRange = inputRangeRef.current;
		if (inputRange) {
			const savedValue = localStorage.getItem("inputRangeValue");
			if (savedValue !== null) {
				inputRange.value = savedValue;
				const ratio =
					((savedValue - inputRange.min) / (inputRange.max - inputRange.min)) *
					100;
				inputRange.style.backgroundSize = `${ratio}% 100%`;
			}

			const handleInput = function () {
				const ratio = ((this.value - this.min) / (this.max - this.min)) * 100;
				this.style.backgroundSize = `${ratio}% 100%`;
				localStorage.setItem("inputRangeValue", this.value);
			};

			inputRange.addEventListener("input", handleInput);

			return () => {
				inputRange.removeEventListener("input", handleInput);
			};
		}
		return () => {
			"";
		};
	}, [inputRangeRef]);

	useEffect(() => {
		if (radioAllRef.current && radioSelectRef.current) {
			radioAllRef.current.checked = !selectCity;
			radioSelectRef.current.checked = selectCity;
		}
		console.log(selectCity);
		
	}, [selectCity]);

	const handleRadioChange = (value) => {
		setSelectCity(value);
		if (!value) {
			setCoordinate({
				lat: null,
				lng: null,
				city: null,
			});
		}
	};

	return (
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.001 }}
				className="  inset-0 z-50 grid   "
			>
				<motion.div
					onClick={(e) => e.stopPropagation()}
					className=" p-4  overflow-hidden  "
				>
					<div className="w-full flex flex-col justify-center items-center gap-5">
						<div>
							<div className="flex items-center mb-3">
								<input
									type="radio"
									name="choose"
									className="ring-0 checked:bg-verde focus:ring-verde mr-2"
									onChange={() => handleRadioChange(false)}
									ref={radioAllRef}
									checked={!selectCity}
									defaultChecked={!selectCity}
								/>
								<label
									onClick={() => handleRadioChange(false)}
									htmlFor="input"
								>
									Tutte le città
								</label>{" "}
								<br />
							</div>
							<div className="flex items-center relative w-full">
								<input
									type="radio"
									name="choose"
									className="ring-0 checked:bg-verde focus:ring-verde mr-2"
									onChange={() => handleRadioChange(true)}
									ref={radioSelectRef}
									checked={selectCity}
									defaultChecked={selectCity}
								/>

								<ReactGoogleAutocomplete
									onClick={() => handleRadioChange(true)}
									onChange={(e) => {
										handleRadioChange(true);
										setInputValue(e.currentTarget.value);
									}}
									apiKey={process.env.REACT_APP_MAPS_API_KEY}
									style={{
										width: "300px",
										height: "40px",
									}}
									value={inputValue}
									className="appearance-none flex justify-center pr-8 items-center ring-1 ring-transparent bg-background rounded-lg p-2 focus:ring-1 col-span-5 focus:ring-verde"
									onPlaceSelected={(place) => {
										try {
											setTimeout(() => {
												setCoordinates(place);
											}, 500);
										} catch (err) {
											setTimeout(() => {
												setCoordinates(place);
											}, 1500);
											console.log(err);
										}
									}}
									placeholder="Comune..."
									options={{
										types: ["(cities)"],
										componentRestrictions: {
											country: "it",
										},
									}}
									language="it"
								/>

								<FiX
									className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-verdeScuro text-xl"
									onClick={() => {
										setInputValue("");
									}}
								/>
							</div>
						</div>
						<div
							onClick={(e) => e.stopPropagation()}
							className="flex flex-col justify-center items-center w-full gap-3 col-span-5"
						>
							<div className="flex w-full justify-between">
								<span>Entro</span> <span>{coordinate.range} km</span>
							</div>
							<input
								onChange={(e) => {
									setCoordinate({
										...coordinate,
										range: e.currentTarget.value,
									});
								}}
								value={coordinate.range}
								min={1}
								max={400}
								type="range"
								id="inputRange"
								className="inputRange w-full h-2 cursor-pointer accent-verde"
								ref={inputRangeRef}
							/>

							<div className="flex w-full text-sm text-grigio justify-between">
								<span>1 km</span> <span>400 km</span>
							</div>
						</div>
					</div>
					<div className="flex gap-2 items-center w-full mt-5 justify-around ">
						<SecondaryButton
							textStyle={"text-sm"}
							fullWidth
							onClick={() => cancellaFiltro()}
						>
							Cancella filtro
						</SecondaryButton>
						<PrimaryButton onClick={conferma} fullWidth>
							Conferma
						</PrimaryButton>
					</div>
				</motion.div>
			</motion.div>
		</AnimatePresence>
	);
}

export default Location;
