import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 98.81 96.95"
      fill="CurrentColor"
      className="fill-black w-full h-full"
    >
      <g data-name="layer 1">
        <path
        fill="CurrentColor"
          d="M37.92 54.02c.73 0 1.46-.28 2-.81.5-.51.82-1.25.82-2.01s-.32-1.46-.82-1.97c-.54-.53-1.27-.84-2-.84s-1.47.31-2 .84c-1.04 1.05-1.04 2.92 0 3.98.53.53 1.24.81 2 .81z"
          className="cls-1"
        ></path>
        <path
        fill="CurrentColor"
          d="M98.81 10.79C98.81 4.84 93.97 0 88.02 0H10.79C4.84 0 0 4.84 0 10.79v68.76c0 3.42 1.63 6.44 4.12 8.42-.02.01-.04.03-.06.04.16.12.34.21.5.32.17.12.35.23.52.34l.33.18c1.59.92 3.41 1.49 5.37 1.49h.06c.9 3.78 4.26 6.61 8.31 6.61s7.41-2.83 8.31-6.61h43.86c.9 3.78 4.26 6.61 8.3 6.61s7.41-2.83 8.31-6.61H88c2.24 0 4.32-.69 6.04-1.85.49-.31.95-.65 1.37-1.04l-.05-.05c2.1-1.97 3.43-4.75 3.43-7.85V10.79zM50.88 33.98h44.66v12.88H50.88V33.98zm0 15.94h44.66V62.8H50.88V49.92zm44.66-18.99H50.88V20.35h44.66v10.58zM10.79 3.27h77.22c4.15 0 7.52 3.37 7.52 7.52v6.6H3.27v-6.6c0-4.15 3.37-7.52 7.53-7.52zM3.24 76.61h.04V20.35h44.65V76.6H6.5s-.03.01-.05.01H3.23zm15.93 17.38c-2.39 0-4.36-1.54-5.16-3.64h10.33c-.81 2.11-2.78 3.64-5.16 3.64zm60.47 0c-2.38 0-4.36-1.54-5.16-3.64h10.33c-.81 2.11-2.78 3.64-5.17 3.64zm10.34-7.21c-.2.02-.38.11-.59.11H9.42c-.14 0-.27-.05-.4-.06-3.28-.8-5.73-3.75-5.74-7.27h92.26c0 3.46-2.37 6.35-5.56 7.21zm-39.1-10.17V65.85h44.66v10.76H50.88z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
