import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 115 115"
    >
      <path
        d="M54.87 84.74c.66.05 2.17.08 3.48.08.9 0 1.7-.01 2.08-.04 6.09-.49 11.88-2.35 16.74-5.35 4.78-2.96 8.91-7.42 11.95-12.91.35-.64.4-1.43.14-2.22-.3-.92-.96-1.69-1.76-2.05-1.79-.82-3.53.2-4.33 1.64-.18.33-.37.66-.54.96l-.69 1.08c-.21.3-.42.6-.64.9-.75 1.02-1.57 2.01-2.44 2.93-.42.44-.85.87-1.27 1.27l-.31.27c-.22.19-.45.38-.68.57-1 .81-2.06 1.56-3.16 2.25-.41.26-.83.49-1.22.71l-.96.5a33.64 33.64 0 01-5.95 2.14c-.23.06-.46.11-.64.16l-.75.15c-1.26.24-2.59.41-4.05.53-.78.06-3.1.1-3.8.04-1.45-.12-2.78-.29-4.06-.53-.66-.12-1.32-.27-1.97-.43-.29-.07-.59-.15-.86-.22l-.6-.18c-2.38-.77-4.67-1.82-6.8-3.1l-.43-.29c-.22-.15-.44-.3-.65-.45-.48-.34-.95-.7-1.4-1.07-.92-.75-1.86-1.61-2.87-2.64l-.22-.23c-.19-.22-.38-.43-.56-.66-.47-.56-.92-1.15-1.34-1.74-.76-1.06-1.48-2.2-2.19-3.49-.35-.63-1.04-1.09-1.89-1.27-.94-.2-1.94-.02-2.59.47-1.74 1.32-1.57 3.23-.82 4.58 5.57 10.08 16.06 16.68 28.06 17.65z"
        className="cls-1"
      ></path>
      <path
        d="M57.5 0C25.79 0 0 25.79 0 57.5S25.79 115 57.5 115 115 89.21 115 57.5 89.21 0 57.5 0zM5 57.5C5 28.55 28.55 5 57.5 5S110 28.55 110 57.5 86.45 110 57.5 110 5 86.45 5 57.5z"
        className="cls-1"
      ></path>
      <path
        d="M31.29 51.37l.1.24c.07.14.12.25.18.35l.06.08c.11.15.2.28.36.44.16.15.28.25.41.34.21.15.57.35 1.21.47.12.02.29.04.47.04h.42c1.05 0 1.96-.8 2.11-1.82l.02-.11c.02-.09.04-.19.05-.3.17-1.97 1-3.56 2.39-4.6l.32-.23.21-.14c.13-.08.19-.13.23-.15.3-.16.53-.26.75-.33.26-.09.51-.16.73-.22l.25-.06a4.14 4.14 0 011.76-.01l.21.05c.29.07.54.15.79.23.23.08.46.18.72.31.09.04.16.09.17.1l.61.43c1.41 1.05 2.24 2.65 2.41 4.63l.05.3c.16 1.11 1.05 1.92 2.12 1.92h.42c.17 0 .33-.02.41-.04l.27-.04c.36-.08.7-.23 1.01-.45.12-.08.24-.18.35-.29l.69-.63-.08-.16s.03-.06.03-.08l.13-.29c.02-.07.04-.15.09-.32l.09-.66v-.22c-.27-6.09-5.26-10.86-11.35-10.86S31.4 44.02 31.12 50.07l-.02.22.12.83c.02.09.04.16.06.22zM64.16 53.34h.42c.56 0 1.09-.22 1.49-.62.42-.42.66-1.03.66-1.66 0-1.93 1-3.67 2.74-4.79l.19-.13c.08-.06.14-.1.2-.13.28-.15.55-.27.81-.36.25-.09.5-.16.72-.22l.25-.06c.32-.07.58-.1.85-.1h.13c.62.02 1.17.14 1.86.4.19.07.39.16.58.25.25.13.46.28.63.41l.21.16c1.4 1.04 2.24 2.64 2.41 4.63a2.412 2.412 0 002.42 2.21h.18c.81 0 1.58-.32 2.14-.91.57-.58.87-1.35.84-2.17-.21-6.14-5.19-10.95-11.35-10.95s-11.08 4.8-11.35 10.92a2.972 2.972 0 002.97 3.11z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;