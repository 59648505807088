import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FiCheck, FiLoader, FiX } from "react-icons/fi";

function LoadingBtn({
    children,
    onClick,
    onCall,
    textStyle,
    border,
    fullWidth,
    disabled = false,
    hoverColor,
    height = 12,
    secondary = false,
    notime = false
}: {
    children: any;
    onClick?: () => void;
    onCall: () => Promise<void>;
    textStyle?: string;
    border?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    hoverColor?: string;
    height?: number;
    secondary?: boolean;
    notime?: boolean;
}) {
    const [variant, setVariant] = useState("neutral");
    
    const setNeutral = () => {
        setTimeout(() => {
            setVariant("neutral");
        }, 5000);
    };


    const className = `py-2 px-4 text-center cursor-pointer 
	  ${
          textStyle ||
          (secondary ? "text-verdeScuro text-lg" : "text-white text-lg")
      } 
	  ${disabled ? "border-none bg-grigio" : border} 
	  ${(!disabled && hoverColor) || ""} 
	  rounded-lg flex items-center justify-center ${fullWidth && "w-full"}
	  ${height && `h-${height}`}`;

    const classNames =
        variant === "neutral"
            ? secondary
                ? "bg-white"
                : "bg-verdeScuro"
            : variant === "error"
            ? "bg-rosso"
            : variant === "success"
            ? "bg-verde"
            : "bg-verdeScuro pointer-events-none";

    return (
        <motion.button
            disabled={variant !== "neutral"}
            onClick={() => {
                if (disabled) return;
                if (onClick) onClick();
                setVariant("loading");
                onCall()
                    .then(() => {
                        // Chiamata asincrona con promessa per passare allo stato successo
                        setVariant("success");
                        if (!notime) {
                            setNeutral();
                        }
                    })
                    .catch(() => {
                        // In caso di errore, impostare lo stato su "error"
                    
                        setVariant("error");
                        if (!notime) {
                            setNeutral();
                        }
                    });
            }}
            className={`relative rounded-md px-4 py-2 font-medium text-white transition-all ${className} ${
                !disabled && classNames
            }`}
        >
            <motion.span
                animate={{
                    y: variant === "neutral" ? 0 : 6,
                    opacity: variant === "neutral" ? 1 : 0,
                }}
                className="inline-block"
            >
                {children}
            </motion.span>
            <IconOverlay Icon={FiLoader} visible={variant === "loading"} spin />
            <IconOverlay Icon={FiX} visible={variant === "error"} />
            <IconOverlay Icon={FiCheck} visible={variant === "success"} />
        </motion.button>
    );
}

const IconOverlay = ({
    Icon,
    visible,
    spin = false,
}: {
    Icon: any;
    visible: boolean;
    spin?: boolean;
}) => {
    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    initial={{
                        y: -12,
                        opacity: 0,
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                    }}
                    exit={{
                        y: 12,
                        opacity: 0,
                    }}
                    className="absolute inset-0 grid place-content-center"
                >
                    <Icon
                        className={`text-xl duration-300 ${
                            spin && "animate-spin"
                        }`}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default LoadingBtn;
