import React from "react";

function SecondaryButton({
    ref = null,
    children,
    onClick = () => {
        ("");
    },
    textStyle = "",
    bgColor = "",
    fullWidth = false,
    disabled = false,
    hoverColor = "",
    height = 12,
    href = "",
}: {
    ref?: any;
    children: any;
    onClick?: () => void;
    textStyle?: string;
    bgColor?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    hoverColor?: string;
    height?: number;
    href?: string;
}) {
	const className = `py-2 px-8 text-center cursor-pointer
                ${textStyle || "text-verdeScuro text-lg"} 
                ${disabled ? "border-none" : "border border-verdeScuro"} 
                ${disabled ? "bg-grigio" : bgColor || "bg-white"}
				${!disabled && hoverColor || ""} "}
				rounded-lg flex items-center justify-center gap-4 ${fullWidth && 'w-full'}
				${height && `h-${height}`}`
	if (href) {
		return (
			<a
				href={href}
				// target="_blank"
				// rel="noreferrer"
				className={className}>
				{children}
			</a>
		)
	}
	return (
		<button
		ref={ref || null}
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={className}>
			{children}
		</button>
	);
}

export default SecondaryButton;
