import React from 'react'
import {
    FaInstagram,
    FaFacebook,
    FaWhatsapp
} from "react-icons/fa";


const IconLink: React.FC<{ icon: React.ReactNode, text: string, url: string }> = ({ icon, text, url }) => {
    const handleClick = (url: string) => window.open(url, "_blank");

    return (
        <div className='flex items-center space-x-4 text-xl cursor-pointer' onClick={() => handleClick(url)}>
            <div>{icon}</div>
            <div>{text}</div>
        </div>
    )
}

const ContactUs: React.FC = () => {
    const emailAddress = "assistenza@togiftit.com";
    const contacts = [
        {
            icon: <FaInstagram />,
            text: "Instagram",
            url: "https://www.instagram.com/togiftit/"
        },
        {
            icon: <FaFacebook />,
            text: "Facebook",
            url: "https://www.facebook.com/togiftit/"
        },
        {
            icon: <FaWhatsapp />,
            text: "WhatsApp",
            url: "https://wa.me/+393892882018"
        },
        // {
        //     icon: <i className="fa-brands fa-tiktok"></i>,
        //     text: "TikTok",
        //     url: "https://www.tiktok.com/@togiftit"
        // }
    ]

    const titleClass = 'text-2xl font-bold pb-2';

    return (
        <div className='flex flex-col space-y-8'>
            <div className='flex flex-col space-y-2'>
                <h2 className={titleClass}>Hai bisogno di supporto?</h2>
                <p>Puoi inviarci una mail alla casella <a className='underline text-blu' href={"mailto:" + emailAddress}>{emailAddress}</a>, ti risponderemo al massimo entro 24h.</p>
                <p>Grazie &#128516;</p>
            </div>
            <div className='flex flex-col space-y-8'>
                <h2 className={titleClass}>Oppure scrivici su:</h2>
                {contacts.map((contact, index) => <IconLink key={index} icon={contact.icon} text={contact.text} url={contact.url} />)}
            </div>
        </div>
    )
}

export default ContactUs