import React, { useState, useEffect } from "react";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import ChipTabs from "../../Components/Atoms/Tabs/ChipTabs";
import CardRecensioni from "../../Components/Atoms/Card/CardRecensioni";
import useNavbarStore from "../../stores/navbarStore";
import { toast } from "sonner";
import UserAPI from "../../utils/API/UserAPI";
import CardContainer from "../../Components/Molecules/Container/CardContainer/CardContainer";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import useUserStore from "../../stores/userStore";
import HappySadStar from "../../assets/Icons/HappySadStar";

const PaginaRecensioni = () => {
    const tabs = ["Effettuate", "Ricevute"];

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbar = useNavbarStore((state) => state.setFootbarVisible);
    const [recensioniFatte, setRecensioniFatte] = useState([]);
    const [recensioniRicevute, setRecensioniRicevute] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const user = useUserStore((state) => state.user);

    useEffect(() => {
        setNavbar(false);
        setFootbar(false);
        const fetchData = async () => {
            const revFatte = await UserAPI.getMyReviews();
            const revRicevute = await UserAPI.getReviews(user.uid);
            setRecensioniFatte(revFatte);
            console.log(revFatte);

            setRecensioniRicevute(revRicevute);
            setLoaded(true);
        };

        fetchData().catch((error) => toast.error(error.message));
    }, [window.location.pathname]);

    const renderReviews = () => {
        const rev =
            selectedTab === tabs[0] ? recensioniFatte : recensioniRicevute;
        if (rev?.length === 0) {
            return (
                <p className="text-verdeScuro/70 text-center text-lg pt-10">
                    Non hai ancora{" "}
                    {selectedTab.toLowerCase().replace("te", "to")} nessuna
                    recensione
                </p>
            );
        } else {
            return (
                <CardContainer>
                    {rev?.map((review) => {
                        return <CardRecensioni review={review} />;
                    })}
                </CardContainer>
            );
        }
    };

    useEffect(() => {
        setReviews(
            selectedTab === tabs[0] ? recensioniFatte : recensioniRicevute
        );
    }, [selectedTab, recensioniFatte, recensioniRicevute]);

    return (
        <>
            {loaded ? (
                <div className="flex flex-col mt-5 items-center gap-[2vh]  min-sh-[80svh]">
                    <div className="flex justify-between items-center w-full my-[1vh]">
                        <div className="absolute left-0">
                            <Indietro />
                        </div>
                        <h1 className="text-3xl text-center mx-auto font-bold">
                            Recensioni
                        </h1>
                    </div>
                    <div className="">
                        <HappySadStar happy={reviews?.length} />
                    </div>
                    <div className="flex flex-col items-center font-semibold">
                        <p>Recensioni {selectedTab.toLowerCase()}</p>
                        <p>{reviews?.length}</p>
                    </div>
                    <div className="w-[90%] md:w-[30%]">
                        <ChipTabs
                            selected={selectedTab}
                            setSelected={setSelectedTab}
                            tabs={tabs}
                        />
                    </div>

                    <div>{renderReviews()}</div>
                </div>
            ) : (
                <div>
                    <Caricamento />
                </div>
            )}
        </>
    );
};

export default PaginaRecensioni;
