import * as React from "react";

const Location = ({ w = 24, full = true, black = false }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={w}
		height={w}
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill={full ? "#333" : black ? "#000" : "#666"}
			d="M12 2a8 8 0 0 0-8 8c0 5.4 7.05 11.5 7.35 11.76a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 10a8 8 0 0 0-8-8m0 17.65c-2.13-2-6-6.31-6-9.65a6 6 0 1 1 12 0c0 3.34-3.87 7.66-6 9.65M12 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8m0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4"
		></path>
	</svg>
);

export default Location;
