import React from "react";
import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";

function Indietro({ color = "verdeScuro", onBeforeClick }:{
    color?: string;
    onBeforeClick?: () => void;
}) {
    return (
        <button
            onClick={
                () => {
                    if (onBeforeClick) {
                        onBeforeClick();
                    }
                    window.history.back();
                }
            }
            className={` text-${color} rounded-full  px-2  col-span-9 flex items-center `}
        >
            <Iconfrecciagiu className={"w-8 rotate-0 "} /> <span className="hidden md:inline-block">Indietro</span>
        </button>
    );
}

export default Indietro;
