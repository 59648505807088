import * as React from "react";

const Search = ({selected}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path   
      stroke={selected ? "#8FBB3B" : "#333"}
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10.5 19a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z"
    ></path>
    <path
      stroke={selected ? "#8FBB3B" : "#333"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.329 7.172A3.99 3.99 0 0 0 10.5 6a3.99 3.99 0 0 0-2.828 1.172M16.61 16.611l4.243 4.243"
    ></path>
  </svg>
);

export default Search;
