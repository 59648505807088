import { Player } from "@lottiefiles/react-lottie-player";

const Caricamento = () => {
    return (
        <div className="p-10 md:relative  bg-white left-0 md:top-24 z-[999] h-full w-full">
            <Player
                autoplay
                loop
                src={require("../../../assets/animation/loading.json")}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-32 md:h-96 p-10 mt-10"
            ></Player>
        </div>
    );
};

export default Caricamento;