import * as React from "react";

const HelpGuy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="125"
    height="114"
    fill="none"
    viewBox="0 0 83 76"
  >
    <g clipPath="url(#clip0_348_4023)">
      <path
        fill="#585858"
        d="M24.585 23.586c1.423 0 2.576-1.173 2.576-2.62v-1.31c0-7.961 6.341-14.415 14.164-14.415s14.164 6.454 14.164 14.414v1.31c0 1.448 1.153 2.621 2.575 2.621s2.576-1.173 2.576-2.62v-1.31C60.64 8.798 51.993 0 41.325 0S22.01 8.799 22.01 19.655v1.31c0 1.448 1.153 2.621 2.575 2.621"
      ></path>
      <path
        fill="#2D302B"
        stroke="#6D6D6D"
        strokeWidth="0.5"
        d="M56.777 17.284h-3.613v12.604h3.613c3.413 0 6.188-2.817 6.188-6.302s-2.775-6.302-6.188-6.302Z"
      ></path>
      <path
        fill="#2D302B"
        stroke="#5A5B5A"
        strokeWidth="0.5"
        d="M25.873 29.888h3.613V17.284h-3.613c-3.414 0-6.188 2.817-6.188 6.302s2.774 6.302 6.188 6.302Z"
      ></path>
      <path
        fill="#9CC70D"
        d="M41.325 36.69c-7.823 0-14.164-6.453-14.164-14.414S33.502 7.862 41.325 7.862 55.49 14.316 55.49 22.276 49.148 36.69 41.325 36.69"
      ></path>
      <path
        fill="#9CC70D"
        d="M2.18 34.65c2.274-1.92 5.504-1.4 7.21 1.16l7.726 11.593 6.76-8.492q.335-.413.708-.783c1.082-1.045 2.44-1.657 3.863-1.739h25.754l3.862 31.882H24.585l1.546-12.71-5.627 5.218-.335.318-.245.218c-2.27 1.9-5.479 1.384-7.185-1.16L1.149 42.766c-1.706-2.56-1.245-6.195 1.03-8.115"
      ></path>
      <path
        fill="#fff"
        d="M49.905 19.843a1 1 0 0 0 .007-.085c.006-.067.008-.094.006-.122l-.212-.015.21-.029c-.086-1.99-1.693-3.553-3.66-3.553-1.965 0-3.572 1.562-3.658 3.558v.013c-.002.054 0 .08 0 .107.004.07.006.098.01.126l.016.066a1 1 0 0 0 .038.133l.022.048a1 1 0 0 0 .068.131l.026.035a.97.97 0 0 0 .61.41l.045.007a1 1 0 0 0 .16.016h.136c.367 0 .674-.282.727-.669a1 1 0 0 0 .017-.098c.054-.631.312-1.133.745-1.461l.069-.052.114-.081c.012-.009.034-.022.055-.035q.105-.057.224-.1.116-.042.236-.073l.07-.017q.141-.03.256-.03h.028q.113 0 .25.028l.059.013a2.109 2.109 0 0 1 .479.177q.029.016.062.039c.021.018.053.037.083.057l.093.07c.433.327.69.83.742 1.45.005.048.013.085.02.122a.74.74 0 0 0 .725.653h.135a1 1 0 0 0 .17-.017 1 1 0 0 0 .74-.581l.021-.046a1 1 0 0 0 .033-.11c.013-.047.019-.07.023-.091zm-16.814 4.683c1.058 3.8 4.504 6.455 8.38 6.455s7.32-2.653 8.38-6.455a.86.86 0 0 0-.141-.754.86.86 0 0 0-.695-.353H33.928a.86.86 0 0 0-.696.353.86.86 0 0 0-.141.754m.22-4.148c.192.201.447.31.72.31h.058c.431 0 .783-.33.822-.766.053-.629.31-1.131.744-1.461l.047-.035c.067-.05.134-.103.209-.142q.091-.048.188-.085c.213-.08.391-.12.567-.125h.043c.077.003.157.014.251.031l.058.013c.097.024.174.048.253.077q.128.044.245.109.03.017.06.041.032.025.066.046c.537.352.844.898.844 1.5 0 .221.082.43.225.58.136.139.318.215.513.215h.135a.99.99 0 0 0 .724-.314c.19-.203.287-.467.276-.747-.087-2.011-1.697-3.586-3.663-3.586-1.987 0-3.597 1.579-3.663 3.597-.009.28.09.542.28.742z"
      ></path>
      <path
        fill="#2D302B"
        stroke="#5D5D5D"
        strokeWidth="0.5"
        d="M76.41 27.768H42.93c-1.99 0-3.612 1.643-3.612 3.68v26.208c0 2.037 1.621 3.68 3.613 3.68H76.41c1.99 0 3.612-1.643 3.612-3.68V31.449c0-2.038-1.621-3.681-3.613-3.681Z"
      ></path>
      <path
        fill="#585858"
        d="M53.232 65.517h12.876l-1.133-8.045a1.295 1.295 0 0 0-1.287-1.127h-8.061a1.295 1.295 0 0 0-1.288 1.127z"
      ></path>
      <path
        fill="#2D302B"
        stroke="#5D5D5D"
        strokeWidth="0.5"
        d="M69.653 64.658H49.05c-.245 0-.498.15-.702.455-.202.304-.335.737-.335 1.227s.133.924.335 1.228c.204.305.457.455.702.455h20.603c.245 0 .498-.15.702-.455.202-.304.335-.737.335-1.228 0-.49-.133-.923-.335-1.227-.204-.305-.457-.455-.702-.455Z"
      ></path>
      <path
        fill="#83612C"
        d="M2.695 68.139h77.259c1.422 0 2.575 1.173 2.575 2.62v2.621c0 1.447-1.153 2.62-2.575 2.62H2.695C1.273 76 .12 74.828.12 73.38v-2.62c0-1.448 1.153-2.621 2.575-2.621"
      ></path>
      <path
        fill="#FF0285"
        d="M54.259 40.8v.004c.367-1.079 1.204-1.642 2.189-1.771q.391-.052.805-.018c.247.022.485.079.708.155.97.33 1.674 1.049 1.691 1.044l.01.005c.074-.055 1.037-.994 2.254-1.154q.076-.01.153-.02c1.32-.102 2.526.455 2.972 1.82.446 1.363.088 3.427-1.717 5.263a14.5 14.5 0 0 1-1.942 1.645h-.006c-1.014.716-1.771 1.059-1.771 1.059l-.013-.004s-1.9-.89-3.678-2.746c-1.784-1.858-2.116-3.924-1.655-5.285z"
      ></path>
      <mask id="path-12-inside-1_348_4023" fill="#fff">
        <path d="M31.077 38.001h2.521c1.423 0 2.576-1.173 2.576-2.62 0-1.449-1.153-2.621-2.575-2.621h-2.423"></path>
      </mask>
      <path
        fill="#2D302B"
        d="M31.077 38.001h2.521c1.423 0 2.576-1.173 2.576-2.62 0-1.449-1.153-2.621-2.575-2.621h-2.423"
      ></path>
      <path
        fill="#494B49"
        d="M31.077 37.501h2.521v1h-2.521zm2.521 0c1.139 0 2.076-.94 2.076-2.12h1c0 1.715-1.368 3.12-3.075 3.12zm2.076-2.12c0-1.18-.937-2.121-2.075-2.121v-1c1.707 0 3.075 1.405 3.075 3.12zm-2.075-2.121h-2.423v-1h2.423z"
        mask="url(#path-12-inside-1_348_4023)"
      ></path>
      <path
        fill="#707070"
        d="M31.177 32.758H27.16v-2.62h-1.287a6.28 6.28 0 0 1-3.863-1.31v6.55c0 1.449 1.152 2.622 2.575 2.622h6.492"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_348_4023">
        <path fill="#fff" d="M82.53 0H.12v76h82.41z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default HelpGuy;
