import * as React from "react";

const DeleteProfile = ({ w=24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={w}
		height={w}
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			stroke="#333"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M12 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8M21 22a9 9 0 1 0-18 0M10 18l4 4M14 18l-4 4"
		></path>
	</svg>
);

export default DeleteProfile;
