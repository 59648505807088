import React from 'react'
import useEditingGiftStore from '../../../stores/editingGiftStore';
import SectionTitle from './SectionTitle';
import GiftUtils from '../../../utils/GiftUtils';
import RadioGroup from '../../../Components/Molecules/RadioButtons/RadioGroup';

const DisponibilitaRegalo = () => {
    const disponibilita = useEditingGiftStore((state) => state.availability);
    const setDisponibilita = useEditingGiftStore((state) => state.setAvailability);

    const options = Object.values(GiftUtils.getAvailability()).map(
        (availability) => {
            return {
                label: availability.text,
                value: availability.id,
            }
        }
    );

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setDisponibilita(value);
    };

    return (
        <div className='w-full flex flex-col items-start gap-4 mt-5 pb-5'>
            <SectionTitle
                title="Disponibilità di consegna"
                descrizione="Seleziona una o più opzioni di disponibilità per consegnare il regalo"
                isMandatory
                isValid={Boolean(disponibilita)}
            />
            <div className='w-full'>
                <RadioGroup
                    items={options}
                    radioGroup="availability"
                    onChange={handleRadioChange}
                    selectedItem={disponibilita}
                />
            </div>

        </div>
    )
}

export default DisponibilitaRegalo