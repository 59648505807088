import React from 'react'

const StepProgress = ({ stepLenght, currentStep, progressive, containerStyle }) => {
    const defaultContainerStyle = 'w-full justify-between space-x-4'
    return (
        <div className={`flex ${containerStyle || defaultContainerStyle}`}>
            {Array.from({ length: stepLenght }).map((_, i) => {
                let shouldFill = progressive ? i <= currentStep : i === currentStep;
                return (
                    <div key={i} className={`flex-1 h-2 bg-grigino rounded-full ${shouldFill ? 'bg-verde' : ''}`} />
                )
            })}
        </div>
    )
}

export default StepProgress