import React, { useEffect, useState } from "react";
import Punti from "../../assets/Punti";
import {
	Availability,
	CreatedAt,
	FullScreenImage,
	GiftCategory,
	GiftDescription,
	GiftStatus,
	Location,
	Separator,
} from "../../utils/InfoRegalo";
import Mappa from "../../Components/Atoms/Mappa/Mappa";
import GiftAPI from "../../utils/API/GiftAPI";
import UserAPI from "../../utils/API/UserAPI";
import useUserStore from "../../stores/userStore";
import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import Recensione from "../../assets/Icons/Recensione";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import Prenotati from "../../Components/Molecules/Prenotati/Prenotati";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import useNavbarStore from "../../stores/navbarStore";
import { useNavigate } from "react-router-dom";
import GiftNotFound from "./GiftNotFound";

/**
 * @param {Object} props
 * @param {import("../../utils/API/UserAPI").User} props.user
 * @returns
 */

function ProdottoApertoP() {
	const user = useUserStore((state) => state.user);
	const slug = window.location.href.split("/").pop();
	const [imgCount, setImgCount] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
	const setFootbar = useNavbarStore((state) => state.setFootbarVisible);
	const [notFound, setNotFound] = useState(false);
	/**
	 * @type {[import("../../utils/API/GiftAPI").Gift, function]}
	 */
	const [gift, setGift] = useState(null);
	const [img, setImg] = useState([]);
	/**
	 * @type {[import("../../utils/API/UserAPI").User, function]}
	 */
	const [owner, setOwner] = useState([]);
	const [stars, setStars] = useState(0);
	const [isOpenImg, setIsOpenImg] = useState(false);
	const navigate = useNavigate();

	const handleLoad = async () => {
		try {
			const _gift = await GiftAPI.getById(slug);
			if (_gift.owner === user?.uid) {
				navigate("/dashboard/" + slug);
			}

			console.log(_gift);

			setGift(_gift);
			setImg(_gift.images.filter((item) => item && item.trim().length > 0));

			const owner = await UserAPI.getById(_gift.owner);

			setOwner(owner);

			const ownerId = owner.uid;

			const reviews = await UserAPI.getReviews(ownerId);

			const ratingAverage =
				reviews?.length > 0
					? (
							reviews.reduce(
								(accumulator, current) => accumulator + current.rating,
								0
							) / reviews.length
					  ).toFixed(1)
					: "0,0";
			console.log(reviews);

			setStars(ratingAverage);

			setLoaded(true);
		} catch (e) {
			if (
				e.response &&
				(e.response.status === 404 || e.response.status === 400)
			) {
				setNotFound(true);
			}
			//TODO: Display generic error
		}
	};

	const handleClick = (segno) => {
		console.log(imgCount, img.length);
		if (segno === "+") {
			if (imgCount === img.length - 1) {
				setImgCount(0);
			} else {
				setImgCount(imgCount + 1);
			}
		} else {
			if (imgCount === 0) {
				setImgCount(img.length - 1);
			} else {
				setImgCount(imgCount - 1);
			}
		}
	};

	useEffect(() => {
		setNavbar(false);
		setFootbar(false);
		if (!loaded) {
			handleLoad();
			//fetchLiked();
		}
	}, [window.location.pathname]);

	if (notFound) {
		return <GiftNotFound />;
	}

	return (
		<div>
			{loaded ? (
				<div className="md:px-32 mb-10 relative">
					<div className="md:hidden flex absolute top-5 left-0 z-10">
						<Indietro color={"white"} />
					</div>
					<div className="md:flex hidden absolute top-5 left-0 z-10">
						<Indietro color={"verdeScuro"} />
					</div>
					<div className="flex w-full md:mt-5 justify-center">
						<div className="flex max-h-[60vh] w-full">
							<div className="flex w-full md:flex-row flex-col-reverse relative">
								<div className=" md:px-0 px-2">
									{img.length > 1 ? (
										<div className="flex  md:flex-col md:w-20 w-14 shrink-0  justify-start items-start  md:mt-0 mt-2 md:mr-3 gap-3">
											{img.map((item, index) => (
												<img
													key={index}
													className={
														"rounded-lg aspect-square object-cover cursor-pointer "
													}
													onClick={() => setImgCount(index)}
													src={item}
													alt={gift.name || "regalo"}
												/>
											))}
										</div>
									) : (
										<div className="pl-5"></div>
									)}
								</div>
								<div className="bg-grigino rounded-lg w-full">
									<div className="grow md:mx-0 relative">
										<div
											onClick={() => setIsOpenImg(true)}
											className="absolute md:hidden w-full h-full bg-gradient-to-t from-transparent via-transparent to-verdeScuro/70"
										></div>

										<div onClick={() => setIsOpenImg(true)}>
											<img
												className=" md:rounded-lg  h-[50vh] w-full bg-opacity-5 object-contain cursor-pointer"
												src={img[imgCount]}
												alt={gift.name || "regalo"}
											/>
										</div>
										<FullScreenImage
											isOpen={isOpenImg}
											setIsOpen={setIsOpenImg}
											img={img}
											count={imgCount}
											handleClick={handleClick}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex px-3 md:px-0 md:flex-row flex-col mt-10 justify-between items-start">
						<div className="flex flex-col pr-4 pb-10 md:w-[70vw] w-full">
							<div className="flex items-center justify-between w-full">
								{" "}
								<div className="">
									<p className="text-3xl font-bold">{gift.name}</p>
								</div>
								<div className="flex h-14 space-x-3 items-center justify-start ">
									<p className="text-3xl font-semibold">100</p>
									<div className="flex h-10 w-10 object-contain">
										<Punti />
									</div>
								</div>
							</div>
							<div className="flex gap-4 mt-5">
								<GiftStatus status={gift.status} />
								<Location city={gift.location.city} />
							</div>
							<div className="">
								<GiftDescription descrizione={gift.description} />
							</div>
							<Separator />
							<div className="">
								<p className="text-2xl font-semibold">Informazioni prodotto</p>
								<div className="flex flex-col gap-5 mt-8">
									<div className="grid md:grid-cols-2 gap-5">
										<div className="flex items-center gap-2">
											<GiftCategory category={gift.category} />
										</div>
										<div className="flex items-center gap-2">
											<CreatedAt createdAt={gift.createdAt} />
										</div>
									</div>
									<div className="grid grid-cols-2">
										<div className="flex items-center col-span-full gap-2">
											<Availability availability={gift.availability} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="md:w-[30vw] w-full ">
							<div className="rounded-lg border-4 p-3 border-grigino w-full">
								<div className="flex items-center justify-between">
									<div
										onClick={() =>
											(window.location.href = "/profilo/" + owner.uid)
										}
										className="flex cursor-pointer items-center gap-3"
									>
										<ProfilePic userId={owner.uid} w={12} />
										<div className="">
											<p className="font-light">Pubblicato da</p>
											<p className="font-semibold text-lg underline">
												{owner.name} {owner.surname}
											</p>
										</div>
									</div>
									<div className="relative flex justify-center gap-2 items-center">
										<p className="text-sm">
											<nobr>{stars}</nobr>{" "}
										</p>
										<div className="w-8 ">
											<Recensione yellow />
										</div>
									</div>
								</div>
								<Separator />
								<div className="flex flex-col gap-5">
									<div className="flex items-center gap-3">
										{" "}
										
										<div className="flex md:flex-row flex-col md:justify-start justify-center w-full items-center md:gap-3">
											<p className="font-light text-lg">
												{gift.isAssigned ? "Questo regalo" : "Prenotato da:"}
											</p>
											{!gift.isAssigned ? (
												<p className="font-semibold text-lg underline">
													{gift.bookingsLength}/20
												</p>
											) : (
												<p className="font-semibold text-lg underline">
													{gift.isDelivered
														? "Ha trovato una seconda casa"
														: gift.isAssignedToMe
														? "Ti è stato assegnato!"
														: "È stato assegnato a qualcun'altro"}
												</p>
											)}
										</div>
									</div>
									<Prenotati gift={gift} />
								</div>
							</div>
							<div className="mt-5 z-10 relative rounded-lg">
								<Mappa lat={gift.location.lat} lng={gift.location.lng} />
							</div>
						</div>
					</div>
				</div>
			) : (
				<Caricamento />
			)}
		</div>
	);
}

export default ProdottoApertoP;
