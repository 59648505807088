import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 194.99 224.56"
      className="fill-rosso"
    >
      <path
        d="M187.55 51.46h-43.88c2.77-2.7 5.52-5.85 7.56-8.91 5.15-7.76 6.95-18 3.44-26.77C149.95 4 138.34-1.8 125.99.54c-11.2 2.13-22.74 13.36-28.19 23.02-.16.29-.3.59-.46.89C92.33 15 83.41 5.48 73.15 1.71c-11.43-4.19-24.5-.64-30.93 9.99-5.24 8.66-4.65 19.28-.14 28.08 2.05 3.98 5.51 8.27 8.93 11.67H7.44C3.33 51.45 0 54.78 0 58.89v30.68c0 4.11 3.33 7.44 7.44 7.44h.27v111.42c0 8.9 7.21 16.11 16.11 16.11h147.34c8.9 0 16.11-7.21 16.11-16.11V97.01h.27c4.11 0 7.44-3.33 7.44-7.44V58.89c0-4.11-3.33-7.44-7.44-7.44zM107.92 31.2c2.62-6.98 8.23-14.04 15.06-17.69 6.74-3.6 15.71-3.06 19.95 3.92 1.85 3.05 2.51 6.49 2.16 10.02-.05.21-.18 1.17-.22 1.33-.17.79-.37 1.57-.61 2.34-.57 1.82-.81 2.33-1.8 4.06-3.87 6.77-11.3 13.41-18.89 16.28h-20.19c-.01-.1-.02-.2-.04-.3.79-6.37 2.23-13.68 4.59-19.96zm-57.78-2.13c-.4-1.76-.42-1.87-.39-3.94.03-1.91.43-3.84 1.17-5.62 3.14-7.59 11.06-9.57 18.4-7.15 7.52 2.48 13.74 9.93 16.93 16.95 2.86 6.29 4.89 14.68 5.75 21.61l-.06.55H71.06c-6.52-2.82-13.52-8.81-17.19-14.09-2-2.88-2.98-4.94-3.73-8.3zm98.9 145l-12.79 14.37c-.55.61-1.49.67-2.1.12l-38.18-33.97-38.18 33.97c-.61.55-1.55.49-2.1-.12l-11.8-13.26c-.55-.61-.49-1.55.12-2.1l36.36-32.35-36.54-32.51c-.61-.55-.67-1.49-.12-2.1l11.8-13.26c.55-.61 1.49-.67 2.1-.12l38.35 34.12 38.35-34.12c.61-.55 1.55-.49 2.1.12l11.8 13.26c.55.61.49 1.55-.12 2.1l-36.54 32.51 37.48 33.34z"
        className="cls-1"
        data-name="layer 1"
      ></path>
    </svg>
  );
}

export default Icon;