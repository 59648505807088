import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Livello 2"
            viewBox="0 0 209.57 238.33"
        >
            <path
                fill="CurrentColor"
                d="M199.43 51.77h-43.84c2.1-2.25 4.1-4.67 5.67-7.05 5.41-8.15 7.31-18.91 3.62-28.13C159.92 4.21 147.71-1.89 134.74.58c-11.77 2.24-23.9 14.04-29.62 24.19-.17.3-.31.63-.48.94C99.37 15.78 90 5.77 79.22 1.81c-12.02-4.41-25.75-.67-32.5 10.5-5.5 9.1-4.89 20.26-.15 29.51 1.71 3.33 4.37 6.85 7.2 9.95H10.14C4.55 51.77 0 56.32 0 61.91v32.25c0 4.83 3.39 8.88 8.11 9.89v115.02c0 10.62 8.64 19.26 19.26 19.26h154.84c10.62 0 19.25-8.64 19.25-19.26V104.09c4.62-.94 8.11-5.04 8.11-9.93V61.91c0-5.59-4.55-10.14-10.14-10.14zm-83.68-18.98c2.75-7.33 8.65-14.76 15.83-18.59 7.08-3.79 16.51-3.22 20.96 4.12 1.94 3.21 2.64 6.82 2.27 10.53-.05.22-.2 1.22-.23 1.4-.18.83-.39 1.65-.64 2.46-.6 1.92-.85 2.45-1.89 4.27-3.24 5.68-8.87 11.23-15.06 14.79h-25.76c.87-6.21 2.3-13.02 4.53-18.98zm-61.14-6.38c.03-2.01.45-4.03 1.23-5.91 3.31-7.97 11.62-10.06 19.34-7.52 7.9 2.6 14.44 10.44 17.79 17.81 2.77 6.1 4.79 14.06 5.8 20.97H72.61c-5.42-3.31-10.63-8.12-13.66-12.49-2.11-3.03-3.13-5.19-3.92-8.72-.42-1.85-.44-1.96-.41-4.15zm-16.43 84.74c-.74-1.82-.48-3.88.24-5.65.71-1.76 2.54-3.31 4.29-3.94 1.55-.55 4.18-.69 5.65.24l5.46 3.45 23.78 15.03c4.97 3.14 9.95 6.29 14.92 9.43 1.81 1.14 3.13 2.29 3.94 4.29.74 1.82.48 3.88-.24 5.65-.71 1.76-2.54 3.31-4.29 3.94-1.55.55-4.18.69-5.65-.24l-5.46-3.45-23.78-15.03c-4.97-3.14-9.95-6.29-14.92-9.43-1.81-1.14-3.13-2.29-3.94-4.29zm128.29 94.67a6.902 6.902 0 01-5.73 3.06 6.87 6.87 0 01-6.38-4.29c-8.27-20.16-27.65-33.18-49.38-33.18S63.87 184.44 55.6 204.6a6.884 6.884 0 01-6.38 4.29c-2.3 0-4.45-1.14-5.73-3.06a6.908 6.908 0 01-.65-6.49c10.41-25.35 34.8-41.72 62.15-41.72s51.74 16.38 62.14 41.72c.88 2.14.63 4.57-.65 6.49zm1.74-91.02l-5.46 3.45-23.78 15.03c-4.97 3.14-9.95 6.29-14.92 9.43-3.55 2.25-7.9 1.43-10.43-1.92-2.38-3.14-1.4-8.34 1.92-10.43l5.46-3.45 23.78-15.03c4.97-3.14 9.95-6.29 14.92-9.43 3.55-2.25 7.9-1.43 10.43 1.92 2.38 3.14 1.4 8.34-1.92 10.43z"
                className="cls-1"
                data-name="layer 1"
            ></path>
        </svg>
    );
}

export default Icon;
