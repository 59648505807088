import React from "react";
import Heart from "../../../assets/Icons/Heart";

export const ToggleFavourite = ({
    isSelected,
    onClick,
}: {
    isSelected: boolean;
    onClick: () => void;
}) => {
    return (
        <button
            onClick={onClick}
            className={`aspect-square flex p-2 justify-center items-center h-full min-w-4 md:min-w-8 rounded-lg text-white text-4xl font-medium `}
        >
            <Heart w={30} selected={isSelected} />
        </button>
    );
};
