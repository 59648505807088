import React, { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import useEditingGiftStore from "../../../stores/editingGiftStore";
import useUserStore from "../../../stores/userStore";
import SectionTitle from "./SectionTitle";
import { toast } from "sonner";
import { FiX } from "react-icons/fi";

const PosizioneRegalo = () => {
    // const [place, setPlace] = useState(null);
    const user = useUserStore((state) => state.user);
    const place = useEditingGiftStore((state) => state.location);
    const setPlace = useEditingGiftStore((state) => state.setLocation);
    const isModifying = useEditingGiftStore((state) => state.createdAt);
    const gift = useEditingGiftStore((state) => state.gift);
    const placeRef = React.useRef(null);
    const [isValid, setIsValid] = useState(Boolean(place));
    const [inputValue, setInputValue] = useState( "");

    //TODO: fix useEffect è bruttissimo ma funziona
    useEffect(() => {
        handleLoad();
        setTimeout(() => {
            handleLoad();
        }, 500);
    }, []);
    useEffect(() => {
        setIsValid(Boolean(place));
    }, [place]);

    const handleLoad = () => {
        setPlace(gift? gift?.location : user?.location);
        setInputValue(gift ? gift?.location?.address : user?.location?.address);
        if (!isModifying && user?.location) {
            setIsValid(true);
        }
    };

    const handlePlaceSelected = async (e) => {
        let streetNumber = "";
        let city = "";
        let country = "";

        e.address_components.forEach((component) => {
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        if (!streetNumber) {
            toast.error("Manca il numero civico");
            return;
        }

        const newLocation = {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng(),
            address: e.formatted_address,
            city: city,
            country: country,
        };
        console.log("newLocation", newLocation);
        setPlace(newLocation);
        setInputValue(newLocation.address)
    };

    return (
        <div className="w-full flex flex-col items-start gap-4 mt-5 pb-5">
            <SectionTitle
                title="Località"
                descrizione="Non mostreremo la tua esatta posizione ma un raggio di 5km casuale intorno a te"
                isMandatory
                isValid={isValid}
            />
            <div className="w-full relative">
                <ReactGoogleAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_API_KEY}
                    ref={placeRef}
                    style={{ width: "100%" }}
                    name="indirizzo"
                    value={inputValue}
                    defaultValue={
                        isModifying ? place?.address : (gift ? gift.location.address : user?.location?.address)
                    }
                    className={`rounded-lg bg-background text-verdeScuro block flex-1 min-w-0 w-full text-lg pr-10 border-gray-300 p-2.5`}
                    onPlaceSelected={async (e) => {
                        try {
                            await handlePlaceSelected(e);
                        } catch (error) {
                            console.log(error);
                            setPlace(null);
                        }
                    }}
                    placeholder="Indirizzo..."
                    language="it"
                    options={{
                        types: ["address"],
                        componentRestrictions: {
                            country: "it",
                        },
                    }}
                    onChange={() => {
                        setInputValue(placeRef.current.value);
                        setPlace(null);
                    }}
                />
                {inputValue && (
                    <FiX
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-verdeScuro text-xl"
                        onClick={() => {
                            setInputValue("");
                            setPlace(null);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default PosizioneRegalo;
