import * as React from "react";

const Donazioni = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="79"
		height="90"
		fill="none"
		viewBox="0 0 79 90"
	>
		<path
			fill="#E8A0C7"
			d="M70.637 28.376c.24-1.73-.53-3.1-1.81-3.97a6 6 0 0 0-1.11-.59c-1.91-.77-4.13-.04-4.16-.05h-.02s-.76-2.24-2.53-3.31c-1.77-1.08-3.83-1.17-5.4.39-1.57 1.55-2.51 4.63-1.27 8.41.46 1.4 1.02 2.63 1.56 3.62.91 1.7 1.73 2.7 1.73 2.7h.02s3.26.13 7.03-1.15 5.68-3.87 5.98-6.06zm-13.59-6.47a1.77 1.77 0 0 1 2.39-.79c.88.44 1.24 1.51.79 2.39-.44.88-1.52 1.24-2.39.79a1.77 1.77 0 0 1-.79-2.39"
		></path>
		<path
			fill="#F1BBDC"
			d="M68.707 27.035c-.3.84-.81 1.59-1.39 2.26-.84.98-1.84 1.83-3 2.41-1.73.86-3.7 1.07-5.63 1.25-.93.09-1.89.17-2.78-.12l-.04.02c-.53-.99-1.1-2.22-1.56-3.62-1.23-3.79-.29-6.87 1.27-8.41 1.57-1.55 3.64-1.46 5.4-.39 1.77 1.08 2.5 3.29 2.53 3.31h.02s2.25-.71 4.16.06c.4.16.77.36 1.11.59v.02c.26.84.18 1.78-.12 2.62h.03"
		></path>
		<path
			fill="#fff"
			d="M60.357 23.135c.24-.96-.35-1.92-1.3-2.16-.96-.24-1.92.35-2.16 1.3-.24.96.35 1.92 1.3 2.16.96.24 1.92-.35 2.16-1.3"
		></path>
		<path
			fill="#80BA0F"
			d="M22.167 26.506c-3.82-.75-9.34-1-14.05.91-.14.05-.26.11-.39.16 0 0 0-.01-.01-.02-2.46 1.07-4.37 2.52-5.68 4.33-.08.11-.14.23-.22.34-.68 1-1.19 2.09-1.5 3.27-.31 1.14-.39 2.15-.25 3.13.33 2.34 2.11 4.44 4.53 5.36 2.43.92 4.89.53 6.51.05 1.96-.6 3.7-1.59 5.32-2.55 3.85-2.32 7.21-4.64 10.23-7.03-1.3-.71-2.33-1.89-2.43-3.7-.05.01-.11.02-.16.04.04.32.12.66.21.97-2.85 2.26-6.03 4.44-9.68 6.64-1.42.87-2.93 1.72-4.5 2.2-1.55.47-3.06.48-4.22.04-1.2-.47-2.11-1.48-2.27-2.55-.07-.47-.01-1.03.16-1.67.24-.87.63-1.7 1.16-2.43.1-.14.22-.27.33-.41.24-.28.49-.56.78-.82.9-.8 2.04-1.48 3.41-2.04.4-.16.81-.31 1.23-.44 5.24-1.58 11.27-.32 13.29.38-.21-1.37.69-2.61 1.43-3.35l-.9-.22c-.66-.21-1.45-.41-2.32-.58z"
		></path>
		<path
			fill="#799F26"
			d="M43.507 9.165h.03c-.65-2.51-2.54-4.5-4.82-5.1-.95-.24-1.96-.27-3.13-.09-.17.03-.35.05-.52.09h.03-.05c-1.14.23-2.22.63-3.22 1.2a11.7 11.7 0 0 0-2.83 2.27c-.85.92-1.63 2.02-2.31 3.27-2.98 5.5-2.85 12.6-2.15 16.27h-.03l.33.33c.73-.73 1.86-1.07 3.22-.87-.49-2.6-.92-9.1 1.75-14.03.69-1.3 1.51-2.35 2.4-3.17q.66-.585 1.38-.99c.79-.44 1.64-.75 2.54-.88.67-.11 1.23-.09 1.68.03 1.04.27 1.95 1.3 2.28 2.54.11.39.15.81.15 1.26 0 .92-.23 1.94-.65 2.94-.64 1.51-1.68 2.91-2.69 4.22-2.47 3.23-4.88 6.03-7.32 8.53 1.43.64 1.44 1.41 1.59 2.62 2.69-2.71 5.84-5.42 8.54-8.95 1.13-1.46 2.28-3.05 3.08-4.9l.06-.12c.68-1.56 1.34-3.95.68-6.48z"
		></path>
		<path
			fill="#80BA0F"
			d="M29.837 26.625c-.55-.16-.66-.2-1.57-.37-2.73-.42-4.74 1.66-4.31 4.4l.1.03v.1c.05-.01.11-.02.16-.04q0-.099 0 0c2.45-.56 4.9-1.41 7.36-.73.03-.03.21-1.02.24-1.05-.15-1.22-.54-1.7-1.97-2.34z"
		></path>
		<path
			fill="#F1BBDC"
			d="M69.637 46.876c-.35-2.94-2.06-4.84-4.31-5.68a8 8 0 0 0-1.88-.47c-3.15-.39-6.13 1.83-6.19 1.82h-.03s-2.06-3.31-5.12-4.27c-3.05-.96-6.15-.16-7.83 3.1-1.52 2.93-1.76 7.63.64 12.64h23.18c1.34-2.5 1.79-5.04 1.54-7.15z"
		></path>
		<path
			fill="#E8A0C7"
			d="M65.117 49.906c.57-1.36 1.02-2.82 1.12-4.34.09-1.51-.17-3.07-.92-4.36v-.03c2.26.85 3.97 2.74 4.31 5.68.25 2.11-.19 4.64-1.54 7.15h-5.36c.98-1.23 1.75-2.62 2.38-4.11zM64.037.996c-.26-.08-.55-.13-.84-.15-1.4-.09-2.68.86-2.69.86 0 0-.97-1.28-2.34-1.6s-2.72.07-3.41 1.41-.63 3.53.98 5.71c.59.8 1.22 1.48 1.78 2.01.95.9 1.7 1.38 1.7 1.38h.02s2.14-.62 4.29-2.25c2.17-1.63 2.84-3.71 2.57-5.19-.22-1.18-1.01-1.89-2.02-2.18h-.04m-7.13 2.28c-.67-.1-1.13-.72-1.02-1.37.1-.67.72-1.13 1.37-1.03.67.1 1.12.72 1.02 1.37-.1.67-.72 1.13-1.37 1.03M78.177 15.585a3 3 0 0 0-.54-.45c-.95-.65-2.24-.5-2.25-.51 0 0-.16-1.31-1-2.1-.83-.78-1.95-1.08-2.98-.41-1.04.67-1.9 2.23-1.67 4.43.09.81.25 1.55.42 2.16.3 1.03.63 1.67.63 1.67s1.75.45 3.96.2c2.2-.26 3.54-1.45 3.96-2.61.33-.91.07-1.74-.53-2.36zm-6.12-2.73c.34-.43.96-.5 1.39-.16s.5.97.16 1.39a.988.988 0 1 1-1.55-1.23"
		></path>
		<path
			fill="#26281F"
			d="m26.937 52.445-.11-.12 29.7-26.56c.52-.47.56-1.27.09-1.79l-1.8-2.02-3.98-4.46c-1.56-1.75-4.27-1.9-6.02-.33l-32.56 29.12c-1.75 1.58-1.9 4.27-.33 6.02l6.21 6.83c.27.29.72.32 1 .05l2.64-2.17-.26-.1v27.83c0 2.35 1.91 4.26 4.26 4.27h40.49a4.27 4.27 0 0 0 4.26-4.26v-30.66c0-.92-.73-1.67-1.66-1.67h-41.93z"
		></path>
		<path
			fill="#F1BBDC"
			d="M54.727 1.515c.69-1.34 2.04-1.71 3.41-1.41 1.36.31 2.32 1.59 2.34 1.6 0 0 1.3-.95 2.69-.85.29.02.58.07.84.15.35.5.5 1.12.49 1.72s-.19 1.2-.4 1.75c-.33.81-.8 1.58-1.42 2.2-.93.93-2.17 1.48-3.37 2.02-.58.25-1.19.52-1.81.52l-.02.03c-.56-.53-1.2-1.2-1.78-2.01-1.61-2.18-1.66-4.37-.98-5.71zM74.887 19.045c-1.04.26-2.14.15-3.2.03-.52-.06-1.05-.13-1.5-.38h-.03c-.18-.6-.34-1.33-.42-2.15-.24-2.2.63-3.77 1.67-4.43 1.04-.67 2.15-.37 2.98.41s.98 2.08 1 2.09c0 0 1.3-.13 2.25.52q.3.195.54.45c.05.5-.11 1-.36 1.42s-.63.77-1.01 1.07c-.57.44-1.22.78-1.91.97z"
		></path>
		<path
			fill="#799F26"
			d="M39.187 36.737c.72.8 1.47 1.64 2.26 2.51l-6.88 6.16c-.79-.88-2.57-2.81-3.29-3.63.33-.29 4.93-4.38 6.91-6.13l1.02 1.07h-.01z"
		></path>
		<path
			fill="#26281F"
			d="m54.807 21.976-3.98-4.46c-1.56-1.75-4.27-1.9-6.02-.33l-12.84 11.49c-1.28 1.16-5.81 5.2-6.88 6.16l-7.96 7.12c2.08 3.45 6.71 5.21 10.54 3.86 1.74-.6 3.22-1.74 4.61-2.94.65-2.35 4.22-5.5 6.91-6.13 3.94-3.5 7.88-7.01 11.82-10.51 1.34-1.18 2.69-2.4 3.51-3.99.01-.35.28-.25.28-.25h.01zM68.107 52.455h-17.39c-1.24 1.79-6.52 1.72-9.23 0h-7c-1.63 1.82-1.92 4.52-2.04 6.97-.28 6.01-.47 12.09.55 18.02.69 4.05 6.23 4.4 8.5 4.7l9.23.13c3.74-.15 7.48 0 11.19-.49 3.17-.41 6.63-2.07 8.62-4.6v-23.42c0-.72-.59-1.31-1.31-1.31h-1.12"
		></path>
		<path
			fill="#80BA0F"
			d="M32.277 42.856c-4.14-4.62-7.75-8.61-7.75-8.61l6.88-6.16 7.76 8.63c-1.96 1.75-3.94 3.5-5.9 5.24-.32.31-.67.6-1 .9z"
		></path>
		<path
			fill="#fff"
			d="M52.237 43.066c-.29 1.48-1.74 2.44-3.22 2.16a2.74 2.74 0 0 1-2.16-3.21c.29-1.5 1.74-2.46 3.22-2.16 1.48.28 2.44 1.72 2.16 3.21M56.887 1.905c.1-.67.72-1.13 1.37-1.03a1.212 1.212 0 0 1-.35 2.4c-.66-.1-1.13-.72-1.02-1.37M72.057 12.856c.34-.43.96-.5 1.39-.16s.5.97.16 1.39a.988.988 0 1 1-1.55-1.23"
		></path>
		<path
			fill="#80BA0F"
			d="M36.727 67.805c-.76 2.26-.18 5.69 2.84 8.75 3.03 3.06 6.24 4.52 6.24 4.52h.02s1.28-.58 2.98-1.78c1-.7 2.14-1.62 3.26-2.75 3.03-3.06 3.61-6.49 2.84-8.75s-2.81-3.17-5.03-2.99-4.01 1.97-4.05 1.97h-.02s-1.82-1.79-4.05-1.97c-.46-.04-.92-.03-1.36.03-1.66.22-3.07 1.17-3.67 2.96z"
		></path>
		<path
			fill="#799F26"
			d="M36.737 67.806c.6-1.79 2.01-2.73 3.67-2.96v.02c-.67.69-1.05 1.63-1.16 2.58-.12.95.01 1.92.24 2.86.33 1.35.89 2.66 1.73 3.78 1.25 1.67 3.06 2.82 4.83 3.93.86.53 1.74 1.08 2.74 1.23l.03.05c-1.7 1.2-2.98 1.78-2.98 1.78h-.02s-3.21-1.46-6.24-4.52-3.61-6.49-2.84-8.75"
		></path>
		<path
			fill="#fff"
			d="M52.997 68.135c0 1.06-.86 1.93-1.92 1.93a1.931 1.931 0 0 1 0-3.86c1.07 0 1.92.86 1.92 1.93"
		></path>
		<path
			fill="#80BA0F"
			d="M68.627 51.705h-41.15c-1.03 1.1-4.97 4.46-5.69 5.29v.08h48.75v-3.47c0-1.05-.85-1.91-1.91-1.91z"
		></path>
		<path
			fill="#799F26"
			d="M25.787 33.905c.4-.42.82-.81 1.26-1.18.65-.56 1.3-1.13 1.97-1.66.54-.43 1.09-.83 1.6-1.29-2.19-.34-4.38.39-6.57.89.08 1.47.78 2.52 1.74 3.25z"
		></path>
	</svg>
);

export default Donazioni;
