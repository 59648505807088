import { create } from "zustand";
import GiftAPI from "../utils/API/GiftAPI";
import GiftUtils from "../utils/GiftUtils";
import { objectKeyFromS3Url } from "../utils/API/CallBackend";

const initialState = {
    name: "",
    description: "",
    images: [],
    category: null,
    availability: null,
    location: null,
    status: null,
    bookings: [],
    booking: null,
    createdAt: null,
    updatedAt: null,
    gift: null,
    assignedBooking: null,
};

const useEditingGiftStore = create((set, get) => ({
    name: "",
    description: "",
    images: [],
    category: null,
    availability: null,
    location: null,
    status: null,
    bookings: [],
    booking: null,
    owner: null,
    isDelivered: false,
    gift: null,
    assignedBooking: null,
    snapshot: null,

    setName: (name) => set({ name }),
    setDescription: (description) => set({ description }),
    setImages: (images) => set({ images }),
    setCategory: (category) => set({ category }),
    setAvailability: (availability) => set({ availability }),
    setLocation: (location) => set({ location }),
    setStatus: (status) => set({ status }),
    setGift: (gift) => set({ gift }),
    setBookings: (bookings) => set({ bookings }),
    setBooking: (booking) => set({ booking }),
    reset: () => {
        set(initialState);
    },
    createSnapshot: (gift) => {
        set({
            snapshot: {
                name: gift.name,
                description: gift.description,
                images: gift.images,
                category: gift.category,
                availability: gift.availability,
                location: gift.location,
                status: gift.status,
            },
        });
    },

    hasPendingChanges: () => {
        const imagesHavePendingChanges = () => {
            if (get().images.length !== get().snapshot?.images.length) {
                return true;
            }
            return get().images.some((image, index) => {
                return image.image !== get().snapshot?.images[index].image;
            });
        };
        return (
            get().name !== get().snapshot?.name ||
            get().description !== get().snapshot?.description ||
            imagesHavePendingChanges() ||
            get().category !== get().snapshot?.category ||
            get().availability !== get().snapshot?.availability ||
            get().location !== get().snapshot?.location ||
            get().status !== get().snapshot?.status
        );
    },

    pubblicaRegalo: async () => {
        const {
            name,
            description,
            images,
            category,
            availability,
            location,
            status,
        } = get();

        const gift = {
            name,
            description,
            category,
            location,
            status: status,
            availability: availability,
            isPublic: true,
        };

        console.log(gift);
        const giftId = await GiftAPI.addGift(gift);

        const additionalImagesPromises = images.map(async (element) => {
            if ("imageFile" in element) {
                const result = await GiftAPI.uploadImage(
                    giftId,
                    element.imageFile
                );
                return result;
            } else {
                return element.image;
            }
        });

        const additionalImages = await Promise.all(additionalImagesPromises);
        console.log(additionalImages);

        await GiftAPI.updateGift(giftId, {
            images: additionalImages,
        });
    },

    updateGift: async (giftId) => {
        
        
        const secureImages = await Promise.all(
            get().images.map((image) => {
                if ("imageFile" in image && image.imageFile !== null) {
                    return GiftAPI.uploadImage(giftId, image.imageFile);
                } else {
                    console.log(objectKeyFromS3Url(image.image));
                    
                    return objectKeyFromS3Url(image.image);
                }
            })
        );
        const newgift = {
            name: get().name,
            description: get().description,
            category: get().category,
            location: get().location,
            status: get().status,
            availability: get().availability,
            images: secureImages,
        };
        console.log(newgift);
        
        await GiftAPI.updateGift(giftId, newgift);

        const imagesItemGift = { ...newgift, images: get().images };
        get().createSnapshot(imagesItemGift);
        set({ ...get(), ...imagesItemGift }, true);
    },

    loadByGiftId: async (giftId) => {
        const regalo = await GiftAPI.getById(giftId);
        regalo.images = GiftUtils.mapImagesStringToItem(regalo.images);
        set({ gift: { ...regalo } });
        get().createSnapshot(regalo);
        set({ ...regalo });
        
    },

    checkFieldsValidity: () => {
        const {
            name,
            description,
            images,
            category,
            availability,
            location,
            status,
        } = get();

        const statusList = Object.values(GiftUtils.getStatus()).map(
            (status) => status.id
        );
        console.log(statusList);

        if (!statusList.includes(status)) {
            return false;
        }

        const availabilityList = Object.values(GiftUtils.getAvailability()).map(
            (availability) => availability.id
        );
        console.log(availabilityList);
        if (!availabilityList.includes(availability)) {
            return false;
        }

        if (!location || typeof location !== "object") {
            return false;
        }

        const { address, city, country, lat, lng } = location;
        if (
            typeof address !== "string" ||
            !address ||
            typeof city !== "string" ||
            !city ||
            typeof country !== "string" ||
            !country ||
            typeof lat !== "number" ||
            typeof lng !== "number"
        ) {
            return false;
        }

        return name && description && images.length > 0 && category && location;
    },
}));

export default useEditingGiftStore;
