import React from "react";

function Regolamento() {
	return (
		<div className="py-6 md:px-12 px-3 ">
			<h1 className="text-2xl font-bold text-center ">
				Cosa non si può pubblicare su ToGiftit e perché
			</h1>
			<p className="mt-4">
				ToGiftit è una piattaforma dedicata al riuso responsabile e alla
				condivisione solidale. Per garantire la sicurezza, l’efficacia e il
				rispetto delle normative, abbiamo stabilito alcune linee guida sui
				contenuti che non possono essere pubblicati. Queste restrizioni aiutano
				a mantenere un ambiente sicuro e coerente con i valori della
				piattaforma.
			</p>

			<h2 className="mt-6 text-xl font-semibold">Cosa non puoi pubblicare</h2>
			<ul className="list-disc list-inside mt-4 space-y-2">
				<li>
					<strong>Droghe illegali, sostanze stupefacenti</strong> o qualsiasi
					altro oggetto vietato dalla legge.
				</li>
				<li>
					<strong>Armi, esplosivi, articoli controllati</strong> come solventi,
					fuochi d’artificio o oggetti pericolosi.
				</li>
				<li>
					<strong>Letteratura o artefatti vietati</strong> (es. memorabilia
					nazisti, oggetti di avorio).
				</li>
				<li>
					<strong>Medicinali</strong> (sia da banco che con prescrizione),
					integratori farmaceutici o prodotti medici scaduti.
				</li>
				<li>
					<strong>Tabacco</strong>, liquidi per sigarette elettroniche o
					prodotti contenenti nicotina.
				</li>
				<li>
					<strong>Alcolici e bevande alcoliche</strong>.
				</li>
			</ul>

			<h3 className="mt-6 text-lg font-semibold">Cibo e bevande</h3>
			<p className="mt-2">
				Per ora, ToGiftit non supporta la condivisione di cibo o bevande. Questa
				funzione potrebbe essere introdotta in futuro, rispettando le normative
				igieniche e di sicurezza.
			</p>

			<h3 className="mt-6 text-lg font-semibold">
				Contenuti inadatti o offensivi
			</h3>
			<ul className="list-disc list-inside mt-4 space-y-2">
				<li>
					Contenuti violenti, espliciti o inappropriati, inclusi immagini o
					descrizioni di natura sessuale.
				</li>
				<li>Materiale discriminatorio o offensivo.</li>
			</ul>

			<h3 className="mt-6 text-lg font-semibold">
				Servizi e articoli digitali
			</h3>
			<ul className="list-disc list-inside mt-4 space-y-2">
				<li>Servizi (es. tutoraggio, dog sitter, parrucchieri).</li>
				<li>
					Contenuti digitali (PDF, musica, videogiochi, abbonamenti o licenze).
				</li>
			</ul>

			<h3 className="mt-6 text-lg font-semibold">Altri oggetti non idonei</h3>
			<ul className="list-disc list-inside mt-4 space-y-2">
				<li>Prodotti contraffatti o falsificati.</li>
				<li>Oggetti etichettati “non per la rivendita”.</li>
				<li>Buoni sconto, coupon, biglietti per eventi o esperienze.</li>
				<li>Articoli danneggiati o inutilizzabili (es. cibo scaduto).</li>
				<li>Oggetti rubati o trovati per strada.</li>
			</ul>

			<h2 className="mt-6 text-xl font-semibold">Perché queste restrizioni?</h2>
			<p className="mt-4">
				ToGiftit mira a costruire una community responsabile e sicura. Limitando
				alcuni articoli, possiamo:
			</p>
			<ul className="list-disc list-inside mt-4 space-y-2">
				<li>Garantire il rispetto delle normative locali.</li>
				<li>Proteggere la sicurezza degli utenti.</li>
				<li>Evitare malintesi, abusi o utilizzi impropri della piattaforma.</li>
				<li>
					Assicurare un focus sul riuso e sulla condivisione responsabile di
					oggetti.
				</li>
			</ul>

			<h2 className="mt-6 text-xl font-semibold">
				Cosa puoi fare se hai dubbi?
			</h2>
			<p className="mt-4">
				Se hai un oggetto che non rientra in queste categorie ma hai dubbi sulla
				sua idoneità, contatta il nostro supporto clienti. Siamo qui per
				aiutarti a rendere il tuo contributo significativo e sicuro!
			</p>

			<p className="mt-4 font-bold">
				📧 Email di supporto:{" "}
				<a
					href="mailto:assistenza@togiftit.com"
					className="text-verde hover:underline"
				>
					assistenza@togiftit.com
				</a>
			</p>
		</div>
	);
}

export default Regolamento;
