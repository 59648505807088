import * as React from "react";

const GuyHoldingGift = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="94"
		height="65"
		fill="none"
		viewBox="0 0 61 57"
	>
		<path
			fill="#9CC70D"
			d="M19.05 25.93C3.633 25.93 0 38.427 0 53.843a2.863 2.863 0 0 0 2.863 2.863h30.805v-2.863c0-15.416-.374-27.888-14.619-27.911"
		></path>
		<path
			fill="#9CC70D"
			d="M19.05 28.96c7.801 0 14.126-6.326 14.126-14.128S26.851.705 19.05.705 4.922 7.03 4.922 14.832 11.247 28.96 19.049 28.96"
		></path>
		<path
			fill="#fff"
			d="M22.4 10.521c.02.044.04.087.084.151q.045.065.104.128a.9.9 0 0 0 .525.273q.08.016.165.017h.131c.357 0 .653-.27.704-.623.006-.034.016-.07.02-.118.05-.592.303-1.07.72-1.382l.087-.068.08-.053a1.603 1.603 0 0 1 .28-.135c.074-.027.151-.05.246-.07l.057-.014q.134-.026.245-.027h.031q.11 0 .249.027l.07.017q.117.03.23.067c.077.027.147.06.218.094q.032.017.054.034l.11.077.068.05c.42.314.673.791.723 1.394.004.037.01.074.017.094.05.37.35.636.707.636h.13a.7.7 0 0 0 .156-.017l.043-.007a.97.97 0 0 0 .498-.273c.037-.036.068-.08.095-.117l.023-.034q.034-.059.067-.124l.02-.044c.014-.03.02-.067.038-.128l.016-.06c.004-.027.007-.054.01-.122 0-.023.004-.05 0-.1v-.014a3.547 3.547 0 0 0-3.552-3.395 3.546 3.546 0 0 0-3.553 3.388l.205.027-.209.014c0 .027 0 .053.007.117q-.002.04.007.081c.003.02.01.04.023.088.01.033.017.07.03.104l.021.044zM31.83 20.906c.98-1.278 1.837-4.464 1.326-7.21h-9.969a.84.84 0 0 0-.814 1.057c1.03 3.627 5.43 6.163 9.192 6.163q.131-.002.262-.01z"
		></path>
		<path
			fill="#2C302B"
			d="M59.65 25.066h-7.933c.501-.488 1-1.056 1.366-1.611.932-1.403 1.258-3.254.622-4.838-.85-2.13-2.953-3.18-5.184-2.756-2.026.384-4.111 2.416-5.097 4.162-.03.05-.054.108-.081.162-.905-1.71-2.517-3.429-4.374-4.112-2.065-.757-4.427-.114-5.591 1.807-.946 1.568-.842 3.485-.024 5.077.37.72.996 1.494 1.618 2.113h-7.88c-.743 0-1.345.602-1.345 1.345v5.548c0 .744.602 1.346 1.346 1.346h.05v20.143a2.915 2.915 0 0 0 2.914 2.913H56.69a2.915 2.915 0 0 0 2.913-2.913V33.309h.05c.744 0 1.346-.602 1.346-1.346v-5.548c0-.743-.602-1.345-1.345-1.345zm-14.393-3.664c.475-1.261 1.487-2.536 2.722-3.2 1.218-.652 2.84-.55 3.607.71.333.553.454 1.175.39 1.81-.007.038-.033.213-.04.243q-.046.212-.111.424c-.105.33-.145.42-.323.733-.7 1.228-2.046 2.426-3.422 2.944h-3.644q0-.029-.006-.057c.141-1.15.403-2.473.827-3.607m-9.773 1.117c-.364-.521-.539-.894-.673-1.5-.07-.32-.078-.337-.07-.713.006-.347.077-.693.211-1.016.569-1.373 1.999-1.73 3.328-1.292 1.359.447 2.483 1.796 3.061 3.065.518 1.137.885 2.654 1.04 3.906l-.01.1h-3.772c-1.18-.51-2.446-1.59-3.109-2.546z"
		></path>
		<g filter="url(#filter0_d_301_4374)">
			<path
				fill="#9CC70D"
				d="M14.06 45.828a222 222 0 0 0 31.774 6.46c2.568.33 5.75.33 7.052-1.908.865-1.487.448-3.455-.572-4.841-1.019-1.386-6.564-6.49-34.966-15.863-2.769-.915-5.252-1.68-8.192-.727-3.812 1.235-5.525 4.185-5.676 8.007-.064 1.592.367 3.25 1.433 4.435.75.834 1.76 1.386 2.766 1.887 2.075 1.033 4.178 1.824 6.382 2.55"
			></path>
		</g>
		<defs>
			<filter
				id="filter0_d_301_4374"
				width="53.881"
				height="27.935"
				x="1.474"
				y="28.511"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				></feColorMatrix>
				<feOffset dy="2"></feOffset>
				<feGaussianBlur stdDeviation="1"></feGaussianBlur>
				<feComposite in2="hardAlpha" operator="out"></feComposite>
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_301_4374"
				></feBlend>
				<feBlend
					in="SourceGraphic"
					in2="effect1_dropShadow_301_4374"
					result="shape"
				></feBlend>
			</filter>
		</defs>
	</svg>
);

export default GuyHoldingGift;
