import React, { useEffect } from "react";
import Wizard from "../../Components/Molecules/Wizard/Wizard";
import ImageRegistrati from "../../assets/PNG/Registrati@4x.png";
import {
    RegistrationStepNome,
    RegistrationStepEmail,
    RegistrationStepProfileImage,
    RegistrationStepPassword,
    RegistrationStepConfirmMail,
} from "./RegistrationSteps";

import { auth } from "../../firebase";
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
    signInWithRedirect,
} from "firebase/auth";
import UserAPI from "../../utils/API/UserAPI";
import { toast } from "sonner";
import useRegistrationStore from "../../stores/registrationStore";
import useNavbarStore from "../../stores/navbarStore";
import AuthAPI from "../../utils/API/AuthAPI";

const StepRegistration = () => {
    const setNavbarVisible = useNavbarStore((state) => state.setNavbarVisible);
    const {
        nome,
        cognome,
        email,
        password,
        profileImage,
        emailConfirmationCode,
        setInviteCode,
        inviteCode,
    } = useRegistrationStore();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const invite = params.get("invitedBy");
        if (invite) {
            setInviteCode(invite);
            console.log("Invited by:", invite);
        }
        setNavbarVisible(false);
    }, [window.location.pathname]);

    const columnClassName =
        "flex flex-col flex-1 md:h-full h-[95dvh] px-5 py-5";
    const wizard = (
        <Wizard
            showStep
            onCompleted={() => handleSubmit()}
            steps={[
                RegistrationStepEmail,
                RegistrationStepPassword,
                RegistrationStepNome,
                RegistrationStepProfileImage,
                RegistrationStepConfirmMail,
            ]}
            handleGoogleSignUp={() => handleGoogleSignUp()}
            handleAppleSignUp={() => handleAppleSignUp()}
        />
    );

    const handleAppleSignUp = async () => {
        const provider = new OAuthProvider("apple.com");
        if (window.ReactNativeWebView) {
            signInWithRedirect(auth, provider); // Avvia il flusso di redirect
        } else {
            const result = await signInWithPopup(auth, provider);

            const uid = result.user.uid;
            try {
                const user = await UserAPI.getById(uid);
                if (user.uid) {
                    window.localStorage.clear();
                    console.log("User logged in:", user);

                    window.history.back();
                    return;
                }
            } catch (error) {
                console.log("User logged in:", error);
                window.location.href = "/register";
            }
        }
    };

    const handleGoogleSignUp = async () => {
        const provider = new GoogleAuthProvider();
        try {
            if (window.ReactNativeWebView) {
                const result = await signInWithRedirect(auth, provider);
                console.log("redirect:", result);
            } else {
                const userCredential = await signInWithPopup(auth, provider);

                const { displayName, email } = userCredential.user;
                const nome = displayName.split(" ")[0];
                const cognome = displayName.split(" ")[1];

                const user = {
                    name: nome,
                    surname: cognome || nome[0],
                    email: email,
                };
                console.log("utente da salvare", user, inviteCode);

                inviteCode
                    ? await UserAPI.addUser(user, inviteCode)
                    : await UserAPI.addUser(user);

                window.location.href = "/";
            }
        } catch (error) {
            toast.error(`Errore nella registrazione: ${error.message}`);
            throw new Error("Errore nella registrazione", error.message);
        } finally {
            return () => {
                "";
            };
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (utente) => {
            console.log("utente", utente);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const handleSubmit = async () => {
        try {
            try {
                await AuthAPI.verifyEmail(email, emailConfirmationCode);
            } catch (e) {
                throw "Codice errato";
            }
            try {
                await createUserWithEmailAndPassword(auth, email, password);

                const image = profileImage
                    ? await UserAPI.uploadProfilePic(profileImage)
                    : null;
                const user = {
                    name: nome,
                    surname: cognome,
                    email: email,
                    image: image,
                };
                inviteCode
                    ? await UserAPI.addUser(user, inviteCode)
                    : await UserAPI.addUser(user);

                window.location.href = "/";
            } catch (e) {
                throw e;
            }
        } catch (error) {
            if (error.code.startsWith("auth/email-already-in-use")) {
                toast.error(`L'email "${email}" è già stata registrata`);
            } else {
                toast.error(
                    "Si è verificato un errore durante la registrazione"
                );
            }
        } finally {
            setTimeout(() => {
                "";
            }, 3000);
        }
    };

    return (
        <div>
            <div
                className={`flex justify-center overflow-hidden items-start h-[90lvh] w-full `}
            >
                <div
                    className={`${columnClassName} hidden md:flex bg-white items-center justify-center`}
                >
                    <div className="flex flex-col px-12 space-y-4 text-black">
                        <h2 className="text-7xl font-semibold">
                            Regala, Ricevi,
                            <span className="">{" Ricicla"}</span>
                        </h2>
                        <p className="text-lg">
                            Togiftit, la 1° piattaforma in Italia, fatta apposta
                            per trovare gratuitamente oggetti che potrebbero
                            servirti e condividere con gli altri quelli di cui
                            non hai più bisogno.
                        </p>
                    </div>
                    <div className="flex w-full">
                        <img
                            src={ImageRegistrati}
                            alt="Registrati"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>

                <div className={`${columnClassName}`}>{wizard}</div>
            </div>
        </div>
    );
};

export default StepRegistration;
