import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 113.18 87.3"
      fill="CurrentColor"
    >
      <g data-name="layer 1">
        <path
          d="M113.13 79.02l-2.16-17.79c-.65-5.38-5.22-9.43-10.63-9.43-.17 0-.3-.13-.3-.29v-1.48c0-.1 0-.2-.01-.29h1.62c.86 0 1.57-.7 1.57-1.57v-8.56c0-.86-.7-1.56-1.57-1.56H79.17l2.37-27.48v-9c0-.86-.7-1.57-1.56-1.57H36.96c-.86 0-1.57.7-1.57 1.57V10.53l5.21 60.36c.07.82.74 1.43 1.56 1.43h4.36v3.37h-2.51c-.86 0-1.57.7-1.57 1.57v8.4c0 .86.7 1.57 1.57 1.57h61.85c2.09 0 4.09-.9 5.48-2.47a7.397 7.397 0 001.8-5.74zM63.01 84.1H45.58v-5.27h16.18l-.02.19c-.22 1.78.25 3.61 1.28 5.08zm-.48-11.77l-.4 3.37H49.64v-3.37h12.89zM81.88 46.6v-5.43h3.98v5.43h-3.98zm7.11 0v-5.43h3.98v5.43h-3.98zm-45.4 22.59l-4.93-57.18h39.62l-2.24 26.03h-2.81c-.86 0-1.57.7-1.57 1.56v8.56c0 .86.7 1.57 1.57 1.57h1.61c0 .09-.01.19-.01.29v1.48c0 .16-.13.29-.29.29-5.42 0-9.99 4.05-10.64 9.43l-.96 7.96H43.61zM74.77 46.6v-5.43h3.98v5.43h-3.98zm-7.79 15.01c.46-3.81 3.7-6.67 7.53-6.67 1.89 0 3.42-1.54 3.42-3.43v-1.48c0-.16.13-.29.29-.29h18.39c.16 0 .29.13.29.29v1.48c0 1.89 1.54 3.43 3.43 3.43 3.83 0 7.07 2.87 7.53 6.67l2.15 17.79c.14 1.19-.23 2.39-1.03 3.29-.8.9-1.94 1.41-3.14 1.41H68.99c-1.2 0-2.34-.52-3.14-1.41-.8-.9-1.17-2.1-1.02-3.29l2.15-17.79zM96.1 46.6v-5.43h3.98v5.43H96.1zM78.41 3.13v5.76H38.52V3.13h39.89z"
          className="cls-1"
        ></path>
        <path
          d="M43.39 81.61c-3.52 1.55-7.4 2.42-11.48 2.42-15.79 0-28.64-12.85-28.64-28.64s12.85-28.64 28.64-28.64c2.16 0 4.26.26 6.28.72-.04-1.12-.08-2.25-.1-3.37-2-.4-4.07-.61-6.18-.61C14.32 23.47 0 37.79 0 55.38s14.32 31.91 31.91 31.91c4.05 0 7.91-.79 11.48-2.17V81.6z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;