import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import UserAPI from "../../utils/API/UserAPI";
import Icon from "../../assets/Illustrazioni/invita un amico@3x.png";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import { toast } from "sonner";
import SecondaryButton from "../../Components/Atoms/Bottoni/SecondaryButton";
import useNavbarStore from "../../stores/navbarStore";
import Copy from "../../assets/Icons/Copy";
import ShareIcon from "../../assets/Icons/ShareIcon";

function Invite() {
	const [link, setLink] = useState("Caricamento...");
	const [copied, setCopied] = useState(false);
	const { setNavbarVisible, setFootbarVisible } = useNavbarStore();

	const handleLoad = async () => {
		const code = await UserAPI.getReferralCode();

		console.log(code);

		setLink(
			code
				? process.env.REACT_APP_URL + "/register?invitedBy=" + code
				: "Caricamento..."
		);
	};

	const copiaLInk = () => {
		navigator.clipboard.writeText(link);
		setCopied(true);
		toast.success("Link copiato negli appunti!");
	};

	const condividiLink = async () => {
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Invita un amico!",
					text: "Iscriviti usando il mio link!",
					url: link,
				});
				toast.success("Link condiviso con successo!");
			} catch (error) {
				console.error("Errore durante la condivisione:", error);
			}
		} else {
			toast.error("Condivisione non supportata sul tuo dispositivo.");
		}
	};

	useEffect(() => {
		setCopied(false);
		setNavbarVisible(false);
		setFootbarVisible(false);
		handleLoad();
	}, [window.location.pathname]);

	return (
		<div className="min-h-[80vh] mb-10 relative w-full">
			<div className="absolute top-0 left-0">
				<Indietro />
			</div>
			<div className="flex mt-5 px-4 flex-col items-center gap-10 w-full">
				<h2 className="text-3xl font-bold">Invita un amico!</h2>
				<img src={Icon} className="h-[25vh]" alt="" />
				<div className="flex flex-col gap-1 md:w-1/2 px-2">
					<p className="text-xl font-bold mb-5">
						Porta i tuoi amici in un nuovo mondo{" "}
						<span className="">
							dove riciclare significa creare valore!
						</span>
					</p>
					<p className="">
						Per ogni amico che si iscrive con il tuo codice,{" "}
						<span className="font-bold">guadagnerai 50 Punti Gift</span>, mentre{" "}
						<span className="font-bold">il tuo amico riceverà 100 punti</span>{" "}
						come bonus di benvenuto.
					</p>
					<p className="md:w-1/2 mt-3">
					Scegli come condividere:{" "}
					<span className="font-bold">copia il link</span> o premi{" "}
					<span className="font-bold">"Condividi"</span> per invitare subito i
					tuoi amici
				</p>
					<div className=" w-full my-6 flex md:flex-row flex-col gap-2">
					<input
						type="text"
						name=""
						value={link}
						className="w-full h-12 rounded-lg"
						id=""
						readOnly
					/>
					<SecondaryButton onClick={() => copiaLInk()}>
						<Copy /> <nobr>{copied ? "Link copiato!" : "Copia Link"}</nobr>
					</SecondaryButton>
					<PrimaryButton onClick={() => condividiLink()}>
						<ShareIcon />
						<nobr>Condividi</nobr>
					</PrimaryButton>
				</div>
					<p className="">
						<span className="font-bold">Più siamo</span>, più la nostra
						community cresce, e{" "}
						<span className="font-bold">maggiore sarà l’impatto</span> positivo
						sul pianeta! Insieme, trasformiamo il riciclo in un gesto virtuoso e
						divertente!
					</p>
				</div>
				
				
			</div>
		</div>
	);
}

export default Invite;
