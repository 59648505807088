import { motion } from "framer-motion";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import GridIcon from "../../../assets/Icons/GridIcon";
import MapIcon from "../../../assets/Icons/MapIcon";
const TOGGLE_CLASSES =
  "text-sm font-medium flex items-center aspect-square gap-2 px-3 md:pl-3 md:pr-3.5 py-3 md:py-1.5 transition-colors relative z-10";



const SliderToggle = ({ selected, setSelected }) => {
  const isMobile = useDeviceDetection() === "Mobile";
  return (
    <div className="relative bg-[#f2f2f2]  flex w-fit items-center rounded-full">
      <button
        className={`${TOGGLE_CLASSES} ${
          selected === "list" ? "text-white" : "text-slate-300"
        }`}
        onClick={() => {
          setSelected("list");
        }}
      >
        <GridIcon w={24} />
      </button>
      <button
        className={`${TOGGLE_CLASSES} ${
          selected === "map" ? "text-white" : "text-slate-800"
        }`}
        onClick={() => {
          setSelected("map");
          isMobile && window.scrollTo(0, 0);
        }}
      >
        <MapIcon w={24} />
        
      </button>
      <div
        className={`absolute inset-0 z-0 flex ${
          selected === "map" ? "justify-end" : "justify-start"
        }`}
      >
        <motion.span
          layout
          transition={{ type: "", damping: 15, stiffness: 250 }}
          className="h-full w-1/2 aspect-square rounded-full bg-verde "
        />
      </div>
    </div>
  );
};

export default SliderToggle;