import React, { useEffect, useState } from "react";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import useUserStore from "../../stores/userStore";
import UserAPI from "../../utils/API/UserAPI";
import StatusOkCheckIcon from "../../assets/Icons/StatusOkCheckIcon";
import Punti from "../../assets/Punti";
import useNavbarStore from "../../stores/navbarStore";
import LevelsUtils from "../../utils/LevelsUtils";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import UpgradeLvl from "../../Components/Atoms/Modals/UpgradeLvl";

function Livello() {
	const user = useUserStore((state) => state.user);
	const setNavbarVisible = useNavbarStore((state) => state.setNavbarVisible);
	const setFootbarVisible = useNavbarStore((state) => state.setFootbarVisible);
	const livello = LevelsUtils.getLevel(user.level);
	const [criteri, setCriteri] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [completionPercentage, setCompletionPercentage] = useState(0);

	const handleLoad = async () => {
		const cri = await UserAPI.getLevel(user.uid);
		console.log(cri);

		setCriteri(cri);
		const completedCriteriaCount = Object.values(cri.criteriaStatus).filter(
			(criteria) => criteria.isCompleted
		).length;

		const totalCriteriaCount = Object.keys(cri.criteriaStatus).length;

		setCompletionPercentage(
			((completedCriteriaCount / totalCriteriaCount) * 100).toFixed(0)
		);

		setLoaded(true);
	};

	useEffect(() => {
		setNavbarVisible(false);
		setFootbarVisible(false);
		handleLoad();
	}, [window.location.pathname]);

	const changeLevel = async () => {
		await UserAPI.changeLevel(user.uid);
		window.location.reload();

		handleLoad();
	};

	const displayGoals = () => {
		return (
			<div className="self-start ">
				{criteri.criteria.map((goal, index) => (
					<div className="flex  items-center gap-2 my-2" key={index}>
						<StatusOkCheckIcon
							valid={criteri.criteriaStatus[goal].isCompleted}
						/>
						<p className="text-lg">
							{LevelsUtils.getText(goal, criteri.criteriaStatus[goal])} (
							{criteri.criteriaStatus[goal].current}/
							{criteri.criteriaStatus[goal].expected})
						</p>
					</div>
				))}
			</div>
		);
	};

	return (
		<>
			{loaded ? (
				<div className="flex flex-col jsutify-center mt-5 items-center gap-[2vh] min-h-[95vh] md:ml-[25%] md:w-1/2 px-4 pb-10">
					<div className="flex justify-between items-center w-full my-[1vh]">
						<div className="absolute left-0">
							<Indietro />
						</div>
						<h1 className="text-3xl text-center mx-auto font-bold">Livello</h1>
					</div>

					<div className="w-full flex flex-col justify-center items-center gap-4 ">
						<div className="w-32 h-32 ">{livello.img()}</div>
						<h2 className="font-semibold text-xl">
							Livello: {user.level} Gifter {livello.nome}
						</h2>
						<p className="font-light italic">{livello.descr}</p>
						<div className="w-[100%] h-3 rounded-full bg-grigino">
							<div
								style={{
									width: `${completionPercentage}%`,
								}}
								className={`h-full  bg-verde rounded-full`}
							></div>
						</div>
						<div className="w-full flex justify-between items-center">
							<p>Livello {user.level}</p>
							<p>Livello {user.level + 1}</p>
						</div>
						<h3 className="font-semibold italic">
							Per diventare un Gifter{" "}
							{LevelsUtils.getLevel(user.level + 1).nome}:
						</h3>
						<div className="bg-grigio w-full h-[1px]"></div>
						{/*TODO: connect checks to backend */}
						{displayGoals()}
						<div className="bg-grigio w-full h-[1px]"></div>

						<h3 className="text-center">
							{criteri.isEligible ? (
								<p className=" font-semibold italic ">
									Hai completato tutte le missioni! Riscatta il tuo premio (
									<span className="w-5 inline-block align-middle">
										<Punti />
									</span>{" "}
									{criteri.points}) e passa al prossimo livello.
								</p>
							) : (
								<div className="font-semibold flex items-center gap-2 ">
									
									
									<p className="italic">
										Completa tutte le missioni per ricevere:{" "}
									</p>
									<div className="flex shadow-md rounded-lg items-center justify-between">
										<span className="px-2 py-1 flex items-center  justify-center space-x-1 md:font-bold font-semibold text-black ">
											<span>{criteri.points}</span>
											<div className="w-5">
												<Punti />
											</div>
										</span>
									</div>
								</div>
							)}
						</h3>

						<UpgradeLvl
							head={"Riscatta"}
							onClick={changeLevel}
							user={user}
							disabled={!criteri.isEligible}
						/>

						{LevelsUtils.getLevel().map((item, index) => {
							return (
								<div className="w-full flex flex-col">
									<div key={index} className="flex items-center gap-7">
										<div className="w-20">
											{item.img && item.img(user.level < index + 1)}
										</div>
										<div>
											<div>Gifter {item.nome}</div>
											<div>{item.regali} donazioni</div>
										</div>
									</div>
									<div className="bg-grigio w-full h-[1px] my-2"></div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className="">
					<Caricamento />
				</div>
			)}
		</>
	);
}

export default Livello;
