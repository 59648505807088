import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import PageTitle from '../../Components/Molecules/PageComponents/PageTitle'
import ChipTabs from '../../Components/Atoms/Tabs/ChipTabs'
import Faq from './Sezioni/Faq'
import ContactUs from './Sezioni/ContactUs'

import useNavbarStore from '../../stores/navbarStore'

const HelpCenter: React.FC = () => {
    const { tabId } = useParams();
    const setNavbarVisible = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbarVisible = useNavbarStore((state) => state.setFootbarVisible);

    const tabs: { [key: string]: React.ReactNode } = {
        "FAQ": <Faq />,
        "Contattaci": <ContactUs />
    }

    const [selectedTab, setSelectedTab] = useState(Object.keys(tabs)[(tabId ? parseInt(tabId) : 0)]);

    useEffect(() => {
        setNavbarVisible(false);
        setFootbarVisible(false);
    }, [window.location.pathname]);

    return (
        <div className=''>
            <PageTitle title="Help Center" />
            <div className='pageContent p-2 md:flex md:flex-col md:items-center'>
                <div className='md:w-[30%]'>
                    <ChipTabs tabs={Object.keys(tabs)} selected={selectedTab} setSelected={setSelectedTab} />
                </div>
                <div className='p-4'>
                    {tabs[selectedTab]}
                </div>
            </div>
        </div>
    )
}

export default HelpCenter