import React from "react";
import { useState } from "react";
import useDeviceDetection from "../../utils/useDeviceDetection";
import DragCloseDrawer from "../../Components/Atoms/Drawers/DragCloseDrawer";

function ReasonTile({ text, isSelected, onClick }) {
    return (
        <div
            className="flex flex-row justify-between items-center cursor-pointer"
            onClick={onClick}
        >
            <div className="mr-4 text-md">{text}</div>
            <input
                type="checkbox"
                name=""
                checked={isSelected}
                className="checked:bg-verde appearance-none checked:border-0 checked:ring-0 focus:ring-0"
                id=""
            />
        </div>
    );
}

function CancelChatDialogueBox({
    setIsDialogueVisible,
    isReceiver,
    onConfirm,
}) {
    const [reason, setReason] = useState(null);
    const [customReason, setCustomReason] = useState("");
    const [isConfirmClickable, setConfirmClickable] = useState(false);
    const device = useDeviceDetection();

    const reasons = isReceiver
        ? [
            "Ho avuto un contrattempo",
            "Non sono più interessato al regalo",
            "Il regalo non è stato presentato chiaramente",
        ]
        : [
            "Ho avuto un contrattempo",
            "Il ricevitore non ha dato istruzioni chiare",
        ];
/*
    const checkConfirmClickable = () => {
        setConfirmClickable(reason !== null || customReason.length > 0);
    };
*/
    const content = (
        <div className="px-4">
            <div className="text-xl font-semibold">
                {isReceiver
                    ? "Perché non vuoi ritirare il tuo regalo?"
                    : "Perché non vuoi consegnare il tuo regalo?"}
            </div>
            <div className="mt-4 w-full flex flex-col gap-3">
                {reasons.map((reasonText, index) => {
                    return (
                        <ReasonTile
                            text={reasonText}
                            isSelected={reason === index}
                            onClick={() => {
                                if (index === reason) {
                                    setReason(null);
                                    if (customReason.length === 0) {
                                        setConfirmClickable(false);
                                    }
                                } else {
                                    setReason(index);
                                    setConfirmClickable(true);
                                }
                            }}
                        />
                    );
                })}
            </div>
            <div className="flex flex-col mt-2 w-full">
                <text className="font-medium">Altro</text>
                <input
                    className="p-2.5 mt-2 text-sm text-gray-900 bg-gray-100 border-0 rounded-lg"
                    type="text"
                    value={customReason}
                    onChange={(e) => {
                        setCustomReason(e.target.value);
                        if (e.target.value.length > 0) {
                            setConfirmClickable(true);
                        } else if (reason === null) {
                            setConfirmClickable(false);
                        }
                    }}
                    id="chat"
                    placeholder="Scrivi qui il tuo messaggio..."
                ></input>
            </div>
            <div className="w-full flex justify-center">
                <button
                    className={`mt-8 ${isConfirmClickable
                        ? "text-white bg-verde"
                        : "bg-grigino text-black text-opacity-50 cursor-default"
                        } rounded-md px-4 py-2 w-full sm:w-auto`}
                    onClick={() => {
                        if (customReason.length > 0) {
                            onConfirm(customReason);
                        } else if (reason !== null) {
                            onConfirm(reasons[reason]);
                        }
                    }}
                >
                    Conferma
                </button>
            </div>
        </div>
    )

    if (device === "Mobile") 
        return (
            <DragCloseDrawer open={true} setOpen={setIsDialogueVisible}>
                {content}
            </DragCloseDrawer>
        )
    return (
        <div
            className="fixed grid backdrop-blur-sm inset-0 place-items-center z-50"
            onClick={() => {
                setIsDialogueVisible(false);
            }}
        >
            <div
                className="w-[95vw] md:w-auto bg-white rounded-md flex flex-col items-start  border-2 p-4"
                onClick={(e) => e.stopPropagation()}
            >
                {content}
            </div>
        </div>
    );
}

export default CancelChatDialogueBox;
