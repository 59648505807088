import React from "react";
import notFoundImage from "../assets/Sfondi/not_found_mobile.svg";
import notFoundImageDesktop from "../assets/Sfondi/not_found_desktop.svg";

const PageNotFound = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const image = isMobile ? notFoundImage : notFoundImageDesktop;

    return (
        <div className="flex flex-col justify-center items-center text-center p-6 pt-32">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Questa pagina non è stata trovata
            </h2>
            <img src={image} alt="Cat illustration" className="w-64 h-auto mb-6" />
            <p className="text-gray-600 text-lg">
                <a href="/" className="text-green-600 font-bold hover:underline">Home</a>
            </p>
            <p className="text-gray-600 text-lg">
                Hai bisogno di supporto?{" "}
                <a href="/faq/1" className="text-green-600 font-bold hover:underline">
                Contattaci
                </a>
            </p>
        </div>
    );
};

export default PageNotFound;
