import React, { memo, useEffect, useRef, useState } from "react";
import Trattative from "../../../assets/sidebar/Trattative";
import {
	useMotionValueEvent,
	AnimatePresence,
	useScroll,
	motion,
} from "framer-motion";
import PrimaryButton from "../../Atoms/Bottoni/PrimaryButton";
import Logo from "../../../assets/Logos/Logo";
import Pittogramma from "../../../assets/Logos/Pittogramma";
import { FaPlus } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import useUserStore from "../../../stores/userStore";
import useNavbarStore from "../../../stores/navbarStore";
import ProfilePic from "../../Atoms/ProfilePic/ProfilePic";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import { Link, useNavigate } from "react-router-dom";
import Search from "../../../assets/sidebar/Search";
import ChatIcon from "../../../assets/sidebar/ChatIcon";

const Navbar = memo(() => {
	const [scrolled] = useState(false);
	const { scrollY } = useScroll();
	const logged = useUserStore((state) => state.isLogged);
	const user = useUserStore((state) => state.user);
	const url = window.location.pathname.split("/")[1];

	const device = useDeviceDetection();
	const isMobile = device === "Mobile";
	const isNavbarVisible = useNavbarStore((state) => state.isNavbarVisible);

	const LINKS = [
		{
			text: "Esplora",
			icon: <Search selected={url === ""} />,
			href: "/",
			url: url === "",
		},
		{
			text: "Trattative",
			icon: <Trattative selected={url === "trattative"} />,
			href: "/trattative",
			url: url === "trattative",
		},
		{
			text: "Messaggi",
			icon: <ChatIcon selected={url === "chat"} />,
			href: "/chat",
			url: url === "chat",
		},
	];

	const MENU = logged
		? [
				{
					text: "Profilo",
					href: "/profilo",
				},
				{
					text: "Notifiche",
					href: "/notifications",
				},
				{
					text: "Invita amici",
					href: "/invite",
				},
				{ text: "I tuoi crediti", href: "/puntigift" },
				{ text: "Logout", href: "/logout" },
		  ]
		: [
				{
					text: "Accedi",
					href: "/login",
				},
				{
					text: "Registrati",
					href: "/register",
				},
		  ];

	// Cambiato il valore di 250 a 50 per la logica dello scroll
	useMotionValueEvent(scrollY, "change", () => {""});

	const navigate = useNavigate();
	const ctasRef = useRef(null);
	const linksRef = useRef(null);
	useEffect(() => {
		if (ctasRef.current && linksRef.current) {
			linksRef.current.style.width = isMobile
				? "auto"
				: `${ctasRef.current.offsetWidth}px`;
		}
	}, []);

	return (
		<nav
			key={"navbar"}
			className={`${
				isNavbarVisible | !isMobile ? "block" : "hidden"
			} fixed shadow-md h-[5rem] top-0 z-50 w-full text-verdeScuro transition-all duration-300 ease-out  px-4 lg:px-6 py-3 ${
				scrolled ? "bg-grigino" : "bg-white"
			}`}
		>
			<div className=" h-full flex items-center justify-between gap-0">
				<div ref={linksRef} onClick={() => navigate("/")}>
					<div className="md:w-36 w-full">
						<span className="md:block hidden cursor-pointer">
							<Logo />
						</span>
						{logged ? (
							<div className="flex gap-0 w-full items-end text-2xl font-semibold md:hidden fill-verde">
								<span className="h-full w-12">
									<Pittogramma />
								</span>
								<p className="md:hidden ml-2 mb-0.5 block">
									{" "}
									<nobr>Ciao {user.name}</nobr>{" "}
								</p>
							</div>
						) : (
							<span className="block md:hidden max-w-[50vw]">
								<Logo />
							</span>
						)}
					</div>
				</div>
				{logged && (
					<div className="hidden gap-6 lg:flex ">
						<Links LINKS={LINKS} />
					</div>
				)}
				<div ref={ctasRef} className="hidden gap-6 lg:flex ">
					<CTAs scrolled={scrolled} user={user} MENU={MENU} />
				</div>
				<div className="block md:hidden">
					<button
						className="bg-verdeScuro rounded-lg  w-12 aspect-square text-white text-xl flex justify-center items-center"
						onClick={() => (window.location.href = "/regala")}
					>
						<FaPlus />
					</button>
				</div>
			</div>
		</nav>
	);
});

export default Navbar;

const Links = ({ LINKS }) => {
	return (
		<div className="flex items-center gap-6">
			{LINKS.map((l) => (
				<NavLink
					key={l.text}
					href={l.href}
					FlyoutContent={l.component}
					icon={l.icon}
					url={l.url}
				>
					{l.text}
				</NavLink>
			))}
		</div>
	);
};

const NavLink = ({ children, href, icon, url }) => {
	return (
		<div className="relative h-fit w-fit">
			<Link
				to={href}
				className={`relative flex items-center gap-2 ${
					url ? "text-verde" : "text-verdeScuro"
				}`}
			>
				<span className={`text-3xl `}>{icon}</span>
				{children}
			</Link>
		</div>
	);
};

const CTAs = ({ user, MENU }) => {
	const navigate = useNavigate();
	return (
		<div className="flex  items-center gap-3">
			<Link to={"/regala"}>
				<PrimaryButton
					onClick={() => navigate("/regala")}
					textStyle="gap-2 text-white"
					bgColor="bg-verdeScuro"
					border="border-verdeScuro"
					height="10"
				>
					<FaPlus />
					<p> Fai un regalo</p>
				</PrimaryButton>
			</Link>
			<MenuButton FlyoutContent={() => <MenuContent MENU={MENU} />}>
				<div className="flex items-center gap-1 border border-grigio pr-2 rounded-lg">
					<IoMenu className="text-2xl my-2 ml-2" />
					{user  ? (
						<ProfilePic w={7} userId={user.uid} />
					) : (
						<span className="w-5 fill-grigio">
							<Pittogramma />
						</span>
					)}
				</div>
			</MenuButton>
		</div>
	);
};

const MenuButton = ({ children, href, FlyoutContent }) => {
	const [open, setOpen] = useState(false);

	const showFlyout = FlyoutContent && open;

	return (
		<div
			onClick={() => setOpen((pv) => !pv)}
			className="relative h-fit w-fit cursor-pointer"
		>
			<Link to={href} className="relative">
				{children}
			</Link>
			<AnimatePresence>
				{showFlyout && (
					<motion.div
						initial={{ opacity: 0, y: 15 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 15 }}
						transition={{ duration: 0.3, ease: "easeOut" }}
						className="absolute right-0 top-16 bg-white text-black"
					>
						{/* Render FlyoutContent come una funzione */}
						{FlyoutContent && <FlyoutContent />}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const MenuContent = ({ MENU }) => {
	return (
		<div className=" h-fit ">
			<div className="flex min-w-52 flex-col gap-1 p-1  shadow-lg">
				{MENU.map((m) => (
					<Link
						key={m.text}
						to={m.href}
						className="text-lg font-semibold hover:bg-grigino px-3 py-1 rounded-lg"
					>
						{m.text}
					</Link>
				))}
			</div>
		</div>
	);
};
