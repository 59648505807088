import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Profilo from "../../../assets/Profilo";
import { lvl } from "../../../Pages/Profilo/lvl";
import UserAPI from "../../../utils/API/UserAPI";
import useUserStore from "../../../stores/userStore";

/**
 * @param {Object} props
 * @param {string} [props.userId]
 * @param {string} [props.image]
 * @param {boolean} [props.full]
 * @param {boolean} [props.hiddenLvl]
 * @param {number} [props.w]
 * @returns
 */
function ProfilePic({
    full = false,
    w = 8,
    userId = null,
    image = null,
    hiddenLvl = false,
}) {
    /**@type {[import("../../../utils/API/UserAPI").User, function]} */
    const [user, setUser] = useState(null);
    const loaded = useRef(false);
    const profilePic = useRef(image);
    const myself = useUserStore((state) => state.user);
    const navigate = useNavigate();

    const getUser = async () => {
        try {
            if (!image) {
                if (userId === myself.uid) {
                    profilePic.current = myself.image;
                    setUser(myself);
                } else {
                    const user = await UserAPI.getById(userId);
                    profilePic.current = user.image;
                    setUser(user);
                }
            } else {
                setUser({ uid: userId });
            }

            loaded.current = true;
        } catch (error) {
            console.error("Error fetching user:", error);
        }
    };

    useEffect(() => {
        if (userId) {
            getUser();
        }
    }, [userId, myself]);

    return (
        <>
            {(loaded.current && user) || image ? (
                <div
                    className={`relative z-0 inline-flex ${
                        full ? "bg-rosso" : "bg-transparent"
                    } items-center justify-center w-${w} h-${w} aspect-square`}
                >
                    <div
                        className="relative w-full h-full overflow-hidden border rounded-full"
                        onClick={() => navigate("/profilo/" + user.uid)}
                    >
                        {profilePic.current ? (
                            <img
                                className="object-cover w-full h-full"
                                src={profilePic.current}
                                alt=""
                            />
                        ) : (
                            <Profilo />
                        )}
                    </div>
                    <div
                        id="lvl"
                        className="absolute w-[40%] z-10 -right-[5%] -bottom-[5%]"
                    >
                        {!hiddenLvl &&
                            user.level !== undefined &&
                            lvl[user.level] &&
                            lvl[user.level].img()}
                    </div>
                </div>
            ) : (
                <div
                    className={`relative z-0 inline-flex ${
                        full ? "bg-rosso" : "bg-transparent"
                    } items-center justify-center w-${w} aspect-square`}
                >
                    <div className="relative w-full h-full overflow-hidden border rounded-full">
                        <Profilo />
                    </div>
                </div>
            )}
        </>
    );
}

export default ProfilePic;
