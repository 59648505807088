import { useEffect, useState } from "react";
import ChatFilter from "../ChatFilter";
import Contacts from "../Contacts";
import { auth } from "../../../../firebase";
import React from "react";
import GiftAPI from "../../../../utils/API/GiftAPI";
import Caricamento from "../../../../Components/Atoms/Caricamento/Caricamento";

function GiverChats() {
    const [chatsFilter, setChatsFilter] = useState(0);
    /**
     * @type {[import("../../../../utils/API/GiftAPI").Gift[], function]}
     */
    const [items, setItems] = useState(null);

    /** @param {import("../../../../utils/API/BookingAPI").Booking} chat **/
    const nullFilter = (chat) => {
        return !chat.isCanceled;
    };

    /** @param {import("../../../../utils/API/BookingAPI").Booking} chat **/
    const infoFilter = (chat) => {
        return !chat.isRequested && !chat.isAccepted && !chat.isCanceled;
    };

    /** @param {import("../../../../utils/API/BookingAPI").Booking} chat **/
    const bookedFilter = (chat) => {
        return chat.isRequested && !chat.isAccepted && !chat.isCanceled;
    };

    /** @param {import("../../../../utils/API/BookingAPI").Booking} chat **/
    const acceptedFilter = (chat) => {
        return chat.isRequested && chat.isAccepted && !chat.isCanceled;
    };

    /** @param {import("../../../../utils/API/BookingAPI").Booking} chat **/
    const canceledFilter = (chat) => {
        return chat.isCanceled;
    };

    const filters = [nullFilter, infoFilter, bookedFilter, acceptedFilter, canceledFilter];

    const getItems = async () => {
        const gifts = await GiftAPI.getByOwner(auth.currentUser.uid);
        setItems(gifts);
    }

    useEffect(() => {
        if (items === null) {
            getItems();
        }
    }, []);

    return (
        <div className="h-full flex flex-col">
            <div className="flex flex-row mb-4 ml-8">
                <ChatFilter text={"Contattati"} chatsFilter={chatsFilter} setChatsFilter={setChatsFilter} index={1}/>
                <div className="w-3"/>
                <ChatFilter text={"Prenotati"} chatsFilter={chatsFilter} setChatsFilter={setChatsFilter} index={2}/>
                <div className="w-3"/>
                <ChatFilter text={"Assegnati"} chatsFilter={chatsFilter} setChatsFilter={setChatsFilter} index={3}/>
                <div className="w-3"/>
                <ChatFilter text={"Annullati"} chatsFilter={chatsFilter} setChatsFilter={setChatsFilter} index={4}/>
            </div>
            <div className="mt-2 flex-grow overflow-y-auto">
                {
                    items !== null ?
                    <Contacts filter={filters[chatsFilter]} isReceiver={false} items={items} key={"giver"}/>
                    :
                    <Caricamento/>
                }
            </div>
        </div>
    );
}

export default GiverChats;