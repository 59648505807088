import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import React, { useState, useEffect } from "react";
import NotificationAPI from "../../utils/API/NotificationAPI";
import NotificationTile from "./NotificationTile";
import RefreshOnPull from "../../Components/Gestures/RefreshOnPull";

function Notifications() {
    /**
     * @type {[import("../../utils/API/NotificationAPI").Notification[], function]}
     */
    const [notifications, setNotifications] = useState(null);
    const [visibleCount, setVisibleCount] = useState(10);
    const [allNotifications, setAllNotifications] = useState(null);

    const loadNotifications = async () => {
        const loadedNotifications = await NotificationAPI.getNotifications();

        // Ordina le notifiche in ordine cronologico dalla più recente alla meno recente
        const sortedNotifications = loadedNotifications.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setAllNotifications(sortedNotifications);
        setNotifications(sortedNotifications.slice(0, visibleCount));
    };

    useEffect(() => {
        loadNotifications();
    }, []);

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 10);
    };

    useEffect(() => {
        if (allNotifications) {
            setNotifications(allNotifications.slice(0, visibleCount));
        }
    }, [visibleCount, allNotifications]);

    return (
        <div className="min-h-[80vh] mt-4 flex flex-col w-full md:items-center bg-white md:rounded-lg">
            <h3 className=" h-16 px-4 text-3xl text-center w-full font-bold">
                Notifiche
            </h3>
            <div className="flex flex-col md:w-1/2  overflow-auto">
                <RefreshOnPull>
                    {(() => {
                        if (notifications) {
                            if (notifications.length > 0) {
                                return (
                                    <>
                                        {notifications.map(
                                            (notification, i) => (
                                                <div className="" key={i}>
                                                    <NotificationTile
                                                        key={notification.id}
                                                        notification={
                                                            notification
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                        {visibleCount <
                                            allNotifications.length && (
                                            <div className="flex mt-5 w-full justify-center">
                                                <button
                                                    className="underline mb-32"
                                                    onClick={handleShowMore}
                                                >
                                                    Mostra di più
                                                </button>
                                            </div>
                                        )}
                                    </>
                                );
                            } else if (notifications.length === 0) {
                                return (
                                    <div className="h-full w-full grid place-items-center">
                                        Non ci sono notifiche
                                    </div>
                                );
                            }
                        }
                        return <Caricamento />;
                    })()}
                </RefreshOnPull>
            </div>
        </div>
    );
}

export default Notifications;
