import React, { useState } from 'react'
import useEditingGiftStore from '../../../stores/editingGiftStore';
import CategorieDropdownRegalo from '../../../Components/Molecules/Dropdown/CategorieDropdownRegalo';
import SectionTitle from './SectionTitle';

const CategoriaRegalo = () => {
    const categoria = useEditingGiftStore((state) => state.category);
    const setCategoria = useEditingGiftStore((state) => state.setCategory);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className='w-full flex flex-col items-start gap-4 mt-5 pb-5'>
            <SectionTitle
                title="Scegli una categoria"
                // descrizione="Seleziona la categoria del tuo regalo"
                isMandatory
                isValid={Boolean(categoria)}
            />
            <div className='w-full'>
                <CategorieDropdownRegalo
                    setIsOpen={() => setIsOpen(!isOpen)}
                    isOpen={isOpen}
                    micro={categoria}
                    setMicro={setCategoria}
                    showTitle={false} />
            </div>

        </div>
    )
}

export default CategoriaRegalo