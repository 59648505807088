import { apiDelete, apiGet, apiPost, apiPut, objectKeyFromS3Url, uploadImage } from "./CallBackend";

/**
 * @typedef {Object} Gift
 * @property {string} _id
 * @property {string} owner
 * @property {string} name
 * @property {string} description
 * @property {GiftLocation} location
 * @property {string[]} images
 * @property {number} price
 * @property {string} category
 * @property {string} availability
 * @property {string} status
 * @property {boolean} isPublic
 * @property {boolean} isAssigned
 * @property {boolean} isAssignedToMe
 * @property {boolean} isBookedByMe
 * @property {number} bookingsLength
 * @property {boolean} isDelivered
 * @property {Date} createdAt
 */

/**
 * @typedef {Object} GiftPrivateFields
 * @property {string[]} bookings
 * @property {string} assignedTo
 */

/**
 * @typedef {Gift & GiftPrivateFields} OwnerGift
 */



const apiPrefix = "gift";

const GiftAPI = {
    /**
     * @param {Object} params
     * @param {string} [params.searchTerm]
     * @param {string} [params.category]
     * @param {string} [params.availability]
     * @param {string} [params.status]
     * @param {string} [params.bookingStatus]
     * @returns {Promise<Gift[]>}
     */
    getPublic: async ({ searchTerm = null, category = [], availability=[], status=[], coordinate = {} }) => {
        const queryParams = new URLSearchParams();

        if (searchTerm) queryParams.append("search", searchTerm);
        if (category.length) queryParams.append("category", category);
        if (availability.length) queryParams.append("availability", availability);
        if (status.length) queryParams.append("status", status);
        if (coordinate.lat) queryParams.append("lat", coordinate.lat);
        if (coordinate.lng) queryParams.append("lng", coordinate.lng);
        if (coordinate.range) queryParams.append("range", coordinate.range);
        //if (bookingStatus.length) queryParams.append("bookingStatus", bookingStatus);

        const queryString = queryParams.toString();
       
        const url = queryString ? `${apiPrefix}?${queryString}` : `${apiPrefix}`;

        return await apiGet(url, true);
    },

    /**
     * @param {string} giftId 
     * @returns {Promise<Gift>}
     */
    getById: async (giftId) => {
        const gift = await apiGet(`${apiPrefix}/${giftId}`, true);
        if (gift.availability.length === 1) {
            gift.availability = gift.availability[0];
        }
        return gift;
    },

    /**
     * @param {string} owner
     * @returns {Promise<Gift[]>}
     */
    getByOwner: async (owner) => {
        const gifts = await apiGet(`${apiPrefix}?owner=${owner}`, true);

        if (gifts) {
            return gifts;
        }
        return [];
    },

    /**
     * @returns {Promise<Gift[]>}
     */
    getBooked: async () => {
        const gifts = await apiGet(`${apiPrefix}/gift/booked`, true);

        if (gifts) {
            return gifts;
        }
        return [];
    },

    /**
     * @returns {Promise<Gift[]>}
     */
    getFavourites: async () => {
        const favourites = await apiGet(`${apiPrefix}/gift/favourites`, true);
        if (favourites) {
            return favourites;
        }
        return [];
    },

    /**
     * @param {Object} gift
     * @returns {Promise<string>} The id of the new gift
     */
    addGift: async (gift) => {
        const giftId = await apiPost(`${apiPrefix}`, {
            gift: {
                ...gift,
            }
        }, true);
        return giftId;
    },

    /**
     * 
     * @param {string} giftId 
     */
    deleteGift: async (giftId) => {
        await apiDelete(`${apiPrefix}/${giftId}}`, true);
    },

    /**
     * 
     * @param {string} giftId 
     * @param {Object} gift 
     */
    updateGift: async (giftId, gift) => {
        await apiPut(`${apiPrefix}/${giftId}`, {
            gift: gift
        }, true);
    },

    /**
     * 
     * @param {string} giftId 
     */
    deleteGift: async (giftId) => {
        await apiDelete(`${apiPrefix}/${giftId}`, true);
    },

    /**
     * 
     * @param {string} giftId 
     * @param {*} imageFile 
     * @returns {Promise<string>}
     */
    uploadImage: async (giftId, imageFile) => {
        const signedUrl = await apiPost(`${apiPrefix}/${giftId}/image`, {}, true);
        await uploadImage(imageFile, signedUrl);
        return objectKeyFromS3Url(signedUrl);
    }
};

export default GiftAPI;