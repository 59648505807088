import React from "react";

function SorrisoBianco({ fill, w }) {
    return (
        <svg width={w} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 900">
            <g>
                <path
                    d="M263.8 601.9C298.1 722.6 408.5 811 540.2 811s242.3-88.6 276.4-209.2c3.9-13.9-7-27.6-21.4-27.6h-510c-14.4-.1-25.3 13.7-21.4 27.7zM701.9 329c-62.6 0-113.9 49.3-116.7 112.3-.7 15.1 11.5 27.8 26.6 27.8h4.6c10.3 0 17.8-8.7 17.8-19 0-23.9 13.1-44 31.9-56 1.6-1.1 3.1-2.3 4.8-3.2 3.1-1.6 6-2.9 9.4-4.1 2.8-.9 5.6-1.9 8.6-2.5.9-.3 1.9-.4 2.8-.7 3.2-.7 6.6-1.1 9.9-1.1h.5c8 0 15.5 1.9 22.5 4.6 2.5.9 4.8 2 7.2 3.1 3.5 1.7 6.6 4.1 9.6 6.6 16.6 12.3 26.1 31.2 28 53.3v.3c.9 10.7 9.9 18.9 20.6 18.9h2c15.1 0 27.2-12.4 26.6-27.6-2.3-63.2-53.4-112.7-116.7-112.7zM262.1 444.2c0 .9 0 1.9.1 2.8.1.7.4 1.3.5 2 .3 1.1.5 2.1.9 3.2.1.4.4.8.7 1.2.5 1.2 1.1 2.4 1.7 3.5.1.3.4.4.5.7.8 1.2 1.7 2.4 2.7 3.3h.1c1.2 1.3 2.4 2.4 3.7 3.3 2.8 2 6.2 3.5 9.6 4.1.3 0 .7 0 .9.1 1.5.3 2.9.4 4.6.4h4.6c9.2 0 16.2-7.1 17.5-16.1.3-.9.4-1.9.5-2.9v-.3c1.9-22.1 11.5-41.1 28.1-53.3.8-.5 1.5-1.1 2.3-1.7.4-.3.8-.7 1.2-.9.9-.7 1.9-1.2 2.8-1.9.9-.5 1.7-1.2 2.7-1.6 2.8-1.5 5.6-2.7 8.6-3.7 2.8-.9 5.6-1.9 8.6-2.5.9-.3 1.9-.4 2.8-.7 3.2-.7 6.6-1.1 9.9-1.1h1c3.5 0 6.7.4 9.9 1.1.9.1 1.9.4 2.8.7 2.9.7 5.8 1.6 8.6 2.5 3.1 1.1 5.8 2.3 8.6 3.7.9.5 1.9 1.1 2.7 1.6.9.7 1.9 1.3 2.8 1.9.4.3.8.5 1.2.9.8.5 1.5 1.1 2.3 1.7 16.6 12.3 26.1 31.2 28 53.3v.3c.1 1.1.3 2 .5 2.9 1.2 9 8.2 16.1 17.5 16.1h4.6c1.6 0 3.1-.3 4.6-.4.3 0 .7 0 .9-.1 3.5-.8 6.8-2.1 9.6-4.1 1.3-.9 2.5-2 3.7-3.2 0-.1.1-.1.3-.3 1.1-1.1 1.9-2.1 2.7-3.3.1-.3.4-.4.5-.7.7-1.1 1.2-2.3 1.7-3.3.1-.4.5-.8.7-1.3.4-1.1.7-2.1.9-3.2.1-.7.4-1.3.5-2 .1-.9.1-1.9.1-2.8s.3-1.7.1-2.7c0-.4 0-.8-.1-1C491.9 377.8 441 329 378.1 329s-113.8 48.9-116.6 111.4c0 .3 0 .7-.1.9.4 1.1.7 2 .7 2.9z"
                    style={{ fill }}
                ></path>
            </g>
        </svg>
    );
}

export default SorrisoBianco;