import * as React from "react";

const Adozioni = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="91"
    fill="none"
    viewBox="0 0 82 91"
  >
    <path
      fill="#EECDBD"
      d="M14.41 16.371S1.056 23.934.236 26.486c-.82 2.551 1.362 9.837 1.362 9.837s-1.19.45-1.547 3.464c-.357 3.001 1.282 8.977 1.282 8.977s-1.19 1.137-.727 4.139c.45 3 1.732 5.46 1.732 5.46s-.542 1.455 0 4.191c.542 2.737 15.985 15.192 15.985 15.192l15.298-4.971z"
    ></path>
    <path
      fill="#EECDBD"
      d="M14.952 17.986c-3.94 2.87-9.48 8.515-3.861 14.861h8.925z"
      opacity="0.82"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#EECDBD"
      d="M67.548 16.373s13.353 7.55 14.173 10.1c.82 2.553-1.362 9.838-1.362 9.838s1.19.45 1.547 3.464c.357 3.001-1.282 8.977-1.282 8.977s1.19 1.137.727 4.139c-.45 3.001-1.732 5.46-1.732 5.46s.542 1.455 0 4.192-15.985 15.191-15.985 15.191l-15.298-4.971 19.225-56.404z"
    ></path>
    <path
      fill="#EECDBD"
      d="M66.992 18.037 62.1 32.396c1.534.992 10.392 6.32 12.217-1.983 1.613-7.391-4.84-11.2-7.325-12.376"
      opacity="0.82"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#80BA0F"
      d="M12.4 9.576c-2.433 7.193-.582 18.1 9.044 27.832 9.638 9.744 19.845 14.385 19.845 14.385h.066s4.06-1.838 9.48-5.646c3.173-2.234 6.81-5.143 10.366-8.74 9.625-9.73 11.49-20.638 9.044-27.83C67.812 2.383 61.32-.5 54.246.07 47.173.652 41.487 6.337 41.37 6.337h-.067c-.119 0-5.804-5.685-12.878-6.267a21.3 21.3 0 0 0-4.323.092c-5.289.714-9.757 3.716-11.675 9.414z"
    ></path>
    <path
      fill="#799F26"
      d="M12.413 9.59C14.33 3.892 18.8.903 24.101.19v.066C21.96 2.45 20.77 5.438 20.4 8.479s.04 6.122.767 9.097c1.058 4.31 2.83 8.475 5.487 12.018 3.993 5.302 9.731 8.991 15.363 12.521 2.724 1.693 5.54 3.425 8.7 3.914l.093.145c-5.408 3.808-9.467 5.646-9.467 5.646h-.066S31.069 47.18 21.43 37.435c-9.625-9.731-11.49-20.64-9.043-27.832h.026z"
    ></path>
    <path
      fill="#fff"
      d="M64.15 10.633c0 3.385-2.737 6.135-6.122 6.135a6.13 6.13 0 0 1-6.135-6.135 6.13 6.13 0 0 1 6.135-6.135c3.385 0 6.122 2.75 6.122 6.135"
    ></path>
    <path
      fill="#EECDBD"
      d="m41.66 90.545-1.746-18.973c-1.97-18.907-8.091-30.939-19.039-34.932 0 0-12.256-19.581-17.453-18.603-5.196.979 9.837 28.467 9.837 28.467 3.835 20.4 6.73 31.679 5.976 44.054h22.411z"
    ></path>
    <path
      fill="#EECDBD"
      d="M36.741 90.546h4.905l-1.745-18.974c-1.27-12.097-4.218-21.366-9.057-27.527 7.285 13.446 6.677 33.358 5.897 46.5"
      opacity="0.64"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#EECDBD"
      d="m41.527 90.545.516-18.973c1.97-18.907 8.092-30.939 19.04-34.932 0 0 12.255-19.581 17.452-18.603 5.196.979-9.837 28.467-9.837 28.467-2.631 17.902-5.434 28.466-4.747 44.054h-22.41z"
    ></path>
    <path
      fill="#EECDBD"
      d="M50.267 45.168c-4.337 6.148-7.034 15.02-8.21 26.404l-.517 18.973h4.654c-.476-13.923-.833-36.069 4.086-45.377z"
      opacity="0.64"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#F0E4E0"
      d="M3.7 19.188c-3.001-1.852-1.613 3.107.093 6.293 1.56-.82 3.133-.119 4.693-.119-.04-.542-.25-1.071-.502-1.56A11.74 11.74 0 0 0 3.7 19.2z"
    ></path>
    <path
      fill="#FAFAFA"
      d="M3.806 25.48c.568 1.058 1.163 1.917 1.653 2.274.78-.053.991-.304 1.758-.436.714-.132 1.216-.846 1.283-1.534.013-.145 0-.278 0-.423-1.56 0-3.134-.7-4.694.119M74.674 27.318c.767.132.978.384 1.758.436.556-.41 1.257-1.467 1.891-2.736-1.613.066-3.226.555-4.852 1.097.172.582.608 1.097 1.203 1.203"
    ></path>
    <path
      fill="#F0E4E0"
      d="M78.178 19.189a11.74 11.74 0 0 0-4.284 4.6c-.318.622-.582 1.296-.516 1.997 0 .12.053.225.08.344 1.613-.542 3.226-1.031 4.852-1.098 1.587-3.173 2.724-7.602-.145-5.83z"
    ></path>
    <path
      fill="#F2DAD1"
      d="M77.027 28.232c-1.348 5.5-4.059 10.829-8.21 14.73 3.517-4.443 6.069-9.507 8.21-14.73"
      style={{ mixBlendMode: "multiply" }}
    ></path>
    <path
      fill="#DDA999"
      d="M81.96 26.95c.237 4.733-.543 9.677-2.857 13.856 1.52-4.416 2.274-9.216 2.856-13.857M.05 26.95c.583 4.627 1.336 9.426 2.857 13.856C.58 36.614-.187 31.669.05 26.949"
    ></path>
    <path
      fill="#F2DAD1"
      d="M5.088 28.338c2.274 5.17 4.958 10.167 8.594 14.53-4.257-3.794-7.1-9.07-8.594-14.53"
      style={{ mixBlendMode: "multiply" }}
    ></path>
  </svg>
);

export default Adozioni;
