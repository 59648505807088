import React, { useEffect, useState } from "react";
import Punti from "../../../assets/Punti";
import { AsyncImage } from "loadable-image";
import { ToggleFavourite } from "../Bottoni/ToggleButtons";
import UserAPI from "../../../utils/API/UserAPI";
import useUserStore from "../../../stores/userStore";
import GiftUtils from "../../../utils/GiftUtils";
import { Link } from "react-router-dom";
import Location from "../../../assets/Icons/Location";
import { Gift } from "../../../types/Gift";


function CardPublic({ gift, dash = false }:
    {
        gift: Gift;
        dash?: boolean;
    }
) {
    const img =
        gift.images?.length && gift.images[0]
            ? gift.images[0].length
                ? gift.images[0]
                : gift.images[1].length
                ? gift.images[1]
                : gift.images[2].length
                ? gift.images[2]
                : gift.images[3].length
                ? gift.images[3]
                : gift.images[4].length
                ? gift.images[4]
                : gift.images[5]
                ? gift.images[5]
                : require("../../../assets/default/default-image.jpg")
            : require("../../../assets/default/default-image.jpg");

    const {user, setUser} = useUserStore();
    const logged = useUserStore((state) => state.isLogged);
    const [isLiked, setLiked] = useState(false);


    const handleFavourite = async () => {
        if (!logged) {
            window.location.href = "/register";
        }
        if (isLiked) {
            setUser({...user, favourites: user.favourites.filter((f:string) => f !== gift._id)});
            await UserAPI.removeFavourite(gift._id);
            setLiked(false);
        } else {
            setUser({...user, favourites: [...user.favourites, gift._id]});
            await UserAPI.addFavourite(gift._id);
            setLiked(true);
        }
    };



    const fetchLiked = async () => {
        setLiked(user.favourites.includes(gift._id));
    };

    useEffect(() => {
        //getUser();
        logged && fetchLiked();
    }, [logged]);

    const Condizioni = ({ condizioni }:{condizioni:string}) => {
        return (
            <p className="flex md:text-sm md:space-x-1 space-x-0 items-center">
                <span className="text-verdeScuro mr-1 w-4">
                    {typeof GiftUtils.getStatus(condizioni).img === 'function' ? (GiftUtils.getStatus(condizioni).img as () => JSX.Element)() : null}
                </span>
                <span style={{ whiteSpace: 'nowrap' }}>
                    {condizioni
                        ? String(GiftUtils.getStatus(condizioni).text)
                        : "Stato Mancante"}{" "}
                </span>
            </p>
        );
    };

    return (
        <div>
            <div className="md:max-w-[14rem] md:min-w-[13rem] w-[43vw] relative transition-all duration-200 hover:scale-[98%]">
                <div className="absolute z-10 top-0 right-0 h-10 w-10 aspect-square flex justify-center items-center">
                    {
                        <ToggleFavourite
                            isSelected={isLiked}
                            onClick={handleFavourite}
                        />
                    }
                </div>
                <div className=" cursor-pointer flex justify-start items-center absolute top-2 left-2 z-10 bg-background shadow-lg rounded-lg">
                    <Link
                        to={
                            !dash
                                ? "/prodotto/" + gift._id
                                : "/dashboard/" + gift._id
                        }
                        className="flex items-center justify-between"
                    >
                        <span className="px-2 py-1 flex items-center  justify-center space-x-1 md:font-bold font-semibold text-black ">
                            <span>100</span>
                            <div className="w-5">
                                <Punti />
                            </div>
                        </span>
                    </Link>
                </div>

                <div
                    className="flex relative items-center justify-center"
                    onClick={() =>
                        (window.location.href = !dash
                            ? "/prodotto/" + gift._id
                            : "/dashboard/" + gift._id)
                    }
                >
                    <AsyncImage
                        className="w-full aspect-square object-cover rounded-lg cursor-pointer"
                        src={img}
                        alt={gift.name}
                    />
                    <div className="absolute items-end flex space-x-2 left-2 bottom-2">
                        {gift.bookingsLength ? (
                            <span className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-verde2">
                                {gift.bookingsLength} Prenotat
                                {gift.bookingsLength === 1 ? "o" : "i"}
                            </span>
                        ) : (
                            <div className="absolute"></div>
                        )}
                        {
                            //TODO: Add single "finalized" field
                            gift.isDelivered ? (
                                <div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-rosso">
                                    <span>Regalato</span>
                                </div>
                            ) : gift.isAssigned ? (
                                <div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-verdeScuro">
                                    <span>Assegnato</span>
                                </div>
                            ) : (
                                <div className="absolute"></div>
                            )
                        }
                    </div>
                </div>
                <div className="flex pb-2 pt-1 flex-col md:mt-0 mt-0">
                    <h5
                        onClick={() =>
                            (window.location.href = !dash
                                ? "/prodotto/" + gift._id
                                : "/dashboard/" + gift._id)
                        }
                        className="text-lg cursor-pointer md:block hidden font-semibold tracking-tight text-black "
                    >
                        {gift.name.slice(0, 22)}
                        {gift.name.length > 22 && "..."}
                    </h5>
                    <h5
                        onClick={() =>
                            (window.location.href = !dash
                                ? "/prodotto/" + gift._id
                                : "/dashboard/" + gift._id)
                        }
                        className="text-md cursor-pointer block md:hidden font-medium tracking-tight text-black "
                    >
                        {gift.name.slice(0, 16)}
                        {gift.name.length > 16 && "..."}
                    </h5>
                    <div className="flex md:flex-row flex-col text-verdeScuro/70 mt-1 md:space-x-5 space-x-0 md:text-sm text-xs md:items-center md:pl-0 pl-0">
                        {/*owner*/}
                        <div className="flex items-end space-x-0 md:gap-1">
                            <Location w={18} full={false} />
                            <span className="md:text-xs text-xs">
                                {gift.location.city}
                            </span>
                        </div>
                        <div className="hidden items-center space-x-0 md:gap-1">
                            <div className="flex justify-center items-center ">
                                <Condizioni condizioni={gift.status} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardPublic;
