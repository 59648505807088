import { motion } from "framer-motion";

const ChipTabs = ({ selected, setSelected, tabs }) => {
	return (
		<div className="border w-full border-grigio rounded-lg bg-[#fafafa] p-1 flex justify-around items-center">
			{tabs.map((tab) => (
				<Chip
					text={tab}
					selected={selected === tab}
					setSelected={setSelected}
					key={tab}
				/>
			))}
		</div>
	);
};

const Chip = ({ text, selected, setSelected }) => {
	return (
		<button
			onClick={() => setSelected(text)}
			className={`${
				selected ? "text-white" : ""
			} text-lg transition-colors md:px-5 px-2 py-1 rounded-lg relative h-full flex-1`}
		>
			<span className="relative z-10"><nobr>{text}</nobr></span>
			{selected && (
				<motion.span
					layoutId="pill-tab"
					transition={{ type: "spring", duration: 0.5 }}
					className="absolute inset-0 z-0 bg-verdeScuro rounded-lg"
				></motion.span>
			)}
		</button>
	);
};

export default ChipTabs;
