import { motion } from "framer-motion";
import { useEffect } from "react";
import DragCloseDrawer from "../../Atoms/Drawers/DragCloseDrawer";
import SecondaryButton from "../../Atoms/Bottoni/SecondaryButton";

const StaggeredDrawer = ({
	titolo,
	opzioni,
	isOpen,
	setIsOpen,
	checkedItems,
	setCheckedItems,
}) => {
	useEffect(() => {
		setCheckedItems([]);
	}, [setCheckedItems]);

	const handleCheck = (id) => {
		// console.log( checkedItems.includes(id) ? checkedItems.filter((item) => item !== id) : [...checkedItems, id]);
		const newItems = checkedItems.includes(id)
			? checkedItems.filter((item) => item !== id)
			: [...checkedItems, id];
		setCheckedItems(newItems);
	};

	const clearChecks = () => {
		setCheckedItems([]);
	};

	return (
		<DragCloseDrawer open={isOpen} setOpen={setIsOpen} titolo={titolo}>
			<p className="pl-4 text-left pr-2 text-verdeScuro text-xl md:hover:underline md:hover:text-verdeScuro cursor-pointer font-bold py-1 rounded-md">
				{titolo === "Condizioni"
					? "Condizioni dell'oggetto"
					: "Disponibilità di ritiro"}
			</p>
			<ul className="flex h-full z-40 flex-col gap-2 p-2 bg-white min-w-48 overflow-hidden my-2">
				{opzioni.map((item, i) => {
					return (
						<Option
							key={i}
							prop={item}
							checked={checkedItems.includes(item.id)}
							onCheck={() => handleCheck(item.id)}
						/>
					);
				})}
			</ul>
			<div className="self-end mr-4">
				<SecondaryButton onClick={clearChecks}>Cancella filtri</SecondaryButton>
			</div>
		</DragCloseDrawer>
	);
};

const Option = ({ prop, checked, onCheck }) => {
	return (
		<motion.li
			onClick={(e) => {
				e.stopPropagation();
				onCheck();
			}}
			className={`flex z-40 ${
				checked ? "bg-grigino" : "bg-white"
			} relative items-center justify-between gap-2 w-full p-2 text-sm whitespace-nowrap rounded-md hover:md:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer`}
		>
			<div className="flex gap-3">
				{prop.imgPresent && <span className="w-5">{prop.img()}</span>}
				<span>{prop.text}</span>
			</div>
			<input
				className="cursor-pointer  rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
				type="checkbox"
				name=""
				id=""
				checked={checked}
				readOnly
			/>
		</motion.li>
	);
};

export default StaggeredDrawer;
