import * as React from "react";

const Heart = ({w= 24, selected}) => (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={w}
      fill="none"
      viewBox="0 0 20 19"
    >
      <path
        fill={selected ? "#FF4040" : "#fff"}
        fillOpacity={selected ? 1 : 0.5}
        d="M2 2.5 5.5 1l8.5.5 4 1v6L10 16 2 8.5z"
      ></path>
      <path
        fill={selected ? "#E2004B" : "#333"}
        d="M18.172 1.85a6.27 6.27 0 0 0-8.16-.61 6.27 6.27 0 0 0-8.16 9.48l7.45 7.45a1 1 0 0 0 1.42 0l7.45-7.45a6.27 6.27 0 0 0 0-8.87m-1.41 7.46-6.75 6.74-6.75-6.74a4.28 4.28 0 0 1 3-7.3 4.25 4.25 0 0 1 3 1.25 1 1 0 0 0 1.42 0 4.27 4.27 0 0 1 6 6.05z"
      ></path>
    </svg>
  
  
);

export default Heart;
