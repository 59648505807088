import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 115 115"
    >
      <path
        d="M27.65 84.53c.44.2.88.29 1.3.29 1.29 0 2.43-.85 3.03-1.93.18-.33.37-.66.54-.96l.69-1.09a31.31 31.31 0 013.07-3.82c.42-.44.85-.87 1.27-1.27l.31-.28a30.635 30.635 0 013.84-2.82c.41-.26.83-.49 1.22-.71l.96-.5c.18-.09.36-.17.55-.26a32.8 32.8 0 013.5-1.34c.63-.2 1.27-.38 1.91-.55.23-.06.45-.11.64-.16l.7-.14c1.29-.24 2.64-.42 4.1-.54.78-.06 3.11-.1 3.81-.04 1.45.12 2.78.29 4.06.53.66.12 1.32.27 1.97.43.29.07.59.15.86.22l.6.18c2.38.77 4.67 1.82 6.8 3.1l.43.29c.22.15.43.29.65.45.48.34.95.7 1.4 1.07.92.75 1.86 1.62 2.86 2.64l.22.24c.19.22.37.43.55.65.47.56.92 1.15 1.34 1.74.76 1.06 1.48 2.2 2.19 3.49.35.63 1.04 1.09 1.89 1.27.95.2 1.94.02 2.58-.47 1.74-1.32 1.57-3.23.82-4.58-5.57-10.08-16.06-16.68-28.06-17.65-.71-.06-2.31-.08-3.5-.08-.56 0-1.58 0-2.06.04-6.09.49-11.88 2.34-16.74 5.35-4.78 2.96-8.91 7.42-11.95 12.91-.47.86-.29 1.76-.14 2.22.3.92.96 1.69 1.77 2.06zM41.17 55.21c5.48 0 9.02-4.15 9.02-10.57s-3.54-10.57-9.02-10.57-9.02 4.15-9.02 10.57 3.54 10.57 9.02 10.57zM73.43 55.21c5.48 0 9.02-4.15 9.02-10.57s-3.54-10.57-9.02-10.57-9.02 4.15-9.02 10.57 3.54 10.57 9.02 10.57z"
        className="cls-1"
      ></path>
      <path
        d="M57.5 0C25.79 0 0 25.79 0 57.5S25.79 115 57.5 115 115 89.21 115 57.5 89.21 0 57.5 0zM5 57.5C5 28.55 28.55 5 57.5 5S110 28.55 110 57.5 86.45 110 57.5 110 5 86.45 5 57.5z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;