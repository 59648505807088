import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 115.86 109.07"
      fill="CurrentColor"
    >
      <g data-name="layer 1">
        <path
          d="M114.11 97.07h-3.44V31.93h1.27c.86 0 1.61-.52 1.91-1.32.3-.81.08-1.69-.56-2.26L81.31.41c-1.1-.58-45.65-.57-47.05.22L2.57 28.36c-.64.57-.86 1.45-.56 2.26.3.8 1.06 1.32 1.91 1.32h1.23v65.14h-3.4c-.97 0-1.75.79-1.75 1.75v8.49c0 .97.78 1.76 1.75 1.76H114.1c.97 0 1.76-.79 1.76-1.76v-8.49c0-.96-.79-1.75-1.76-1.75zM35.93 3.44c4.23-.21 39.38-.2 43.94 0l28.82 25.23H7.18L35.93 3.44zm71.54 28.49v65.14H70.22V53.19c0-.97-.79-1.75-1.76-1.75H47.39c-.97 0-1.75.78-1.75 1.75v43.88H8.35V31.93h99.11zM66.7 54.95v42.13H49.15V54.95H66.7zm45.65 50.61H3.51v-4.97h108.84v4.97z"
          className="cls-1"
        ></path>
        <path
          d="M77.74 72.15h23.11c.97 0 1.75-.79 1.75-1.75V43.77c0-.97-.78-1.75-1.75-1.75H77.74c-.97 0-1.76.78-1.76 1.75V70.4c0 .96.79 1.75 1.76 1.75zm1.76-26.62h19.6v7.66H79.5v-7.66zm0 11.18h19.6v11.93H79.5V56.71zM15 72.15h23.11c.97 0 1.76-.79 1.76-1.75V43.77c0-.97-.79-1.75-1.76-1.75H15c-.97 0-1.76.78-1.76 1.75V70.4c0 .96.79 1.75 1.76 1.75zm1.75-26.62h19.6v7.66h-19.6v-7.66zm0 11.18h19.6v11.93h-19.6V56.71zM61.99 79.47c.97 0 1.76-.78 1.76-1.75s-.79-1.75-1.76-1.75-1.75.79-1.75 1.75.79 1.75 1.75 1.75z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;