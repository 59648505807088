import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css"; // Stili di MarkerCluster
import MarkerClusterGroup from "react-leaflet-markercluster";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import useUserStore from "../../stores/userStore";

import pittogramma from "../../assets/Icons/Pointer Mappa 1.svg";

const Map  = ({gifts}) => {
   
    const user = useUserStore((state) => state.user);
   

	const svgIcon = L.divIcon({
		html: `<img src="${pittogramma}" alt="Pittogramma" style="width: 30px; height: 45px;" />`,
		iconSize: [30, 45],
		iconAnchor: [15, 45],
		className: "",
	});

	return (
		<div className="h-full md:h-[80dvh] w-full">
			<MapContainer
				center={[42.3389, 11.6549]}
				zoom={6}
				className="h-full w-full "
				style={{ borderRadius: "10px" }}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
				/>

				{
					<MarkerClusterGroup>
						{gifts.map((item, index) => (
							<Marker
								key={index}
								position={[item.location.lat, item.location.lng]}
								icon={svgIcon}
							>
								<Popup className="w-[60vw]  md:w-auto text-base">
									<CardPublic gift={item} dash={user?.uid === item.owner} />
								</Popup>
							</Marker>
						))}
					</MarkerClusterGroup>
				}
			</MapContainer>
		</div>
	);
}

export default Map;
