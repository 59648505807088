import React, { useEffect } from "react";
import Caricamento from "./Caricamento/Caricamento";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";

function Exit() {
    useEffect(() => {
        exit();
    }, []);

    const exit = async () => {
        await signOut(auth)
            .then(() => {
                // Pulisci il localStorage
                window.localStorage.clear();

                // Pulisci il sessionStorage
                window.sessionStorage.clear();

                

                // Reindirizza alla home page
                window.location.href = "/";
            })
            .catch((error) => {
                return error;
            });
    };

    return (
        <div>
            <Caricamento />
        </div>
    );
}

export default Exit;
