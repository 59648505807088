import React from 'react'
import Indietro from '../../Atoms/Bottoni/Indietro'

const PageTitle = ({ title }) => {
    return (
        <div className="flex items-center justify-center py-5 ">
            <span className="absolute left-0">
                <Indietro />
            </span>
            <h1 className="text-3xl font-bold">{title}</h1>
        </div>
    )
}

export default PageTitle