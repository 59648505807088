import React from 'react'
import useDeviceDetection from '../../../utils/useDeviceDetection'

import DragCloseDrawer from './DragCloseDrawer'
import PopupCloseDrawer from './PopupCloseDrawer'

const AdaptiveDrawer = ({ isOpen, setOpen, children }:{
    isOpen: boolean,
    setOpen: (value: boolean) => void,
    children: React.ReactNode
}) => {

    const device = useDeviceDetection()

    if (device === "Mobile") {
        return (
            <DragCloseDrawer open={isOpen} setOpen={setOpen}>
                {children}
            </DragCloseDrawer>
        )
    }

    return (
        <PopupCloseDrawer open={isOpen} setOpen={setOpen}>
            {children}
        </PopupCloseDrawer>
    )
}

export default AdaptiveDrawer