import {
	AnimatePresence,
	motion,
	useAnimate,
	useDragControls,
	useMotionValue,
} from "framer-motion";
import { useEffect, useState } from "react";
import PrimaryButton from "../Bottoni/PrimaryButton";
import SecondaryButton from "../Bottoni/SecondaryButton";
import LoadingBtn from "../Bottoni/LoadingBtn";
import useMeasure from "react-use-measure";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import LevelsUtils from "../../../utils/LevelsUtils";
import Punti from "../../../assets/Punti";

const UpgradeLvl = ({
	user,
	title,
	head,
	text,
	onClick,
	secondary = false,
	cancel = false,
	disabled = false,
	withoutConfirm = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const device = useDeviceDetection();
	const isMobile = device === "Mobile";

	const livello = LevelsUtils.getLevel(user.level + 1);

	return (
		<div className="">
			{secondary ? (
				<SecondaryButton
					disabled={disabled}
					fullWidth
					onClick={() => (withoutConfirm ? onClick() : setIsOpen(true))}
				>
					{head}
				</SecondaryButton>
			) : (
				<PrimaryButton
					disabled={disabled}
					fullWidth
					onClick={() => (withoutConfirm ? onClick() : setIsOpen(true))}
				>
					{head}
				</PrimaryButton>
			)}
			{isMobile ? (
				<DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
					<div className="bg-white w-full cursor-default relative overflow-hidden ">
						<div className="relative z-10 mt-1 flex flex-col justify-center items-center gap-4 w-full">
							<h3 className="text-2xl font-extrabold text-center ">
								COMPLIMENTI!
							</h3>
							<h3 className="text-2xl font-extrabold text-center mb-2 	">
								Hai raggiunto il livello {livello.number}
							</h3>
							<div className="w-24">{livello.img()}</div>
							<p className="font-bold">Gifter {livello.nome}</p>
							<p className="text-center">{livello.descr}</p>
							<p className="font-bold text-center text-xl mb-6">
								Prosegui al prossimo step <br /> e ricevi la tua la ricompensa
							</p>
							<div className="flex flex-col gap-2 w-full">
								<LoadingBtn fullWidth onCall={onClick}>
									<div className="flex items-center justify-center gap-4">
										<span>Riscatta</span>
										<span className="px-2 py-1 flex items-center  justify-center space-x-1 md:font-bold font-semibold text-white ">
											<span>{livello.reward}</span>
											<div className="w-5">
												<Punti />
											</div>
										</span>
									</div>
								</LoadingBtn>
								<SecondaryButton fullWidth onClick={() => setIsOpen(false)}>
									Indietro
								</SecondaryButton>
							</div>
						</div>
					</div>
				</DragCloseDrawer>
			) : (
				<SpringModal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					title={title}
					text={text}
					head={head}
					cancel={cancel}
					onClick={onClick}
					user={user}
				/>
			)}
		</div>
	);
};

const SpringModal = ({
	user,
	isOpen,
	setIsOpen,
	text,
	onClick,
	cancel,
}) => {
	useEffect(() => {
		console.log(cancel);
	}, []);
	const livello = LevelsUtils.getLevel(user.level + 1);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					onClick={() => setIsOpen(false)}
					className="backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
				>
					<motion.div
						initial={{ scale: 0, rotate: "12.5deg" }}
						animate={{ scale: 1, rotate: "0deg" }}
						exit={{ scale: 0, rotate: "0deg" }}
						onClick={(e) => e.stopPropagation()}
						className="bg-background p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
					>
						<h3 className="text-2xl font-extrabold text-center ">
								COMPLIMENTI!
							</h3>
							<h3 className="text-2xl font-extrabold text-center mb-4 	">
								Hai raggiunto il livello {livello.number}
							</h3>
						<div className="relative z-10 flex flex-col justify-center items-center gap-4">
						<div className="w-24">{livello.img()}</div>
							<p className="font-bold">Gifter {livello.nome}</p>
							<p className="text-center">{livello.descr}</p>
							<p className="font-bold text-center text-xl ">
								Prosegui al prossimo step <br /> e ricevi la tua la ricompensa
							</p>
							<p className="text-center ">{text}</p>
							<div className="flex gap-2 w-full">
								<SecondaryButton fullWidth onClick={() => setIsOpen(false)}>
									Indietro
								</SecondaryButton>
								<LoadingBtn fullWidth onCall={onClick}>
								<div className="flex items-center justify-center gap-4">
										<span>Riscatta</span>
										<span className="px-2 py-1 flex items-center  justify-center space-x-1 md:font-bold font-semibold text-white ">
											<span>{livello.reward}</span>
											<div className="w-5">
												<Punti />
											</div>
										</span>
									</div>
								</LoadingBtn>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

const DragCloseDrawer = ({ open, setOpen, children }) => {
	const [scope, animate] = useAnimate();
	const [drawerRef, { height }] = useMeasure();

	const y = useMotionValue(0);
	const controls = useDragControls();

	const handleClose = async () => {
		animate(scope.current, {
			opacity: [1, 0],
		});

		const yStart = typeof y.get() === "number" ? y.get() : 0;

		await animate("#drawer", {
			y: [yStart, height],
		});

		setOpen(false);
	};

	return (
		<>
			{open && (
				<motion.div
					ref={scope}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					onClick={handleClose}
					className="fixed inset-0 z-50 backdrop-blur"
				>
					<motion.div
						id="drawer"
						ref={drawerRef}
						onClick={(e) => e.stopPropagation()}
						initial={{ y: "100%" }}
						animate={{ y: "0%" }}
						transition={{
							ease: "easeInOut",
						}}
						className="absolute bottom-0 h-[65vh] w-full overflow-hidden rounded-t-3xl bg-white"
						style={{ y }}
						drag="y"
						dragControls={controls}
						onDragEnd={() => {
							if (y.get() >= 100) {
								handleClose();
							}
						}}
						dragListener={false}
						dragConstraints={{
							top: 0,
							bottom: 0,
						}}
						dragElastic={{
							top: 0,
							bottom: 0.5,
						}}
					>
						<div className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-white p-4">
							<button
								onPointerDown={(e) => {
									controls.start(e);
								}}
								className="h-2 w-14 cursor-grab touch-none rounded-full bg-grigio active:cursor-grabbing"
							></button>
						</div>
						<div className="relative z-0 h-full overflow-y-scroll p-4 pt-12">
							{children}
						</div>
					</motion.div>
				</motion.div>
			)}
		</>
	);
};

export default UpgradeLvl;
