import React from "react";

function Icon({color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
            className="w-full"
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM8.253 11.692L7.03 10.47a.75.75 0 10-1.06 1.06l2 2a.75.75 0 001.226-.251c.957-2.392 2.138-3.793 3.048-4.59.456-.399.849-.65 1.117-.797a3.604 3.604 0 01.371-.178l.01-.004a.75.75 0 00-.48-1.421h-.001l-.002.001-.006.002-.013.005a2.064 2.064 0 00-.17.067 5.074 5.074 0 00-.431.213 7.909 7.909 0 00-1.383.984c-.939.821-2.047 2.12-3.003 4.131z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_467_2870"
                    x1="10"
                    x2="10"
                    y1="0"
                    y2="20"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8FBB3B"></stop>
                    <stop offset="1" stopColor="#8FBB3B"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default Icon;
