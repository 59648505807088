import React, { useEffect, useState } from "react";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DropdownLocation from "../../Components/Molecules/Dropdown/DropdownLocation";
import StaggeredDropDown from "../../Components/Molecules/Dropdown/StaggeredDropDown";
import CategorieDropdown from "../../Components/Molecules/Dropdown/CategorieDropdown";
import StaggeredDrawer from "../../Components/Molecules/Drawer/StaggeredDrawer";
import CategorieDrawer from "../../Components/Molecules/Drawer/CategorieDrawer";
import LocationDrawer from "../../Components/Molecules/Drawer/LocationDrawer";
import { IoClose, IoSearch } from "react-icons/io5";
import GiftAPI from "../../utils/API/GiftAPI";
import useUserStore from "../../stores/userStore";
import texture_white from "../../assets/Textures/Texture Square grigio 2 (1).svg";
import bagliore_sfondo from "../../assets/Sfondi/bagliore sfondo grigio.svg";
import SliderToggle from "../../Components/Atoms/Tabs/MapTab";
import Map from "./Map";
import CardContainer from "../../Components/Molecules/Container/CardContainer/CardContainer";
import GiftUtils from "../../utils/GiftUtils";
import useFilterStore from "../../stores/filterStore";
import StarHandshake from "../../assets/Illustrazioni/StarHandshake";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import useNavbarStore from "../../stores/navbarStore";

function Home() {
	const user = useUserStore((state) => state.user);
	const logged = useUserStore((state) => state.isLogged);
	const [loaded, setLoaded] = useState(false);
	const [ultimiArrivi, setUltimiArrivi] = useState(100);
	const [regaliUltimiArrivi, setRegaliUltimiArrivi] = useState([]);
	const [isOpen, setIsOpen] = useState(0);
	const status = useFilterStore((state) => state.status);
	const availability = useFilterStore((state) => state.availability);
	const category = useFilterStore((state) => state.category);
	const bookingStatus = useFilterStore((state) => state.bookingStatus);
	const coordinate = useFilterStore((state) => state.coordinate);
	const setStatus = useFilterStore((state) => state.setStatus);
	const setAvailability = useFilterStore((state) => state.setAvailability);
	const setCategory = useFilterStore((state) => state.setCategory);
	const setBookingStatus = useFilterStore((state) => state.setBookingStatus);
	const setCoordinate = useFilterStore((state) => state.setCoordinate);
	const [search, setSearch] = useState("");
	const [selectedMap, setSelectedMap] = useState("list");
	const [selectCity, setSelectCity] = useState(false);
	const {setNavbarVisible, setFootbarVisible} = useNavbarStore();

	useEffect(() => {
		user?.location ? setSelectCity(true) : setSelectCity(false);
		setNavbarVisible(true);
		setFootbarVisible(true);
	}, [window.location.pathname]);
	

	const areFiltersActive = () => {
		return (
			coordinate.lat !== (user?.location ? user.location.lat : null) ||
			coordinate.lng !== (user?.location ? user.location.lng : null) ||
			availability.length > 0 ||
			status.length > 0 ||
			category.length > 0 ||
			bookingStatus.length > 0
		);
	};
	// Function to clear filters and cookies
	const cancellaCoordinate = () => {
		// Reset filter states
		setCoordinate({
			lat: user?.location ? user.location.lat : null,
			lng: user?.location ? user.location.lng : null,
			city: user?.location ? user.location.city : null,
			range: user?.location ? 100 : null,
		});
		user?.location ? setSelectCity(true) : setSelectCity(false);
	};

	const cancellaFiltri = () => {
		setAvailability([]);
		setStatus([]);
		setCategory([]);
		setBookingStatus([]);
		cancellaCoordinate();
	};

	useEffect(() => {
		fetchRegaliUltimiArrivi();
	}, [coordinate, availability, status, category, bookingStatus]);

	const fetchRegaliUltimiArrivi = async () => {
		try {
			const params = new URLSearchParams(window.location.search);
			setLoaded(false);

			const searchTerm = params.get("search") || null;

			const gifts = await GiftAPI.getPublic({
				searchTerm,
				category,
				availability,
				status,
				coordinate,
			});

			setRegaliUltimiArrivi(gifts);
			setLoaded(true);
			return () => {""};
		} catch (error) {
			
			return () => {""};
		} finally {
			 // Imposta un timer di 5 secondi per verificare se loaded è true
			
		}
	};

	const handleSetIsOpen = (dropdownNumber) => {
		const body = document.querySelector("body");
		if (window.innerWidth < 768) {
			if (isOpen === dropdownNumber || dropdownNumber === false) {
				body.style.overflow = "auto";
			} else {
				body.style.overflow = "hidden";
			}
		}
		setIsOpen((prevIsOpen) =>
			prevIsOpen === dropdownNumber ? 0 : dropdownNumber
		);
	};

	useEffect(() => {
		// Crea un'istanza di URLSearchParams per analizzare i parametri della query string
		const params = new URLSearchParams(window.location.search);

		// Logga la query string completa per il debug
		console.log(params.toString());

		// Funzione di supporto per ottenere e decodificare i parametri
		function getDecodedParam(param) {
			const value = params.get(param);
			return value ? decodeURIComponent(value) : null;
		}

		// Gestisci il parametro "search" con un caso speciale per "null"
		const searchParam = getDecodedParam("search");
		setSearch(searchParam === "null" ? "" : searchParam || "");
	}, [user]);

	const handleSearch = (e) => {
		e.preventDefault();
		const encodedSearch = encodeURIComponent(search);
		window.location.href = encodedSearch ? `/?search=${encodedSearch}` : "/";
	};


	return (

			<div
				
				className={`flex min-h-[100vh] overflow-y-auto overflow-x-hidden flex-col pb-20 relative items-center mb-10 ${selectedMap === "map" ? "space-y-0 md:space-y-7" : "space-y-7"}`}
			>
				<div
					style={{
						backgroundImage: `url('${texture_white}'`,
					}}
					className={`w-full md:h-[40vh] rounded-b-lg bg-cover  bg-bottom ${
						selectedMap === "map" ? "hidden md:flex " : "flex"
					} flex-col justify-center items-center  `}
				>
					<div
						style={{
							backgroundImage: `url('${bagliore_sfondo}'`,
							backgroundSize: "110%",
						}}
						className="md:w-[70%] w-[100%] bg-center bg-no-repeat md:px-20 md:py-32 py-10 flex flex-col gap-4 justify-center items-center "
					>
						<div className="md:flex hidden">
							{logged ? (
								<h1 className="text-verdeScuro text-3xl font-semibold">
									Ciao {user.name}, cosa stai cercando?
								</h1>
							) : (
								<div className="flex flex-col justify-center items-center gap-5">
									<h1 className="text-verdeScuro text-3xl font-semibold">
										Te lo regalo se lo vieni a prendere.
									</h1>
									<h2 className="text-verdeScuro/70 text-lg font-semibold">
										Regala o prenota un oggetto, dagli una seconda vita. Se lo
										merita :)
									</h2>
								</div>
							)}
						</div>
						<div className="w-full flex md:flex-row flex-col-reverse  px-4 md:px-0 justify-center items-center md:gap-8 gap-2">
							<DropdownLocation
								isOpen={isOpen === 1}
								setIsOpen={handleSetIsOpen}
								opennth={1}
								coordinate={coordinate}
								setCoordinate={setCoordinate}
								cancellaFiltro={cancellaCoordinate}
								setSelectCity={setSelectCity}
								selectCity={selectCity}
							/>
							<form
								id="searchNavbar"
								className=" w-full  h-12 flex bg-white items-center relative focus:ring-verde ring-verde border border-grigio rounded-lg pr-0 py-0"
								onSubmit={handleSearch}
							>
								<input
									value={search}
									onChange={(e) => setSearch(e.currentTarget.value)}
									onInput={(e) => setSearch(e.currentTarget.value)}
									type="search"
									className="w-full  bg-transparent text-sm appearance-none md:ring-none border-0 focus:ring-0 placeholder:text-verdeScuro/50"
									placeholder="Mobile, bicicletta, sedia..."
								/>
								<button
									type="submit"
									className="group bg-verde text-bianco flex justify-center items-center p-0 rounded-r-md h-full aspect-square cursor-pointer"
								>
									<IoSearch className="w-5 aspect-square fill-white " />
								</button>
							</form>
							<h1 className="self-start font-semibold md:hidden">
								Cosa stai cercando?
							</h1>
						</div>
						<div className="w-full self-start ml-4 mt-4 md:ml-0">
							{window.innerWidth < 768 && (
								<p className="font-semibold mb-1">Filtra per:</p>
							)}
							<div
								className="flex md:justify-center items-center md:pl-32 pr-8 elemento-scrollable md:overflow-x-visible overflow-x-auto z-30 w-full gap-5"
							>
								{window.innerWidth > 768 && (
									<nobr className="font-semibold mb-1">Filtra per:</nobr>
								)}
								<button
									disabled={!areFiltersActive()}
									onClick={() => cancellaFiltri()}
									className={`flex md:hidden items-center gap-2 px-3 py-2 rounded-md text-white border-2 ${
										areFiltersActive()
											? "opacity-100 flex"
											: "opacity-0 hidden md:flex"
									} ${
										false ? "bg-grigino" : "bg-verdeScuro/80"
									} border-transparent transition-colors`}
								>
									<IoClose />
									<span className="font-medium  text-sm">
										<nobr>Cancella Filtri</nobr>
									</span>
								</button>
								<CategorieDropdown
									isOpen={isOpen === 3}
									setIsOpen={handleSetIsOpen}
									opennth={3}
									micro={category}
									setMicro={setCategory}
								/>
								<StaggeredDropDown
									isOpen={isOpen === 2}
									setIsOpen={handleSetIsOpen}
									opennth={2}
									titolo="Condizioni"
									setCheckedItems={setStatus}
									checkedItems={status}
									opzioni={Object.values(GiftUtils.getStatus()).map(
										(item) => item
									)}
								/>

								<StaggeredDropDown
									isOpen={isOpen === 4}
									setIsOpen={handleSetIsOpen}
									opennth={4}
									titolo="Disponibilità"
									setCheckedItems={setAvailability}
									checkedItems={availability}
									opzioni={Object.values(GiftUtils.getAvailability()).map(
										(item) => item
									)}
								/>
								{/*<StaggeredDropDown
								isOpen={isOpen === 5}
								setIsOpen={handleSetIsOpen}
								opennth={5}
								titolo="Stato regalo"
								setCheckedItems={setBookingStatus}
								checkedItems={bookingStatus}
								opzioni={Object.values(GiftUtils.getBookingStatus()).map((item) => item)}
								/>*/}

								<button
									disabled={!areFiltersActive()}
									onClick={() => cancellaFiltri()}
									className={`md:flex hidden items-center gap-2 px-3 py-2 rounded-md text-white border-2 ${
										areFiltersActive()
											? "opacity-100 flex"
											: "opacity-0 hidden md:flex"
									} ${
										false ? "bg-grigino" : "bg-verdeScuro/80"
									} border-transparent transition-colors`}
								>
									<IoClose />
									<span className="font-medium  text-sm">
										<nobr>Cancella Filtri</nobr>
									</span>
								</button>
							</div>
						</div>
						{!logged && (
							<div className="w-full flex justify-center gap-12">
								<a
									className="font-semibold cursor-pointer hover:underline"
									href="/FAQ"
								>
									Come funziona?
								</a>
								
							</div>
						)}
					</div>
				</div>
				<div className="w-full md:relative fixed md:left-0 md:translate-x-0 md:bottom-0 left-1/2 -translate-x-1/2 bottom-20 md:z-10 z-50 flex justify-center">
					<SliderToggle selected={selectedMap} setSelected={setSelectedMap} />
				</div>
				{selectedMap === "map" ? (
					loaded && (
						<div className="w-full relative z-10">
							{" "}
							<div className="md:w-[80vw] md:pl-20 h-[100dvh] md:h-full">
								<Map gifts={regaliUltimiArrivi} />
							</div>
						</div>
					)
				) : (
					<CardContainer>
						{loaded ? (
							regaliUltimiArrivi.map((item, i) => {
								if (i < ultimiArrivi) {
									return (
										<div className="cardRegaloPubblica" key={i}>
											<CardPublic
												gift={item}
												chiave={i}
												dash={user?.uid === item.owner}
											/>
										</div>
									);
								}
								return null;
							})
						) : (
							<div className="col-span-full">
								<Caricamento />
							</div>
						)}

						{regaliUltimiArrivi.length === 0 && loaded && (
							<div className="col-span-full flex flex-col justify-center items-center gap-12">
								<p className="text-lg text-center text-verdeScuro/70 font-semibold">
									In questa zona non ci sono regali con le caratteristiche
									selezionate
								</p>
								<StarHandshake />
								<p className="text-lg text-center text-verdeScuro/70 font-semibold">
									Sii tu il primo a regalare qualcosa <br /> in questa zona
								</p>
								<PrimaryButton href="/regala">
									<span className="text-3xl mr-2 mb-2">+</span>Fai un regalo
								</PrimaryButton>
							</div>
						)}
					</CardContainer>
				)}
				{!selectedMap && regaliUltimiArrivi.length > ultimiArrivi && (
					<div className="flex w-full justify-center">
						<button
							onClick={() => setUltimiArrivi(ultimiArrivi + 8)}
							className="py-2 px-3 text-white bg-verde rounded-lg"
						>
							Mostra altro
						</button>
					</div>
				)}
				{window.innerWidth < 768 && (
					<div className="md:hidden">
						<LocationDrawer
							isOpen={isOpen === 1}
							setIsOpen={handleSetIsOpen}
							opennth={1}
							coordinate={coordinate}
							setCoordinate={setCoordinate}
							cancellaFiltro={cancellaCoordinate}
							setSelectCity={setSelectCity}
							selectCity={selectCity}
						/>
						<CategorieDrawer
							isOpen={isOpen === 3}
							setIsOpen={handleSetIsOpen}
							opennth={3}
							micro={category}
							setMicro={setCategory}
						/>
						<StaggeredDrawer
							isOpen={isOpen === 2}
							setIsOpen={handleSetIsOpen}
							opennth={2}
							titolo="Condizioni"
							setCheckedItems={setStatus}
							checkedItems={status}
							opzioni={Object.values(GiftUtils.getStatus()).map((item) => item)}
						/>
						<StaggeredDrawer
							isOpen={isOpen === 4}
							setIsOpen={handleSetIsOpen}
							opennth={4}
							titolo="Disponibilità"
							setCheckedItems={setAvailability}
							checkedItems={availability}
							opzioni={Object.values(GiftUtils.getAvailability()).map(
								(item) => item
							)}
						/>
						<StaggeredDrawer
							isOpen={isOpen === 5}
							setIsOpen={handleSetIsOpen}
							opennth={5}
							titolo="Stato regalo"
							setCheckedItems={setBookingStatus}
							checkedItems={bookingStatus}
							opzioni={Object.values(GiftUtils.getBookingStatus()).map(
								(item) => item
							)}
						/>
					</div>
				)}
			</div>

	);
}

export default Home;
