import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useEditingGiftStore from "../../stores/editingGiftStore";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import ChipTabs from "../../Components/Atoms/Tabs/ChipTabs";
import useNavbarStore from "../../stores/navbarStore";
import GattiniSvg from "../../assets/Illustrazioni/gattini.svg";
import UserAPI from "../../utils/API/UserAPI";
import BookingAPI from "../../utils/API/BookingAPI";
import { toast } from "sonner";
import RadioGroup from "../../Components/Molecules/RadioButtons/RadioGroup";
import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";
import Punti from "../../assets/Punti";
import { showPrompt } from "../../Components/Prompts/Prompts";
import {
    Availability,
    CreatedAt,
    FullScreenGallery,
    GiftCategory,
    GiftDescription,
    GiftStatus,
    Location,
    Separator,
} from "../../utils/InfoRegalo";
import Mappa from "../../Components/Atoms/Mappa/Mappa";
import StripCaricaFoto from "../Regala/components/StripCaricaFoto";
import PosizioneRegalo from "../Regala/components/PosizioneRegalo";
import CategoriaRegalo from "../Regala/components/CategoriaRegalo";
import StatusRegalo from "../Regala/components/StatusRegalo";
import DisponibilitaRegalo from "../Regala/components/DisponibilitaRegalo";
import NomeDescrizioneRegalo from "../Regala/components/NomeDescrizioneRegalo";
import useDeviceDetection from "../../utils/useDeviceDetection";
import useUserStore from "../../stores/userStore";
import ProfilePicName from "../../Components/Atoms/ProfilePic/ProfilePicName";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import { ReviewSpringModal } from "../../Components/Atoms/Modals/Recensisci";
import AreYouShure from "../../Components/Atoms/Modals/AreYouShure";
import DeleteGift from "../Regala/components/DeleteGift";

const ProdottoApertoPrivato = () => {
    const { id } = useParams();
    const user = useUserStore((state) => state.user);
    const loadGift = useEditingGiftStore((state) => state.loadByGiftId);
    const gift = useEditingGiftStore((state) => state.gift);
    const giftImages = useEditingGiftStore((state) => state.images);
    const giftName = useEditingGiftStore((state) => state.name);
    const giftDescription = useEditingGiftStore((state) => state.description);
    const giftLocation = useEditingGiftStore((state) => state.location);
    const giftStatus = useEditingGiftStore((state) => state.status);
    const giftCategory = useEditingGiftStore((state) => state.category);
    const giftAvailability = useEditingGiftStore((state) => state.availability);
    const giftCreatedAt = useEditingGiftStore((state) => state.createdAt);
    const giftOwner = useEditingGiftStore((state) => state.owner);
    const giftIsDelivered = useEditingGiftStore((state) => state.isDelivered);
    const giftHasPendingChanges = useEditingGiftStore(
        (state) => state.hasPendingChanges
    );
    const bookings = useEditingGiftStore((state) => state.bookings);
    const setBookings = useEditingGiftStore((state) => state.setBookings);
    const device = useDeviceDetection();
    const navigation = useNavigate();
    useEditingGiftStore((state) => state.snapshot);

    const [isOpenImg, setIsOpenImg] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState(null);

    const [bookingRadioItems, setBookingRadioItems] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [assignedBooking, setAssignedBooking] = useState(null);

    const nomeRegalo = useEditingGiftStore((state) => state.name);

    const updateGift = useEditingGiftStore((state) => state.updateGift);

    const tabs = ["Gestisci", "Visualizza", "Modifica"];
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const renderPreviewSelector = activeTab === tabs[1] || device === "Desktop";

    const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbar = useNavbarStore((state) => state.setFootbarVisible);
    const [consegnato, setConsegnato] = useState(false);
    const [otherUser, setOtherUser] = useState({});
    const [isReviewOpen, setIsReviewOpen] = useState(false);
    const navigate = useNavigate();
    const stileSezione = `flex px-6 md:px-0 flex-col justify-between items-start pb-6 h-full`;

    const ensureIsImageUrl = (image) => {
        if (image !== null && typeof image === "object" && "image" in image) {
            return image.image;
        }
        return image;
    };

    useEffect(() => {
        if (giftOwner && user.uid !== giftOwner)
            navigation(`/prodotto/${id}`, { replace: true });
    }, [giftOwner]);

    useEffect(() => {
        const initialize = async () => {
            try {
                // Disabilita Navbar e Footbar
                setNavbar(false);
                setFootbar(false);

                // Carica il regalo
                await loadGift(id);

                const giftData = await useEditingGiftStore.getState().gift;

                // Procedi solo se il regalo è disponibile
                if (giftData && giftData.bookingsLength > 0) {
                    await handleLoad(giftData);
                }
            } catch (error) {
                toast.error("Errore durante il caricamento del regalo");
            }
        };

        initialize();
    }, []); // Eseguito solo al primo render

    const handleLoad = async (giftData) => {
        try {
            console.log("Gift:", giftData);

            const bookings = await BookingAPI.getGiftBookings(id);
            const loadedBookings = bookings.filter(
                (booking) => !booking.isCanceled && booking.isRequested
            );

            if (giftData.assignedTo) {
                const otherUser = await UserAPI.getById(giftData.assignedTo);
                setOtherUser(otherUser);
            }
            console.log("Bookings:", loadedBookings);

            setBookings(loadedBookings);

            const idBookingConsegna = loadedBookings.find(
                (b) =>
                    b.receiver.uid === giftData.assignedTo &&
                    b.isAccepted &&
                    !b.isCanceled &&
                    b.isConfirmedByOwner
            );
            const assigned = loadedBookings.find(
                (b) => !b.isCanceled && b.isAccepted
            );

            if (assigned) {
                console.log("Assigned booking:", assigned);

                setSelectedBooking(assigned);
                setAssignedBooking(assigned);
            }

            if (idBookingConsegna) {
                setConsegnato(true);
            }

            console.log("Loaded bookings:", loadedBookings);
            if (loadedBookings.length > 0) {
                const fetchUsers = async (uids) => {
                    return await Promise.all(
                        uids.map((uid) => UserAPI.getById(uid))
                    );
                };

                const users = loadedBookings.length
                    ? await fetchUsers(
                          loadedBookings.map((b) => b.receiver.uid)
                      )
                    : null;

                const filteredUsers = users?.filter(
                    (user) => user.uid !== giftData.assignedTo
                );

                setBookingRadioItems(
                    users.length &&
                        filteredUsers?.map(
                            (user) =>
                                ({
                                    label: `${user.name} ${user.surname[0]}.`,
                                    value: user.uid,
                                    icon: (
                                        <ProfilePic w={10} userId={user.uid} />
                                    ),
                                } || [])
                        )
                );
            }
        } catch (error) {
            toast.error(error.message);
            console.error(
                "Errore durante il caricamento delle prenotazioni:",
                error
            );
        }
    };

    useEffect(() => {
        if (
            !selectedPreview ||
            !giftImages.find((image) => image.image === selectedPreview)
        )
            setSelectedPreview(ensureIsImageUrl(giftImages[0]));
    }, [giftImages]);

    const handleSalvaModifiche = async () => {
        try {
            // await useEditingGiftStore.getState().save();
            await updateGift(id);
            toast.success("Modifiche salvate con successo");
        } catch (e) {
            toast.error(e.message);
            throw e;
        }
    };

    const handleAssegna = async () => {
        try {
            if (!selectedBooking) {
                const error = "Seleziona un utente a cui assegnare";
                toast.error(error);
                throw new Error(error);
            }
            const idBookingAssegnazione = bookings.find(
                (b) =>
                    b.receiver.uid === selectedBooking &&
                    b.gift.id === id &&
                    b.isCanceled === false
            )._id;

            await BookingAPI.assignGift(idBookingAssegnazione);
            navigate("/chat/" + idBookingAssegnazione);
        } catch (e) {
            console.log(e);
            toast.error(e.message);
            throw e;
        }
    };

    const cancelAssignment = async () => {
        try {
            const idBookingAssegnazione = bookings.find(
                (b) =>
                    b.receiver.uid === gift.assignedTo &&
                    b.isAccepted === true
            )._id;
            console.log("bo",idBookingAssegnazione);

            await BookingAPI.cancelBooking(idBookingAssegnazione);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const confirmDeliver = async () => {
        try {
            const idBookingAssegnazione = bookings.find(
                (b) =>
                    b.receiver.uid === gift.assignedTo &&
                    b.gift.id === id &&
                    b.isAccepted === true &&
                    b.isCanceled === false
            );
            console.log(idBookingAssegnazione);

            await BookingAPI.confirmGiftDelivery(idBookingAssegnazione._id);
            if (idBookingAssegnazione.isConfirmedByReceiver)
                showPrompt.pointsReceived(100);
            window.location.reload();
        } catch (e) {
            toast.error(
                "Si è verificato un errore durante la conferma di consegna"
            );
        }
    };

    const cancelDeliver = async () => {
        const idBookingAssegnazione = bookings.find(
            (b) =>
                b.receiver.uid === gift.assignedTo &&
                b.gift.id === id &&
                b.isAccepted === true &&
                b.isCanceled === false
        );
        console.log(idBookingAssegnazione);

        await BookingAPI.undoGiftDelivery(idBookingAssegnazione._id);
        window.location.reload();
    };

    const displayUser = (user) => {
        return (
            <div className="flex items-center gap-2">
                <ProfilePic userId={user} />
                <ProfilePicName textStyle={"text-xl"} userId={user} />
            </div>
        );
    };

    const sezioneGestisci = (
        <div
            className={`${stileSezione} md:w-[50%] md:place-self-center items-center`}
        >
            {bookings?.length > 0 ? (
                <div className="flex flex-col  justify-between h-full w-full">
                    {gift.assignedTo?.length > 0 ? (
                        <div className="flex flex-col items-center gap-10 md:mb-20 mb-10">
                            <div className="flex flex-col  items-center gap-2">
                                <p className="text-xl font-bold">
                                    Hai{" "}
                                    {gift.isDelivered
                                        ? "regalato"
                                        : consegnato
                                        ? "consegnato"
                                        : "assegnato"}{" "}
                                    questo{" "}
                                    {gift.isDelivered ? "oggetto" : "regalo"} a:{" "}
                                </p>
                                <span>{displayUser(gift.assignedTo)} </span>
                            </div>
                            {assignedBooking?.isConfirmedByOwner &&
                            assignedBooking?.isConfirmedByReceiver ? (
                                assignedBooking?.isReviewedByOwner ? (
                                    <p>Scambio effettuato!</p>
                                ) : (
                                    <div className="">
                                        <PrimaryButton
                                            onClick={() =>
                                                setIsReviewOpen(true)
                                            }
                                        >
                                            Lascia una recensione
                                        </PrimaryButton>
                                        <ReviewSpringModal
                                            userUid={gift.assignedTo}
                                            userName={otherUser.name}
                                            isOpen={isReviewOpen}
                                            setIsOpen={setIsReviewOpen}
                                            onReviewed={async () => {
                                                await BookingAPI.setReviewed(
                                                    selectedBooking._id
                                                );

                                                navigate(
                                                    "/profilo/" +
                                                        gift.assignedTo
                                                );
                                            }}
                                        />
                                    </div>
                                )
                            ) : consegnato ? (
                                <div className="flex md:flex-row flex-col gap-2">
                                    <AreYouShure
                                        onClick={cancelDeliver}
                                        secondary
                                        cancel
                                        text={
                                            "Annulla la consegna se non hai ancora consegnato il regalo al destinatario."
                                        }
                                        title={"Vuoi annullare la consegna?"}
                                        head={"Annulla consegna"}
                                    />
                                </div>
                            ) : (
                                <div className="flex md:flex-row flex-col gap-2">
                                    <AreYouShure
                                        onClick={cancelAssignment}
                                        secondary
                                        cancel
                                        text={
                                            "Annullando l'assegnazione, il regalo tornerà disponibile per altri utenti, e questo utente verrà rimosso dai prenotati."
                                        }
                                        title={"Vuoi annullare l'assegnazione?"}
                                        head={"Annulla assegnazione"}
                                    />
                                    <AreYouShure
                                        text={
                                            "Confermando la consegna, il regalo verrà segnato come consegnato al destinatario."
                                        }
                                        title={"Confermi la consegna?"}
                                        head={"Conferma consegna"}
                                        onClick={confirmDeliver}
                                    />
                                </div>
                            )}
                            <div className="mt-5">
                                <p className="text-xl mb-5 flex flex-col gap-3 font-semibold">
                                    Altri prenotati:
                                </p>
                                {bookingRadioItems.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex mt-3 items-center space-x-4 w-full text-xl"
                                        >
                                            {displayUser(item.value)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-3 md:mb-20 mb-10">
                            <div className="flex mt-5 items-center space-x-4 w-full text-xl">
                                <span className="font-bold">Prenotato da:</span>
                                <span className="underline">
                                    {bookings.length}/20
                                </span>
                            </div>
                            <RadioGroup
                                toggle
                                containerClass="flex flex-col space-y-2 text-xl w-full"
                                items={bookingRadioItems}
                                radioGroup="booking"
                                onChange={(e) => {
                                    setSelectedBooking(
                                        e.target.value !== selectedBooking
                                            ? e.target.value
                                            : null
                                    );
                                }}
                                selectedItem={
                                    gift.assignedTo?.length > 0
                                        ? null
                                        : selectedBooking
                                }
                            />

                            <AreYouShure
                                text={
                                    "Confermando l'assegnazione, il regalo verrà assegnato al destinatario selezionato."
                                }
                                title={"Confermi l'assegnazione?"}
                                head={"Assegna"}
                                disabled={!selectedBooking}
                                onClick={handleAssegna}
                            />
                        </div>
                    )}

                    <div className="flex justify-center w-full text-lg"></div>
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center text-center p-4 w-full">
                    <div className="w-[80%] mb-4">
                        <img
                            src={GattiniSvg}
                            alt="immagine"
                            className="place-self-center object-contain h-[30vh]"
                        />
                    </div>
                    <h4 className="text-xl pb-1 font-bold">
                        Non ci sono ancora prenotazioni
                    </h4>
                    <p className="text-sm px-8">
                        Qui potrai monitorare le persone prenotate potendo
                        assegnare a chi meglio credi
                    </p>
                </div>
            )}
        </div>
    );

    const sezioneVediRegalo = (
        <div className={`${stileSezione} md:flex-row`}>
            <div className="flex flex-col pr-4 w-full">
                <div className="flex items-center justify-between w-full">
                    {" "}
                    <div className="">
                        <p className="text-3xl font-bold">{giftName}</p>
                    </div>
                    <div className="md:flex hidden h-14 space-x-3 items-center justify-start ">
                        <p className="text-3xl font-semibold">100</p>
                        <div className="flex h-8 w-8 object-contain">
                            <Punti />
                        </div>
                    </div>
                </div>
                <div className="flex gap-4 mt-5">
                    <GiftStatus status={giftStatus} />
                    <Location city={giftLocation?.city} />
                </div>
                <div className="">
                    <GiftDescription descrizione={giftDescription} />
                </div>
                <Separator />
                <div className="">
                    <p className="text-2xl font-semibold">
                        Informazioni prodotto
                    </p>
                    <div className="flex flex-col gap-5 mt-8">
                        <div className="grid grid-cols-2">
                            <div className="flex items-center gap-2">
                                <GiftCategory category={giftCategory} />
                            </div>
                            <div className="flex items-center gap-2">
                                <CreatedAt createdAt={giftCreatedAt} />
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="flex items-center gap-2">
                                <Availability availability={giftAvailability} />
                            </div>
                        </div>
                    </div>
                </div>
                <Separator />
                <div className="">
                    <p className="hidden text-2xl font-semibold">
                        Altri prodotti simili
                    </p>
                </div>
            </div>
            <div className="w-full md:w-[30vw]">
                <div className="mt-5 rounded-lg">
                    <Mappa lat={giftLocation?.lat} lng={giftLocation?.lng} />
                </div>
            </div>
        </div>
    );

    const sezioneModifica = (
        <div
            className={`${stileSezione} md:w-[50%] md:place-self-center items-center -mt-5`}
        >
            <div className="w-full border-b-2">
                <StripCaricaFoto />
            </div>
            <div className="w-full border-b-2">
                <PosizioneRegalo />
            </div>
            <div className="w-full border-b-2">
                <CategoriaRegalo />
            </div>
            <div className="w-full border-b-2">
                <DisponibilitaRegalo />
            </div>
            <div className="w-full border-b-2">
                <StatusRegalo />
            </div>
            <div className="w-full border-b-0">
                <NomeDescrizioneRegalo />
            </div>
            <div className="w-full mt-5">
                <LoadingBtn
                    disabled={!giftHasPendingChanges()}
                    fullWidth
                    onCall={handleSalvaModifiche}
                >
                    Salva modifiche
                </LoadingBtn>
            </div>
            <div className="w-full border-b-0">
                <DeleteGift />
            </div>
        </div>
    );

    const renderSezioneModifica = () =>
        giftIsDelivered ? (
            <div
                className="relative py-2"
                onClick={() =>
                    toast.info("Non puoi modificare un regalo consegnato")
                }
            >
                {/* <div className='absolute top-0 left-0 right-0 bottom-0 bg-grigino/20 w-full h-full z-10 text-grigio'></div> */}
                <div className="pointer-events-none">{sezioneModifica}</div>
            </div>
        ) : (
            sezioneModifica
        );

    return (
        <div className="flex flex-col items-center relative justify-between gap-y-4 min-h-[100vh] md:h-full">
            <div className="absolute left-0 top-5 z-10">
                <Indietro />
            </div>
            <div
                className={`flex flex-col-reverse md:flex-row md:aspect-auto w-full md:pt-6 md:pb-6 md:px-32`}
            >
                {renderPreviewSelector && (
                    <div className="stackMiniImages flex md:flex-col md:w-20 w-14 shrink-0 justify-start items-start md:mt-0 mt-2 md:mr-3 gap-3 mx-2 md:mx-0">
                        {giftImages.length > 1 &&
                            giftImages.map((img, index) => (
                                <img
                                    key={index}
                                    className="rounded-lg aspect-square object-cover cursor-pointer"
                                    src={ensureIsImageUrl(img)}
                                    alt={nomeRegalo}
                                    onClick={() =>
                                        setSelectedPreview(
                                            ensureIsImageUrl(img)
                                        )
                                    }
                                />
                            ))}
                    </div>
                )}

                <div className="flex justify-center aspect-square md:aspect-auto bg-grigino rounded-lg w-full h-[50vh]">
                    <img
                        className="md:rounded-lg h-[50vh] w-auto bg-opacity-5 object-contain cursor-pointer"
                        src={selectedPreview}
                        alt={nomeRegalo}
                        onClick={() => setIsOpenImg(true)}
                    />
                </div>
                <FullScreenGallery
                    isOpen={isOpenImg}
                    setIsOpen={setIsOpenImg}
                    images={giftImages.map((img) => ensureIsImageUrl(img))}
                    startFrom={giftImages.findIndex(
                        (img) => img.image === selectedPreview
                    )}
                />
            </div>

            <div className="flex flex-col w-full items-center my-2 ">
                <div className="flex w-[90vw] md:w-auto ">
                    <ChipTabs
                        tabs={tabs}
                        selected={activeTab}
                        setSelected={setActiveTab}
                    />
                </div>
            </div>

            <div className="flex-1 w-full md:px-32">
                {activeTab === tabs[0] && sezioneGestisci}
                {activeTab === tabs[1] && sezioneVediRegalo}
                {activeTab === tabs[2] && renderSezioneModifica()}
            </div>
        </div>
    );
};

export default ProdottoApertoPrivato;
