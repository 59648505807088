import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 194.99 224.56"
      className="fill-verde"
    >
      <g data-name="layer 1">
        <path
          d="M71.06 51.46h20.88c.02-.18.05-.37.06-.55-.86-6.92-2.89-15.32-5.75-21.61-3.19-7.01-9.41-14.47-16.93-16.95-7.34-2.42-15.26-.43-18.4 7.15-.74 1.78-1.14 3.7-1.17 5.62-.04 2.08 0 2.18.39 3.94.75 3.36 1.73 5.42 3.73 8.3 3.67 5.28 10.66 11.27 17.19 14.09zM123.57 51.46c7.59-2.87 15.02-9.51 18.89-16.28.99-1.73 1.23-2.24 1.8-4.06.24-.77.45-1.55.61-2.34.04-.17.18-1.12.22-1.33.35-3.52-.31-6.97-2.16-10.02-4.24-6.98-13.21-7.53-19.95-3.92-6.83 3.65-12.44 10.71-15.06 17.69-2.35 6.28-3.79 13.59-4.59 19.96.01.1.02.2.04.3h20.19z"
          className="cls-1 fill-none stroke-none"
        ></path>
        <path
          d="M187.55 51.46h-43.88c2.77-2.7 5.52-5.85 7.56-8.91 5.15-7.76 6.95-18 3.44-26.77C149.95 4 138.34-1.8 125.99.54c-11.2 2.13-22.74 13.36-28.19 23.02-.16.29-.3.59-.46.89C92.33 15 83.41 5.48 73.15 1.71c-11.43-4.19-24.5-.64-30.93 9.99-5.24 8.66-4.65 19.28-.14 28.08 2.05 3.98 5.51 8.27 8.93 11.67H7.44C3.33 51.45 0 54.78 0 58.89v30.68c0 4.11 3.33 7.44 7.44 7.44h.27v111.42c0 8.9 7.21 16.11 16.11 16.11h147.34c8.9 0 16.11-7.21 16.11-16.11V97.01h.27c4.11 0 7.44-3.33 7.44-7.44V58.89c0-4.11-3.33-7.44-7.44-7.44zM107.92 31.2c2.62-6.98 8.23-14.04 15.06-17.69 6.74-3.6 15.71-3.06 19.95 3.92 1.85 3.05 2.51 6.49 2.16 10.02-.04.21-.18 1.17-.22 1.33-.17.79-.37 1.57-.61 2.34-.57 1.82-.81 2.33-1.8 4.06-3.87 6.77-11.3 13.41-18.89 16.28h-20.19c-.01-.1-.02-.2-.04-.3.79-6.37 2.23-13.68 4.59-19.96zm-57.78-2.13c-.4-1.76-.42-1.87-.39-3.94.03-1.91.43-3.84 1.17-5.62 3.14-7.59 11.06-9.57 18.4-7.15 7.52 2.48 13.74 9.93 16.93 16.95 2.86 6.29 4.89 14.68 5.75 21.61l-.06.55H71.06c-6.52-2.82-13.52-8.81-17.19-14.09-2-2.88-2.98-4.94-3.73-8.3zm37.83 162.97l-46.36-49.87c-.77-.83-.72-2.13.12-2.9l12.61-11.54c.83-.76 2.1-.71 2.87.11l29.97 31.98c.87.92 2.35.88 3.15-.1l51.44-62.1c.72-.87 2.01-.99 2.88-.27l13.16 10.9c.87.72.99 2.01.27 2.88l-66.94 80.82c-.81.98-2.29 1.02-3.16.09z"
          className="cls-2 stroke-none"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;