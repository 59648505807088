import React, { useEffect, useState } from "react";
import ReceiverChats from "./ReceiverChats";
import GiverChats from "./GiverChats/GiverChats";
import useBookingStore from "../../../stores/bookingStore";
import BookingAPI, { parseBooking } from "../../../utils/API/BookingAPI";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../firebase";
import useNavbarStore from "../../../stores/navbarStore";
import { motion } from "framer-motion";
import { getAbsoluteImage } from "../../../utils/API/CallBackend";

function ChatsList() {
    const [receiverSelected, setReceiverSelected] = useState(true);

    const setSelectedBooking = useBookingStore(
        (state) => state.setSelectedBooking
    );
    const setBookings = useBookingStore((state) => state.setBookings);
    const updateBookings = useBookingStore((state) => state.updateBookings);
    const appendMessage = useBookingStore((state) => state.appendMessage);

    const setNavbarVisible = useNavbarStore((state) => state.setNavbarVisible);

    const urlBooking = useParams().booking;

    const navigate = useNavigate();

    const loadBookings = async () => {
        try {
            const bookings = await BookingAPI.getBookings();

            /**
             * @type {Record<string, import("../../../utils/API/BookingAPI").Booking>}
             */
            const mappedBookings = {};

            for (let booking of bookings.flat()) {
                mappedBookings[booking._id] = booking;
            }

            if (urlBooking && urlBooking in mappedBookings) {
                if (
                    mappedBookings[urlBooking].owner.uid ===
                    auth.currentUser.uid
                ) {
                    setReceiverSelected(false);
                }
                setSelectedBooking(mappedBookings[urlBooking]);
            }

            setBookings(mappedBookings);

            const closeConnection = BookingAPI.onBookingUpdate(
                //On booking status change (canceled, confirmed ecc.)
                (booking) => {
                    booking = parseBooking(booking);
                    updateBookings(booking);
                },
                //On new chat message, message contains bookingId
                (message) => {
                    if (message.image) {
                        message.image = getAbsoluteImage(message.image);
                    }
                    appendMessage(message);
                }
            );

            return () => {
                closeConnection();
            };
        } catch (e) {
            //TODO: Handle error
            return () => {""};
        }
    };

    useEffect(() => {
        setNavbarVisible(false);
        loadBookings();
    }, [window.location.href]);

    const [selected, setSelected] = useState(0);
    return (
        <div className="h-full flex flex-col">
            <div className="flex h-16 shadow-sm items-center px-4 text-3xl  justify-center font-bold">
                Centro messaggi
            </div>
            <div className="mt-4 px-10">
                <div className="border md:w-auto w-full border-grigio relative rounded-lg bg-[#fafafa] p-1 flex justify-around items-center">
                    <button
                        onClick={() => {
                            if (!receiverSelected) {
                                navigate("/chat/");
                                setSelectedBooking({});
                            }
                            setReceiverSelected(true);
                            setSelected(0);
                        }}
                        className={`${
                            selected === 0 ? "text-white" : "text-verdeScuro"
                        }  transition-colors md:px-5 px-2 py-1 rounded-lg relative h-full flex-1`}
                    >
                        <span className="relative z-10">
                            <nobr>Adozioni</nobr>
                        </span>
                        {selected === 0 && (
                            <motion.span
                                layoutId="pill-tab"
                                transition={{ type: "spring", duration: 0.5 }}
                                className="absolute inset-0 z-0 bg-verdeScuro rounded-lg"
                            ></motion.span>
                        )}
                    </button>
                    <button
                        onClick={() => {
                            if (receiverSelected) {
                                navigate("/chat/");
                                setSelectedBooking({});
                            }
                            setReceiverSelected(false);
                            setSelected(1);
                        }}
                        className={`${
                            selected === 1 ? "text-white" : "text-verdeScuro"
                        }  transition-colors md:px-5 px-2 py-1 rounded-lg relative h-full flex-1`}
                    >
                        <span className="relative z-10">
                            <nobr>Donazioni</nobr>
                        </span>
                        {selected === 1 && (
                            <motion.span
                                layoutId="pill-tab"
                                transition={{ type: "spring", duration: 0.5 }}
                                className="absolute inset-0 z-0 bg-verdeScuro rounded-lg"
                            ></motion.span>
                        )}
                    </button>
                </div>
            </div>
            <div className="h-full mt-4 flex-grow flex flex-col overflow-auto">
                {receiverSelected ? <ReceiverChats /> : <GiverChats />}
            </div>
        </div>
    );
}

export default ChatsList;

/*
<button className={`mr-4 ml-8 font-bold ${receiverSelected ? "text-black" : "text-gray-400"}`} onClick={() => {
                    if (!receiverSelected) {
                        navigate('/chat/');
                        setSelectedBooking({});
                    }
                    setReceiverSelected(true);
                }}>
                    Adozioni
                </button>
                <button className={`font-bold ${receiverSelected ? "text-gray-400" : "text-black"}`} onClick={() => {
                    if (receiverSelected) {
                        navigate('/chat/');
                        setSelectedBooking({});
                    }
                    setReceiverSelected(false);
                }}>
                    Donazioni
                </button>
*/
