import React, { useEffect, useRef, useState, useContext } from "react";
import { auth } from "../../../firebase";
import ProfilePic from "../../../Components/Atoms/ProfilePic/ProfilePic";
import Caricamento from "../../../Components/Atoms/Caricamento/Caricamento";
import RenderMessage from "./RenderMessage";
import ConfirmBookingTile from "./ConfirmBookingTile";
import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";
import { ReducerContext } from "../../../rootReducer";
import ChatAPI from "../../../utils/API/ChatAPI";
import useBookingStore from "../../../stores/bookingStore";
import { useNavigate } from "react-router-dom";
import chatBackground from "../../../assets/Sfondi/chat_texture2.svg";
import chatBackgroundDesktop from "../../../assets/Sfondi/chat_texture2_desktop.svg";
import { toast } from "sonner";
import Camera from "../../../assets/Icons/Camera";
import { getAbsoluteImage } from "../../../utils/API/CallBackend";

function ChatMessages() {
    const [text, setText] = useState("");
    const loaded = useRef(false);
    const [imagePreview, setImagePreview] = useState(null);
    const { dispatch } = useContext(ReducerContext);
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const backgroundImage = isMobile ? chatBackground : chatBackgroundDesktop;

    const navigate = useNavigate();

    /**
     * @type {import("../../../utils/API/ChatAPI").Message[]}
     */
    const messages = useBookingStore((state) => state.displayedMessages);
    const setMessages = useBookingStore((state) => state.setDisplayedMessages);
    const appendMessage = useBookingStore((state) => state.appendMessage);

    const scrollRef = useRef(null);

    const fileInputRef = useRef(null);
    const textInputRef = useRef(null);
    let tmpImageFile = useRef(null);

    /**
     * @type {import("../../../utils/API/BookingAPI").Booking}
     */
    const booking = useBookingStore((state) => state.selectedBooking);
    const setSelectedBooking = useBookingStore(
        (state) => state.setSelectedBooking
    );
    const updateBooking = useBookingStore((state) => state.updateBookings);

    useEffect(() => {
        if (!loaded.current && booking._id) {
            dispatch({ type: "hide_sidebar_phone" });
            load();
        }
    }, [booking._id]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, []);

    const load = async () => {
        const chat = await ChatAPI.getMessages(booking._id);

        loaded.current = true;

        setMessages(chat.messages.reverse());
        updateBooking({
            _id: booking._id,
            isRead: true,
        });

        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };

    const onPhotoChoose = (e) => {
        tmpImageFile.current = e.target.files[0];
        const imageUrl = URL.createObjectURL(tmpImageFile.current);
        setImagePreview(imageUrl);
        const fileInput = e.target;
        fileInput.value = null;
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        if (textInputRef.current) {
            textInputRef.current.focus();
        }
    };

    function isalnum(c) {
        return (
            (c >= "a" && c <= "z") ||
            (c >= "A" && c <= "Z") ||
            (c >= "0" && c <= "9")
        );
    }

    const send = async (e) => {
        e.preventDefault();

        if (!isalnum(text) && !tmpImageFile.current) {
            return;
        }

        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }

        try {
            let imageUrl = null;

            if (tmpImageFile.current) {
                imageUrl = await ChatAPI.uploadMessageImage(
                    tmpImageFile.current,
                    booking._id
                );
                tmpImageFile.current = null;
                console.log(imageUrl);
            }

            const completeImageUrl = imageUrl ? getAbsoluteImage(imageUrl) : null;

            const randomId = Math.floor(Math.random() * 10000);

            setText("");
            setImagePreview(null);
            appendMessage({
                content: text,
                sendBy: auth.currentUser.uid,
                createdAt: new Date(),
                image: completeImageUrl,
                bookingId: booking._id,
                isLoading: true,
                id: randomId,
            });

            let sent = false;

            for (let i = 0; i < 3; i++) {
                try {
                    await ChatAPI.sendMessage(booking._id, {
                        content: text,
                        image: imageUrl,
                    });
                    sent = true;
                    break;
                } catch(e) {
                    if (!e.status || e.status !== 429) {
                        throw(e);
                    }
                }
            }

            if (!sent) {
                throw('Message not sent');
            }

            appendMessage({
                content: text,
                sendBy: auth.currentUser.uid,
                createdAt: new Date(),
                image: completeImageUrl,
                bookingId: booking._id,
                id: randomId,
            });
        } catch (e) {
            toast.error(
                "Si è verificato un errore durante la consegna del messaggio"
            );
        }
    };

    let fired = false;

    window.onkeydown = function (e) {
        if (!fired && e.key === "Enter") {
            fired = true;
            if (text.length > 0 && isalnum(text)) {
                send(e);
            }
        }
    };

    window.onkeyup = function () {
        fired = false;
    };

    if (!booking._id) {
        return <div />;
    }

    return (
        <div
            className={`${isMobile && "pb-16"} flex flex-col h-full`}
            style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
            <div
                className={`${
                    isMobile && "fixed left-0 top-0 w-full"
                } h-24 shadow-sm border-b-2 border-b-grigino bg-white flex p-4 items-center text-base font-light`}
            >
                <button
                    className="pr-4 md:hidden"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch({ type: "show_sidebar_phone" });
                        setSelectedBooking({});
                        navigate("/chat");
                    }}
                >
                    <Iconfrecciagiu className={"w-7 rotate-0 fill-white"} />
                </button>
                <div className="flex md:flex-row-reverse w-full justify-end items-center md:text-left text-right font-light cursor-pointer">
                    <div className="mr-3 md:ml-3 flex flex-col gap-1">
                        <p
                            onClick={() =>
                                navigate("/profilo/" + booking.otherUser.uid)
                            }
                            className="font-bold text-lg"
                        >
                            {booking.otherUser.completeName}
                        </p>{" "}
                        <p
                            onClick={() =>
                                booking.owner.uid !== booking.otherUser.uid
                                    ? navigate("/dashboard/" + booking.gift.id)
                                    : navigate("/prodotto/" + booking.gift.id)
                            }
                            className="text-base underline "
                        >
                            {" "}
                            {booking.gift.name}
                        </p>
                    </div>
                    <ProfilePic w={14} image={booking.otherUser.image} userId={booking.otherUser.uid} hiddenLvl={true} />
                </div>
            </div>
            <div
                id="chatdiv"
                className={`flex flex-col-reverse flex-1 overflow-y-auto py-4 ${
                    isMobile && "mt-16"
                }`}
                ref={scrollRef}
            >
                {!booking.isCanceled &&
                    (auth.currentUser.uid === booking.receiver.uid ||
                        booking.isRequested) && (
                        <ConfirmBookingTile booking={booking} />
                    )}
                {loaded.current && messages.length > 0 ? (
                    messages.map((item, i) => {
                        return (
                            //TODO: Enforce message ordering
                            //TODO: Optimize conditions on messages spacing and date display
                            //TODO: Messages date is compared only basing on month day
                            <>
                                <RenderMessage
                                    message={item}
                                    gap={
                                        (i < messages.length - 1 &&
                                            item.createdAt.getDate() !==
                                                messages[
                                                    i + 1
                                                ].createdAt.getDate()) ||
                                        !(
                                            i < messages.length - 1 &&
                                            item.sendBy ===
                                                messages[i + 1].sendBy
                                        )
                                    }
                                />
                                {i === messages.length - 1 ||
                                (i < messages.length - 1 &&
                                    item.createdAt.getDate() !==
                                        messages[i + 1].createdAt.getDate()) ? (
                                    <div className="flex justify-center">
                                        <div className="bg-grigino  rounded-lg px-4 py-1 mt-8 text-xs font-light text-gray-600 select-none">
                                            {item.createdAt.toLocaleString(
                                                "default",
                                                {
                                                    weekday: "long",
                                                    day: "numeric",
                                                    month: "long",
                                                }
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </>
                        );
                    })
                ) : loaded.current && messages.length === 0 ? (
                    <div className="h-full w-full flex justify-center items-center select-none text-grigio">
                        Ancora nessun messaggio
                    </div>
                ) : (
                    <Caricamento />
                )}
            </div>
            {imagePreview && (
                <div className="flex px-4 justify-between">
                    <img
                        src={imagePreview}
                        alt="Selected photo"
                        className="h-20 w-auto bg-blue-300"
                    />
                    <button
                        onClick={() => {
                            setImagePreview(null);
                            tmpImageFile = null;
                        }}
                    >
                        X
                    </button>
                </div>
            )}
            <div
                className={`${
                    isMobile && "fixed left-0 bottom-0 w-full"
                } h-16 shadow-sm bg-white rounded-lg border-t-2 border-t-grigino`}
            >
                <form className={`flex items-center px-4 py-2 rounded-b-lg`}>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={onPhotoChoose}
                    />
                    <button
                        className=""
                        onClick={(e) => {
                            e.preventDefault();
                            if (fileInputRef.current) {
                                fileInputRef.current.click();
                            }
                        }}
                        type="button"
                    >
                        <Camera w="30"/>
                    </button>
                    <input
                        className="block mx-4 p-2.5 w-full text-gray-900 bg-gray-100 border-0 rounded-lg focus:ring-verde focus:border-verde"
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        id="chat"
                        rows="1"
                        placeholder="Scrivi qui il tuo messaggio..."
                        ref={textInputRef}
                    ></input>
                    <button
                        id="myButton"
                        onClick={(e) => send(e)}
                        type="submit"
                        className="inline-flex justify-center p-2 rounded-full cursor-pointer hover:scale-110 transition-all duration-200"
                    >
                        <svg
                            className="w-6 h-6 rotate-90 text-black"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                        <span className="sr-only">Send message</span>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ChatMessages;
