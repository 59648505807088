import React from "react";
import useUserStore from "../../stores/userStore";
import useNavbarStore from "../../stores/navbarStore";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import AreYouShure from "../../Components/Atoms/Modals/AreYouShure";
import UserAPI from "../../utils/API/UserAPI";

function DeleteAccount() {
    const user = useUserStore((state) => state.user);
    const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbar = useNavbarStore((state) => state.setFootbarVisible);

    const handleDelete = async () => {
        await UserAPI.deleteUser();
        window.location.href = "/logout";
    };

    React.useEffect(() => {
        setNavbar(false);
        setFootbar(false);
    }, [window.location.pathname]);

    return (
        <div className="flex flex-col items-center gap-[10vh] min-h-[80vh] relative mb-[8vh] px-4">
            <div className="flex justify-between items-center w-full my-[1vh] px-4">
                <div className="absolute left-0">
                    <Indietro />
                </div>
                <h1 className="text-3xl text-center mx-auto font-bold">
                    Elimina Profilo
                </h1>
            </div>
            <div className="text-left md:max-w-[50%] flex flex-col gap-2">
                <h2>
                    Ciao{" "}
                    <strong>
                        {user.name} {user.surname}
                    </strong>
                    ,
                </h2>

                <p>
                    Ci dispiace che tu voglia lasciarci! Ecco cosa succede se
                    deciderai di eliminare il tuo account ToGiftit:
                    <br />
                    <br />
                    •⁠ ⁠Il tuo nome, le tue informazioni personali e tutti i
                    dati associati saranno completamente cancellati.
                    <br />
                    <br />
                    •⁠ ⁠Le tue pubblicazioni e interazioni non saranno più
                    riconducibili a te.
                    <br />
                    <br />
                    •⁠ ⁠Messaggi, foto e contenuti condivisi verranno eliminati
                    o resi anonimi.
                    <br />
                    <br />
                    ⚠️ Attenzione: Una volta eliminato l'account, non potrai più
                    recuperare le informazioni, quindi ti chiediamo di pensarci
                    bene prima di procedere.
                    <br />
                    <br />
                    Se hai bisogno di aiuto, scrivici a{" "}
                    <a
                        className="text-blue-500 underline"
                        href="mailto:assistenza@togiftit.com"
                    >
                        assistenza@togiftit.com
                    </a>. Saremo felici di ascoltarti! Se sei sicuro della tua
                    decisione, clicca qui sotto per eliminare il tuo account.
                </p>
            </div>
            <AreYouShure
                head={"Elimina Profilo"}
                title={"Sei sicuro di voler eliminare il tuo account?"}
                text={
                    "Questa azione è irreversibile e non potrà essere annullata."
                }
                secondary
                cancel
                onClick={handleDelete}
            />
        </div>
    );
}

export default DeleteAccount;
