import React, { useState, useEffect } from 'react';
import PrimaryButton from './PrimaryButton';

const ButtonTimer = ({onClick, textStyle, customStyle, bgColor,  delay = 1000, children, initialState = false, waitingText = "Attendere..." }:{
    onClick: () => void;
    textStyle?: string;
    customStyle?: string;
    bgColor?: string;
    delay?: number;
    children: string;
    initialState?: boolean;
    waitingText?: string;
}) => {
    const [disabled, setDisabled] = useState(initialState);
    const [countdown, setCountdown] = useState(delay);

    const handlePress = () => {
        if (!disabled) {
            setDisabled(true);
            setCountdown(delay);
            onClick();
        }
    };

    useEffect(() => {
        let timerId: NodeJS.Timeout | undefined = undefined;

        if (disabled && countdown > 0) {
            timerId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 100); // decrementa il countdown ogni 100 ms
            }, 100);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [disabled, countdown]);

    useEffect(() => {
        if (countdown <= 0 && disabled) {
            setDisabled(false);
        }
    }, [countdown, disabled]);

    return (
        <div className={customStyle || 'w-full flex justify-center'}>
            {disabled ? (
                <label>
                    {waitingText} {parseInt((countdown/1000).toString())} s
                </label>
            ) : (
                <PrimaryButton onClick={handlePress} textStyle={textStyle} fullWidth bgColor={bgColor} >
                    {children}
                </PrimaryButton>
            )}
        </div>
    );
};

export default ButtonTimer;