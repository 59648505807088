import React from "react";
import BookingAPI from "../../../utils/API/BookingAPI";
import SecondaryButton from "./SecondaryButton";
import { Gift } from "../../../types/Gift";

function GoToChat({ gift }:{ gift:Gift}) {
    const handleClick = async () => {
        try {
            const booking = await BookingAPI.getByGift(gift._id);
            window.location.href = `/chat/${booking._id}`;
        } catch (err) {
            const bookingId = await BookingAPI.makeBooking(gift._id);
            window.location.href = `/chat/${bookingId}`;
        }
    };
    return (
        <SecondaryButton onClick={handleClick} fullWidth>
            Chiedi informazioni
        </SecondaryButton>
    );
}

export default GoToChat;
