import {
    FiChevronDown,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import cats from "../Categorie/cats";

import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";

const CategorieDropdown = ({ isOpen, setIsOpen, opennth, micro, setMicro }) => {
    const [open, setOpen] = useState(false);
    const [macro, setMacro] = useState(null);
    //const [micro, setMicro] = useState([]);
    const selectall = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    const handleClick = () => {
        setIsOpen(opennth);
    };

    const toggleSelection = (macro) => {
        const category = cats.find((el) => el.macro === macro);
        if (!category) return;

        const hasSelectedMicro = category.micro.some((item) =>
            micro.includes(item)
        );
        if (hasSelectedMicro) {
            // Deseleziona tutte le micro di questa macro
            const micros = category.micro.filter((item) => !category.micro.includes(item));
            setMicro(micros
            );
        } else {
            // Seleziona tutte le micro di questa macro
            const micros =  [...micro, ...category.micro.filter((item) => !micro.includes(item))];
            setMicro(micros);
        }
        console.log(micro);
        
    };

    return (
        <div id="filtriRegalo" className="flex items-center ">
            <motion.div animate={open ? "open" : "closed"} className="relative">
                <button
                    onClick={() => handleClick()}
                    className={`flex items-center gap-2 px-3 py-2 rounded-md text-verdeScuro border-2  ${
                        micro.length ? "bg-verde2 text-white font-semibold" : "bg-white font-medium"
                    } border-2 border-transparent transition-colors`}
                >
                    <nobr className="font-medium   text-sm">
                        {"Categorie"}{ micro.length > 0 && <span className="px-2 py-0.5 bg-verdeScuro rounded-md ml-2">{micro.length>0 ? micro.length:""}</span>}
                    </nobr>
                    <motion.span className="md:block hidden" variants={iconVariants}>
                        <FiChevronDown />
                    </motion.span>
                </button> 

                <motion.ul
                    initial={wrapperVariants.closed}
                    variants={wrapperVariants}
                    transition={{ duration: 0.001 }}
                    style={{ originY: "top"}}
                    className="md:flex hidden min-w-[22rem] z-40 flex-col p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-0 w-48 overflow-hidden"
                >
                    {macro === null ? (
                        cats.map((item, i) => {
                            return (
                                <Option
                                    key={i}
                                    prop={item.macro}
                                    Icon={item.img}
                                    setOpen={setOpen}
                                    setMacro={setMacro}
                                    macro={macro}
                                    micro={micro}
                                />
                            );
                        })
                    ) : (
                        <>
                            <div
                                onClick={() => {
                                    setMacro(null);
                                }}
                                className="text-lg space-x-4 capitalize w-full flex justify-between pr-2 items-center cursor-pointer hover:bg-verdeScuro/10 py-2 rounded-md"
                            >
                                <div className="flex justify-start pl-3 space-x-3 text-black">
                                    <Iconfrecciagiu className={"w-3 opacity-75 rotate-0"} /> <p>{macro}</p>
                                </div>
                                {cats
                                    .find((el) => el.macro === macro)
                                    .micro.filter((item) =>
                                        micro.includes(item)
                                    ).length ? (
                                    <span className=" rounded-md text-sm aspect-square h-5 flex justify-center font-medium items-center bg-grigino">
                                        {
                                            cats
                                                .find(
                                                    (el) => el.macro === macro
                                                )
                                                .micro.filter((item) =>
                                                    micro.includes(item)
                                                ).length
                                        }
                                    </span>
                                ) : (
                                    <span></span>
                                )}
                            </div>

                            <div
                                onClick={() => {
                                    selectall.current.click();
                                }}
                                className="flex justify-between items-center p-2 text-lg font-medium rounded-lg  hover:bg-verdeScuro/10"
                            >
                                <span>Seleziona tutte</span>
                                <input
                                    className="cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                                    type="checkbox"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleSelection(
                                            cats.find(
                                                (el) => el.macro === macro
                                            ).macro
                                        );
                                    }}
                                    ref={selectall}
                                    checked={cats
                                        .find((el) => el.macro === macro)
                                        .micro.every((item) =>
                                            micro.includes(item)
                                        )}
                                />
                            </div>
                            <div className="w-full h-[1px] bg-verdeScuro/30 "></div>

                            {cats.map((item) => {
                                if (item.macro === macro) {
                                    return item.micro.map((micros, idx) => {
                                        return (
                                            <Optionmicro
                                                key={idx}
                                                prop={micros}
                                                setOpen={setOpen}
                                                setMacro={setMacro}
                                                macro={macro}
                                                setMicro={setMicro}
                                                micro={micro}
                                            />
                                        );
                                    });
                                }
                                return null; // Return null if the condition is not met to avoid warnings
                            })}
                        </>
                    )}

                    <div className="flex items-center gap-2 mt-2 justify-stretch">
                        <p
                            onClick={() => {
                                setMacro(null);
                                setMicro([]);
                            }}
                            className="w-full text-right pr-2 text-verdeScuro underline  cursor-pointer  py-1 rounded-md"
                        >
                            Cancella filtri
                        </p>
                    </div>
                </motion.ul>
            </motion.div>
        </div>
    );
};

const Option = ({ prop, Icon, setMacro,  micro }) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                setMacro(prop);
            }}
            className="flex z-40 capitalize relative items-center justify-between gap-2 w-full px-2.5 py-2 whitespace-nowrap rounded-md hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <div className="flex items-center text-sm space-x-3">
            <div className="w-6">{Icon}</div>
            <span>{prop}</span>
            </div>
            <div className="flex space-x-5 items-center">
                {cats
                    .find((el) => el.macro === prop)
                    .micro.filter((item) => micro.includes(item)).length ? (
                    <span className=" rounded-md text-sm aspect-square h-5 flex justify-center font-medium items-center bg-grigino">
                        <p>
                            {
                                cats
                                    .find((el) => el.macro === prop)
                                    .micro.filter((item) =>
                                        micro.includes(item)
                                    ).length
                            }
                        </p>
                    </span>
                ) : (
                    <span></span>
                )}
                <span><Iconfrecciagiu className={"w-3 opacity-75 rotate-180"}/></span>
            </div>
        </motion.li>
    );
};
const Optionmicro = ({
    prop,
    setMicro,
    micro,
}) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                setMicro(
                    micro.includes(prop)
                        ? micro.filter((el) => el !== prop)
                        : [...micro, prop]
                );
            }}
            className="flex z-40 relative items-center text-sm justify-between gap-2 w-full p-1.5  whitespace-nowrap rounded-md hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <span>{prop}</span>
            <input
                className="cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                type="checkbox"
                name=""
                id=""
                checked={micro.includes(prop)}
            />
        </motion.li>
    );
};

export default CategorieDropdown;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};
