import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 112.62 112.62"
      fill="CurrentColor"
    >
      <g data-name="layer 1">
        <path
          d="M110.24 2.38c-.8-.8-2.97-2.61-6.03-2.36-2.5.22-4.77 1.75-6.76 4.57-3.14 4.44-5.53 3.53-7.44 2.8l-.41-.15c-2.14-.78-5.28-.06-7.8 2.56l-2.4-2.4c-.67-.66-1.74-.66-2.41 0l-7.14 7.13c-1.93-1.9-4.47-2.95-7.17-2.95s-5.29 1.06-7.22 2.99L1.99 68.05C.7 69.33 0 71.04 0 72.86s.71 3.53 1.99 4.81l32.95 32.95c1.33 1.33 3.07 1.99 4.81 1.99s3.48-.67 4.81-1.99l53.47-53.47c1.93-1.92 2.99-4.49 2.99-7.22s-1.05-5.24-2.95-7.17l7.14-7.14c.67-.66.67-1.74 0-2.41l-2.4-2.4c2.62-2.53 3.33-5.67 2.55-7.8l-.15-.4c-.73-1.92-1.64-4.31 2.8-7.44 2.82-1.99 4.35-4.27 4.57-6.77.26-3.06-1.56-5.24-2.35-6.03zM95.63 54.75l-53.47 53.47c-1.33 1.32-3.49 1.32-4.81 0L4.4 75.27c-.64-.65-.99-1.5-.99-2.41s.35-1.77.99-2.41l53.47-53.46c2.57-2.57 7.05-2.57 9.62 0l1.16 1.16 25.82 25.82 1.16 1.16c1.29 1.29 1.99 2.99 1.99 4.81s-.7 3.53-1.99 4.81zm.04-14.39L72.25 16.94l5.93-5.93 23.42 23.41-5.93 5.94zm10.39-27.96c-6.6 4.67-4.86 9.24-4.02 11.44l.13.36c.32.87-.1 2.65-1.76 4.23L84.19 12.21c1.58-1.66 3.37-2.07 4.23-1.76l.36.14c2.19.83 6.77 2.57 11.43-4.03 1.39-1.96 2.83-3.01 4.28-3.14 1.65-.15 2.98 1.02 3.34 1.38.36.36 1.51 1.66 1.37 3.34-.13 1.45-1.18 2.89-3.14 4.27z"
          className="cls-1"
        ></path>
        <path
          d="M63.69 27.52c-.67-.67-1.74-.67-2.41 0L25.54 63.26 16.6 72.2c-.33.33-.5.77-.5 1.2s.17.87.5 1.2l9.08 9.08c.33.33.77.5 1.21.5s.87-.17 1.2-.5c.66-.67.66-1.74 0-2.41l-7.88-7.88 6.53-6.53 7.87 7.87c.33.33.77.5 1.21.5s.87-.17 1.2-.5c.66-.67.66-1.74 0-2.41l-7.87-7.87 6.53-6.53 7.87 7.87c.33.33.77.5 1.21.5s.87-.17 1.2-.5c.67-.67.67-1.74 0-2.41l-7.87-7.87 6.53-6.53 7.87 7.87c.33.33.77.5 1.21.5s.87-.17 1.2-.5c.67-.67.67-1.74 0-2.41l-7.87-7.87 6.53-6.53 7.87 7.87c.33.33.77.5 1.21.5s.87-.17 1.2-.5c.67-.67.67-1.74 0-2.41l-7.87-7.87 6.53-6.53 7.87 7.88c.33.33.77.5 1.2.5s.87-.17 1.2-.5c.66-.67.66-1.74 0-2.41l-9.08-9.07z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;