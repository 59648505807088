import { capitalizeWords } from "../StringUtils";
import { apiGet } from "./CallBackend";

const apiPrefix = "notification";

/**
 * @typedef {Object} Notification
 * @property {string} title
 * @property {string} content
 * @property {string} link
 * @property {string} sender
 * @property {Date} createdAt
 */

function parseNotification(notification: any) {
    if (notification.link.startsWith('chat')) {
        notification.title = capitalizeWords(notification.title);
    }
    return notification;
}

const NotificationAPI = {
    /**
     * 
     * @returns {Promise<Notification[]>}
     */
    getNotifications: async () => {
        const notifications = await apiGet(`${apiPrefix}`, true);
        if (notifications === null) {
            return [];
        }
        return (notifications as any[]).map((n: any) => parseNotification(n));
    },
};

export default NotificationAPI;