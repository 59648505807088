import * as React from "react";

const BlackCircles = () => (
	<svg
    className="w-full h-auto"
		xmlns="http://www.w3.org/2000/svg"
		width="375"
		height="361"
		fill="none"
		viewBox="0 0 375 361"
	>
		<ellipse
			cx="145.951"
			cy="269.031"
			fill="#6B6B6B"
			rx="145.951"
			ry="269.031"
			transform="rotate(89.11 214.12 277.706)skewX(.058)"
		></ellipse>
		<ellipse
			cx="152.597"
			cy="259.223"
			fill="#3D403C"
			rx="152.597"
			ry="259.223"
			transform="rotate(89.11 207.192 243.373)skewX(.058)"
		></ellipse>
		<ellipse
			cx="159.571"
			cy="258.747"
			fill="#2D302B"
			rx="159.571"
			ry="258.747"
			transform="rotate(89.11 207.82 211.888)skewX(.058)"
		></ellipse>
	</svg>
);

export default BlackCircles;
