import React from "react";

function Richieste({w = 25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={w}
      fill="none"
      viewBox="0 0 113 108"
    >
      <path
        fill="#E3C2B3"
        d="M7.284 102.061v.004c-.078-.054-16.35-11.419 0 .035 16.355 11.459 102.68.047 103.032.001.01-.002.001-.008.001-.017 0-.01-.007-.019-.017-.019-.326.003-4.988.035.066.035 4.868 0 .678-58.63.071-66.801a1.936 1.936 0 00-.882-1.481L59.833.704a1.975 1.975 0 00-2.13-.052c-7.005 4.26-46.034 28.127-50.42 33.706-4.82 6.131 0 67.701 0 67.703z"
      ></path>
      <path
        fill="#E8E1A7"
        d="M58.28 0l26.126 16.526s-25.358 8.022-53.021.501v-.009L58.285 0h-.005z"
      ></path>
      <path
        fill="#8FBB3B"
        d="M90.911 32.913c-2.144-6.327-7.11-9.66-12.983-10.452-1.55-.22-3.161-.25-4.808-.119-7.859.648-14.176 6.956-14.3 6.956h-.083c-.132 0-6.441-6.308-14.309-6.956-7.858-.647-15.078 2.58-17.782 10.57-2.712 7.992-.647 20.103 10.047 30.934 3.94 3.988 7.969 7.206 11.482 9.672 6.036 4.253 10.562 6.309 10.562 6.309h.084S70.16 74.67 80.856 63.84C91.549 53.015 93.614 40.904 90.91 32.908v.005z"
      ></path>
      <path
        fill="#8FAB47"
        d="M90.911 32.914c-2.144-6.327-7.11-9.66-12.983-10.452-1.55-.22-3.161-.25-4.808-.119-2.593.212-5.019 1.044-7.105 2.06 2.355 1.88 4.587 3.937 6.414 6.345 3.645 4.803 5.503 11.508 2.897 16.95-1.814 3.786-5.41 6.335-8.81 8.8-6.141 4.452-12.203 9.277-16.395 15.599a23.833 23.833 0 00-1.18 1.96c5.649 3.887 9.792 5.77 9.792 5.77h.083s11.341-5.155 22.035-15.985c10.694-10.826 12.76-22.937 10.056-30.932l.004.004z"
      ></path>
      <path
        fill="#FFFCE5"
        d="M108.697 35.437l-.049.035-.202.132-18.43 11.926-31.245 20.204-31.267-20.278-17.698-12.02c-.01.031.779 26.447 2.126 30.832 2.47 8 8.237 14.678 15.004 19.6.744.546 1.51 1.066 2.29 1.568h.052l-.026.013c12.332 8.012 27.987 10.936 42.238 7.29 5.856-1.496 11.416-4.125 16.343-7.63l-.022-.012h.044c7.814-5.565 14.035-13.345 17.346-22.335 3.425-9.303 3.764-19.345 3.496-29.325z"
      ></path>
      <path
        fill="#fff"
        d="M40.21 40.892a6.806 6.806 0 100-13.613 6.806 6.806 0 000 13.613z"
      ></path>
    </svg>
  );
}

export default Richieste;