import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 115 115"
    >
      <path
        d="M38.19 72.84c-.65.71-1.01 1.7-.94 2.57.15 1.74 1.62 2.95 3.57 2.95h34.02c.97 0 1.65-.59 1.97-.94.65-.71 1.01-1.7.94-2.57-.15-1.74-1.62-2.95-3.56-2.95H40.17c-.71 0-1.41.33-1.97.94z"
        className="cls-1"
      ></path>
      <path
        d="M57.5 0C25.79 0 0 25.79 0 57.5S25.79 115 57.5 115 115 89.21 115 57.5 89.21 0 57.5 0zM5 57.5C5 28.55 28.55 5 57.5 5S110 28.55 110 57.5 86.45 110 57.5 110 5 86.45 5 57.5z"
        className="cls-1"
      ></path>
      <path
        d="M41.37 59.59c4.97 0 9.02-5.64 9.02-12.57s-4.05-12.57-9.02-12.57-9.02 5.64-9.02 12.57 4.05 12.57 9.02 12.57zM73.63 59.59c4.97 0 9.02-5.64 9.02-12.57s-4.05-12.57-9.02-12.57-9.02 5.64-9.02 12.57 4.05 12.57 9.02 12.57z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;