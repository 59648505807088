import * as React from "react";

const Calendar = ({w=24}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={w}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#333"
      d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1z"
    ></path>
  </svg>
);

export default Calendar;
