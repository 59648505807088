import Seme from "../assets/Livelli/Seme";
import Germoglio from "../assets/Livelli/Germoglio";
import Arbusto from "../assets/Livelli/Arbusto";
import Albero from "../assets/Livelli/Albero";

/*
 interface Level {
    points: number;
    isEligible: boolean;
    criteria: string[];
    criteriaStatus: {
        [key: string]: {
            expected: number;
            current: number;
            isCompleted: boolean;
        };
    };
}
*/
const LevelsUtils = {
    getText: (
        criteria: string,
        status: { expected: number; current: number; isCompleted: boolean },
    ) => {
        const criteriaText: { [key: string]: string } = {
            donations: `Pubblica ${status.expected} regal${
                status.expected > 1 ? "i" : "o"
            }`,
            profilePictureAdded: "Aggiungi una foto al profilo",
            invitedFriends: `Invita ${status.expected} amic${
                status.expected > 1 ? "i" : "o"
            } `,
            bookings: `Prenotati a ${status.expected} regal${
                status.expected > 1 ? "i" : "o"
            }`,
            adoptions: `Adotta ${status.expected} regal${
                status.expected > 1 ? "i" : "o"
            }`,
            favouriteGifts: `Aggiungi ${status.expected} regal${
                status.expected > 1 ? "i" : "o"
            } ai preferiti`,
            assigns: `Assegna ${status.expected} regal${
                status.expected > 1 ? "i" : "o"
            }`,
        };

        return criteriaText[criteria];
    },

    getLevel: (level: number) => {
        const lvl = [
            {
                number : 1,
                regali: 0,
                nome: "Seme",
                descr: '"Ogni grande cambiamento parte da un seme!"',
                reward: 150,
                img: (empty: boolean) => Seme({ empty }),
            },
            {
                number : 2,
                regali: 1,
                nome: "Germoglio",
                descr: '"Sei un germoglio, pronto a crescere.Le tue azioni cominciano a creare un impatto visibile”',
                reward: 250,
                img: (empty: boolean) => Germoglio({ empty }),
            },
            {
                number : 3,
                regali: 3,
                nome: "Arbusto",
                descr: '"Ormai sei un arbusto in piena fioritura. Le tue azioni hanno preso forza e stai diffondendo il riuso sempre più”',
                reward: 400,
                img: (empty: boolean) => Arbusto({ empty }),
            },
            {
                number : 4,
                regali: 10,
                nome: "Albero",
                descr: '"Come un albero che si radica, hai contribuito ad aiutare chi ti sta intorno diventando una presenza solida.”',
                reward: 500,
                img: (empty: boolean) => Albero({ empty }),
            },
        ];
        return level ? lvl[level - 1] : lvl;
    },
};

export default LevelsUtils;
