import { auth } from "../../../firebase";
import { useEffect, useState } from "react";
import CancelChatDialogueBox from "../CancelChatDialogueBox";
import React from "react";
import { ReviewSpringModal } from "../../../Components/Atoms/Modals/Recensisci";
import BookingAPI from "../../../utils/API/BookingAPI";
import { toast } from "sonner";
import useBookingStore from "../../../stores/bookingStore";
import { showPrompt } from "../../../Components/Prompts/Prompts";
import AreYouShure from "../../../Components/Atoms/Modals/AreYouShure";

/**
 * @typedef {Object} BookingAction
 * @property {string} text
 * @property {function(): Promise<void>} action
 */

/**
 * @typedef {Object} BookingStateUpdate
 * @property {any} message
 * @property {any} booking
 */

/**
 *
 * @param {object} props
 * @param {import("../../../utils/API/BookingAPI").Booking} props.booking
 */
function ConfirmBookingTile({ booking }) {
	const [isDialogueVisible, setIsDialogueVisible] = useState(false);
	const [isReviewOpen, setIsReviewOpen] = useState(false);

	const isReceiver = booking.receiver.uid === auth.currentUser.uid;

	const appendMessage = useBookingStore((state) => state.appendMessage);
	const updateBookings = useBookingStore((state) => state.updateBookings);

	const [statusText, setStatusText] = useState("");

	/**
	 * @type {[BookingAction, function]}
	 */
	const [confirm, setConfirm] = useState(null);
	/**
	 * @type {[BookingAction, function]}
	 */
	const [cancel, setCancel] = useState(null);

	/**
	 *
	 * @param {string} text
	 * @param {function(string): Promise<BookingStateUpdate>} bookingAction
	 * @param {string} successMessage
	 * @param {string} errorMessage
	 * @returns {BookingAction}
	 */
	const getBookingAction = (
		text,
		bookingAction,
		successMessage,
		errorMessage
	) => {
		return {
			text: text,
			action: async () => {
				try {
					const stateUpdate = await bookingAction(booking._id);

					if (stateUpdate.booking.isDelivered !== undefined) {
						if (isReceiver) {
							stateUpdate.booking.isConfirmedByReceiver =
								stateUpdate.booking.isDelivered;
							stateUpdate.message.autoType = stateUpdate.booking.isDelivered
								? "confirmedReceiver"
								: "deliveryUndone";
						} else {
							stateUpdate.booking.isConfirmedByOwner =
								stateUpdate.booking.isDelivered;
							stateUpdate.message.autoType = stateUpdate.booking.isDelivered
								? "confirmedOwner"
								: "deliveryUndone";
						}
					}

					appendMessage(stateUpdate.message);
					const updatedBooking = { ...booking, ...stateUpdate.booking };

					updateBookings(updatedBooking);
					initTexts();
					toast.success(successMessage);
					if (
						stateUpdate.booking.isDelivered !== undefined &&
						booking.isConfirmedByOwner &&
						booking.isConfirmedByReceiver &&
						!isReceiver
					) {
						showPrompt.pointsReceived();
					} else if (stateUpdate.booking.isRequested) {
						showPrompt.pointsSpent();
					}
					window.location.reload();
				} catch (e) {
					toast.error(errorMessage);
				}
			},
		};
	};

	const initTexts = () => {
		setStatusText("");
		setConfirm(null);
		setCancel(null);
		if (isReceiver) {
			if (booking.isAccepted) {
				if (booking.isConfirmedByReceiver) {
					if (booking.isConfirmedByOwner) {
						setStatusText("Scambio effettuato!");
						if (!booking.isReviewedByReceiver) {
							setConfirm({
								text: "Lascia una recensione",
								action: async () => {
									setIsReviewOpen(true);
								},
							});
						}
					} else {
						setStatusText(
							"Hai confermato il ritiro, in attesa della conferma di consegna."
						);
						setCancel(
							getBookingAction(
								"Annulla conferma di ritiro",
								BookingAPI.undoGiftDelivery,
								"Conferma di ritiro annullata",
								"Si è verificato un errore durante l'annullamento"
							)
						);
					}
				} else {
					setStatusText("Hai ritirato il regalo?");
					setConfirm(
						getBookingAction(
							"Conferma ritiro",
							BookingAPI.confirmGiftDelivery,
							"Ritiro confermato!",
							"Si è verificato un errore durante la conferma"
						)
					);
					setCancel({
						text: "Non posso più ritirare",
						action: async () => {
							setIsDialogueVisible(true);
						},
					});
				}
			} else if (booking.isRequested) {
				setStatusText("La tua richiesta è in attesa di assegnazione.");
				setCancel({
					text: "Rinuncia",
					action: async () => {
						setIsDialogueVisible(true);
					},
				});
			} else {
				setStatusText("Sei interessato a questo prodotto?");
				setConfirm(
					getBookingAction(
						"Prenotati",
						BookingAPI.updateInfoToBooking,
						"Hai effettuato la prenotazione!",
						"Si è verificato un errore durante la prenotazione"
					)
				);
			}
		} else {
			if (booking.isAccepted) {
				if (booking.isConfirmedByOwner) {
					if (booking.isConfirmedByReceiver) {
						setStatusText("Scambio effettuato!");
						if (!booking.isReviewedByOwner) {
							setConfirm({
								text: "Lascia una recensione",
								action: async () => {
									setIsReviewOpen(true);
								},
							});
						}
					} else {
						setStatusText(
							"Hai confermato la consegna del regalo, in attesa della conferma di ritiro."
						);
						setCancel(
							getBookingAction(
								"Annulla conferma di consegna",
								BookingAPI.undoGiftDelivery,
								"Conferma di consegna annullata",
								"Si è verificato un errore durante l'annullamento"
							)
						);
					}
				} else {
					setStatusText("Hai consegnato il regalo?");
					setConfirm(
						getBookingAction(
							"Conferma consegna",
							BookingAPI.confirmGiftDelivery,
							"Consegna confermata!",
							"Si è verificato un errore durante la conferma"
						)
					);
					setCancel({
						text: "Non assegnare più questo regalo",
						action: async () => {
							setIsDialogueVisible(true);
						},
					});
				}
			} else if (booking.isRequested) {
				setStatusText(
					"Vuoi assegnare questo regalo a " +
						booking.otherUser.completeName +
						"? 💭"
				);
				setConfirm(
					getBookingAction(
						"Assegna regalo",
						BookingAPI.assignGift,
						"Regalo assegnato!",
						"Si è verificato un errore durante l'assegnazione"
					)
				);
			}
		}
	};

	useEffect(() => {
		initTexts();
	}, []);

	return (
		<div className="flex flex-col items-center mt-4">
			<ReviewSpringModal
				userUid={booking.otherUser.uid}
				userName={booking.otherUser.completeName}
				isOpen={isReviewOpen}
				setIsOpen={setIsReviewOpen}
				onReviewed={async () => {
					await BookingAPI.setReviewed(booking._id);
					if (isReceiver) {
						booking.isReviewedByReceiver = true;
					} else {
						booking.isReviewedByOwner = true;
					}
					initTexts();
					window.location.href = "/profilo/"+booking.otherUser.uid
				}}
			/>
			<div className="font-normal text-sm">{statusText}</div>
			{confirm !== null && (
				<div className="mt-2">
					<AreYouShure
						withoutConfirm={confirm.text === "Lascia una recensione"}
						title={confirm.text}
						head={confirm.text}
						onClick={confirm.action}
					/>
				</div>
			)}
			{cancel !== null && (
				<div className="mt-2">
					<AreYouShure
						secondary
						cancel
						title={cancel.text}
						head={cancel.text}
						onClick={cancel.action}
					/>
				</div>
			)}
			{isDialogueVisible && (
				<CancelChatDialogueBox
					setIsDialogueVisible={setIsDialogueVisible}
					isReceiver={isReceiver}
					onConfirm={async (reason) => {
						try {
							const stateUpdate = await BookingAPI.cancelBooking(
								booking._id,
								reason
							);
							appendMessage(stateUpdate.message);
							booking = { ...booking, ...stateUpdate.booking };
							updateBookings(booking);
							toast.success("Hai annullato la prenotazione");
							setIsDialogueVisible(false);
						} catch (e) {
							toast.error("Si è verificato un errore durante la cancellazione");
						}
					}}
				/>
			)}
		</div>
	);
}

export default ConfirmBookingTile;
