import { create } from "zustand";

const useRegistrationStore = create(
    (set) => ({
        nome: "",
        cognome: "",
        email: "",
        password: "",
        isAgeGreaterThan16: false,
        isTermAndCondChecked: false,
        isNewsletterAllowed: false,
        profileImage: null,
        error: null,
        loading: false,
        isNextAllowed: false,
        emailConfirmationCode: "",
        step: 0,
        inviteCode: "",

        setNome: (nome) => set({ nome }),
        setCognome: (cognome) => set({ cognome }),
        setEmail: (email) => set({ email }),
        setPassword: (password) => set({ password }),
        toggleTermAndCond: () =>
            set((state) => ({
                isTermAndCondChecked: !state.isTermAndCondChecked,
            })),
        toggleAgeCheck: () =>
            set((state) => ({ isAgeGreaterThan16: !state.isAgeGreaterThan16 })),
        toggleNewsletter: () =>
            set((state) => ({
                isNewsletterAllowed: !state.isNewsletterAllowed,
            })),
        setProfileImage: (profileImage) => set({ profileImage }),
        setError: (error) => set({ error }),
        setEmailConfirmationCode: (emailConfirmationCode) =>
            set({ emailConfirmationCode }),
        enableNext: () => set({ isNextAllowed: true }),
        disableNext: () => set({ isNextAllowed: false }),
        nextStep: () => set((state) => ({ step: state.step + 1 })),
        prevStep: () => set((state) => ({ step: state.step - 1 })),
        setInviteCode: (inviteCode) => set({ inviteCode }),
    }),
    {
        name: "registration-storage",
    }
);

export default useRegistrationStore;
