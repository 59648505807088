import { useState } from "react";
import RegaloArrabbiato from "../../../assets/Icons/RegaloArrabbiato";
import axios from "axios";
import LoadingBtn from "../Bottoni/LoadingBtn";
import useUserStore from "../../../stores/userStore";
import DragCloseDrawer from "../Drawers/DragCloseDrawer";
import PopupCloseDrawer from "../Drawers/PopupCloseDrawer";
import React from "react";

const Segnala = ({ user }) => {
    const utente = useUserStore((state) => state.user);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="">
            <button
                onClick={() => setIsOpen(true)}
                className="text-rosso font-semibold px-3 py-2 rounded-md flex items-center gap-2"
            >
                <div className="w-6 hidden">
                    <RegaloArrabbiato />
                </div>
                Segnala 
            </button>
            
            <SpringModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    nome={user.nome}
                    cognome={user.cognome}
                    segnalato={user}
                    user={utente}
                />

        </div>
    );
};

const SpringModal = ({ isOpen, setIsOpen, nome, cognome, segnalato, user }) => {
    const [selectedReason, setSelectedReason] = useState("");
    const [sent, setSent] = useState(null);

    const handleReasonChange = (e) => {
        setSelectedReason(e.target.value);
    };

    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const handleSubmit = async () => {


        await axios
            .post(
                process.env.REACT_APP_API_URL + "/mail/segnala",
                {
                    segnalato: {
                        nome: segnalato.nome,
                        cognome: segnalato.cognome,
                        mail: segnalato.mail,
                        id: segnalato.id,
                    },
                    nome: user.nome,
                    cognome: user.cognome,
                    mail: user.mail,
                    testo: selectedReason,
                },
                { headers }
            )
            .then((res) => {

                if (res.data.status !== "ok") {
                    setSent(false);
                } else {
                    setSent(true);
                }
                setTimeout(() => {
                    setSent(null);
                    setIsOpen(false);
                }, 3000);
            });
    };

    const content = <div className="relative z-10 h-[60dvh] md:h-auto">
        <div className="bg-white text-rosso w-12 h-12 mb-2 rounded-full grid place-items-center mx-auto">
            <RegaloArrabbiato />
        </div>
        <h3 className="text-3xl font-bold text-center mb-2">
            Segnala {nome} {cognome}
        </h3>
        <div className="flex flex-col gap-2 my-5">
            <label
                for="reason"
                className="text-sm text-secondary"
            >
                Seleziona il motivo per cui vorresti
                segnalare questo utente:
            </label>
            <select
                id="reason"
                name="reason"
                onChange={handleReasonChange}
                className="bg-verdeScuro/10 w-full placeholder:text-sm focus:ring-rosso border-0 appearance-none rounded-lg placeholder:text-verdeScuro/50 "
            >
                <option
                    className="appearance-none hover:bg-verdeScuro/20"
                    value="Commenti sessisti"
                >
                    Commenti sessisti
                </option>
                <option
                    className="appearance-none hover:bg-verdeScuro/20"
                    value="Molestia"
                >
                    Molestia
                </option>
                <option
                    className="appearance-none hover:bg-verdeScuro/20"
                    value="Commenti inappropriati o offensivi"
                >
                    Commenti inappropriati o offensivi
                </option>
                <option
                    className="appearance-none hover:bg-verdeScuro/20"
                    value="Commenti razzisti"
                >
                    Commenti razzisti
                </option>
                <option
                    className="appearance-none hover:bg-verdeScuro/20"
                    value="Account falso"
                >
                    Account falso
                </option>
                <option
                    className="appearance-none hover:bg-verdeScuro/20"
                    value="Altro"
                >
                    Altro
                </option>
            </select>
        </div>
        <div className="flex gap-2">
            <button
                onClick={() => setIsOpen(false)}
                className="bg-transparent text-verdeScuro hover:bg-verdeScuro/10 hover:text-black transition-colors  font-semibold w-full py-2 rounded"
            >
                Annulla
            </button>
            <LoadingBtn
                success={sent}
                fullWidth={true}
                onClick={handleSubmit}
            >
                Segnala
            </LoadingBtn>
        </div>
    </div>

    return (
        // <AnimatePresence>
        //     {isOpen && (
        //         <motion.div
        //             initial={{ opacity: 0 }}
        //             animate={{ opacity: 1 }}
        //             exit={{ opacity: 0 }}
        //             onClick={() => setIsOpen(false)}
        //             className=" backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        //         >
        //             <motion.div
        //                 initial={{ scale: 0, rotate: "12.5deg" }}
        //                 animate={{ scale: 1, rotate: "0deg" }}
        //                 exit={{ scale: 0, rotate: "0deg" }}
        //                 onClick={(e) => e.stopPropagation()}
        //                 className=" bg-background p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
        //             >
        //                 {content}
        //             </motion.div>
        //         </motion.div>
        //     )}
        // </AnimatePresence>
        (<div>
            <div className="hidden md:block">
                <PopupCloseDrawer open={isOpen} setOpen={setIsOpen}>
                    {content}
                </PopupCloseDrawer>
            </div>
            <div className="md:hidden">
                <DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
                    {content}
                </DragCloseDrawer>
            </div>
        </div>)
    );
};

export default Segnala;
