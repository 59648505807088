import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import RegaloSi from "../../../assets/Icons/RegaloSi";
import RegaloNo from "../../../assets/Icons/RegaloNo";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import DragCloseDrawer from "../../Atoms/Drawers/DragCloseDrawer";
import BookingAPI from "../../../utils/API/BookingAPI";
import useUserStore from "../../../stores/userStore";
import PrimaryButton from "../../Atoms/Bottoni/PrimaryButton";
import SecondaryButton from "../../Atoms/Bottoni/SecondaryButton";
import AreYouShure from "../../Atoms/Modals/AreYouShure";
import { ReviewSpringModal } from "../../Atoms/Modals/Recensisci";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { showPrompt } from "../../Prompts/Prompts";
import GoToChat from "../../Atoms/Bottoni/GoToChat";
/**
 *
 * @param {Object} props
 * @param {import("../../../utils/API/UserAPI").User} props.user
 * @param {import("../../../utils/API/GiftAPI").Gift} props.gift
 * @param {import("../../../utils/API/UserAPI").User} props.owner
 * @returns
 */
const Prenotati = ({ gift }) => {
	const [isOpen, setIsOpen] = useState(false);
	const logged = useUserStore((state) => state.isLogged);
	const [booking, setBooking] = useState({});
	const [isReviewOpen, setIsReviewOpen] = useState(false);

	const prenota = async () => {
		try {
			const bookingId = await BookingAPI.makeBooking(gift._id, true);
			showPrompt.pointsSpent(100);
			window.location.href = "/chat/" + bookingId;
		} catch (error) {
			console.error("Transaction failed: ", error);
			//alert(error.message); // Show error message to the user
		}
	};

	const handleConfermaRitiro = async () => {
		try {
			await BookingAPI.confirmGiftDelivery(booking._id);
			window.location.reload();
		} catch (error) {
			toast.error("Si è verificato un errore con la conferma");
			console.log(error);
		}
	};

	const cancelBooking = async () => {
		try {
			await BookingAPI.cancelBooking(booking._id, "Rifiuto ritiro");
			window.location.reload();
		} catch (error) {
			console.log(error);
		}
	};
	/*
    const annullaConferma = async () => {
        try {
            await BookingAPI.undoGiftDelivery(booking._id);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };
*/
	const handleLoad = async () => {
		const temp =
			gift.isAssignedToMe || gift.isBookedByMe
				? await BookingAPI.getByGift(gift._id)
				: {};
		setBooking(temp);
		console.log("booking", temp);
	};

	useEffect(() => {
		handleLoad();
	}, []);

	const navigate = useNavigate();

	return (
		<>
			{logged ? (
				<div className="w-full">
					{!gift.isAssignedToMe ? (
						<div className="flex flex-col gap-2">
							{!gift.isAssigned &&
								(!gift.isBookedByMe ? (
									<AreYouShure
										head="Prenotati"
										title="Confermi di prenotarti per questo regalo?"
										text="Ti verranno sottratti 100 punti"
										onClick={prenota}
									/>
								) : (
									<AreYouShure
										cancel
										secondary
										head="Annulla prenotazione"
										title="Confermi di annullare la prenotazione?"
										text="Ti verranno restituiti 100 punti"
										onClick={cancelBooking}
									/>
								))}
							{gift.isAssigned &&
								(!gift.isDelivered ? (
									<div className="flex flex-col gap-2">
										{gift.isBookedByMe ? (
											<p>
												Questo regalo è stato assegnato ed è in attesa di essere
												ritirato. Se desideri riavere i tuoi punti prenotazione,
												annulla la prenotazione.
											</p>
										) : (
											<p>
												Questo regalo è stato assegnato a qualcun'altro ed è in
												attesa di essere ritirato. Se desideri prenotarti può
												ancora venirti assegnato
											</p>
										)}
										{gift.isBookedByMe ? (
											<AreYouShure
												cancel
												secondary
												head="Annulla prenotazione"
												title="Confermi di annullare la prenotazione?"
												text="Rimarrai prenotato finché il proprietario non consegnerà il regalo"
												onClick={cancelBooking}
											/>
										) : (
											<AreYouShure
												head="Prenotati"
												title="Confermi di prenotarti per questo regalo?"
												text="Ti verranno sottratti 100 punti"
												onClick={prenota}
											/>
										)}
									</div>
								) : (
									<SecondaryButton disabled>è stato regalato</SecondaryButton>
								))}
						</div>
					) : !booking.isConfirmedByReceiver ? (
						<div className="flex flex-col gap-2">
							<AreYouShure
                            cancel
								secondary
								head="Rinuncia al ritiro"
								title="Confermi di voler rinunciare al ritiro?"
								text="Il proprietario verrà avvisato e potrà assegnare il regalo a qualcun altro"
								onClick={cancelBooking}
							/>
							<AreYouShure
								head="Conferma ritiro"
								title="Vuoi confermare il ritiro?"
								text="Il proprietario verrà avvisato e potrà confermare la consegna"
								onClick={handleConfermaRitiro}
							/>
						</div>
					) : booking.isConfirmedByOwner ? (
						booking.isReviewedByReceiver ? (
							<div className="flex w-full justify-center">
								<p>Scambio effettuato!</p>
							</div>
						) : (
							<div className="flex flex-col gap-2">
								<p className="text-center">E' stata confermata la consegna</p>
								<PrimaryButton fullWidth onClick={() => setIsReviewOpen(true)}>
									Lascia una recensione
								</PrimaryButton>
							</div>
						)
					) : (
						<div className="flex flex-col gap-2">
							<p className="text-center">Hai confermato il ritiro</p>
							<AreYouShure
                                cancel
								secondary
								head="Annulla conferma ritiro"
								title="Vuoi annullare la conferma di ritiro?"
								text="Il proprietario verrà avvisato e potrà assegnare il regalo a qualcun altro"
								onClick={cancelBooking}
							/>
						</div>
					)}

					{!gift.isAssigned && !gift.isDelivered && !gift.isBookedByMe && (
						<SpringModal
							prenota={prenota}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					)}

					{gift.isBookedByMe && (
						<RimuoviPrenotazioneModal
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							rimuovi={cancelBooking}
						/>
					)}
					<ReviewSpringModal
						userUid={gift.owner}
						userName={booking?.otherUser?.completeName}
						isOpen={isReviewOpen}
						setIsOpen={setIsReviewOpen}
						onReviewed={async () => {
							await BookingAPI.setReviewed(booking._id);

							navigate("/profilo/" + gift.owner);
						}}
					/>
					{!gift.isDelivered && !gift.isBookedByMe && (
						<div className="mt-3">
							<GoToChat gift={gift} />
						</div>
					)}
				</div>
			) : (
				<div className="w-full">
					<PrimaryButton
						fullWidth={true}
						onClick={() => (window.location.href = "/register")}
					>
						Crea un account gratuito
					</PrimaryButton>
				</div>
			)}
		</>
	);
};

const RimuoviPrenotazioneModal = ({ isOpen, setIsOpen, rimuovi }) => {
	const device = useDeviceDetection();

	const content = (
		<div className="relative z-10">
			<div className="bg-white w-16 h-16 mb-5 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
				<RegaloNo />
			</div>
			<h3 className="text-3xl font-bold text-center mb-6">
				Rimuovere la prenotazione?
			</h3>
			<p className="text-center mb-6 px-4 sm:px-0">
				Vuoi davvero rimuovere la tua prenotazione a questo item? <br />
				Se rimuovi la prenotazione, otterrai indietro i punti spesi.
			</p>
			<div className="flex flex-col-reverse sm:flex-row gap-2">
				<SecondaryButton fullWidth onClick={() => setIsOpen(false)}>
					No, torna indietro
				</SecondaryButton>

				<PrimaryButton fullWidth onClick={() => rimuovi()}>
					Conferma!
				</PrimaryButton>
			</div>
		</div>
	);

	if (device === "Mobile")
		return (
			<DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
				{content}
			</DragCloseDrawer>
		);
	else
		return (
			<AnimatePresence>
				{isOpen && (
					<div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						onClick={() => setIsOpen(false)}
						className="bg-verdeScuro/5 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  cursor-pointer"
					>
						<div
							initial={{ scale: 0, rotate: "12.5deg" }}
							animate={{ scale: 1, rotate: "0deg" }}
							exit={{ scale: 0, rotate: "0deg" }}
							onClick={(e) => e.stopPropagation()}
							className="bg-white text-black p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
						>
							{content}
						</div>
					</div>
				)}
			</AnimatePresence>
		);
};

const SpringModal = ({ isOpen, setIsOpen, prenota }) => {
	const device = useDeviceDetection();
	const content = (
		<div className="relative z-10">
			<div className="bg-white w-16 h-16 mb-5 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
				<RegaloSi />
			</div>
			<h3 className="text-xl font-bold text-center mb-3">
				Confermi di prenotarti per questo regalo?
			</h3>
			<p className="text-center mb-6">
				Se vieni scelto dal proprietario, ti arriverà un messaggio automatico
				nella chat.
			</p>
			<div className="flex flex-col-reverse sm:flex-row gap-2">
				<SecondaryButton fullWidth onClick={() => setIsOpen(false)}>
					No, torna indietro
				</SecondaryButton>
				<PrimaryButton fullWidth onClick={() => prenota()}>
					Conferma!
				</PrimaryButton>
			</div>
		</div>
	);

	if (device === "Mobile")
		return (
			<DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
				{content}
			</DragCloseDrawer>
		);
	else
		return (
			<AnimatePresence>
				{isOpen && (
					<div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						onClick={() => setIsOpen(false)}
						className="bg-verdeScuro/5 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  cursor-pointer"
					>
						<div
							initial={{ scale: 0, rotate: "12.5deg" }}
							animate={{ scale: 1, rotate: "0deg" }}
							exit={{ scale: 0, rotate: "0deg" }}
							onClick={(e) => e.stopPropagation()}
							className="bg-white text-black p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
						>
							{content}
						</div>
					</div>
				)}
			</AnimatePresence>
		);
};

export default Prenotati;
