import React from "react";
import TogiftitLogo from "../../../assets/Logos/Pittogramma.jsx"

function TogiftitProfilePic() {
    return (
        <>
            <div
                className={`flex items-center justify-center h-12 w-12 aspect-square border rounded-full`}
            >
                <div className={`relative w-6 h-6 fill-verde mb-1`}>
                    <TogiftitLogo />
                </div>
            </div>
        </>
    );
}

export default TogiftitProfilePic;
