export const addNewItem = (newItemImage, newItemFile, newItemIndex) => {

    const newItem = {
        id: newItemIndex,
        image: newItemImage,
        imageFile: newItemFile
    };

    return newItem;
};
