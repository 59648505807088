import React from 'react'
import PrimaryButton from '../../../Components/Atoms/Bottoni/PrimaryButton'
import Question from "../components/Question";
import { faq } from '../faq';

const SelectableButton: React.FC<{ children: React.ReactNode, isSelected: boolean, onClick: () => void, className?: string }> = (
    { children, isSelected, onClick }
) => {
    return (
        <PrimaryButton textStyle={`text-lg`} onClick={onClick}
            bgColor={`${isSelected ? 'bg-verdeScuro text-white' : 'bg-white text-verdeScuro'}`}
            fullWidth
        >
            {children}
        </PrimaryButton>
    )
}

const Faq = () => {
    const [selectedCategory, setSelectedCategory] = React.useState(0);
    const [openQuestion, setOpenQuestion] = React.useState<number | null>(null);

    const handleToggle = (index: number) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    const buttonsClass = `text-lg`;
    const categories = [
        'Tutte',
        'Punti Gift',
        'Pubblicare',
        'Prenotazioni e Regali',
        'Account',
        'Privacy'
    ]

    return (
        <div className='md:flex md:flex-col mt-6 md:items-center'>
            <div className='flex overflow-x-auto overflow-y-hidden z-10 space-x-2'>
                {categories.map((category, index) => (
                    <SelectableButton key={index}
                        isSelected={selectedCategory === index}
                        className={buttonsClass}
                        onClick={() => {
                            setOpenQuestion(null);
                            setSelectedCategory(index);
                        }}
                    >
                        {category}
                    </SelectableButton>
                ))}
            </div>
            <div className='flex flex-col space-y-4 mt-10 md:w-1/2'>
                {selectedCategory > 0 ?
                    (faq[selectedCategory].map((question, index) => (
                        <Question key={index} {...question} index={index}
                            openQuestion={openQuestion} handleToggle={(i: number) => handleToggle(i)} />
                    )))
                    :
                    (faq.map((categoryGroup, categoryIndex) => (
                        categoryGroup.map((question, index) => (
                            <Question key={index} {...question} index={categoryIndex * 100 + index}
                                openQuestion={openQuestion} handleToggle={(i: number) => handleToggle(i)} />
                        ))
                    )))}
            </div>
        </div>
    )
}

export default Faq