import React from 'react'

const CheckBox = ({ isChecked, toggleValue, containerStyle, checkBoxStyle, children }:
    {
        isChecked: boolean;
        toggleValue: () => void;
        containerStyle?: string;
        checkBoxStyle?: string;
        children: any;
    }
) => {
    return (
        <div className={containerStyle || "flex flex-row items-center space-x-4 p-2 cursor-pointer hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors rounded-md"}
            onClick={() => toggleValue()}
        >
            <input
                className={checkBoxStyle || "cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"}
                type="checkbox"
                name=""
                id=""
                checked={isChecked}
            />
            {children}
        </div>
    )
}

export default CheckBox