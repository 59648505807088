import * as React from "react";

const InfoIcon = ({w=24, selected}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={w}
    fill="none"
    viewBox="0 0 19 19"
  >
    <path fill="#fff" d="M9.73 7.482h.007ZM8.993 9.693h.737v2.948h.737"></path>
    <path
      fill="#fff"
      d="M9.73 3.06c5.307 0 6.633 1.326 6.633 6.633s-1.326 6.634-6.633 6.634S3.096 15 3.096 9.693 4.423 3.06 9.73 3.06"
    ></path>
    <path
      stroke={selected ? "#8FBB3B" : "#333"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.474"
      d="M9.73 7.482h.007m-.744 2.211h.737v2.948h.737M9.73 3.06c5.307 0 6.633 1.326 6.633 6.633s-1.326 6.634-6.633 6.634S3.096 15 3.096 9.693 4.423 3.06 9.73 3.06"
    ></path>
  </svg>
);

export default InfoIcon;
