import React, { useEffect, useState } from "react";
import { Circle, MapContainer, TileLayer } from "react-leaflet";




function Mappa({ lat, lng }) {
    const [center, setCenter] = useState([lat, lng]);

    useEffect(() => {
        const getRandomOffset = () => (Math.random() * 0.004) - 0.002;

        const newLat = lat + getRandomOffset();
        const newLng = lng + getRandomOffset();

        setCenter([newLat, newLng]);
    }, [lat, lng]);


    return (
        <div className="aspect-square z-10 w-full">
            <MapContainer
                center={center}
                zoom={11}
                className="h-full w-full"
                style={{ borderRadius: "10px" }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Circle center={center} radius={500}></Circle>
            </MapContainer>
        </div>
    );
}

export default React.memo(Mappa);
