import { capitalizeWords } from "../StringUtils";
import { apiDelete, apiGet, apiPost, apiPut, objectKeyFromS3Url, uploadImage } from "./CallBackend";

const apiPrefix = 'user';

/**
 * @typedef {Object} User
 * @property {string} _id
 * @property {string} uid
 * @property {string} email
 * @property {string} name
 * @property {string} surname
 * @property {GiftLocation|null} location
 * @property {string[]} favourites
 * @property {number} points
 * @property {number} level
 * @property {string} image
 * @property {number} donations
 * @property {number} adoptions
 * @property {boolean} isFirstLogin
 * @property {boolean} hasNotifications
 * @property {string|null} invitedBy 
 * @property {Date} createdAt
 */

/**
 * @typedef {Object} Review
 * @property {string} _id
 * @property {string} toUser
 * @property {string} author
 * @property {string} content
 * @property {string} updatedAt
 * @property {number} rating
 * @property {Date} createdAt
 */


const UserAPI = {
    /**
     * @param {string} userId 
     * @returns {Promise<User>}
     */
    getById: async (userId) => {
        const user = await apiGet(`${apiPrefix}/${userId}`, true);
        user.name = capitalizeWords(user.name);
        user.surname = capitalizeWords(user.surname);
        if (!user.favourites) {
            user.favourites = [];
        }
        return user;
    },

    /**
     * @param {string} userId 
     * @returns {Promise<Review[]>}
     */
    getReviews: async (userId) => {
        return await apiGet(`${apiPrefix}/${userId}/reviews`);
    },

    /**
     * @returns {Promise<Review[]>}
     */
    getMyReviews: async () => {
        return await apiGet(`${apiPrefix}/user/reviews/mine`, true);
    },

    /**
     * @param {string} userId The user to drop a review to
     * @param {string} content
     * @param {number} rating
     */
    addReview: async (userId, content, rating) => {
        await apiPost(`${apiPrefix}/${userId}/reviews`, {
            review: {
                content: content,
                rating: rating
            }
        }, true);
    },

    /**
     * @param {string} giftId 
     */
    addFavourite: async (giftId) => {
        await apiPost(`${apiPrefix}/favourite/${giftId}`, {}, true);
    },

    /**
     * @param {string} giftId
     */
    removeFavourite: async (giftId) => {
        await apiDelete(`${apiPrefix}/favourite/${giftId}`, true);
    },

    updateFirstLogin: async () => {
        await apiPut(`${apiPrefix}/user/firstlogin`, {}, true);
    },

    /**
     * 
     * @param {*} imageFile 
     */
    uploadProfilePic: async (imageFile) => {
        const signedUrl = await apiPost(`${apiPrefix}/user/image`, {}, true);
        await uploadImage(imageFile, signedUrl);
        return objectKeyFromS3Url(signedUrl);
    },

    /**
     * 
     * @param {Record<string, any>} user 
     */
    update: async (user) => {
        const whitelist = ["name", "surname", "image", "location", "email", "birthday", "gender"];
        const filteredUser = Object.keys(user).filter(key => whitelist.includes(key))
            .reduce((obj, key) => {
                obj[key] = user[key];
                return obj;
            }, {});
        await apiPut(`${apiPrefix}`, {
            user: filteredUser
        }, true);
    },

    getReferralCode: async () => {
        const code = await apiGet(`${apiPrefix}/user/referral`, true);
        return code;
    },

    /**
     * 
     * @param {any} user 
     * @param {string|null} referral 
     */
    addUser: async (user, referral=null) => {
        await apiPost(`${apiPrefix}`, {
            user: user,
            referral: referral
        }, true);
    },

    deleteUser: async () => {
        await apiDelete(`${apiPrefix}`, true);
    },

    requestAssistance: async (content) => {
        await apiPost('support', {
            content: content
        }, true);
    },

    getLevel: async (userId) => {
        return await apiGet(`${apiPrefix}/${userId}/level`, true);
    },

    changeLevel: async (userId) => {
        return await apiPut(`${apiPrefix}/${userId}/level`, {}, true);
    }
};

export default UserAPI;