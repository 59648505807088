import { create } from "zustand";

const deviceDetection = () => {
    return /Mobi|Android/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
};

const useNavbarStore = create((set) => {
    const device = deviceDetection();
    const isMobile = device === "Mobile";



    return {
        isNavbarVisible: true,
        isFootbarVisible: true,
        setNavbarVisible: (value) => {
            if (isMobile) {
                set({ isNavbarVisible: value });
            } else {
                set({ isNavbarVisible: true });
            }
        },
        setFootbarVisible: (value) => set({ isFootbarVisible: value }),
        
    };
});

export default useNavbarStore;

