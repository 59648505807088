import React from "react";
import FadeInComponent from "../../../../Components/Atoms/Transitions/FadeInComponent";

/**
 * @param {Object} props
 * @param {import("../../../../utils/API/GiftAPI").Gift} props.gift
 * @returns 
 */
function ChatItem({ gift }) {
    return (
        <FadeInComponent>
            <div className={`flex py-4 px-4 justify-start items-center bg-grigio bg-opacity-40 cursor-pointer`} onClick={() => {
                window.location.href = "/dashboard/" + gift._id;
            }}>
                <div className="ml-4 mr-4">
                {
                    gift.images.length > 0 ?
                    <img className="h-16 w-16 rounded-full" src={gift.images[0]} alt={gift.name}/>
                    :
                    <div className="h-16 w-16 bg-grigio rounded-full"/>
                }
                </div>
                <div className="flex flex-col gap-2">
                <div className="text-lg font-medium">{gift.name}</div>
                <div className="text-sm">{gift.bookingsLength} prenotat{gift.bookingsLength === 1 ? "o":"i"}</div>
                </div>
            </div>
        </FadeInComponent>
  );
}

export default ChatItem;