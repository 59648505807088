import React from 'react'

function CardContainer({children}) {
  return (
    <div className=' flex flex-wrap w-full justify-center gap-4 md:gap-5'>
        {children}
    </div>
  )
}

export default CardContainer