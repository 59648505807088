import React, { useEffect, useRef, useState } from "react";

import VerificationInput from "react-verification-input";

import Tooltip from "../../Components/Atoms/Card/Tooltip";
import CheckBox from "../../Components/Atoms/Bottoni/CheckBox";
import Bottone from "../../Components/Atoms/Bottoni/PrimaryButton";
import ButtonTimer from "../../Components/Atoms/Bottoni/ButtonTimer";
import { toast } from "sonner";
import TextInputField from "../../Components/Atoms/Inputs/TextInputField";
import ProfilePicPlaceholder from "../../assets/PNG/add profile image@4x.png";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { auth } from "../../firebase";
import SecondaryButton from "../../Components/Atoms/Bottoni/SecondaryButton";
import { FcGoogle } from "react-icons/fc";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import useRegistrationStore from "../../stores/registrationStore";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";
import AuthAPI from "../../utils/API/AuthAPI";
import { Link } from "react-router-dom";

const inputClassName =
    "md:text-xl text-lg text-verdeScuro font-semibold w-full bg-transparent placeholder:font-light border-grigio focus:border-verdeScuro focus:ring-0 rounded-md";
const containerClassName = "flex flex-col w-full h-full md:px-12";
const titleClassName = "text-2xl font-semibold";

export const RegistrationStepNome = () => {
    const {
        nome,
        cognome,
        setNome,
        setCognome,
        isNextAllowed,
        nextStep,
        enableNext,
        disableNext,
        toggleAgeCheck,
        toggleTermAndCond,
        toggleNewsletter,
        isAgeGreaterThan16,
        isTermAndCondChecked,
        isNewsletterAllowed,
    } = useRegistrationStore();

    useEffect(() => {
        nome?.length > 0 &&
        cognome?.length > 0 &&
        isAgeGreaterThan16 &&
        isTermAndCondChecked
            ? enableNext()
            : disableNext();
    }, [nome, cognome, isAgeGreaterThan16, isTermAndCondChecked]);

    return (
        <div className={containerClassName}>
            <h2 className={titleClassName}>Qual è il tuo nome?</h2>
            <p className="mt-6">Nome</p>
            <TextInputField
                placeholder={"Es. Mario"}
                inputStyle={inputClassName}
                marginTop={0}
                value={nome}
                setValue={(e) => setNome(e)}
            />
            <p className="mt-6">Cognome</p>
            <TextInputField
                placeholder={"Es. Rossi"}
                inputStyle={inputClassName}
                marginTop={0}
                value={cognome}
                setValue={(e) => setCognome(e)}
            />

            <div className="">
                <div className="flex flex-col mb-4 mt-6">
                    <CheckBox
                        isChecked={isTermAndCondChecked}
                        toggleValue={toggleTermAndCond}
                    >
                        <p>
                            Dichiaro di aver letto e accetto i Termini e
                            Condizioni.
                            <a
                                href="/termini-e-condizioni"
                                onClick={(e) => e.stopPropagation()}
                                className="underline text-verde italic pl-2 text-sm"
                            >
                                {"(Leggi qui)"}
                            </a>
                        </p>
                    </CheckBox>
                    <CheckBox
                        isChecked={isAgeGreaterThan16}
                        toggleValue={toggleAgeCheck}
                    >
                        <p>Dichiaro di avere più di 16 anni.</p>
                    </CheckBox>
                    <CheckBox
                        isChecked={isNewsletterAllowed}
                        toggleValue={toggleNewsletter}
                    >
                        <p>
                            Desidero ricevere notizie e aggiornamenti da parte
                            di togiftit.
                        </p>
                    </CheckBox>
                </div>
                <PrimaryButton
                    fullWidth
                    disabled={!isNextAllowed}
                    onClick={() => {
                        isNextAllowed && nextStep();
                    }}
                >
                    Prosegui
                </PrimaryButton>
            </div>
        </div>
    );
};

const checkEmailExists = async (email) => {
    try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        console.log("Sign-in methods for", email, ":", signInMethods);
        return signInMethods.length > 0;
    } catch (error) {
        console.error("Error checking email:", error);
        return false;
    }
};

const isValid = (email) => {
    if (!email) return false;
    if (!email.includes("@")) return false;
    if (!email.includes(".")) return false;
    return true;
};

export const RegistrationStepEmail = ({ handleGoogleSignUp, handleAppleSignUp }) => {
    const {
        email,
        isNextAllowed,
        setEmail,
        disableNext,
        enableNext,
        nextStep,
    } = useRegistrationStore();

    const [emailError, setEmailError] = useState(null);

    useEffect(() => {
        const validateEmail = async () => {
            // Prima verifica i requisiti di base dell'email
            if (!isValid(email)) {
                setEmailError(null);
                disableNext();
                return;
            } else {
                enableNext();
            }
        };

        validateEmail();
    }, [email, disableNext, enableNext]);

    return (
        <div className={containerClassName}>
            <h2 className={titleClassName}>Inserisci la tua email</h2>
            <p className="text-left text-base mt-2">
                Hai già un account?{" "}
                <Link to="/login" className="font-semibold underline">
                    Accedi
                </Link>
            </p>
            <TextInputField
                placeholder={"Es. mariorossi@example.com"}
                inputStyle={inputClassName}
                marginTop={6}
                value={email}
                setValue={(e) => setEmail(e)}
            />
            {emailError && <p className="text-rosso">{emailError}</p>}
            <div className="mt-5">
                <PrimaryButton
                    fullWidth
                    disabled={!isNextAllowed}
                    onClick={() => {
                        isNextAllowed && nextStep();
                    }}
                >
                    Prosegui
                </PrimaryButton>
                <p className="text-center my-5">Oppure</p>
                <div className="flex flex-col space-y-2">
                    <SecondaryButton
                        onClick={() => handleGoogleSignUp()}
                        height={12}
                    >
                        <div className={"flex items-center px-0"}>
                            <FcGoogle className="mr-2 text-2xl" />
                            <p>Continua con Google</p>
                        </div>
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={() => handleAppleSignUp()}
                        height={12}
                    >
                        <div className={"flex items-center px-0"}>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                                alt="Apple"
                                className="w-6 mr-3"
                            />
                            <p>Continua con Apple</p>
                        </div>
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );
};

export const RegistrationStepProfileImage = () => {
    const { profileImage, nome, cognome, setProfileImage, nextStep } =
        useRegistrationStore();
    const [loaded, setLoaded] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const btnUpload = useRef(null);
    const [preview, setPreview] = useState(false);

    const onPhotoChoose = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
        setPreview(true);
    };

    const handleSubmit = async () => {
        setProfileImage(imageFile);
        nextStep();
    };

    useEffect(() => {
        setLoaded(true);
    }, []);

    const displayShortName = () => (
        <div className="flex font-bold space-x-2 capitalize mt-5 text-xl">
            <p>{nome}</p>
            <p>{cognome[0]}.</p>
        </div>
    );

    return (
        <div className={containerClassName}>
            <div className="flex flex-col justify-between pb-4">
                <h2 className={titleClassName}>Aggiungi foto profilo</h2>
                <div className="flex flex-col items-center">
                    <div
                        onClick={() => btnUpload.current.click()}
                        className="md:max-w-72 max-w-52  rounded-full overflow-hidden"
                    >
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="profile"
                                className={"aspect-square object-cover"}
                            />
                        ) : preview ? (
                            <img
                                src={imagePreview}
                                alt="profile"
                                className={"aspect-square object-cover"}
                            />
                        ) : (
                            <img
                                src={ProfilePicPlaceholder}
                                alt="profile"
                                className={""}
                            />
                        )}
                    </div>
                    {displayShortName()}
                    <input
                        onChange={(e) => onPhotoChoose(e)}
                        type="file"
                        name=""
                        className="hidden"
                        ref={btnUpload}
                        id=""
                    />
                </div>
                <div className="flex flex-col space-y-4">
                    <Tooltip className="pt-8 font-semibold text-sm">
                        <p>
                            La foto profilo aiuta ad aumentare l'affidabilità
                            tra gli utenti e accresce la probabilità di
                            donazione e adozione.
                        </p>
                    </Tooltip>
                </div>
            </div>

            {/* Questo è il rendering dei bottoni interni, casistica particolare per questo step */}
            {loaded && (
                <div className="flex flex-col-reverse md:flex-row-reverse space-y-2 md:space-y-0 space-y-reverse md:space-x-4 md:space-x-reverse">
                    {imagePreview || profileImage ? (
                        <Bottone
                            bgColor={"bg-transparent"}
                            textStyle={"text-verdeScuro font-bold"}
                            fullWidth
                            onClick={() => btnUpload.current.click()}
                        >
                            <span className="font-bold">Cambia foto</span>
                        </Bottone>
                    ) : (
                        <Bottone
                            fullWidth
                            onClick={() => btnUpload.current.click()}
                        >
                            <span className="font-bold">
                                Aggiungi foto profilo
                            </span>
                        </Bottone>
                    )}
                    {!(imagePreview || profileImage) ? (
                        <Bottone
                            fullWidth
                            bgColor="bg-background"
                            textStyle="text-verdeScuro font-bold"
                            hoverColor="hover:bg-verdeScuro hover:text-white"
                            onClick={nextStep}
                        >
                            Continua senza foto
                        </Bottone>
                    ) : (
                        <Bottone
                            fullWidth
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Prosegui
                        </Bottone>
                    )}
                </div>
            )}
        </div>
    );
};

export const RegistrationStepPassword = () => {
    const {
        password,
        setPassword,
        isNextAllowed,
        enableNext,
        disableNext,
        nextStep,
    } = useRegistrationStore();
    const [confermaPassword, setConfermaPassword] = useState("");

    const isConfermaValid =
        password === confermaPassword && password.length > 0;
    const highlightConferma =
        !isConfermaValid && confermaPassword.length > 0
            ? "!border !border-rosso"
            : "";

    const isValid = () => {
        if (!password) return false;
        if (password.length < 8) return false;
        if (!password.match(/[a-z]/)) return false;
        if (!password.match(/[A-Z]/)) return false;
        if (!password.match(/[0-9]/)) return false;

        if (password !== confermaPassword) return false;
        return true;
    };

    useEffect(() => {
        isValid() ? enableNext() : disableNext();
    }, [password, confermaPassword]);

    return (
        <div className={containerClassName}>
            <div className="flex flex-col space-y-10">
                <div className="w-full ">
                    <h2 className={titleClassName}>Inserisci una password</h2>
                    <div className="flex justify-between items-center pt-6">
                        <TextInputField
                            password
                            placeholder={"Password"}
                            inputStyle={inputClassName}
                            value={password}
                            setValue={(e) => setPassword(e)}
                        />
                    </div>
                </div>
                <div className="">
                    <h2 className={titleClassName}>Conferma password</h2>
                    <div className="flex justify-between items-center pt-6">
                        <div className="flex flex-col w-full ">
                            <TextInputField
                                password
                                placeholder={"Conferma password"}
                                inputStyle={`${inputClassName} ${highlightConferma}`}
                                value={confermaPassword}
                                setValue={(e) => setConfermaPassword(e)}
                            />
                            {password !== confermaPassword &&
                                confermaPassword.length > 0 && (
                                    <p className="text-rosso text-xs">
                                        Le password non corrispondono
                                    </p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6">
                <PrimaryButton
                    fullWidth
                    disabled={!isNextAllowed}
                    onClick={() => nextStep()}
                >
                    Prosegui
                </PrimaryButton>
            </div>
            <div className="flex justify-center ">
                <Tooltip className="pt-8 w-full md:w-auto ">
                    <div className="flex flex-col pl-2 md:pr-4">
                        <p className="text-base font-semibold">
                            La password deve contenere:
                        </p>
                        <ul className="list-disc pl-4 md:text-sm">
                            <li>almeno 8 caratteri</li>
                            <li>almeno una lettera minuscola</li>
                            <li>almeno una lettera maiuscola</li>
                            <li>almeno un numero</li>
                        </ul>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export const RegistrationStepConfirmMail = ({ onCompleted }) => {
    const {
        isNextAllowed,
        email,
        enableNext,
        disableNext,
        emailConfirmationCode,
        setEmailConfirmationCode,
    } = useRegistrationStore();
    const hasLoadedRef = useRef(false); // Aggiungi il riferimento

    const handleLoad = async () => {
        try {
            await AuthAPI.sendVerificationCode(email);
        } catch (e) {
            if (e.response && e.response.status === 429) {
                //Too many requests error
                return;
            }
            toast.error(
                "Si è verificato un errore durante la consegna della mail"
            );
        }
    };

    useEffect(() => {
        if (!hasLoadedRef.current) {
            hasLoadedRef.current = true; // Imposta il riferimento a true
            handleLoad();
        }
    }, []);

    useEffect(() => {
        emailConfirmationCode && emailConfirmationCode.length === 4
            ? enableNext()
            : disableNext();
    }, [emailConfirmationCode]);

    return (
        <div className={containerClassName}>
            <h2 className={titleClassName}>
                Inserisci il codice che abbiamo inviato alla tua email
            </h2>
            <div className="flex flex-col justify-center items-center mt-10 space-y-10">
                <VerificationInput
                    classNames={{
                        container: "border-none",
                        character:
                            "bg-transparent border-t-0 border-l-0 border-r-0 border-b-2 border-b-grigio text-3xl text-grigino",
                        characterFilled: "text-verdeScuro",
                        characterSelected:
                            "hover:ring-0 hover:border-none border-none",
                    }}
                    length={4}
                    placeholder="0"
                    validChars="0-9"
                    autoFocus
                    onChange={(code) => setEmailConfirmationCode(code)}
                />
                <ButtonTimer
                    bgColor={"bg-transparent"}
                    onClick={() => handleLoad()}
                    delay={30000}
                    waitingText="Nuovo codice tra"
                    customStyle="w-1/2 flex p-1 justify-center"
                    initialState={true}
                >
                    <p className="text-verdeScuro underline text-xl">
                        Reinvia codice
                    </p>
                </ButtonTimer>
                <LoadingBtn
                    textStyle={"font-semibold text-white"}
                    disabled={!isNextAllowed}
                    fullWidth
                    onCall={onCompleted}
                >
                    Completa
                </LoadingBtn>
            </div>
        </div>
    );
};
