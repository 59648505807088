import Moment from "react-moment";
import cats from "../Components/Molecules/Categorie/cats";
import LocationIcon from "../assets/Icons/Location";
import IconDistanza from "../assets/Icons/Distanza";
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { IoIosClose } from "react-icons/io";
import Iconfrecciagiu from "../assets/Icons/Iconfrecciagiu";
import GiftUtils from "./GiftUtils";
import Calendar from "../assets/Icons/Calendar";
import Clock from "../assets/Icons/Clock";
import { Gift } from "../types/Gift";

export const Location = ({city}:{city: string}) => {
    return (
        <p className="text-lg px-2 py-1 bg-grigino flex items-center gap-3 rounded-lg font-light">
            <span className="w-6">
                <LocationIcon w={30} black />
            </span>
            {city}
        </p>
    );
};

export const Distanza = ({distanza}:{distanza: string}) => {
    return (
        <p className="font-light flex items-center space-x-2 md:px-6 md:border-r border-verdeScuro">
            <span className="w-5 mr-2 fill-verdeScuro">
                <IconDistanza />
            </span>{" "}
            {distanza} Km
        </p>
    );
};

export const GiftStatus = ({status}: {status: string}) => {
    const objectStatus = GiftUtils.getStatus(status);
    console.log(objectStatus);

    return (
        <div
            className="text-lg px-2 py-1 flex items-center gap-3 rounded-lg font-light"
            style={{ backgroundColor: objectStatus.bgColor as string }}
        >
            <span
                style={{ fill: objectStatus.color as string }}
                className="w-6"
            >
                {typeof objectStatus.img === "function"
                    ? objectStatus.img()
                    : null}
            </span>
            <p
                className="font-semibold"
                style={{ color: objectStatus.color as string }}
            >
                {objectStatus.text as string}
            </p>
        </div>
    );
};

export const GiftDescription = ({descrizione}:{descrizione: string}) => {
    const [expanded, setExpanded] = useState(false);
    const [shouldTruncate, setShouldTruncate] = useState(false);
    const [truncatedDescription, setTruncatedDescription] =
        useState(descrizione);

    const toggleExpanded = () => setExpanded(!expanded);

    const descriptionRef = React.useRef<HTMLParagraphElement>(null);

    React.useEffect(() => {
        if (descriptionRef.current) {
            const lineHeight = parseFloat(
                getComputedStyle(descriptionRef.current).lineHeight
            );
            const maxHeight = lineHeight * 3;
           
            if (descriptionRef.current.scrollHeight > maxHeight) {
                setShouldTruncate(true);
                setTruncatedDescription(
                    descrizione.split("\n").slice(0, 3).join("\n")
                );
            }
        }
    }, [descrizione]);

    return (
        <>
            <p
                ref={descriptionRef}
                className="font-light md:mt-5 mt-2 text-lg"
                style={{
                    maxHeight: expanded ? "none" : "4.5em",
                    overflow: "hidden",
                }}
            >
                {expanded || !shouldTruncate
                    ? descrizione
                    : truncatedDescription}
            </p>
            {shouldTruncate && (
                <button
                    onClick={toggleExpanded}
                    className="text-verdeScuro text-sm underline focus:outline-none"
                >
                    {expanded ? "Leggi meno" : "Leggi tutto"}
                </button>
            )}
        </>
    );
};

export const FullScreenGallery = (
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    images: string[],
    startFrom = 0
) => {
    const [imgCount, setImgCount] = useState(0);

    const handleClick = (segno: string) => {
        console.log(imgCount, images.length);
        if (segno === "+") {
            if (imgCount === images.length - 1) {
                setImgCount(0);
            } else {
                setImgCount(imgCount + 1);
            }
        } else {
            if (imgCount === 0) {
                setImgCount(images.length - 1);
            } else {
                setImgCount(imgCount - 1);
            }
        }
    };

    useEffect(() => setImgCount(startFrom), [startFrom]);

    if (imgCount < 0) return null;

    return (
        <FullScreenImage
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            img={images}
            count={imgCount}
            handleClick={handleClick}
        />
    );
};

interface FullScreenImageProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    img: string[];
    count: number;
    handleClick: (arg0: string) => void;
}

export const FullScreenImage: React.FC<FullScreenImageProps> = ({
    isOpen,
    setIsOpen,
    img,
    count,
    handleClick,
}) => {
    if (!img || img.length === 0) {
        //console.error("L'array img è vuoto o non definito");
        return null; // Non renderizzare nulla
    }

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="backdrop-blur fixed inset-0 z-50 grid place-items-center cursor-pointer"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "0deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="flex justify-center items-center bg-white text-white p-2 w-full h-full shadow-xl cursor-default relative overflow-hidden"
                    >
                        <IoIosClose
                            onClick={() => setIsOpen(false)}
                            className="w-5 h-5 absolute right-5 top-5 bg-verdeScuro hover:bg-verde fill-white rounded-full cursor-pointer"
                        />
                        <button
                            className="rounded-full w-10 h-10 bg-white absolute top-1/2 -translate-y-1/2 left-5 flex justify-center items-center"
                            onClick={() => handleClick("-")}
                        >
                            <Iconfrecciagiu className="w-8 h-8 rotate-0 text-verdeScuro cursor-pointer" />
                        </button>
                        <div className="h-full">
                            <img
                                src={img[count]}
                                className="object-contain h-full w-full"
                                alt="Immagine non caricata correttamente"
                            />
                        </div>
                        <button
                            className="rounded-full w-10 h-10 bg-white absolute top-1/2 -translate-y-1/2 right-5 flex justify-center items-center"
                            onClick={() => handleClick("+")}
                        >
                            <Iconfrecciagiu className="w-8 h-8 rotate-180 text-verdeScuro cursor-pointer" />
                        </button>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export const Separator = () => {
    return <div className="w-full h-2 bg-grigino rounded-full my-5"></div>;
};

export const GiftCategory = ({category}: {category: string}) => {
    return (
        <>
            <div className="w-8 text-verdeScuro">
                {category &&
                    cats.filter((item) => item.micro.includes(category))[0].img}
            </div>
            <p className="">{category}</p>
        </>
    );
};
export const CreatedAt = ({createdAt}:{createdAt: string}) => {
    return (
        <p className=" flex space-x-2 items-center">
            <span className="text-verdeScuro text-xl">
                {" "}
                <Clock w={36} />
            </span>

            <Moment fromNow locale="it">
                {createdAt}
            </Moment>
        </p>
    );
};
export const Availability = ({availability}:{availability: string}) => {
    return (
        <>
            <div className="text-xl text-verdeScuro">
                <Calendar w={36} />
            </div>
            <p className=" ">
                {GiftUtils.getAvailability(availability).text as string}
            </p>
        </>
    );
};

const CardInfoRegalo = ( gift:Gift ) => {
    <div className="flex flex-col">
        <div className="flex items-center justify-between">
            <GiftCategory category={gift.category} />
            <GiftStatus status={gift.status} />
        </div>
    </div>;
};

export default CardInfoRegalo;
