import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Recensione from "../../../assets/Icons/Recensione";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import DragCloseDrawer from "../Drawers/DragCloseDrawer";
import React from "react";
import UserAPI from "../../../utils/API/UserAPI";
import useUserStore from "../../../stores/userStore";
import SecondaryButton from "../Bottoni/SecondaryButton";
import PrimaryButton from "../Bottoni/PrimaryButton";

const Recensisci = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="">
            <button
                onClick={() => setIsOpen(true)}
                className="bg-grigino px-3 py-2 h-full rounded-md flex items-center gap-2"
            >
                <div className="w-6 ">
                    <Recensione yellow={true} />
                </div>
                Recensisci{" "}
            </button>
            <ReviewSpringModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

/**
 *
 * @param {Object} props
 * @param {string} props.userUid
 * @param {string} props.userName
 * @param {boolean} props.isOpen
 * @param {function(boolean): void} props.setIsOpen
 * @param {function(): void} props.onReviewed
 * @returns
 */
const ReviewSpringModal = ({
    userUid,
    userName,
    isOpen,
    setIsOpen,
    onReviewed,
}) => {
    const [star, setStar] = useState(0);
    const [testo, setTesto] = useState("");
    const device = useDeviceDetection();
    const user = useUserStore((state) => state.user);

    const handleRecensisci = async (e) => {
        e.preventDefault();
        try {
            await UserAPI.addReview(userUid ? userUid : user.uid, testo, star);
            onReviewed();
        } catch (error) {
            console.log(error);
        }

        setIsOpen(false);
        setStar(0);
        setTesto("");
    };

    const content = (
        <div className="relative z-10 flex flex-col gap-5">
            <h3 className="text-2xl font-normal  mb-2">
                <span className="font-bold block">Recensione a:</span>{" "}
                {userName ? userName : user.nome}
            </h3>
            <div className="flex flex-col justify-start gap-2">
                <p className="font-semibold">Valutazione complessiva</p>
                <div className="flex gap-2">
                    {Array.from({ length: 5 }).map((_, i) => {
                        return (
                            <button
                                key={i}
                                onClick={() => setStar(i + 1)}
                                className={` ${
                                    star >= i + 1
                                        ? "text-verde"
                                        : "text-grigino"
                                } w-10 rounded-full bg-white `}
                            >
                                <Recensione
                                    yellow={star >= i + 1}
                                    gray={star < i + 1}
                                />
                            </button>
                        );
                    })}
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <p className="font-semibold">Descrizione</p>
                <div className="flex justify-center">
                    <textarea
                        onChange={(e) => setTesto(e.currentTarget.value)}
                        value={testo}
                        placeholder="Scrivi una recensione"
                        className="w-full h-32 p-3 rounded-lg bg-background outline-none border-0 focus:border-0 focus:ring-0"
                    ></textarea>
                </div>
            </div>
            <div className="flex flex-col-reverse sm:flex-row gap-2">
                <SecondaryButton onClick={() => setIsOpen(false)} fullWidth>
                    Annulla
                </SecondaryButton>
                <PrimaryButton
                    onClick={(e) =>
                        star > 0 && testo.length && handleRecensisci(e)
                    }
                    fullWidth
                >
                    Invia
                </PrimaryButton>
            </div>
        </div>
    );

    if (device === "Mobile")
        return (
            <DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
                {content}
            </DragCloseDrawer>
        );
    else
        return (
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => setIsOpen(false)}
                        className=" backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
                    >
                        <motion.div
                            initial={{ scale: 0, rotate: "12.5deg" }}
                            animate={{ scale: 1, rotate: "0deg" }}
                            exit={{ scale: 0, rotate: "0deg" }}
                            onClick={(e) => e.stopPropagation()}
                            className=" bg-white py-6 px-8 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
                        >
                            {content}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
};

export { Recensisci, ReviewSpringModal };
