import React from "react";
import SectionTitle from "./SectionTitle";
import StatusOkCheckIcon from "../../../assets/Icons/StatusOkCheckIcon";
import SecondaryButton from "../../../Components/Atoms/Bottoni/SecondaryButton";
import AreYouShure from "../../../Components/Atoms/Modals/AreYouShure";
import GiftAPI from "../../../utils/API/GiftAPI";

function DeleteGift() {
	const handleDelete = async () => {
		await GiftAPI.deleteGift();
	};

	return (
		<div className="w-full flex flex-col  gap-4 mt-5 pb-5">
			<AreYouShure
				head="Elimina regalo"
				text="Sei sicuro di voler eliminare il regalo? Una volta eliminato non sarà più possibile recuperarlo."
				onClick={() => handleDelete()}
				secondary
				cancel
				title="Elimina regalo"
			/>
		</div>
	);
}

export default DeleteGift;
