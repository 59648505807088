import React from 'react';
import Tic from "./Tic"


const StatusOkCheckIcon = ({ valid }) => {
    const iconStyle = {
        color: valid ? '#8fbb3b' : '#e2e2e2',
        fontSize: '24px',
    };

    return (
        <div  className={`w-6 h-6`}>
           <Tic color={iconStyle.color}/>
        </div>
    );
};

export default StatusOkCheckIcon;
