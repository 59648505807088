import React from "react";
import { Link } from "react-router-dom";

function PrimaryButton({
    ref = null,
    children,
    onClick = () => {
        ("");
    },
    textStyle = "",
    bgColor = "",
    fullWidth = false,
    disabled = false,
    hoverColor = "",
    height = 12,
    href = "",
}: {
    ref?: any;
    children: any;
    onClick?: () => void;
    textStyle?: string;
    bgColor?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    hoverColor?: string;
    height?: number;
    href?: string;
}) {
    const className = `py-2 px-8 text-center cursor-pointer
                ${textStyle || "text-white text-lg"} 
                ${disabled ? "border-none" : "border border-verdeScuro"} 
                ${disabled ? "bg-grigio" : bgColor || "bg-verdeScuro"}
				${
                    (!disabled && hoverColor) || ""
                } hover:scale-[101%] transition-transform duration-200 ease-in-out
				rounded-lg flex items-center justify-center gap-4 ${fullWidth && "w-full"}
				${height && `h-${height}`}`;
    if (href) {
        return (
            <Link
                to={href}
                // target="_blank"
                // rel="noreferrer"
                className={className}
            >
                {children}
            </Link>
        );
    }
    return (
        <button
            ref={ref || null}
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={className}
        >
            {children}
        </button>
    );
}

export default PrimaryButton;
