import React, { useEffect, useState } from 'react';

const FadeInComponent = ({ children, duration=100 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set the visibility to true after the component mounts
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`transition-opacity duration-${duration} ease-in-out ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {children}
    </div>
  );
};

export default FadeInComponent;