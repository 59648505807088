import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 124.34 115.07"
      fill="CurrentColor"
    >
      <g data-name="layer 1">
        <path
          d="M94.73 58.85c-4.79-2.51-10.81-1.23-14.08 3.29-.99 1.38-1.6 2.9-1.89 4.46l-20.78-4.85C40.62 18.67 27.16 19.29 25.37 19.58c-.52.09-.97.41-1.23.88-.25.47-.26 1.02-.05 1.5l16.05 35.63-38.05-8.88a1.7 1.7 0 00-1.47.34c-.4.34-.63.84-.62 1.37.05 1.81 2.09 15.12 47.66 23.85l8.77 19.48c-1.48.58-2.86 1.48-4.01 2.71-4.15 4.43-3.94 11.46.5 15.63 4.45 4.17 11.46 3.94 15.63-.5.17-.18.32-.36.48-.55 2.57-3.13 3.14-7.32 1.78-10.93-2.45-8.66-4.87-16.33-7.23-23.23 7.22.99 15.21 1.89 24.18 2.63 3.8.64 7.81-.73 10.39-3.86.15-.18.3-.37.44-.57.93-1.29 1.52-2.72 1.83-4.18-2.1-3.92-4.54-7.77-5.69-12.03zm-90.1-6.04l37.28 8.7 4.02 8.92c-29.34-5.97-38.51-13.7-41.3-17.62zm61.42 56.44a7.556 7.556 0 01-5.33 2.41c-2.04.06-3.98-.67-5.47-2.06-2.95-2.77-3.21-7.35-.68-10.43.11-.13.22-.25.33-.38 2.88-3.07 7.73-3.23 10.8-.34 3.07 2.88 3.24 7.75.35 10.8zm-.52-15.05a11.14 11.14 0 00-5.7-1.2L28.61 23.69c5.83 2.66 20.31 14.86 36.92 70.52zm-3.21-20.96c-.96-2.69-1.9-5.2-2.83-7.62l19.19 4.48a11.12 11.12 0 002.27 5.36c-6.81-.66-12.97-1.41-18.63-2.22zm33.5-.18c-2.47 3.46-7.25 4.2-10.67 1.74-3.42-2.47-4.2-7.25-1.74-10.67.1-.13.2-.27.31-.39 2.54-3.08 7.08-3.71 10.36-1.35a7.586 7.586 0 013.08 4.97c.33 2.01-.15 4.03-1.34 5.69z"
          className="cls-1"
        ></path>
        <path
          d="M51.6 29.34l4.11 7.31c.78 1.41 1.54 2.79 2.26 4.29 1.69 3.51 3.77 4.79 6.11 3.84 3.25-1.32 6.36-2.96 9.36-4.55l6.46-3.41c6.45 12.14 30.12 56.63 30.12 56.63.15.15.29.3.45.44.06.05.22.18.27.21.16.11.33.21.5.3.07.04.26.13.32.15.12.04.23.09.35.13.34.11.71.16 1.05.27.05 0 .09.01.14.02.2.01.4.04.6.04.41.02.81 0 1.22-.02.08 0 .1 0 .15-.01.17-.03.34-.05.5-.08.41-.08.81-.17 1.2-.29.2-.06.39-.12.59-.19.11-.04.69-.32.14-.05.36-.18.74-.34 1.09-.54.34-.19.65-.4.97-.61l1.01-1a12.6 12.6 0 003.2-5.26c1.11-3.62.55-7.67-1.81-10.96l-3.92-5.14c-2.44-3.41-13.37-22.34-15.31-26.05l-8.2-15.73 18.33-9.68c3.47-1.93 4.76-6.29 2.92-9.94-.9-1.78-2.48-3.15-4.35-3.75L92.97 2.59a7.112 7.112 0 00-3.93-.14c-.46.12-.92.28-1.35.49l-5.75 2.77c-.13-.36-.26-.71-.38-1.07-.06.03-.12.05-.18.08l-.93-1.78A5.561 5.561 0 0077.16.22a5.538 5.538 0 00-4.24.4L68.05 3.2c-2.71 1.43-3.76 4.83-2.32 7.54l.49.92c-.11.05-.2.1-.31.15.05.04.1.07.15.11.35.3.67.63.99.96l-12.09 5.83c-1.59.77-2.85 2.11-3.69 4.12-.87 2.09-.75 4.45.32 6.48zm18.06-23.1l4.87-2.58c.51-.27 1.09-.32 1.64-.15.55.17 1.01.54 1.27 1.04l1.12 2.14c-5.47 2.83 0 0-8.71 4.53l-1.09-2.07c-.54-1.01-.12-2.38.9-2.92zm30.06 40.13c2.01 3.86 13.04 22.93 15.57 26.48l3.93 5.16a9.124 9.124 0 01-1.02 11.8l-.71.7c-1.69 1.66-4.5 1.23-5.61-.86L83.11 35.14l8.44-4.46 8.18 15.69zM54.44 23.75c.38-.92 1.07-1.65 1.94-2.07L89.12 5.9c.87-.43 1.9-.5 2.86-.19l18.46 3.12c1.04.33 1.93 1.1 2.44 2.11 1.03 2.03.31 4.54-1.56 5.59L93.19 26.1c-.02-.05-.05-.1-.08-.16L78.3 33.78s.05.09.07.14l-6.46 3.41c-3.07 1.62-5.97 3.15-9.07 4.41-.01 0-.03.01-.05.02-.59.15-1.34-1.13-1.88-2.24-.75-1.56-1.54-2.99-2.35-4.46l-4.08-7.27c-.6-1.13-.67-2.52-.04-4.04z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;