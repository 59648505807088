
export function renderTime(time: Date) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
}

export function renderDate(rawTime: any) {
    const time = rawTime.toDate();
    const day = time.getDate();
    const month = time.getMonth() + 1;
    const year = time.getFullYear();
    return (
        (day < 10 ? "0" + day : day) +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        year
    );
}

export function capitalizeWords(str:string) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}
