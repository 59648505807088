import { useState, useEffect } from "react";

const useIsStandalone = () => {
    const [isStandalone, setIsStandalone] = useState(false);

    useEffect(() => {
        const mediaQuery = "(display-mode: standalone)";
        const mediaMatcher = window.matchMedia(mediaQuery);

        const updateIsStandalone = (e: any) => {
            console.log("updateIsStandalone", e);

            setIsStandalone(e.matches);
        };

        if (mediaMatcher.matches) {
            setIsStandalone(true);
        }

        mediaMatcher.addEventListener("change", updateIsStandalone);

        return () => {
            mediaMatcher.removeEventListener("change", updateIsStandalone);
        };
    }, []);

    return isStandalone;
};

export default useIsStandalone;
