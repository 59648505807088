import { useEffect, useState } from "react";
import DragCloseDrawer from "../../Atoms/Drawers/DragCloseDrawer";
import Location from "../Dropdown/Location";

const LocationDrawer = ({
	isOpen,
	setIsOpen,
	opennth,
	setCoordinate,
	coordinate,
	cancellaFiltro,
	selectCity,
	setSelectCity,
}) => {
	const [location, setLocation] = useState({ ...coordinate });
	useEffect(() => {
		for (let e of document.querySelectorAll(
			'input[type="range"].slider-progress'
		)) {
			e.style.setProperty("--value", e.value);
			e.style.setProperty("--min", e.min === "" ? "0" : e.min);
			e.style.setProperty("--max", e.max === "" ? "100" : e.max);
			e.addEventListener("input", () =>
				e.style.setProperty("--value", e.value)
			);
		}
	}, []);


	useEffect(() => {
		setLocation(coordinate);
	}, [coordinate]);

	const handleClick = () => {
		setIsOpen(opennth);
	};

	const conferma = () => {
		setCoordinate(location);
		handleClick();
	};

	const deleteFiltro = () => {
		cancellaFiltro();
		handleClick();
	};
	return (
		<DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
			<p className="pl-4 text-left pr-2 text-verdeScuro text-xl md:hover:underline md:hover:text-verdeScuro cursor-pointer font-bold py-1 rounded-md">
				Filtra per località
			</p>
			<Location
				coordinate={location}
				setCoordinate={setLocation}
				cancellaFiltro={deleteFiltro}
				setSelectCity={setSelectCity}
				selectCity={selectCity}
				conferma={conferma}
			/>
		</DragCloseDrawer>
	);
};


export default LocationDrawer;
