import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 147.96 163.52"
    >
      <g data-name="layer 1">
        <circle cx="73.27" cy="61.5" r="27.69" className="cls-1"></circle>
        <path
          d="M33.79 89.61c-13.82-19.7-11.49-46.36 5.52-63.37 9.64-9.64 22.31-14.46 34.98-14.46s25.33 4.82 34.98 14.46a49.374 49.374 0 015.52 63.37l-26.41 37.66h14.38l21.66-30.9c17.1-24.4 14.23-57.39-6.84-78.46-23.88-23.88-62.73-23.88-86.61 0C9.9 38.98 7.02 71.98 24.13 96.37l21.66 30.9h14.38l-26.4-37.66zM74.29 147.38l-5.24-7.47H54.67l14.8 21.11c1.1 1.57 2.9 2.51 4.82 2.51s3.72-.94 4.82-2.51l14.8-21.11H79.53l-5.24 7.47zM146.32 130.02l-12.9-12.64c-2.41-2.36-6.48-.66-6.48 2.72v7.18h-24.17l-8.86 12.63h33.03V148c0 3.39 4.1 5.09 6.5 2.69l12.91-12.91a5.468 5.468 0 00-.04-7.77zM21.01 127.27v-7.18c0-3.38-4.07-5.08-6.48-2.72l-12.9 12.64a5.468 5.468 0 00-.04 7.77l12.91 12.91c2.4 2.4 6.5.7 6.5-2.69v-8.09h33.66l-8.86-12.63H21z"
          className="cls-1"
        ></path>
        <path
          d="M60.19 127.27L45.81 127.27 54.67 139.91 69.05 139.91 60.19 127.27z"
          className="cls-1"
        ></path>
        <path
          d="M88.39 127.27L79.53 139.91 93.91 139.91 102.77 127.27 88.39 127.27z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;