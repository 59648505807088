import React  from 'react';

function CookiePolicy() {

  return (
    <div className='h-[50vh]'>
      <iframe className='w-full h-screen' src="https://www.iubenda.com/privacy-policy/67170133/cookie-policy" title='privacy' frameborder="0"></iframe>
    </div>
  );
}

export default CookiePolicy;