import React, { useState, useEffect } from "react";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import useUserStore from "../../stores/userStore";
import useNavbarStore from "../../stores/navbarStore";
import ProfilePicPlaceholder from "../../assets/PNG/add profile image@4x.png";
import UserAPI from "../../utils/API/UserAPI";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { toast } from "sonner";
import { Link } from "react-router-dom";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";
import useFilterStore from "../../stores/filterStore";

function ModProfilo({}) {
    const user = useUserStore((state) => state.user);
    const [originalUser, setOriginalUser] = useState({ ...user });
    const [modifiedUser, setModifiedUser] = useState({ ...user });
    const [isModified, setIsModified] = useState(false);
    const btnUpload = React.useRef(null);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [preview, setPreview] = useState(false);
    const { resetFilters, setCoordinate } = useFilterStore();

    useEffect(() => {
        setIsModified(
            JSON.stringify(originalUser) !== JSON.stringify(modifiedUser) ||
                imagePreview
        );
    }, [modifiedUser, originalUser, imagePreview]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setModifiedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const setUser = useUserStore((state) => state.setUser);
    const setNavbarVisible = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbarVisible = useNavbarStore(
        (state) => state.setFootbarVisible
    );

    useEffect(() => {
        setNavbarVisible(false);
        setFootbarVisible(false);
    }, [window.location.pathname]);

    const onPhotoChoose = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
        console.log(imageUrl);

        setPreview(true);
    };

    const handlePlaceSelected = async (e) => {
        //e.preventDefault();
        console.log("Place selected", e);

        let streetNumber = "";
        let city = "";
        let country = "";

        e.address_components.forEach((component) => {
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        if (!streetNumber) {
            toast.error("Manca il numero civico");
            return;
        }

        const newLocation = {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng(),
            address: e.formatted_address,
            city: city,
            country: country,
        };

        setModifiedUser({ ...modifiedUser, location: newLocation });
    };

    const handleSubmit = async () => {
        //e.preventDefault();
        // Logica per inviare i dati modificati

        try {
            const updatedUser = modifiedUser;

            if (imagePreview) {
                console.log("Uploading image");

                const imageKey = await UserAPI.uploadProfilePic(imageFile);
                modifiedUser.image = imageKey;
            } else {
                delete modifiedUser.image;
            }

            console.log(modifiedUser);

            await UserAPI.update(modifiedUser);
            resetFilters();
            if (updatedUser.location) {
                setCoordinate({
                    lat: updatedUser.location.lat,
                    lng: updatedUser.location.lng,
                    city: updatedUser.location.city,
                    range: 100,
                });
            }
            setUser(updatedUser);
            setOriginalUser(updatedUser);
            toast.success("Profilo aggiornato con successo");
            setTimeout(() => {
                window.location.href = "/profilo";
            }, 1000);
        } catch (e) {
            toast.error(
                "Si è verificato un errore durante la modifica del profilo"
            );
            throw e;
        }
    };

    return (
        <div className="flex flex-col jsutify-center mt-5 items-center gap-[2vh] min-h-[95vh] md:ml-[25%] md:w-1/2 px-4 mb-4">
            <div className="flex justify-between items-center w-full my-[1vh]">
                <div className="absolute left-0">
                    <Indietro />
                </div>
                <h1 className="text-2xl text-center mx-auto font-semibold">
                    Tuo profilo
                </h1>
            </div>
            <div className="w-full flex flex-col justify-center items-center gap-4 ">
                <div className="flex flex-col items-center">
                    <div
                        onClick={() => btnUpload.current.click()}
                        className="max-w-32  rounded-full overflow-hidden"
                    >
                        {preview ? (
                            <img
                                src={imagePreview}
                                alt="profile"
                                className={"aspect-square w-full object-cover"}
                            />
                        ) : originalUser.image ? (
                            <img
                                src={originalUser.image}
                                alt="profile"
                                className={"aspect-square w-full object-cover"}
                            />
                        ) : (
                            <img
                                src={ProfilePicPlaceholder}
                                alt="profile"
                                className={""}
                            />
                        )}
                    </div>
                    <input
                        onChange={(e) => onPhotoChoose(e)}
                        type="file"
                        name=""
                        className="hidden"
                        ref={btnUpload}
                        id=""
                    />
                </div>
                <h2
                    onClick={() => btnUpload.current.click()}
                    className="font-light cursor-pointer text-grigio underline text-lg"
                >
                    Modifica foto
                </h2>
                <input
                    onChange={(e) => onPhotoChoose(e)}
                    type="file"
                    name=""
                    className="hidden"
                    accept="image/*"
                    ref={btnUpload}
                    id=""
                />
            </div>
            <div className="space-y-4 w-full">
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Località
                    </label>
                    <ReactGoogleAutocomplete
                        apiKey={"AIzaSyDQ9eD1kvMthn2cOXACyzT71fKNoOANPBU"}
                        style={{ width: "100%" }}
                        name="indirizzo"
                        defaultValue={modifiedUser?.location?.address}
                        className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm  sm:text-sm`}
                        onPlaceSelected={async (e) => {
                            try {
                                await handlePlaceSelected(e);
                            } catch (error) {
                                console.log(error);
                                //setModifiedUser({ ...modifiedUser, location: null });
                            }
                        }}
                        placeholder="Indirizzo..."
                        language="it"
                        options={{
                            types: ["address"],
                            componentRestrictions: {
                                country: "it",
                            },
                        }}
                        onChange={() => {
                            //setModifiedUser({ ...modifiedUser, location: null });
                        }}
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Nome
                    </label>
                    <input
                        type="text"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        value={modifiedUser.name}
                        onChange={(e) =>
                            setModifiedUser({
                                ...modifiedUser,
                                name: e.target.value,
                            })
                        }
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Cognome
                    </label>
                    <input
                        type="text"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        value={modifiedUser.surname}
                        onChange={(e) =>
                            setModifiedUser({
                                ...modifiedUser,
                                surname: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="flex flex-col">
                    <label className="block text-sm font-medium text-gray-700">
                        E-mail - non modificabile
                    </label>
                    <input
                        type="email"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm  sm:text-sm bg-gray-100"
                        value={modifiedUser.email}
                        disabled
                    />
                    <Link
                        to="/editpassword"
                        className="text-verdeScuro text-sm mt-3 underline self-end"
                    >
                        Reimposta password
                    </Link>
                </div>

                <div className="hidden">
                    <label className="block text-sm font-medium text-gray-700">
                        Data di nascita{" "}
                        <span className="text-gray-400">(opzionale)</span>
                    </label>
                    <input
                        type="date"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-verdeScuro focus:border-verdeScuro"
                        name="birthdate"
                        value={modifiedUser.birthdate || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="accent-verdeScuro hidden">
                    <label className="block text-sm font-medium text-gray-700">
                        Sesso <span className="text-gray-400">(opzionale)</span>
                    </label>
                    <select
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-verdeScuro focus:border-verdeScuro"
                        name="gender"
                        value={modifiedUser.gender || ""}
                        onChange={handleChange}
                    >
                        <option value="">Seleziona</option>
                        <option value="male">Maschio</option>
                        <option value="female">Femmina</option>
                        <option value="other">Altro</option>
                    </select>
                </div>

                <LoadingBtn
                    type="submit"
                    fullWidth
                    onCall={handleSubmit}
                    disabled={!isModified}
                >
                    Modifica
                </LoadingBtn>
            </div>
        </div>
    );
}

export default ModProfilo;
