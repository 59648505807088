import { create } from "zustand";
import { persist } from "zustand/middleware";

const useFilterStore = create(
    persist(
        (set) => ({
            availability: [],
            status: [],
            category: [],
            bookingStatus: [],
            coordinate: {
                lat: null,
                lng: null,
                range: null,
                city: null,
            },
            
            setAvailability: (value) => set({ availability: value }),
            setStatus: (value) => set({ status: value }),
            setCategory: (value) => set({ category: value }),
            setBookingStatus: (value) => set({ bookingStatus: value }),
            setCoordinate: (value) => set({ coordinate: value }),

            resetFilters: () => set({
                availability: [],
                status: [],
                category: [],
                bookingStatus: [],
                coordinate: {
                    lat: null,
                    lng: null,
                    range: null,
                    city:null
                },
            }),
        }),
        {
            name: "filter-storage", // Nome per il localStorage
        }
    )
);

export default useFilterStore;