import React from "react";
import Accettabile from "../assets/Icons/Stati/Accettabile";
import Buono from "../assets/Icons/Stati/Buono";
import ComeNuovo from "../assets/Icons/Stati/ComeNuovo";
import DaAggiustare from "../assets/Icons/Stati/DaAggiustare";

export interface GiftStatusType {
    id: string;
    text: string;
    img: () => JSX.Element;
    imgPresent?: boolean;
    color: string;
    bgColor: string;
}

const GiftUtils = {
    getStatus: (status: string) => {
        const map: { [key: string]: GiftStatusType } = {
            new: {
                id: "new",
                text: "Come nuovo",
                img: () => React.createElement(ComeNuovo),
                imgPresent: true,
                color: "#1c5439",
                bgColor: "#d4e8d4",
            },
            good: {
                id: "good",
                text: "Buone",
                img: () => React.createElement(Buono),
                imgPresent: true,
                color: "#557000",
                bgColor: "#edf9e4",
            },
            acceptable: {
                id: "acceptable",
                text: "Accettabili",
                img: () => React.createElement(Accettabile),
                imgPresent: true,
                color: "#89410b",
                bgColor: "#fceedb",
            },
            to_fix: {
                id: "to_fix",
                text: "Da riparare",
                img: () => React.createElement(DaAggiustare),
                imgPresent: true,
                bgColor: "#fce3e8",
                color: "#7c001b",
            },
        };
        return status ? map[status] : map;
    },

    getAvailability: (availability: string) => {
        const map: { [key: string]: { id: string; text: string } } = {
            weekend: {
                id: "weekend",
                text: "Fine settimana",
            },
            workdays_evening: {
                id: "workdays_evening",
                text: "Infrasettimanale di sera",
            },
            workdays_day: {
                id: "workdays_day",
                text: "Infrasettimanale di giorno",
            },
            flexible: {
                id: "flexible",
                text: "Flessibile",
            },
        };
        return availability ? map[availability] : map;
    },

    mapImagesStringToItem: (images: string[]) => {
        return images.map((image, index) => ({
            image: image,
            id: index,
            imageFile: null,
        }));
    },

    getBookingStatus: (status: string) => {
        const map: { [key: string]: { id: string; text: string } } = {
            booked: {
                id: "booked",
                text: "Prenotato",
            },
            available: {
                id: "available",
                text: "Disponibile",
            },
            donated: {
                id: "donated",
                text: "Regalato",
            },
        };
        return status ? map[status] : map;
    },
};

export default GiftUtils;
