import * as React from "react";

const Clock = ({w=24}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={w}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#333"
      d="M11 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20m0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16m0-14a1 1 0 0 0-1 1v4.42l-2.1 1.21a1 1 0 0 0 .5 1.87 1 1 0 0 0 .5-.13l2.6-1.5.09-.09.16-.13a1 1 0 0 0 .1-.16 1 1 0 0 0 .08-.17.7.7 0 0 0 .05-.2L12 12V7a1 1 0 0 0-1-1"
    ></path>
  </svg>
);

export default Clock;
