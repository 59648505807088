import * as React from "react";

const PwIcon = ({w}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={w}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#333"
      d="M12 8a2 2 0 0 0-2 2 2 2 0 0 0 1 1.72V15a1 1 0 0 0 2 0v-3.28A2 2 0 0 0 14 10a2 2 0 0 0-2-2m0-6a10 10 0 1 0 0 20 10 10 0 0 0 0-20m0 18a8 8 0 1 1 0-16.001A8 8 0 0 1 12 20"
    ></path>
  </svg>
);

export default PwIcon;
