import React, { useState } from "react";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import { FiEye, FiEyeOff } from "react-icons/fi";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import { auth } from "../../firebase"; // Assicurati che questa sia l'importazione corretta
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { toast } from "sonner";

function EditPW() {
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const handleSave = async () => {
		setError("");
		setSuccess("");

		if (newPassword !== confirmPassword) {
			setError("Le nuove password non corrispondono.");
			return;
		}

		try {
			const user = auth.currentUser;
			if (!user) {
				setError("Nessun utente autenticato.");
				return;
			}

			// Re-autenticazione dell'utente
			const credential = EmailAuthProvider.credential(user.email, oldPassword);
			await reauthenticateWithCredential(user, credential);

			// Aggiorna la password
			await updatePassword(user, newPassword);
			toast.success("Password aggiornata con successo.");
			setSuccess("Password aggiornata con successo.");
		} catch (err) {
			console.error(err);
			toast.error("Errore durante l'aggiornamento della password");
			setError("Errore durante l'aggiornamento della password: " + err.message);
		}
	};

	return (
		<div className="flex flex-col  items-center gap-[2vh] min-h-[80vh] px-4">
			<div className="flex justify-between items-center w-full my-[1vh]">
				<div className="absolute left-0">
					<Indietro />
				</div>
				<h1 className="text-3xl text-center mx-auto font-bold">Modifica Password</h1>
			</div>
			<div className="w-full mt-[7vh] md:max-w-[50%]">
				{/* Vecchia password */}
				<div className="mb-4">
					<label
						htmlFor="old-password"
						className="block text-base font-semibold text-gray-700"
					>
						Vecchia password
					</label>
					<div className="relative mt-1">
						<input
							id="old-password"
							type={showOldPassword ? "text" : "password"}
							value={oldPassword}
							onChange={(e) => setOldPassword(e.target.value)}
							className="w-full p-2 pr-10 text-sm border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-200"
							placeholder="Vecchia password"
						/>
						<button
							type="button"
							onClick={() => setShowOldPassword((prev) => !prev)}
							className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 hover:text-gray-600"
						>
							{showOldPassword ? <FiEye /> : <FiEyeOff />}
						</button>
					</div>
				</div>

				{/* Nuova password */}
				<div className="mb-4">
					<label
						htmlFor="new-password"
						className="block text-base font-semibold text-gray-700"
					>
						Nuova password
					</label>
					<div className="relative mt-1">
						<input
							id="new-password"
							type={showNewPassword ? "text" : "password"}
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							className="w-full p-2 pr-10 text-sm border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-200"
							placeholder="Password"
						/>
						<button
							type="button"
							onClick={() => setShowNewPassword((prev) => !prev)}
							className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 hover:text-gray-600"
						>
							{showNewPassword ? <FiEye /> : <FiEyeOff />}
						</button>
					</div>
				</div>

				{/* Conferma nuova password */}
				<div className="mb-4">
					<label htmlFor="confirm-password" className="sr-only">
						Conferma password
					</label>
					<div className="relative mt-1">
						<input
							id="confirm-password"
							type={showConfirmPassword ? "text" : "password"}
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							className="w-full p-2 pr-10 text-sm border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-200"
							placeholder="Conferma password"
						/>
						<button
							type="button"
							onClick={() => setShowConfirmPassword((prev) => !prev)}
							className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 hover:text-gray-600"
						>
							{showConfirmPassword ? <FiEye /> : <FiEyeOff />}
						</button>
					</div>
				</div>

				{/* Messaggi di errore o successo */}
				{error && <p className="text-red-500 text-sm">{error}</p>}
				{success && <p className="text-green-500 text-sm">{success}</p>}

				<div className="flex justify-end mb-6">
					<a
						href="/resetpassword"
						className="text-sm text-gray-500 underline cursor-pointer"
					>
						Non ricordi la password?
					</a>
				</div>

				{/* Pulsante Salva */}
				<PrimaryButton fullWidth onClick={handleSave}>
					Salva
				</PrimaryButton>
			</div>
		</div>
	);
}

export default EditPW;