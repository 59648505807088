import * as React from "react";

const StandingMan = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="94"
		height="204"
		fill="none"
		viewBox="0 0 72 157"
	>
		<g clipPath="url(#clip0_322_3980)">
			<path
				fill="#9CC70D"
				d="M36.119 71.828v12.405H35.06V71.696l-22.776-2.749 2.919 77.421v.459a9 9 0 0 0-.032.735c0 5.212 4.261 9.438 9.517 9.438s9.517-4.226 9.517-9.438c0-.204-.01-.407-.022-.607 0 0 .621-30.357.969-42.996a8 8 0 0 0 .849-.006c.306 12.632.845 42.982.845 42.982a10 10 0 0 0-.023.625c0 5.213 4.254 9.438 9.5 9.438s9.297-4.027 9.492-9.068h.012v-1.594l2.919-77.259-22.63 2.75z"
			></path>
			<path
				fill="#9CC70D"
				d="M36 32.253c-19.882 0-36 12.65-36 32.359v3.66l72 1.88v-3.66c0-19.708-16.118-34.24-36-34.24"
			></path>
			<path
				fill="#9CC70D"
				d="M36.001 36.124c10.063 0 18.22-8.086 18.22-18.062C54.222 8.087 46.065 0 36.002 0s-18.22 8.087-18.22 18.062S25.937 36.124 36 36.124"
			></path>
			<path
				fill="#fff"
				d="M25.444 14.577q-.008-.05-.01-.103a1 1 0 0 1-.008-.15l.267-.018-.264-.035c.108-2.427 2.122-4.333 4.583-4.333 2.46 0 4.474 1.906 4.582 4.34v.015c.005.068 0 .1-.001.13-.005.086-.008.12-.013.154l-.02.078c-.021.08-.033.121-.049.163l-.028.058a1 1 0 0 1-.084.16l-.032.044a1.2 1.2 0 0 1-.307.305q-.21.143-.457.196l-.055.007c-.059.01-.129.019-.202.019h-.169a.925.925 0 0 1-.91-.815 1 1 0 0 1-.023-.12c-.067-.77-.39-1.382-.932-1.782l-.085-.062-.143-.097c-.016-.01-.042-.028-.07-.042a2 2 0 0 0-.28-.122 4 4 0 0 0-.296-.088l-.09-.02a1.6 1.6 0 0 0-.319-.037h-.035q-.141 0-.315.037l-.073.016a3 3 0 0 0-.315.092c-.101.033-.192.077-.284.123q-.036.02-.077.048c-.027.02-.066.046-.105.07l-.115.085c-.543.399-.864 1.01-.931 1.769a1 1 0 0 1-.025.15.92.92 0 0 1-.908.796h-.17q-.108-.001-.21-.02a1.25 1.25 0 0 1-.68-.35 1.3 1.3 0 0 1-.135-.165 1 1 0 0 1-.11-.196l-.027-.056a1 1 0 0 1-.04-.131c-.016-.058-.023-.084-.027-.11zm21.053 5.713c-1.327 4.636-5.64 7.874-10.493 7.874s-9.168-3.238-10.493-7.874a1.03 1.03 0 0 1 .176-.919 1.09 1.09 0 0 1 .872-.431H45.45c.344 0 .662.156.871.431.203.266.267.602.177.919zm-.276-5.06a1.24 1.24 0 0 1-.9.377h-.072c-.54 0-.982-.402-1.029-.935-.067-.768-.39-1.38-.932-1.78l-.058-.044c-.085-.062-.167-.126-.262-.172a2 2 0 0 0-.238-.103 2.1 2.1 0 0 0-.709-.152l-.054.003c-.096 0-.197.011-.315.036l-.072.016c-.12.029-.219.06-.316.093a2 2 0 0 0-.308.133q-.04.023-.076.05-.04.03-.082.057c-.671.428-1.056 1.095-1.056 1.831.001.27-.101.525-.282.705a.9.9 0 0 1-.64.265h-.17a1.25 1.25 0 0 1-1.25-1.295 4.58 4.58 0 0 1 4.586-4.375c2.488 0 4.502 1.927 4.587 4.386.012.34-.114.662-.352.904"
			></path>
			<path
				fill="#2D302B"
				d="M56.998 46.628h-10.23c.646-.624 1.288-1.353 1.762-2.06 1.201-1.794 1.622-4.16.803-6.188-1.099-2.723-3.809-4.064-6.687-3.521-2.61.492-5.302 3.089-6.573 5.32-.038.066-.07.137-.107.205-1.169-2.183-3.247-4.385-5.64-5.256-2.667-.97-5.713-.15-7.211 2.309-1.222 2.002-1.085 4.457-.032 6.49.477.921 1.287 1.912 2.085 2.7H15.003c-.957 0-1.733.769-1.733 1.718v7.092c0 .949.776 1.719 1.733 1.719h.065v25.753c0 2.057 1.682 3.723 3.756 3.723h34.353c2.075 0 3.757-1.666 3.757-3.724V57.155h.064c.957 0 1.735-.77 1.735-1.719v-7.091c0-.95-.776-1.72-1.734-1.72zm-18.565-4.686c.61-1.613 1.919-3.245 3.511-4.09 1.57-.833 3.663-.707 4.65.907.432.705.585 1.5.503 2.315-.01.05-.044.269-.052.308q-.059.273-.143.541c-.133.421-.189.54-.42.939-.903 1.568-2.64 3.1-4.411 3.764h-4.702l-.009-.072c.184-1.473.521-3.163 1.07-4.614zM25.829 43.37c-.468-.667-.695-1.143-.87-1.918-.091-.408-.097-.432-.09-.912.007-.443.1-.887.273-1.3.734-1.753 2.579-2.212 4.289-1.653 1.753.573 3.203 2.296 3.948 3.918.667 1.454 1.141 3.393 1.34 4.994q-.006.064-.014.129H29.84c-1.523-.653-3.154-2.036-4.01-3.258"
			></path>
			<path
				fill="#9CC70D"
				d="M34.288 67.187c.022-1.96-.836-3.746-2.99-4.596-2.124-.84-4.655-.24-6.43 1.189-1.272 1.023-2.194 2.407-3.391 3.514-1.74 1.61-3.777 1.95-6.017 1.873-.295-.01-5.016-5.425-5.203-7.942C9.983 57.569.02 67.274.012 67.45c-.207 5.048 2.275 13.456 6.4 16.425 4.122 2.968 9.765 3.737 14.543 1.98 3.78-1.39 6.911-4.224 9.114-7.572 1.85-2.814 3.085-5.982 3.95-9.217a7.7 7.7 0 0 0 .269-1.88M37.713 67.187c-.022-1.96.837-3.746 2.99-4.596 2.124-.84 4.655-.24 6.43 1.189 1.272 1.023 2.194 2.407 3.391 3.514 1.74 1.61 3.789 1.642 6.017 1.873l7.6.792c.455.047 7.84-.806 7.847-.63.207 5.048-2.276 11.576-6.4 14.544-4.123 2.969-9.765 3.737-14.543 1.98-3.78-1.39-6.912-4.224-9.114-7.571-1.85-2.814-3.085-5.983-3.95-9.217a7.7 7.7 0 0 1-.27-1.88z"
			></path>
		</g>
		<defs>
			<clipPath id="clip0_322_3980">
				<path fill="#fff" d="M0 0h72v157H0z"></path>
			</clipPath>
		</defs>
	</svg>
);

export default StandingMan;
