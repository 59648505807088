import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import TogiftitProfilePic from "../../Components/Atoms/ProfilePic/TogiftitProfilePic";
import FadeInComponent from "../../Components/Atoms/Transitions/FadeInComponent";
import React from "react";

/**
 * 
 * @param {Object} props
 * @param {import("../../utils/API/NotificationAPI").Notification} props.notification
 * @returns 
 */
function NotificationTile({ notification }) {
    return (
        <FadeInComponent>
            <div className={`flex py-4 px-4 justify-start rounded-lg items-stretch hover:bg-grigio hover:bg-opacity-20 transition duration-300 cursor-pointer`}
                onClick={() => {
                    window.location.href = notification.link;
                }}>
                <div className="flex flex-col items-center">
                    {
                        notification.sender === "TOGIFTIT" ?
                        <TogiftitProfilePic/> :
                        <ProfilePic w={12} userId={notification.sender} key={notification.sender}/>
                    }
                </div>
                <div className="w-4"></div>
                <div className="flex flex-col w-3/5 justify-between pb-2">
                    <div className="text-lg font-medium">{notification.title}</div>
                    <div className="text-gray-600 text-xs break-words h-4">
                        {notification.content.length > 60
                            ? `${notification.content.substring(0, 60)}...`
                            : notification.content}
                    </div>
                </div>
                <p className="ml-2 text-sm text-grigio">
                    {
                        //(notification.createdAt)
                    }
                </p>
            </div>
        </FadeInComponent>
    );
}

export default NotificationTile;