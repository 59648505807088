import React from "react";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import Punti from "../../assets/Punti";
import useUserStore from "../../stores/userStore";
import BlackCircles from "../../assets/Sfondi/BlackCircles";
import GroupPoints from "../../assets/Illustrazioni/GroupPoints";
import StandingMan from "../../assets/Illustrazioni/StandingMan";
import GuyHoldingGift from "../../assets/Illustrazioni/GuyHoldingGift";
import HandshakeColored from "../../assets/Illustrazioni/HandshakeColored";
import HelpGuy from "../../assets/Illustrazioni/HelpGuy";
import { Link } from "react-router-dom";
import useNavbarStore from "../../stores/navbarStore";
import Ellisse from "../../assets/Ellisse";

function PuntiGiftPage() {
	const user = useUserStore((state) => state.user);
	const availableBookings = Math.floor(user.points / 100);
	const windowWidth = window.innerWidth;
	const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
	const setFootbar = useNavbarStore((state) => state.setFootbarVisible);

	React.useEffect(() => {
		setNavbar(false);
		setFootbar(false);
	}, [window.location.pathname]);

	return (
		<div className="flex flex-col justify-center items-center gap-[2vh] min-h-[80vh] relative mb-[8vh]">
			<div className="flex justify-between items-center w-full my-[1vh] px-4">
				<div className="absolute left-0">
					<Indietro />
				</div>
				<h1 className="text-3xl text-center mx-auto font-bold">
					I tuoi punti Gift
				</h1>
			</div>

			<div className="w-full flex flex-col justify-center items-center gap-[2vh] relative">
				<div className="flex justify-center items-center gap-4 rounded-xl shadow-lg px-4 py-2">
					<h2 className="font-bold text-4xl">{user.points}</h2>
					<div className="w-12">
						<Punti />
					</div>
				</div>
				<h2 className="text-xl">
					Puoi prenotarti a {" "}
					<span className="font-bold">{availableBookings}{" "}
					{availableBookings > 1 ? "regali" : "regalo"}</span>
				</h2>
				<div className="mt-[2vh] w-full flex flex-col jsutify-center items-center relative z-0">
					{windowWidth < 450 ? (
						<div className="w-full">
							<BlackCircles />
						</div>
					) : (
						<div className="">
							<Ellisse w="800" />
						</div>
					)}
					<div
						className={`absolute -top-[7%] ${
							windowWidth < 400
								? "-right-[14%]"
								: windowWidth < 450
								? "right-[8%]"
								: "right-[30%]"
						} z-10`}
					>
						<GroupPoints />
					</div>
					<div className="absolute min-w-[90%] md:min-w-0 md:max-w-[30vw] top-[12%] md:top-[20%] left-1/2 -translate-x-1/2 z-20 md:mx-4">
						<h3 className="text-white text-2xl font-black">
							Cosa sono <br />i <span className="text-3xl ">Punti Gift?</span>
						</h3>
						<p
							className={`text-white ${
								windowWidth < 400 ? "text-sm leading-5" : "text-lg leading-6"
							} mt-[5%] `}
						>
							I Punti Gift sono la moneta della generosità. Non si acquistano,
							ma si accumulano{" "}
							<span className="font-bold">
								partecipando attivamente alla community;
							</span>{" "}
							donando, condividendo, e compiendo azioni virtuose.
						</p>
						<p
							className={`text-white ${
								windowWidth < 400 ? "text-sm leading-5" : "text-lg leading-6"
							} mt-[3%] `}
						>
							Ogni punto rappresenta{" "}
							<span className="font-bold">
								il tuo contributo per un mondo più solidale e sostenibile.
							</span>
						</p>
					</div>
				</div>
				<div className="flex flex-col gap-2 md:flex-row mt-[3vh] md:mt-[16vh] px-6 md:px-44 ">
					<div className=" w-full justify-start">
						<h3 className="font-black text-2xl ">A cosa servono?</h3>
						<div className="flex mt-[2vh] md:gap-10 gap-[4vh] justify-center items-center">
							<div className="">
								<StandingMan />
							</div>
							<div>
								<h4 className="font-extrabold text-lg">Per prenotarti:</h4>
								<p className="">
									Quando ti prenoti, i tuoi punti vengono temporaneamente
									sottratti dal tuo saldo. Questo serve a evitare prenotazioni
									indiscriminate. Se il regalo andrà a qualcun altro, i punti ti
									verranno restituiti automaticamente.{" "}
								</p>
							</div>
						</div>
					</div>
					<div className=" w-fulljustify-start md:mt-0 mt-[4vh]">
						<h3 className="font-black text-2xl ">Come li guadagno?</h3>
						<div className="flex mt-[2vh] justify-center items-center gap-[4vh]">
							<div className="w-[94px] h-auto">
								<GuyHoldingGift />
							</div>
							<div>
								<h4 className="font-extrabold text-lg">Dona un oggetto:</h4>
								<p className="">
									Guadagni <strong>100 punti </strong> per ogni regalo
									consegnato.
								</p>
							</div>
						</div>
						<div className="flex mt-[4vh] justify-center items-center gap-[4vh]">
							<div className="">
								<HandshakeColored />
							</div>
							<div>
								<h4 className="font-extrabold text-lg">Completa missioni:</h4>
								<p className="">
									Nella sezione missioni trovi attività come leggere articoli
									educativi o compiere azioni virtuose.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-[4vh] w-full flex flex-col justify-center items-center gap-[2vh] px-4">
					<h3 className="font-black text-2xl ">Hai altre domande?</h3>
					<HelpGuy />
					<p className="w-full text-center">
						Visita le nostre{" "}
						<Link className="font-bold underline" to={"/FAQ"}>
							FAQ
						</Link>{" "}
						per scoprire tutto sui Punti Gift e come usarli!
					</p>
				</div>
			</div>
		</div>
	);
}

export default PuntiGiftPage;
