import Cookies from "universal-cookie";
import { apiGet, apiPost, getAbsoluteImage, objectKeyFromS3Url, uploadImage } from "./CallBackend";

const apiPrefix = "chat";

/**
 * @typedef {Object} Chat
 * @property {Message[]} messages
 */

/**
 * @typedef {Object} Message
 * @property {string} content
 * @property {string} sendBy
 * @property {string | null} image 
 * @property {string | null} autoType
 * @property {Date} createdAt
 */

/**
 * @typedef {Object} LocalMessage
 * @property {string} content
 * @property {string | null} image
 */

const ChatAPI = {
    /**
     * 
     * @param {string} bookingId 
     * @returns {Promise<Chat>}
     */
    getMessages: async (bookingId) => {
        const chatData = await apiGet(`${apiPrefix}/${bookingId}`, true);

        new Cookies().set('messages_token', chatData.token, {});

        for (let message of chatData.chat.messages) {
            message.createdAt = new Date(message.createdAt);
        }

        for (const message of chatData.chat.messages) {
            if (message.image) {
                message.image = getAbsoluteImage(message.image);
            }
        }

        return chatData.chat;
    },

    /**
     * @param {string} bookingId 
     * @param {LocalMessage} message
     */
    sendMessage: async (bookingId, message) => {
        await apiPost(`${apiPrefix}/${bookingId}`, {
            message: message
        }, true);
    },

    /**
     * 
     * @param {*} imageFile 
     * @param {string} bookingId 
     * @returns {Promise<string>}
     */
    uploadMessageImage: async (imageFile, bookingId) => {
        const signedUrl = await apiPost(`${apiPrefix}/${bookingId}/image`, {}, true);
        await uploadImage(imageFile, signedUrl);
        return objectKeyFromS3Url(signedUrl);
    }

};

export default ChatAPI;