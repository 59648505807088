import React, { useContext, useEffect } from "react";
import { ReducerContext } from "../../rootReducer";
import { RecuperoStepEmail, ConfermaEmail } from "./Steps";
import Wizard from "../../Components/Molecules/Wizard/Wizard";

import Logo from "../../assets/Logos/Logo";
import ImageRegistrati from "../../assets/Illustrazioni/Registrati.svg";
import useNavbarStore from "../../stores/navbarStore";
import useUserStore from "../../stores/userStore";
const RecuperoPassword = () => {
	const { dispatch } = useContext(ReducerContext);

	useEffect(() => {
		dispatch({ type: "hide_sidebar_phone" });
	}, []);

	const { setFootbarVisible } = useNavbarStore();

    useEffect(() => {
        setFootbarVisible(false);
    }, []);

    const user = useUserStore((state) => state.user);
    const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbar = useNavbarStore((state) => state.setFootbarVisible);

    React.useEffect(() => {
        setNavbar(false);
        setFootbar(false);
    }, [window.location.pathname]);

	const columnClassName = "flex flex-col flex-1 h-full px-5 py-5";
	const wizard = (
		<Wizard
			showStep
			isNextAllowed={true}
			isPrevAllowed={true}
			completaText="Vai all'accesso"
			onCompleted={() => {
				window.location.href = "/login";
			}}
			steps={[RecuperoStepEmail, ConfermaEmail]}
		/>
	);

	return (
		<div>
			<div
				className={`flex justify-center overflow-hidden items-start h-[100dvh] py-4 w-full bg-background/60`}
			>
				<div
					className={`${columnClassName} hidden md:flex items-center justify-center`}
				>
					<div className="flex flex-1 justify-center">
						<Logo />
					</div>
					<div className="flex-1">
						<img
							src={ImageRegistrati}
							alt="Registrati"
							className="w-full h-full object-cover"
						/>
					</div>
				</div>

				<div className={`${columnClassName}`}>{wizard}</div>
			</div>
		</div>
	);
};

export default RecuperoPassword;
