import { motion } from "framer-motion";
import { useRef, useState } from "react";
import cats from "../Categorie/cats";
import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";
import DragCloseDrawer from "../../Atoms/Drawers/DragCloseDrawer";
import SecondaryButton from "../../Atoms/Bottoni/SecondaryButton";

const CategorieDrawer = ({ isOpen, setIsOpen, micro, setMicro }) => {
    const [macro, setMacro] = useState(null);
    const selectall = useRef(null);

    const toggleSelection = (macro) => {
        const category = cats.find((el) => el.macro === macro);
        if (!category) return;

        const hasSelectedMicro = category.micro.some((item) =>
            micro.includes(item)
        );
        if (hasSelectedMicro) {
            // Deseleziona tutte le micro di questa macro
            const micros = category.micro.filter((item) => !category.micro.includes(item));
            setMicro(micros
            );
        } else {
            // Seleziona tutte le micro di questa macro
            const micros =  [...micro, ...category.micro.filter((item) => !micro.includes(item))];
            setMicro(micros);
        }
        console.log(micro);
        
    };

    return (
        <DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
            <p className="pl-4 text-left pr-2 text-verdeScuro text-xl md:hover:underline md:hover:text-verdeScuro cursor-pointer font-bold py-1 rounded-md">
                Seleziona categorie...
            </p>
            <div className="flex items-center ">
                <motion.div
                    animate={isOpen ? "open" : "closed"}
                    className="relative w-full"
                >
                    <motion.ul
                        initial={wrapperVariants.closed}
                        variants={wrapperVariants}
                        transition={{ duration: 0.001 }}
                        style={{ originY: "top" }}
                        className="flex z-40 flex-col p-2 w-full overflow-hidden"
                    >
                        {macro === null ? (
                            cats.map((item, i) => {
                                return (
                                    <Option
                                        key={i}
                                        prop={item.macro}
                                        Icon={item.img}
                                        setOpen={setIsOpen}
                                        setMacro={setMacro}
                                        macro={macro}
                                        micro={micro}
                                    />
                                );
                            })
                        ) : (
                            <div className="overflow-y-scroll">
                                <div
                                    onClick={() => {
                                        setMacro(null);
                                    }}
                                    className="text-lg space-x-4 capitalize w-full flex justify-between pr-2 items-center cursor-pointer md:hover:bg-verdeScuro/10 py-1 rounded-md"
                                >
                                    <div className="flex justify-start pl-3 space-x-3 text-black">
                                        <Iconfrecciagiu
                                            className={
                                                "w-3 opacity-75 rotate-0"
                                            }
                                        />{" "}
                                        <p>{macro}</p>
                                    </div>
                                    {cats
                                        .find((el) => el.macro === macro)
                                        .micro?.filter((item) =>
                                            micro?.includes(item)
                                        ).length ? (
                                        <span className=" rounded-md text-sm aspect-square h-5 flex justify-center font-medium items-center bg-grigino">
                                            {
                                                cats
                                                    .find(
                                                        (el) =>
                                                            el.macro === macro
                                                    )
                                                    .micro.filter((item) =>
                                                        micro.includes(item)
                                                    ).length
                                            }
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>

                                <div
                                    onClick={() => {
                                        selectall.current.click();
                                    }}
                                    className="flex justify-between items-center p-2 text-lg font-medium rounded-lg  md:hover:bg-verdeScuro/10"
                                >
                                    <span>Seleziona tutte</span>
                                    <input
                                        className="cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                                        type="checkbox"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleSelection(
                                                cats.find(
                                                    (el) => el.macro === macro
                                                ).macro
                                            );
                                        }}
                                        ref={selectall}
                                        checked={cats
                                            .find((el) => el.macro === macro)
                                            .micro.every((item) =>
                                                micro.includes(item)
                                            )}
                                    />
                                </div>
                                <div className="w-full h-[1px] bg-verdeScuro/30 "></div>

                                {cats.map((item) => {
                                    if (item.macro === macro) {
                                        return item.micro.map((micros, idx) => {
                                            return (
                                                <Optionmicro
                                                    key={idx}
                                                    prop={micros}
                                                    setOpen={setIsOpen}
                                                    setMacro={setMacro}
                                                    macro={macro}
                                                    setMicro={setMicro}
                                                    micro={micro}
                                                />
                                            );
                                        });
                                    }
                                    return null; // Return null if the condition is not met to avoid warnings
                                })}
                            </div>
                        )}

                        <div className="flex items-center gap-2 mt-2 mr-3 justify-stretch self-end">
                            <SecondaryButton
                                onClick={() => {
                                    setMacro(null);
                                    setMicro([]);
                                }}
                            >
                                Cancella filtri
                            </SecondaryButton>
                        </div>
                    </motion.ul>
                </motion.div>
            </div>
        </DragCloseDrawer>
    );
};

const Option = ({ prop, Icon, setMacro, micro }) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                setMacro(prop);
            }}
            className="flex z-40 capitalize relative items-center justify-between gap-2 w-full px-2.5 py-3 whitespace-nowrap rounded-md md:hover:bg-verdeScuro/10 text-slate-700 md:hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <div className="flex items-center text-sm space-x-3">
                <div className="w-6">{Icon}</div>
                <span>{prop}</span>
            </div>
            <div className="flex space-x-5 items-center">
                {cats
                    .find((el) => el.macro === prop)
                    .micro.filter((item) => micro.includes(item)).length ? (
                    <span className=" rounded-md text-sm aspect-square h-5 flex justify-center font-medium items-center bg-grigino">
                        <p>
                            {
                                cats
                                    .find((el) => el.macro === prop)
                                    .micro.filter((item) =>
                                        micro.includes(item)
                                    ).length
                            }
                        </p>
                    </span>
                ) : (
                    <span></span>
                )}
                <span>
                    <Iconfrecciagiu className={"w-3 opacity-75 rotate-180"} />
                </span>
            </div>
        </motion.li>
    );
};
const Optionmicro = ({ prop, setMicro, micro }) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                setMicro(
                    micro.includes(prop)
                        ? micro.filter((el) => el !== prop)
                        : [...micro, prop]
                );
            }}
            className="flex z-40 relative items-center text-sm justify-between gap-2 w-full p-1.5  whitespace-nowrap rounded-md md:hover:bg-verdeScuro/10 text-slate-700 md:hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <span>{prop}</span>
            <input
                className="cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                type="checkbox"
                name=""
                id=""
                checked={micro.includes(prop)}
            />
        </motion.li>
    );
};

export default CategorieDrawer;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};
