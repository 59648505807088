import React, { useEffect, useState } from "react";
import useUserStore from "../../stores/userStore";
import Iconfrecciagiu from "../../assets/Icons/Iconfrecciagiu";
import Punti from "../../assets/Punti";
import Trattative from "../../assets/sidebar/Trattative";
import StellaVuota from "../../assets/Icons/StellaVuota";
import InvitaUnAmico from "../../assets/Icons/InvitaUnAmico";
import Termini from "../../assets/Icons/Termini";
import Privacy from "../../assets/Icons/Privacy";
import Cookies from "../../assets/Icons/Cookies";
import DatiAccount from "../../assets/Icons/DatiAccount";
import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import useNavbarStore from "../../stores/navbarStore";
import { useNavigate } from "react-router-dom";
import LevelsUtils from "../../utils/LevelsUtils";
import UserAPI from "../../utils/API/UserAPI";
import Missioni from "../../assets/sidebar/Missioni";
import Fabiano from "../../assets/Icons/fabiano.svg";
import Heart from "../../assets/Icons/Heart";
import PwIcon from "../../assets/Icons/PwIcon";
import DeleteAccount from "./DeleteAccount";
import DeleteProfile from "../../assets/Icons/DeleteProfile";

function Profilo() {
	const user = useUserStore((state) => state.user);
	const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
	const setFootbar = useNavbarStore((state) => state.setFootbarVisible);

	const navigate = useNavigate();

	const menuP = [
		{
			icona: <Trattative w="30" />,
			titolo: "Le mie trattative",
			link: "/trattative",
		},
		{
			icona: <Heart w="30" />,
			titolo: "I miei preferiti",
			link: "/favourites",
		},
		{
			icona: <StellaVuota w="30" />,
			titolo: "Le mie recensioni",
			link: "/recensioni",
		},
		{
			icona: <InvitaUnAmico w="30" />,
			titolo: "Invita un amico",
			link: "/invite",
		},
		{
			icona: <Missioni w="30" />,
			titolo: "Missioni Gifter",
			link: "/missioni",
		},
	];
	const menuS = [
		{
			icona: <PwIcon w="45" />,
			titolo: "Modifica Password",
			link: "/editpassword",
		},
		{
			icona: <Termini w="45" />,
			titolo: "Termini e condizioni",
			link: "/termini",
		},
		{
			icona: <Privacy w="45" />,
			titolo: "Privacy Policy",
			link: "/privacy",
		},
		{ icona: <Cookies w="45" />, titolo: "Cookie Policy", link: "/cookie" },
		{
			icona: <DeleteProfile w="45" />,
			titolo: "Elimina Account",
			link: "/deleteaccount",
		},
	];

	const [completionPercentage, setCompletionPercentage] = useState(0);

	const handleLoad = async () => {
		const cri = await UserAPI.getLevel(user.uid);
		const completedCriteriaCount = Object.values(cri.criteriaStatus).filter(
			(criteria) => criteria.isCompleted
		).length;

		const totalCriteriaCount = Object.keys(cri.criteriaStatus).length;

		setCompletionPercentage(
			((completedCriteriaCount / totalCriteriaCount) * 100).toFixed(0)
		);
	};

	useEffect(() => {
		setNavbar(false);
		setFootbar(true);
		handleLoad();
	}, []);

	const helpCenter = () => {
		return (
			<div
				onClick={() => navigate("/faq")}
				className="flex cursor-pointer bg-verdeScuro px-4 py-3 rounded-2xl items-center justify-between"
			>
				<div className="">
					<img src={Fabiano} alt="" />
				</div>
				<div className="md:px-5 flex flex-col text-white font-medium gap-2">
					<p className="text-xl font-bold">Hai bisogno di aiuto?</p>
					<div className="">
						<p className="">Consulta la sezione FAQ</p>
						<p className="">...oppure contattaci ;)</p>
					</div>
				</div>
				<div className="text-white">
					<Iconfrecciagiu className={" w-10 rotate-180"} />
				</div>
			</div>
		);
	};

	return (
		<div className="min-h-[100vh] relative mb-16 mt-5 ">
			<div className="px-4 md:px-12 my-2 md:mt-0 flex flex-col justify-center  items-center ">
				<div className="w-full flex flex-col justify-center items-center  md:w-1/2">
					<h1 className="text-2xl md:text-3xl w-full md:text-center p-2¢ font-semibold self-start">
						Profilo
					</h1>
					<div
						onClick={() => navigate("/modprofilo")}
						className="w-full h-full  flex items-center justify-between border border-grigino shadow-lg sha rounded-lg cursor-pointer my-3"
					>
						<div className="flex gap-2 h-full">
							<div className="p-2">
								<ProfilePic userId={user.uid} w={16} />
							</div>
							<div className="flex flex-col justify-center">
								<p className="capitalize">
									<span>{user.name}</span> <span>{user.surname}</span>
								</p>
								<p className="text-grigio">Visita il tuo profilo</p>
							</div>
						</div>
						<div className="w-10 rotate-180 ">
							<Iconfrecciagiu />
						</div>
					</div>
					<div
						onClick={() => navigate("/livello")}
						className="w-full  grid grid-cols-12 items-center border border-grigino shadow-lg sha rounded-lg cursor-pointer my-3 pl-2"
					>
						<div className="flex gap-2 col-span-11">
							<div className="w-20 h-20 grid place-items-center ">
								{LevelsUtils.getLevel(user.level).img()}
							</div>
							<div className="flex flex-col justify-center gap-2 w-full">
								<p className="capitalize">
									<span>Gifter liv.</span>{" "}
									<span className="font-semibold">
										{LevelsUtils.getLevel(user.level).nome}
									</span>
								</p>
								<div className="w-[90%] h-3 rounded-full bg-grigino">
									<div
										style={{
											width: `${completionPercentage}%`,
										}}
										className={`h-full  bg-verde rounded-full`}
									></div>
								</div>
							</div>
						</div>
						<div className="w-10 rotate-180 justify-self-end">
							<Iconfrecciagiu />
						</div>
					</div>
					<div
						onClick={() => navigate("/puntigift")}
						className="w-full  flex items-center justify-between border border-grigino shadow-lg sha rounded-lg cursor-pointer my-3"
					>
						<div className="flex gap-2">
							<div className="w-20 h-20 flex justify-center items-center p-2">
								<Punti />
							</div>
							<div className="flex flex-col justify-center">
								<p className="">
									<span>I tuoi Punti Gift</span>
								</p>
								<p className="font-bold text-lg">{user.points}</p>
							</div>
						</div>
						<div className="w-10 rotate-180 ">
							<Iconfrecciagiu />
						</div>
					</div>
				</div>
				<div className="w-full md:w-1/2 md:mt-10 md:flex md:flex-col md:items-center">
					<h1 className="text-2xl md:text-3xl font-semibold md:w-1/2 self-start md:mt-0 mt-12">
						Panoramica
					</h1>
					{menuP.map((item, index) => (
						<div onClick={() => navigate(item.link)} className="w-full fill">
							<div
								key={index}
								className=" flex justify-between items-center  cursor-pointer my-3"
							>
								<div className="flex gap-5 h-full">
									<div className=" w-9 h-9 flex justify-center items-center ">
										{item.icona}
									</div>
									<div className="flex flex-col justify-center">
										<p className="capitalize">{item.titolo}</p>
									</div>
								</div>
								<div className="w-7 rotate-180 ">
									<Iconfrecciagiu />
								</div>
							</div>
							<div className="h-[1px] w-full bg-verdeScuro"></div>
						</div>
					))}
				</div>
				<div className="w-full md:mt-10 md:w-1/2 mt-5 md:flex md:flex-col md:items-center">
					{helpCenter()}
				</div>
				<div className="w-full md:mt-10 md:w-1/2 md:flex md:flex-col md:items-center">
					<h1 className="text-2xl md:text-3xl font-semibold self-start md:mt-0 mt-12">
						Sicurezza
					</h1>
					{menuS.map((item, index) => (
						<div
							onClick={() =>
								item.target === "_blank"
									? window.open(item.link)
									: (window.location.href = item.link)
							}
							className="w-full"
						>
							<div
								key={index}
								className=" flex justify-between items-center  cursor-pointer my-3"
							>
								<div className="flex gap-5 h-full">
									<div className="fill-verdeScuro text-verdeScuro w-9 h-9 flex justify-center items-center ">
										{item.icona}
									</div>
									<div className="flex flex-col justify-center">
										<p className="capitalize">{item.titolo}</p>
									</div>
								</div>
								<div className="w-7 rotate-180 ">
									<Iconfrecciagiu />
								</div>
							</div>
							<div className="h-[1px] w-full bg-verdeScuro"></div>
						</div>
					))}
				</div>
				<button
					onClick={() => navigate("/logout")}
					className="text-xl text-rosso font-semibold my-8 md:col-span-2"
				>
					Esci
				</button>
			</div>
		</div>
	);
}

export default Profilo;
