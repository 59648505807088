import React from 'react'
import useEditingGiftStore from '../../../stores/editingGiftStore';
import SectionTitle from './SectionTitle';

const NomeDescrizioneRegalo = () => {
    const nome = useEditingGiftStore((state) => state.name);
    const setNome = useEditingGiftStore((state) => state.setName);
    const descrizione = useEditingGiftStore((state) => state.description);
    const setDescrizione = useEditingGiftStore((state) => state.setDescription);

    return (
        <div className='w-full flex flex-col items-start gap-4 mt-5 pb-5'>
            <SectionTitle
                title={"Nome e descrizione"}
                descrizione={"Inserisci il nome e la descrizione del tuo regalo"}
                isMandatory
                isValid={Boolean(nome) && Boolean(descrizione)}
            />
            <div className='flex flex-col w-full gap-2'>
                <input type="text" className='rounded-lg border-0 bg-background text-verdeScuro block flex-1 min-w-0 w-full text-lg p-2.5'
                placeholder='Nome del regalo' value={nome} onChange={(e) => setNome(e.target.value)}/>
                <textarea className='rounded-lg border-0 bg-background text-verdeScuro block flex-1 min-w-0 w-full min-h-32 text-lg p-2.5'
                placeholder='Descrizione del regalo' value={descrizione} onChange={(e) => setDescrizione(e.target.value)}></textarea>
            </div>

        </div>
    )
}

export default NomeDescrizioneRegalo