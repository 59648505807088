import React from 'react'
import StatusOkCheckIcon from '../../../assets/Icons/StatusOkCheckIcon';

const SectionTitle = ({ title, descrizione, isMandatory, isValid }) => {
    return (
        <div>
            <div className='flex items-center gap-2 md:text-3xl font-bold'>
                <StatusOkCheckIcon valid={isValid} />
                <h3 className="text-lg md:text-xl ml-2 font-bold">
                    {title} <span className={isMandatory ? "" : "hidden"}></span>
                </h3>
            </div>
            {descrizione && <div className="text-xs md:text-base text-grigio">
                <p>{descrizione}</p>
            </div>}
        </div>
    )
}

export default SectionTitle