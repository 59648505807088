import React from "react";
import { TbBrandFacebook } from "react-icons/tb";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { FiLinkedin } from "react-icons/fi";
import LogoTypeBicolor from "../../../assets/Logos/LogoTypeBicolor";
import WhiteSmile from "../../../assets/Illustrazioni/WhiteSmile";

function FooterSite() {

	(function (w, d) {
		var loader = function () {
			var s = d.createElement("script"),
				tag = d.getElementsByTagName("script")[0];
			s.src = "https://cdn.iubenda.com/iubenda.js";
			tag.parentNode.insertBefore(s, tag);
		};
		if (w.addEventListener) {
			w.addEventListener("load", loader, false);
		} else if (w.attachEvent) {
			w.attachEvent("onload", loader);
		} else {
            var wa = w;
			wa.onload = loader;
		}
	})(window, document);

	return (
		<div className=" bottom-0 block  right-0 w-full">
			<footer className="bg-verdeScuro  shadow text-white">
				<div className="w-full  mx-auto p-8">
					<div className="flex flex-col gap-12 md:grid md:grid-cols-3 w-full items-center">
						<div className="">
							<div className="md:w-[150px] w-44">
								<LogoTypeBicolor />
							</div>
							<h2 className="my-4 font-bold">
								TOGIFTIT S.R.L. SOCIET&Agrave; BENEFIT
							</h2>{" "}
							<p className="md:max-w-[80%] md:text-base font-extralight text-sm ">
								Promuoviamo la generosità e la sostenibilità:
								un luogo dove ogni
								dono trova una nuova casa costruendo insieme un futuro più
								solidale e rispettoso dell’ambiente.
							</p>
						</div>

						<div className="flex flex-col items-center  justify-start gap-6 md:mt-[20%] h-full">
							<h2 className="font-bold text-lg text-center">
								Seguici sui social e resta <br /> aggiornato sulle ultime novità
								:){" "}
							</h2>
							<div className="flex space-x-5 items-center justify-center ">
								<button
									onClick={() => window.open("https://instagram.com/togiftit/")}
									className="text-white w-9 h-9  rounded-full  transition-all duration-150 hover:-translate-y-2"
								>
									<FaInstagram className="w-full h-full" />
								</button>
								<button
									onClick={() =>
										window.open(
											"https://www.facebook.com/profile.php?id=100091961577076"
										)
									}
									className="text-white w-9 h-9  rounded-full  transition-all duration-150 hover:-translate-y-2"
								>
									<TbBrandFacebook className="w-full h-full" />
								</button>
								<button
									onClick={() =>
										window.open("https://www.linkedin.com/company/to-gift-it/")
									}
									className="text-white w-9 h-9  rounded-full  transition-all duration-150 hover:-translate-y-2"
								>
									<FiLinkedin className="w-full h-full" />
								</button>
								<button
									onClick={() => window.open("https://tiktok.com/@togiftit")}
									className="text-white w-9 h-9  rounded-full p-1 transition-all duration-150 hover:-translate-y-2"
								>
									<FaTiktok className="w-full h-full" />
								</button>
							</div>
						</div>
						<div className="flex flex-row-reverse md:flex-col justify-center items-center md:items-end">
							<ul className="flex md:flex-col items-end md:mb-6 text-sm font-thin text-white sm:mb-0 ">
								<li>
									<p
										rel="noreferrer"
										className="iubenda-nostyle cursor-pointer hover:underline iubenda-noiframe iubenda-embed iubenda-noiframe  "
										onClick={() =>
											window.open(
												"https://www.iubenda.com/privacy-policy/67170133"
											)
										}
									>
										Privacy Policy
									</p>
								</li>
								<span className="mx-2 md:hidden">|</span>
								<li>
									<p
										rel="noreferrer"
										className="iubenda-nostyle cursor-pointer hover:underline iubenda-noiframe iubenda-embed iubenda-noiframe "
										onClick={() =>
											window.open(
												"https://www.iubenda.com/privacy-policy/67170133/cookie-policy"
											)
										}
									>
										Cookie Policy
									</p>{" "}
								</li>
								<span className="mx-2 md:hidden">|</span>
								<li>
									<p
										rel="noreferrer"
										onClick={() => {
											window.open(
												"https://www.iubenda.com/termini-e-condizioni/67170133"
											);
										}}
										className="iubenda-nostyle cursor-pointer hover:underline iubenda-noiframe iubenda-embed iubenda-noiframe"
									>
										Termini e Condizioni
									</p>
								</li>
							</ul>
							<div className=" fill-white md:block hidden col-start-3 place-self-end">
								<WhiteSmile />
							</div>
						</div>
					</div>
					<p className=" mt-2 md:text-sm text-xs text-white sm:text-center">
						<span className="md:hidden"> - </span>
						<span>
							<a
								href="https://g.co/kgs/Bv7XwwN"
								className="hover:underline  mr-1"
							>
								C.da Cetti 29, 66023 Francavilla al Mare (CH)
							</a>{" "}
						</span>{" "}
						<br className="md:hidden" />
						- CF e P. IVA: 02414500682 <br className="md:hidden" /> - Numero
						REA: CH - 431586 <br className="md:hidden" />- Capitale Sociale:
						10.000,00 € <br className="md:hidden" />- PEC:
						<span>
							<a className="hover:underline ml-1" href="mailto:togiftit@pec.it">
								togiftit@pec.it
							</a>
						</span>
					</p>
				</div>
			</footer>
		</div>
	);
}

export default FooterSite;
