import Seme from "../../assets/Livelli/Seme";
import Germoglio from "../../assets/Livelli/Germoglio";
import Arbusto from "../../assets/Livelli/Arbusto";
import Albero from "../../assets/Livelli/Albero";

export const lvl = {
	0: {
		regali: 0,
		nome: "Seme",
		descr: '"Ogni grande cambiamento parte da un seme!"',
		reward: 150,
		img: (empty) => <Seme empty={empty} />,
	},
	1: {
		regali: 1,
		nome: "Germoglio",
		descr:
			'"Sei un germoglio, pronto a crescere.Le tue azioni cominciano a creare un impatto visibile”',
		reward: 250,
		img: (empty) => <Germoglio empty={empty} />,
	},
	2: {
		regali: 3,
		nome: "Arbusto",
		descr:
			'"Ormai sei un arbusto in piena fioritura. Le tue azioni hanno preso forza e stai diffondendo il riuso sempre più”',
		reward: 400,
		img: (empty) => <Arbusto empty={empty} />,
	},
	3: {
		regali: 10,
		nome: "Albero",
		descr:
			'"Come un albero che si radica, hai contribuito ad aiutare chi ti sta intorno diventando una presenza solida.”',
		reward: 500,
		img: (empty) => <Albero empty={empty} />,
	},
};

export const goals = {
	0: [
		{
			descr: "Pubblica un regalo",
			check: true,
			counter: 0,
			max: 1,
		},
		{
			descr: "Aggiungi una foto profilo",
			check: false,
		},
		{
			descr: "Fai iscivere un amico",
			check: false,
			counter: 0,
			max: 1,
		},
	],

	1: [
		{
			descr: "Pubblica 3 regali",
			check: false,
			counter: 0,
			max: 3,
		},
		{
			descr: "Prenotati per un regalo",
			check: false,
			counter: 0,
			max: 1,
		},
		{
			descr: "Fai iscivere cinque amici",
			check: false,
			counter: 0,
			max: 5,
		},
		{
			descr: "Aggiungi un regalo ai preferiti",
			check: false,
			counter: 0,
			max: 1,
		},
	],
	2: [
		{
			descr: "Pubblica sei regali",
			check: false,
			counter: 0,
			max: 6,
		},
		{
			descr: "Prenotati per tre regali",
			check: false,
			counter: 0,
			max: 3,
		},
		{
			descr: "Assegna due regali",
			check: false,
			counter: 0,
			max: 2,
		},
		{
			descr: "Porta a termine un'adozione e ricevi un regalo",
			check: false,
			counter: 0,
			max: 1,
		},
	],
	3: [
		{
			descr: "Pubblica dieci regali",
			check: false,
			counter: 0,
			max: 10,
		},
		{
			descr: "Prenotati per sei regali",
			check: false,
			counter: 0,
			max: 6,
		},
		{
			descr: "Porta a termine tre donazioni e consegna tre regali",
			check: false,
			counter: 0,
			max: 3,
		},
		{
			descr: "Porta a termine tre adozioni e ricevi tre regali",
			check: false,
			counter: 0,
			max: 3,
		},
	],
};
