import * as React from "react";

const Ellisse = ({w=518}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={w/1.61875}
    fill="none"
    viewBox="0 0 518 320"
  >
    <ellipse
      cx="159.571"
      cy="258.747"
      fill="#2D302B"
      rx="159.571"
      ry="258.747"
      transform="rotate(89.11 259.743 259.747)skewX(.058)"
    ></ellipse>
  </svg>
);

export default Ellisse;
