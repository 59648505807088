import { apiPost } from "./CallBackend";

const apiPrefix = 'auth';

const AuthAPI = {
    /**
     * 
     * @param {string} email 
     */
    sendVerificationCode: async (email) => {
        await apiPost(`${apiPrefix}/sendcode`, {email}, true);
    },

    /**
     * 
     * @param {string} email
     * @param {string} verificationCode 
     */
    verifyEmail: async (email, verificationCode) => {
        await apiPost(`${apiPrefix}/verifyemail`, {
            email: email,
            verificationCode: verificationCode
        });
    },

    /**
     * 
     * @param {string} email 
     */
    resetPassword: async (email) => {
        await apiPost(`${apiPrefix}/resetpassword`, {email}, true);
    }
};

export default AuthAPI;