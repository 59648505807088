import React, { useState } from "react";
import Benvenuto from "./Benvenuto";
import PuntiGuadagnati from "./PuntiGuadagnati";
import InserisciLocalita from "./InserisciLocalita";
import useFirstLoginStore from "../../../stores/firstLoginStore";
import useUserStore from "../../../stores/userStore";
import UserAPI from "../../../utils/API/UserAPI";

const DrawersPrimoLogin = ({ onFinished }) => {
    const user = useUserStore((state) => state.user);
    const firstLogin = useFirstLoginStore((state) => state.firstLogin);
    const [indiceAttuale, setIndiceAttuale] = useState(user.referredBy?.length > 0 ? 0 : 1);
    const [finished, setFinished] = useState(false);


    const handleDrawerClose = async () => {
        const newIndex = indiceAttuale + 1;
        console.log("new", newIndex, drawersStack.length);
        
        
        if (newIndex === drawersStack.length ) {
            console.log("DrawersPrimoLogin: handleDrawerClose: updateFirstLogin");
            
            try {
                await UserAPI.updateFirstLogin();
            } catch (e) {
                console.log(e);
            }
            
            setFinished(true);
            onFinished();
        }
        setIndiceAttuale(newIndex);
    };

    const drawersStack = [
        //<CodiceBenvenuto onClose={(e) => handleDrawerClose(e)} />,
        <PuntiGuadagnati onClose={(e) => handleDrawerClose(e)} />,
        <Benvenuto onClose={(e) => handleDrawerClose(e)} />,
        <InserisciLocalita onClose={(e) => handleDrawerClose(e)} />,
    ];



    return (
        <div className={`${finished ? "hidden" : ""}`}>
            {firstLogin && !finished && drawersStack[indiceAttuale]}
        </div>
    );
};

export default DrawersPrimoLogin;

/*
<AccettaNotifiche onClose={(e) => handleDrawerClose(e)} />,
*/
