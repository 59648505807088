import { useState, useEffect } from "react";
import ButtonTimer from "../../Components/Atoms/Bottoni/ButtonTimer";
import TextInputField from "../../Components/Atoms/Inputs/TextInputField";
import MailAmicoImage from "../../assets/Illustrazioni/Mail-amico.svg";
import ImageRegistrati from "../../assets/Illustrazioni/Registrati.svg";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";
import AuthAPI from "../../utils/API/AuthAPI";
import { toast } from "sonner";

const inputClassName =
	"md:text-xl text-lg text-verdeScuro font-semibold w-full bg-transparent placeholder:font-light border-grigio focus:border-verdeScuro focus:ring-0 rounded-md";
const containerClassName = "flex flex-col w-full h-full";
const titleClassName = "text-2xl font-semibold";

export const RecuperoStepEmail = ({ nextStep, setDefaultButtonsHidden }) => {
	const [email, setEmail] = useState("");

	useEffect(() => {
		setDefaultButtonsHidden(true);
	}, []);

	const isValid = () => {
		if (!email) return false;
		if (!email.includes("@")) return false;
		if (!email.includes(".")) return false;
		return true;
	};

	const handleOnClose = async () => {
		if (!isValid()) return;

		try {
			await AuthAPI.resetPassword(email);
			nextStep();
		} catch (e) {
			console.log(e);
			toast.error("Si è verificato un errore con la consegna della mail");
		}
	};

	
	return (
		<div className={containerClassName}>
			<div className="flex flex-col mb-10 space-y-8 md:space-y-10">
				<h2 className={titleClassName}>Non ricordi la password?</h2>
				<TextInputField
					placeholder={"Inserisci la tua mail"}
					inputStyle={inputClassName}
					marginTop={6}
					value={email}
					setValue={(e) => setEmail(e)}
				/>
				<div className="flex flex-col pr-6 text-lg">
					<p className="font-semibold">
						Non preoccuparti, succede a tutti ! ;&#41;
					</p>
					<p className="">
						Inserisci la tua mail e riceverai un link per reimpostare la
						password.
					</p>
				</div>
				<img
					src={ImageRegistrati}
					alt="Registrati"
					className="w-full md:hidden"
				/>
			</div>
			<div className="w-full flex justify-end">
				<LoadingBtn
					textStyle="text-white font-semibold"
					fullWidth={true}
					bgColor="bg-verde"
					onCall={handleOnClose}
					disabled={!isValid()}
				>
					Prosegui
				</LoadingBtn>
			</div>
		</div>
	);
};

export const ConfermaEmail = () => {
	return (
		<div className={containerClassName}>
			<div className="flex flex-col mb-6 space-y-6">
				<h2 className={titleClassName}>Mail inviata correttamente</h2>
				<img
					src={MailAmicoImage}
					alt="Registrati"
					className="w-2/3 md:w-1/4 place-self-center"
				/>
				<div className="flex flex-col pr-8 text-lg">
					<p className="font-semibold">Controlla la tua casella di posta,</p>
					<p className="">
						Se la mail che hai inserito è corretta, troverai una mail con un
						link per reimpostare la tua password.
					</p>
				</div>
				<div className="flex flex-col space-y-6  pt-8">
					<p className="font-semibold place-self-center text-lg">
						Non hai ricevuto la mail?
					</p>
					<div className="flex justify-center">
						<ButtonTimer
							onClick={() => toast("Mail reinviata")}
							delay={60000}
							waitingText="Nuova mail tra:"
							customStyle="w-1/2 md:w-1/4 flex justify-center "
							bgColor={"transparent"}
							textStyle={"text-verdeScuro"}
							initialState={true}
						>
							Invia nuova mail
						</ButtonTimer>
					</div>
				</div>
			</div>
		</div>
	);
};
