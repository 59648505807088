import { toast } from "sonner";
import Punti from "../../assets/Punti";

export const showPrompt = {
    pointsReceived: (points = 100) => {
        toast(`Hai ricevuto ${points} punti`, { 
            duration: 5000,
            icon:  <Punti />
        });
    },

    pointsSpent: (points = 100) => {
        toast(`Hai speso ${points} punti`, { 
            duration: 5000,
            icon:  <Punti />
        });
    }
};