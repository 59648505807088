import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 112.62 81.77"
      fill="CurrentColor"
    >
      <g data-name="layer 1">
        <path
          d="M105.08 0H32.61c-4.16 0-7.54 3.38-7.54 7.54v9.84H7.64C3.42 17.38 0 20.81 0 25.02v49.1c0 4.22 3.43 7.65 7.64 7.65h32.27v-3.41H7.64a4.24 4.24 0 01-4.23-4.24v-49.1c0-2.34 1.9-4.24 4.23-4.24h37.34c2.34 0 4.23 1.9 4.23 4.24v14.33h3.41V25.02c0-4.22-3.43-7.64-7.65-7.64h-16.5V7.54c0-2.27 1.85-4.12 4.12-4.12h72.47a4.12 4.12 0 014.12 4.12V50.5H61.69v3.42h47.49v6.01c0 2.27-1.85 4.12-4.12 4.12H61.69v3.41h13.68v8.81H61.25v3.41H84.5c.95 0 1.71-.76 1.71-1.71s-.76-1.7-1.71-1.7h-5.72v-8.81h26.27c4.16 0 7.54-3.38 7.54-7.54V7.54c0-4.16-3.38-7.54-7.54-7.54z"
          className="cls-1"
        ></path>
        <path
          d="M63.39 47.26l-.13-3.41A6.395 6.395 0 0057 38.73h-4.38v-.02h-3.41v.02h-8.07c-3.52 0-6.39 2.87-6.39 6.39l.13 31.54a6.393 6.393 0 006.26 5.12H57c3.52 0 6.39-2.86 6.39-6.39V47.26zm-3.41 28.12c0 1.64-1.34 2.98-2.98 2.98H41.14c-1.64 0-2.97-1.33-2.97-2.98V45.11c0-1.64 1.34-2.98 2.97-2.98H57c1.64 0 2.98 1.34 2.98 2.98v30.27zM26.31 73.14c-.94 0-1.7.76-1.7 1.7s.76 1.7 1.7 1.7 1.71-.76 1.71-1.7-.76-1.7-1.71-1.7zM68.84 60.64c.94 0 1.71-.77 1.71-1.71s-.77-1.7-1.71-1.7-1.71.76-1.71 1.7.76 1.71 1.71 1.71z"
          className="cls-1"
        ></path>
        <path
          d="M49.22 73.14c-.94 0-1.7.77-1.7 1.7s.77 1.71 1.7 1.71 1.71-.77 1.71-1.71-.77-1.7-1.71-1.7z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;