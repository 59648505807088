import React from 'react'
import DraggableList from './DraggableList';
import useEditingGiftStore from '../../../stores/editingGiftStore';
import SectionTitle from './SectionTitle';

const StripCaricaFoto: React.FC = () => {
    const immagini = useEditingGiftStore((state) => state.images);
    const cambiaImmagini = useEditingGiftStore((state) => state.setImages);
    
    return (
        <div className='w-full flex flex-col items-start gap-2 mt-5'>
            <SectionTitle
                title="Carica le foto del tuo regalo"
                descrizione="Trascina nel primo box la fotografia da mostrare come copertina"
                isMandatory={true}
                isValid={Boolean(immagini.length)}
            />
            <div className='flex justify-start w-full'>
                <DraggableList maxImagesNumber={5} images={immagini} setImages={async e => cambiaImmagini(e)} />
            </div>
            <div className="flex w-full text-xs justify-end text-grigio pb-2">
                <p>Max 5</p>
            </div>
        </div>
    )
}

export default StripCaricaFoto