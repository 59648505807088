import * as React from "react";

const Copy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="28"
    fill="none"
    viewBox="0 0 24 28"
  >
    <path
      fill="#000"
      d="M17 12.94a1.3 1.3 0 0 0-.06-.27v-.09a1 1 0 0 0-.19-.28l-6-6a1 1 0 0 0-.28-.19h-.09L10.06 6H4a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V12.94m-6-3.53L13.59 12H11zM15 23a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5v5a1 1 0 0 0 1 1h5z"
    ></path>
    <g filter="url(#filter0_d_484_4460)">
      <path fill="#fff" d="M6 5h6l4 3 2 3v10H6z"></path>
      <mask id="path-3-inside-1_484_4460" fill="#fff">
        <path d="M20 9.94a1.3 1.3 0 0 0-.06-.27v-.09a1 1 0 0 0-.19-.28l-6-6a1 1 0 0 0-.28-.19h-.09L13.06 3H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9.94m-6-3.53L16.59 9H14zM18 20a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h5v5a1 1 0 0 0 1 1h5z"></path>
      </mask>
      <path
        fill="#000"
        d="M20 9.94a1.3 1.3 0 0 0-.06-.27v-.09a1 1 0 0 0-.19-.28l-6-6a1 1 0 0 0-.28-.19h-.09L13.06 3H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9.94m-6-3.53L16.59 9H14zM18 20a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h5v5a1 1 0 0 0 1 1h5z"
      ></path>
      <path
        fill="#000"
        d="M20 9.94h10v-.565l-.064-.562zm-.06-.27h-10v1.636l.522 1.552zm0-.09h10V7.357l-.942-2.013zM13.75 3.3l7.071-7.071-.108-.108-.11-.104zm-.28-.19 4.236-9.059-2.013-.941H13.47zm-.09 0-3.25 9.457 1.58.543h1.67zM13.06 3l3.25-9.457L14.73-7h-1.67zM7 3V-7zM4 6H-6zm3 17v10zm10 0v10zm3-3h10zM14 6.41l7.071-7.071L4-17.732V6.41zM16.59 9v10h24.142L23.662 1.929zM14 9H4v10h10zM7 5v10zm5 0h10V-5H12zm6 6h10V1H18zm11.936-2.187a11.3 11.3 0 0 0-.518-2.33l-18.956 6.375a8.7 8.7 0 0 1-.398-1.791zm.004.857v-.09h-20v.09zm-.942-4.326a11 11 0 0 0-1.965-2.897L12.467 16.153a9 9 0 0 1-1.585-2.337zm-2.177-3.115-6-6L6.68 10.37l6 6zm-6.218-6.212a11 11 0 0 0-2.897-1.966L9.234 12.17a9 9 0 0 1-2.337-1.586zM13.47-6.89h-.09v20h.09zm3.16.543-.32-.11-6.5 18.914.32.11zM13.06-7H7v20h6.06zM7-7a13 13 0 0 0-9.192 3.808L11.95 10.95A7 7 0 0 1 7 13zm-9.192 3.808A13 13 0 0 0-6 6h20a7 7 0 0 1-2.05 4.95zM-6 6v14h20V6zm0 14a13 13 0 0 0 3.808 9.192L11.95 15.05A7 7 0 0 1 14 20zm3.808 9.192A13 13 0 0 0 7 33V13a7 7 0 0 1 4.95 2.05zM7 33h10V13H7zm10 0a13 13 0 0 0 9.192-3.808L12.05 15.05A7 7 0 0 1 17 13zm9.192-3.808A13 13 0 0 0 30 20H10a7 7 0 0 1 2.05-4.95zM30 20V10H10v10zM20 10h10v-.06H10V10zM6.929 13.481l2.59 2.59L23.66 1.93l-2.59-2.59zM16.59-1H14v20h2.59zM24 9V6.41H4V9zM8 20a9 9 0 0 1 2.636-6.364l14.142 14.142A11 11 0 0 0 28 20zm2.636-6.364A9 9 0 0 1 17 11v20a11 11 0 0 0 7.778-3.222zM17 11H7v20h10zM7 11a9 9 0 0 1 6.364 2.636L-.778 27.778A11 11 0 0 0 7 31zm6.364 2.636A9 9 0 0 1 16 20H-4a11 11 0 0 0 3.222 7.778zM16 20V6H-4v14zm0-14a9 9 0 0 1-2.636 6.364L-.778-1.778A11 11 0 0 0-4 6zm-2.636 6.364A9 9 0 0 1 7 15V-5A11 11 0 0 0-.778-1.778zM7 15h5V-5H7zM2 5v5h20V5zm0 5a11 11 0 0 0 3.222 7.778L19.364 3.636A9 9 0 0 1 22 10zm3.222 7.778A11 11 0 0 0 13 21V1a9 9 0 0 1 6.364 2.636zM13 21h5V1h-5zM8 11v9h20v-9z"
        mask="url(#path-3-inside-1_484_4460)"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_484_4460"
        width="24"
        height="28"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.726667 0 0 0 0 0.726667 0 0 0 0 0.726667 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_484_4460"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_484_4460"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

export default Copy;
