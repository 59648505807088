import React, { useState } from 'react'
import { auth } from '../../../firebase'
import FadeInComponent from '../../../Components/Atoms/Transitions/FadeInComponent'
import { renderTime } from '../../../utils/StringUtils';
import { AnimatePresence, motion } from 'framer-motion';
import { IoIosClose } from 'react-icons/io';

/**
 * @param {Object}o props
 * @param {import('../../../utils/API/ChatAPI').Message} props.messager
 * @param {boolean} props.isRight
 * @returns 
 */
function ChatMessage({message, isRight}) {
    const [imageDisplayed, setImageDisplayed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedContent = message.content.length > 250 ? message.content.substring(0, 250) + "..." : message.content;

    return (
        <div className={`flex flex-col max-w-[80vw] rounded-xl ${isRight ? "bg-verdino rounded-br-none" : "bg-gray-200 rounded-bl-none"} ${message.isLoading ? "opacity-40" : "opacity-100"}`}>
            {
                message.image ?
                <div>
                    <img src={message.image} alt="Sent image" className={`h-20 w-auto cursor-pointer hover:brightness-125 transition duration-300 ${message.content.length > 0 ? "rounded-t-xl" : "rounded-t-xl"}`} onClick={
                        () => {
                            setImageDisplayed(true);
                        }}
                    />
                    <ChatDisplayImage image={message.image} isOpen={imageDisplayed} setIsOpen={setImageDisplayed}/>
                </div>
                : <div/>
            }
            <div className="flex items-end justify-between py-2 px-3 overflow-hidden">
                {
                    message.content.length > 0 ?
                    <div className="pr-2 text-left text-black font-normal leading-5 max-w-48 sm:max-w-sm text-base break-words">
                        {isExpanded ? message.content : truncatedContent}
                        {message.content.length > 250 && (
                            <span
                                className="text-blue-500 cursor-pointer"
                                onClick={toggleExpand}
                            >
                                {isExpanded ? " Mostra meno" : " Mostra di più"}
                            </span>
                        )}
                    </div>
                    : <div/>
                }
                <div className="font-extralight text-xs text-gray-500">
                    {renderTime(message.createdAt)}
                </div>
            </div>
        </div>
    );
}

function ChatDisplayImage ({isOpen, setIsOpen, image}) {
    return (
        <AnimatePresence>
            ({ isOpen &&
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="backdrop-blur fixed inset-0 z-50 grid place-items-center cursor-pointer"
                    >
                    <motion.div
                        initial={{ scale: 0, rotate: "0deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="flex justify-center items-center bg-white text-white p-2 w-full h-full shadow-xl cursor-default relative overflow-hidden"
                    >
                        <IoIosClose
                            onClick={() => setIsOpen(false)}
                            className="w-5 h-5 absolute right-5 top-5 bg-verdeScuro hover:bg-verde fill-white rounded-full cursor-pointer"
                        />
                        <div className="">
                            <img
                                src={image}
                                className="object-contain h-full w-full"
                                alt="Immagine non caricata correttamente"
                            />
                        </div>
                    </motion.div>
                </motion.div>
            })
        </AnimatePresence>
    );
}

/**
 * @param {Object} props
 * @param {import('../../../utils/API/ChatAPI').Message} props.message
 */
function AutoMessage({message}) {
    const autoTypes = {
        "confirmedOwner": {title: "Consegna confermata! 🌱", text: "Il donatore ha consegnato il regalo"},
        "confirmedReceiver": {title: "Ritiro confermato! 🌱", text: "Il regalo è stato ricevuto"},
        "canceled": {title: "Prenotazione annullata", text: "La prenotazione è stata annullata"},
        "deliveryUndone": {title: "Conferma di consegna annullata", text: "La conferma di consegna è stata annullata"},
        "booked": {title: "Prenotazione effettuata! 🙋‍♀️", text: "Utilizzate questa chat per accordarvi"},
        "assigned": {title: "Assegnazione effettuata! 😊", text: "Una volta fatto, confermate lo scambio"},
        "info": {title: "Chiedi informazioni! 💬", text: "Chiedi informazioni sul regalo"}
    };

    const autoType = autoTypes[message.autoType];

    return (
        <div className="flex flex-col items-center mb-8">
            <div className='text-sm font-semibold'>
            {autoType.title}
            </div>
            <div className='text-sm'>
                {autoType.text}
            </div>
        </div>
    );
}

function RenderMessage({message, gap}) {
  return (
    <FadeInComponent>
      <div className={`mt-${gap ? 4 : 2} px-4`}>
        {
          message.autoType ?
          <div className="flex w-full justify-center">
            <AutoMessage message={message}/>
          </div>
          :
          message.sendBy === auth.currentUser.uid ?
          <div className="flex w-full flex-row-reverse">
            <ChatMessage message={message} isRight={true}/>
          </div>
          :
          <div className="flex overflow-hidden">
			      <ChatMessage message={message} isRight={false}/>
		      </div>
        }
    </div>
    </FadeInComponent>
  )
}

export default RenderMessage;