export const faq = [
    [
        // Tutte, lasciare vuoto per bloccare la sezione
    ],
    [ // Punti Gift
        {
            title: "Cosa sono i Punti Gift?",
            children: <p>I Punti Gift sono la "moneta della generosità" di ToGiftIt. Si accumulano partecipando alla community, donando oggetti e completando missioni. Ogni punto riflette il tuo contributo verso un mondo più sostenibile e solidale.</p>
        },
        {
            title: "Come si guadagnano i Punti Gift?",
            children:
                <p>
                    <ul className="list-decimal">
                        <li><span className="font-bold">Donando oggetti:</span> Ricevi 100 punti per ogni regalo consegnato.</li>
                        <li><span className="font-bold">Completando missioni:</span> Leggi articoli educativi o svolgi attività nella sezione Missioni.</li>
                    </ul>
                </p>
        },
        {
            title: "A cosa servono i Punti Gift?",
            children:
                <p>
                    Servono per prenotare regali. Al momento della prenotazione, i punti vengono sottratti temporaneamente e restituiti se il regalo viene assegnato a qualcun altro. Questo sistema evita che una persona prenoti senza un reale interesse.
                </p>
        }
    ],
    [ // Pubblicare
        {
            title: "Come pubblico un regalo?",
            children: <p>Clicca sul “+” in alto a destra della schermata principale, accedi alla sezione "Pubblica", compila i dettagli del tuo oggetto (descrizione, foto, categoria, ecc.) e conferma. <span className="font-bold">Fatto!</span> 😊</p>
        },
        {
            title: "Cosa devo scrivere nella descrizione dell'oggetto?",
            children: <p>Indica lo stato dell'oggetto (nuovo, usato, con difetti), eventuali dettagli utili e informazioni che aiutino ad agevolare il processo di donazione.</p>
        }
    ],
    [ // Prenotazioni e Regali
        {
            title: "Come prenoto un regalo?",
            children: <p>Vai sull'oggetto che desideri e clicca su "Prenota". Se hai abbastanza Punti Gift, la prenotazione sarà confermata immediatamente.</p>
        },
        {
            title: "Cosa succede se il regalo non è più disponibile?",
            children: <p>I tuoi punti saranno restituiti automaticamente e potrai utilizzarli per altri regali.</p>
        },
        {
            title: "Posso annullare una prenotazione?",
            children: <p>Certo! Vai nella sezione "Le mie prenotazioni", seleziona il regalo e clicca su "Annulla". I punti ti saranno restituiti immediatamente.</p>
        }
    ],
    [ // Account
        {
            title: "Come creo un account?",
            children: <p>Scarica l'app ToGiftIt, clicca su "Registrati", inserisci i tuoi dati (nome, email, password) e verifica l'account seguendo le istruzioni ricevute via email.</p>
        },
        {
            title: "Come cambio la password?",
            children: <p>Vai su "Impostazioni", seleziona "Cambia password" e segui le indicazioni. Riceverai un'email di conferma per completare il processo.</p>
        }
    ],
    [ // Privacy
        {
            title: "Come proteggete i miei dati?",
            children: <p>ToGiftIt utilizza sistemi di crittografia avanzati per garantire la sicurezza delle tue informazioni personali.</p>
        },
        {
            title: "I miei dati saranno condivisi con altri?",
            children: <p>No, i tuoi dati non saranno mai condivisi con terze parti senza il tuo consenso.</p>
        }
    ]
]