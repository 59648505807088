import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";
import Icon from "../../../assets/Illustrazioni/Localita.svg";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import DragCloseDrawer from "../Drawers/DragCloseDrawer";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import PrimaryButton from "../Bottoni/PrimaryButton";
import { toast } from "sonner";
import UserAPI from "../../../utils/API/UserAPI";
import LoadingBtn from "../Bottoni/LoadingBtn";
import SecondaryButton from "../Bottoni/SecondaryButton";

const InserisciLocalita = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [step, setStep] = useState(0);
    const device = useDeviceDetection();
    const [localita, setLocalita] = useState("");

    const handleClose = async (e) => {
        if (localita) {
            await UserAPI.update({location: localita}).then(()=>{

                toast.success("Località impostata con successo");
                onClose(e);
            }).catch((e)=>{
                console.log(e);
                
                toast.error("Errore nell'impostazione della località");
            })
        }
        setIsOpen(false);
        
    };

    const handlePlaceSelected = async (e) => {
        //e.preventDefault();
        console.log("Place selected", e);

        let streetNumber = "";
        let city = "";
        let country = "";

        e.address_components.forEach((component) => {
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        if (!streetNumber) {
            toast.error("Manca il numero civico");
            return;
        }

        const newLocation = {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng(),
            address: e.formatted_address,
            city: city,
            country: country,
        };

        setLocalita(newLocation);
    };

    const welcomeMessage = (
        <div className="w-full flex px-4 flex-col items-center text-center gap-5">
            <h1 className="md:text-5xl text-4xl font-malik text-center text-verdeScuro font-bold px-4">
                Trova regali nella tua zona!
            </h1>
            <p className="text-xl">
                Imposta la località per vedere{" "}
                <span className="font-bold block">
                    i nuovi regali vicino a te
                </span>
            </p>
            <div className="w-full md:w-2/3 -my-8">
                <img src={Icon} alt="Località" />
            </div>
            <p className="md:text-base md:px-12">
                Così potrai scoprire le migliori opportunità vicino a te!
            </p>
        </div>
    );
    const secondoStepLocalita = (
        <div className="w-full flex px-4 flex-col items-center text-center gap-5 mb-64">
            <div className="relative">
                <h1 className="md:text-5xl text-4xl font-malik text-center text-verdeScuro font-bold px-4">
                    Inserisci la tua località
                </h1>
                <div
                    className="absolute top-1 -left-4"
                    onClick={() => setStep(0)}
                >
                    <Iconfrecciagiu className={"w-8 rotate-0 stroke-white"} />
                </div>
            </div>
            <p className="text-xl">
                Digita l'indirizzo in cui vuoi localizzarti e selezionalo dal
                menù a tendina
            </p>
            <ReactGoogleAutocomplete
                apiKey={"AIzaSyDQ9eD1kvMthn2cOXACyzT71fKNoOANPBU"}
                style={{ width: "100%" }}
                name="indirizzo"
                className={`rounded-lg bg-gray-50 border text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5`}
                onPlaceSelected={async (e) => handlePlaceSelected(e)}
                placeholder="Via..."
                language="it"
                options={{
                    types: ["address"],
                    componentRestrictions: {
                        country: "it",
                    },
                }}
                
            />
        </div>
    );
    const welcomeButton = (
        <div className="flex flex-col md:flex-row-reverse md:items-center gap-2 justify-center mt-8">
            {/* TODO: cambiare con Bottone.jsx della PR#14 */}
            <PrimaryButton
                onClick={() => setStep(1)}
                fullWidth
                 >
                Si grazie
            </PrimaryButton>
            <SecondaryButton
                fullWidth
                onClick={(e) => onClose(e)}
                 >
                Forse dopo
            </SecondaryButton>
        </div>
    );
    const secondoStepButton = (
        <div className="flex flex-col md:flex-row-reverse md:items-center space-y-2 justify-center mt-8">
            {/* TODO: cambiare con Bottone.jsx della PR#14 */}
            <LoadingBtn onCall={handleClose} disabled={!localita} fullWidth>
                Imposta località
            </LoadingBtn>
        </div>
    );
    const mobileBody = (
        <DragCloseDrawer open={isOpen} hideCloseButton>
            <div className="flex flex-col relative h-full max-w-xl bg-white rounded-lg overflow-hidden justify-around">
                {step === 1 ? secondoStepLocalita : welcomeMessage}
                {step === 1 ? secondoStepButton : welcomeButton}
            </div>
        </DragCloseDrawer>
    );
    const desktopBody = (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // onClick={() => handleClose(false)}
            className="bg-slate-900/20 text-xs backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
        >
            <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden"
            >
                <div className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden">
                    {step === 1 ? secondoStepLocalita : welcomeMessage}
                    {step === 1 ? secondoStepButton : welcomeButton}
                </div>
            </motion.div>
        </motion.div>
    );
    const body = device === "Mobile" ? mobileBody : desktopBody;

    return <AnimatePresence>{isOpen && body}</AnimatePresence>;
};

export default InserisciLocalita;
