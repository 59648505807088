import Cookies from "universal-cookie";
import { auth } from "../../firebase";
import { apiDelete, apiGet, apiPost, apiPut, getAbsoluteImage, websocketUrl } from "./CallBackend";
import { capitalizeWords } from "../StringUtils";

/**
 * @typedef {Object} BookingGift
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} BookingUser
 * @property {string} uid
 * @property {string} completeName
 * @property {string|undefined} image
 */

/**
 * @typedef {Object} Booking
 * @property {BookingUser} owner
 * @property {BookingUser} receiver
 * @property {BookingUser} otherUser
 * @property {BookingGift} gift
 * @property {string} chat
 * @property {import("./ChatAPI").Message} lastMessage
 * @property {boolean} isRead
 * @property {boolean} isRequested
 * @property {boolean} isAccepted
 * @property {boolean} isConfirmedByOwner
 * @property {boolean} isConfirmedByReceiver
 * @property {boolean} isCanceled
 * @property {string} _id
 */

const apiPrefix = "booking";

/**
 * 
 * @param {Booking} booking 
 * @returns 
 */
export function parseBooking(booking) {
    if (booking.owner && booking.receiver) {
        booking.owner.completeName = capitalizeWords(booking.owner.completeName);
        booking.receiver.completeName = capitalizeWords(booking.receiver.completeName);
        
        if (booking.owner.image) {
            booking.owner.image = getAbsoluteImage(booking.owner.image);
        }
        if (booking.receiver.image) {
            booking.receiver.image = getAbsoluteImage(booking.receiver.image);
        }

        booking.otherUser = auth.currentUser.uid === booking.owner.uid ? booking.receiver : booking.owner;
    }
    if (booking.lastMessage) {
        booking.lastMessage.createdAt = new Date(booking.lastMessage.createdAt);
    }
    return booking;
}

const BookingAPI = {
    /**
     * @param {string} giftId
     * @param {boolean} [isRequested]
     * @returns {Promise<string>} The id of the created booking
     */
    makeBooking: async (giftId, isRequested = false) => {
        const bookingId = await apiPost(`${apiPrefix}`, {
            gift: giftId,
            isRequested: isRequested
        }, true);
        return bookingId;
    },

    /**
     * @param {string} bookingId 
     */
    updateInfoToBooking: async (bookingId) => {
        await apiPut(`${apiPrefix}/${bookingId}/book`, {}, true);
        return {message: {autoType: 'booked', sendBy: auth.currentUser.uid, bookingId: bookingId, createdAt: new Date()}, booking: {isRequested: true}};
    },

    getBookings: async () => {
        const bookings = await apiGet(`${apiPrefix}`, true);
        new Cookies().set('bookings_token', bookings.token, {});
        return bookings.bookings ? bookings.bookings.map((booking) => parseBooking(booking)) : [];
    },

    /**
     * Used by the receiver to retrieve his most recent, not canceled, booking given a certain gift
     * @param {string} giftId
     * @returns {Promise<Booking>}
     */
    getByGift: async (giftId) => {
        const booking = await apiGet(`${apiPrefix}/bygift/${giftId}`, true);
        return parseBooking(booking);
    },

    /**
     * Used by the owner to retrieve all bookings made to a gift he has
     * @param {string} giftId 
     * @returns {Promise<Booking[]>}
     */
    getGiftBookings: async (giftId) => {
        const bookings = await apiGet(`${apiPrefix}/bygift/${giftId}/owner`, true);        
        return bookings ? bookings.map((booking) => parseBooking(booking)): [];
    },

    /**
     * @param {string} bookingId
     * @param {string|null} reason
     */
    cancelBooking: async (bookingId, reason=null) => {
        await apiPost(`${apiPrefix}/${bookingId}/cancel`, {
            reason: reason
        }, true);
        return {message: {autoType: 'canceled', sendBy: auth.currentUser.uid, bookingId: bookingId, createdAt: new Date()}, booking: {isCanceled: true}};
    },

    /**
     * @param {string} bookingId
     */
    confirmGiftDelivery: async (bookingId) => {
        await apiPut(`${apiPrefix}/${bookingId}/confirm`, {}, true);
        return {message: {autoType: 'delivered', sendBy: auth.currentUser.uid, bookingId: bookingId, createdAt: new Date()}, booking: {isDelivered: true}};
    },

    /**
     * @param {string} bookingId
     */
    undoGiftDelivery: async (bookingId) => {
        await apiDelete(`${apiPrefix}/${bookingId}/confirm`, true);
        return {message: {autoType: 'deliveryUndone', sendBy: auth.currentUser.uid, bookingId: bookingId, createdAt: new Date()}, booking: {isDelivered: false}};
    },

    /**
     * @param {string} bookingId
     */
    setReviewed: async (bookingId) => {
        await apiPut(`${apiPrefix}/${bookingId}/reviewed`, {}, true);
    },

    /**
     * @param {string} bookingId
     */
    assignGift: async (bookingId) => {
        await apiPost(`${apiPrefix}/${bookingId}/assign`, {}, true);
        return {message: {autoType: 'assigned', sendBy: auth.currentUser.uid, bookingId: bookingId, createdAt: new Date()}, booking: {isAccepted: true}};
    },

    /**
     * @param {string} receiverUid 
     * @param {string} giftId 
     */
    assignGiftTo: async (receiverUid, giftId) => {
        await apiPost(`${apiPrefix}/booking/assign`, {
            giftId: giftId,
            receiverUid: receiverUid
        }, true);
    },

    /**
     * 
     * @param {function(Object): void} onBookingUpdateCallback 
     * @param {function(import("./ChatAPI").Message): void} onNewMessageCallback
     * @returns 
     */
    onBookingUpdate: (onBookingUpdateCallback, onNewMessageCallback) => {
        const token = new Cookies().get('bookings_token');

        const ws = new WebSocket(`${websocketUrl}?token=${token}`);

        ws.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data);
            if (parsedMessage.createdAt) {
                parsedMessage.createdAt = new Date(parsedMessage.createdAt);
            }

            if (parsedMessage.type === 0) {
                if (parsedMessage.lastMessage && parsedMessage.lastMessage.createdAt) {
                    parsedMessage.lastMessage.createdAt = new Date(parsedMessage.lastMessage.createdAt);
                }
                onBookingUpdateCallback(parsedMessage);
            }
            else if (parsedMessage.type === 1) {
                onNewMessageCallback(parsedMessage);
            }
        };

        return () => {
            ws.close();
        };
    }
};

export default BookingAPI;