import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserAPI from "../../utils/API/UserAPI";
import GiftAPI from "../../utils/API/GiftAPI";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import Segnala from "../../Components/Atoms/Modals/Segnala";
import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import Recensione from "../../assets/Icons/Recensione";
import { Separator } from "../../utils/InfoRegalo";
import ChipTabs from "../../Components/Atoms/Tabs/ChipTabs";
import CardContainer from "../../Components/Molecules/Container/CardContainer/CardContainer";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import Moment from "react-moment";
import CardRecensioni from "../../Components/Atoms/Card/CardRecensioni";
import useNavbarStore from "../../stores/navbarStore";
import LevelsUtils from "../../utils/LevelsUtils";

function Profili() {
	const [user, setUser] = useState();
	const slug = useParams().id;
	const [recensioni, setRecensioni] = useState([]);
	const [avg, setAvg] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [regali, setRegali] = useState([]);
	const setIsNavbarVisible = useNavbarStore((state) => state.setNavbarVisible);
	const setIsFootbarVisible = useNavbarStore(
		(state) => state.setFootbarVisible
	);

	const handleLoad = async () => {
		const promises = await Promise.all([
			UserAPI.getById(slug),
			GiftAPI.getByOwner(slug),
			GiftAPI.getBooked(),
			UserAPI.getReviews(slug),
		]);

		const user = promises[0];
		const regali = promises[1];
		const recensioni = promises[3];

		setUser({ ...user, level: user.level === 0 ? 1 : user.level });
		setRegali(regali);
		setRecensioni(recensioni);

		const sum =
			recensioni &&
			recensioni?.reduce((acc, recensione) => acc + recensione.rating, 0);
		setAvg(recensioni?.length ? sum / recensioni?.length : 0);

		setLoaded(true);
	};

	useEffect(() => {
		handleLoad();
		setIsNavbarVisible(false);
		setIsFootbarVisible(false);
	}, [window.location.pathname]);

	const tabs = ["Donazioni", "Recensioni"];
	const [selected, setSelected] = useState("Donazioni");

	return (
		<div className="flex flex-col min-h-[80vh] w-full items-center">
			{loaded ? (
				<div className="flex md:w-1/2 flex-col  items-center gap-[2vh]  px-4">
					<div className="flex justify-between items-center w-full my-[3vh]">
						<div className="absolute left-0">
							<Indietro />
						</div>
						<div className="absolute right-0">
							<Segnala user={user} />
						</div>
					</div>
					<div className="grid grid-cols-2 w-full md:gap-10 gap-5 ">
						<div className="md:hidden place-self-end">
							<ProfilePic hiddenLvl w="full" userId={slug} />
						</div>
						<div className="hidden md:flex place-self-end">
							<ProfilePic hiddenLvl w="40" userId={slug} />
						</div>

						<div className="h-full flex flex-col ">
							<h1 className="font-semibold text-xl mb-2">
								{user.name} {user.surname}
							</h1>
							<p className="font-semibold">
								<div className="flex items-center">
									{Array.from({ length: 5 }).map((_, index) => {
										return (
											<div
												key={index}
												className={`${
													index < avg ? "text-verde" : "text-grigino"
												} w-5 mr-1 scale-x-[-1]`}
											>
												<Recensione yellow={index < avg} gray={index >= avg} />
											</div>
										);
									})}
									<span className="ml-2">({recensioni.length})</span>
								</div>
							</p>
							<p className="mt-5">
								Membro dal:{" "}
								<span className="font-semibold">
									<Moment
										date={new Date(user.createdAt)}
										format="DD/MM/YY"
									></Moment>
								</span>
							</p>
							<p>
								Adozioni:{" "}
								<span className="font-semibold">{user.adoptions}</span>
							</p>
							<p>
								Donazioni:{" "}
								<span className="font-semibold">{user.donations}</span>
							</p>
						</div>
					</div>
					<div className="w-full grid grid-cols-2 md:gap-10 gap-5">
						<div className=" flex items-center  md:justify-end gap-2">
							<div className="w-10">
								{LevelsUtils.getLevel(user.level).img()}
							</div>
							<div className="">
								<p className="text-grigio text-xs">Liv. {user.level}</p>
								<p className="font-semibold">
									{LevelsUtils.getLevel(user.level).nome}
								</p>
							</div>
						</div>
						<div className=" flex items-center  md:justify-start gap-2"></div>
					</div>
					<Separator />
				</div>
			) : (
				<Caricamento />
			)}
			{loaded && (
				<div className="flex flex-col items-center gap-5">
					<div className="md:w-auto w-[90vw]">
						<ChipTabs
							tabs={tabs}
							selected={selected}
							setSelected={setSelected}
						/>
					</div>

					{selected === "Donazioni" &&
						(regali.length > 0 ? (
							<CardContainer>
								{regali.map((regalo, i) => {
									return (
										<div className="cardRegaloPubblica" key={i}>
											<CardPublic gift={regalo} chiave={i} />
										</div>
									);
								})}
							</CardContainer>
						) : (
							<h2 className="text-center mt-[30%] text-2xl">
								Questo utente non ha ancora effettuato donazioni
							</h2>
						))}
					{selected === "Recensioni" &&
						(recensioni.length > 0 ? (
							<CardContainer>
								{recensioni.map((item, i) => {
									return (
										<div className="cardRegaloPubblica" key={i}>
											<CardRecensioni review={item} chiave={i} />
										</div>
									);
								})}
							</CardContainer>
						) : (
							<h2 className="text-center mt-[30%] text-2xl">
								Questo utente non ha ancora ricevuto recensioni
							</h2>
						))}
				</div>
			)}
		</div>
	);
}

export default Profili;
