import { useEffect, useState } from "react";
import ChatMessages from "./ChatMessages/ChatMessages";
import ChatsList from "./ChatsList/ChatsList";
import React from "react";
import useBookingStore from "../../stores/bookingStore";


function Chat() {
    const selectedBooking = useBookingStore((state) => state.selectedBooking);

    //TODO: Ask Andrea if it is correct
    const [isMobile, setIsMobile] = useState(window.innerWidth < 990);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth < 990);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <div className="md:mb-4 h-full">
            {
                isMobile ?
                <div className="bg-white h-[100vh] rounded-lg">
                {
                    selectedBooking._id ?
                        <ChatMessages key={selectedBooking._id}/>
                    :
                    <div className="pt-4 h-full bg-white rounded-lg">
                        <ChatsList/>
                    </div>
                }
                </div>
                :
                <div className="flex justify-around h-[80vh]">
                    <div className="md:w-1/2 lg:w-1/3 bg-white rounded-lg shadow h-full">
                        <ChatsList/>
                    </div>
                    <div className="w-4"></div>
                    <div className="md:w-1/2 lg:w-2/3 bg-white rounded-lg shadow h-full">
                        {
                            selectedBooking._id ?
                            <ChatMessages key={selectedBooking._id}/>
                            :
                            <div className="flex h-full text-center justify-center items-center text-grigio">
                                Nessuna chat selezionata
                            </div>
                        }
                    </div>
                </div>
            }
        </div>        
    );
}

export default Chat;