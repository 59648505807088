import ContactTile from "./ContactTile";
import ChatItem from "./GiverChats/ChatItem";
import React from "react";
import useBookingStore from "../../../stores/bookingStore";
import { auth } from "../../../firebase";
import Caricamento from "../../../Components/Atoms/Caricamento/Caricamento";

function NoChatsText({ text }) {
    return (
        <div className="flex flex-col h-full items-center justify-center text-grigio">
            {text}
        </div>
    );
}

/**
 *
 * @param {Object} props
 * @param {boolean} props.isReceiver
 * @param {import("../../../utils/API/GiftAPI").Gift[]} props.items
 * @returns
 */
function Contacts({ filter, isReceiver, items }) {
    console.log(items);
    /*
     * @type {import("../../../utils/API/BookingAPI").Booking[]}
     */

    const bookings = useBookingStore((state) => state.bookings);

    return (
        <div className="h-full">
            {(() => {
                if (bookings !== null) {
                    const filteredBookings = Object.values(bookings).filter(
                        (booking) =>
                            isReceiver
                                ? booking.receiver.uid === auth.currentUser.uid
                                : booking.owner.uid === auth.currentUser.uid
                    );

                    if (bookings.length === 0) {
                        return <NoChatsText />;
                    }

                    const filteredChats = filteredBookings
                        .sort((a, b) => {
                            //Chats are ordered basing on last messages timestmap
                            if (a.lastMessage === null) {
                                return -1;
                            } else if (b.lastMessage === null) {
                                return 1;
                            }
                            return (
                                b.lastMessage.createdAt.getTime() -
                                a.lastMessage.createdAt.getTime()
                            );
                        })
                        //Chats are filtered if a filter is selected
                        .filter(filter);

                    if (items !== null && items !== undefined) {
                        if (items?.length === 0) {
                            return (
                                <NoChatsText
                                    text={"Non hai messo niente in regalo"}
                                />
                            );
                        }

                        const groupedChats = {};

                        for (let item of items) {
                            groupedChats[item._id] = [];
                        }

                        for (let filteredChat of filteredChats) {
                            if (filteredChat.gift.id in groupedChats) {
                                groupedChats[filteredChat.gift.id].push(
                                    filteredChat
                                );
                            } else {
                                groupedChats[filteredChat.gift.id] = [
                                    filteredChat,
                                ];
                            }
                        }

                        return items.map((item) => {
                            return (
                                <div className="">
                                    <ChatItem gift={item} />
                                    {groupedChats[item._id].length > 0 ? (
                                        groupedChats[item._id].map((chat) => (
                                            <ContactTile
                                                bookingId={chat._id}
                                                key={chat._id}
                                            />
                                        ))
                                    ) : (
                                        <div className="py-4">
                                            <NoChatsText
                                                text={"Nessuna prenotazione"}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        });
                    }

                    if (filteredChats.length > 0) {
                        return (
                            <div className="overflow-y-auto">
                                {filteredChats.map((chat) => {
                                    return (
                                        <ContactTile
                                            bookingId={chat._id}
                                            key={chat._id}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                    return (
                        <NoChatsText text={"Non hai prenotato nessun regalo"} />
                    );
                }
                return <Caricamento />;
            })()}
        </div>
    );
}

export default Contacts;
