import React from "react";

function Icon({className=""}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" className={className} viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 20l-8-8 8-8"
      ></path>
    </svg>
  );
}

export default Icon;