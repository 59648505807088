import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 115 115"
    >
      <path
        d="M64.16 53.34h.42c.56 0 1.09-.22 1.49-.62.42-.42.66-1.03.66-1.66 0-1.93 1-3.67 2.74-4.79.06-.04.12-.08.18-.13.08-.06.15-.11.21-.14.28-.15.54-.26.8-.35.25-.09.5-.16.73-.22l.25-.06c.31-.07.58-.1.85-.1h.13c.62.02 1.17.14 1.86.4.2.07.39.16.59.25.24.12.45.28.64.42l.2.15c1.4 1.04 2.24 2.64 2.41 4.63a2.412 2.412 0 002.42 2.21h.18c.82 0 1.58-.32 2.14-.91.57-.59.86-1.36.84-2.17-.21-6.14-5.19-10.95-11.35-10.95S61.47 44.1 61.2 50.22c-.04.82.26 1.59.83 2.19.57.59 1.33.92 2.15.92zM32.13 63.44a1.93 1.93 0 00-.33 1.75c3.25 11.46 13.82 19.46 25.71 19.46s22.46-8 25.71-19.46c.17-.6.05-1.24-.33-1.74-.4-.53-.99-.83-1.66-.83H33.79c-.67 0-1.25.29-1.66.83zM31.28 51.35l.12.27c.06.13.11.23.17.32l.07.09c.11.16.21.28.35.44.16.16.28.25.42.35.34.24.71.38 1.21.48.12.02.29.04.47.04h.42c1.07 0 1.96-.78 2.11-1.82l.02-.11c.02-.09.04-.19.05-.3.17-1.97 1-3.57 2.39-4.6l.32-.24c.08-.05.17-.11.2-.13.13-.09.2-.13.24-.16.3-.15.52-.25.74-.33.26-.09.51-.16.73-.22l.25-.06a4.193 4.193 0 011.77-.01l.22.05c.29.07.54.15.79.23a4.849 4.849 0 01.86.4l.64.45c1.41 1.05 2.24 2.65 2.41 4.63l.05.3c.16 1.11 1.05 1.92 2.12 1.92h.42c.17 0 .33-.02.41-.04l.27-.04c.36-.08.7-.23 1.01-.45.12-.08.24-.18.35-.29l.15-.14c.09-.1.16-.2.18-.22l.2-.28c.05-.09.1-.19.11-.23l.14-.3c.02-.06.04-.12.06-.2l.08-.37c0-.05.01-.12.02-.24v-.16-.2c-.27-6.1-5.25-10.87-11.34-10.87s-11.06 4.73-11.33 10.78l-.02.3.12.76c.02.08.04.14.05.2z"
        className="cls-1"
      ></path>
      <path
        d="M57.5 0C25.79 0 0 25.79 0 57.5S25.79 115 57.5 115 115 89.21 115 57.5 89.21 0 57.5 0zM5 57.5C5 28.55 28.55 5 57.5 5S110 28.55 110 57.5 86.45 110 57.5 110 5 86.45 5 57.5z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;