import React from 'react'
import useEditingGiftStore from '../../../stores/editingGiftStore';
import SectionTitle from './SectionTitle';
import RadioGroup from '../../../Components/Molecules/RadioButtons/RadioGroup';
import GiftUtils from '../../../utils/GiftUtils';

const StatoRegalo = () => {
    const status = useEditingGiftStore((state) => state.status);
    const setStato = useEditingGiftStore((state) => state.setStatus);


    const statuses = ['new', 'good', 'acceptable', 'to_fix'];
    const radioOptions = statuses.map((status) => ({ 
        label: GiftUtils.getStatus(status).text, 
        value: GiftUtils.getStatus(status).id,
        icon: GiftUtils.getStatus(status).img(),
    }));

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setStato(value);
    };

    return (
        <div className='w-full flex flex-col items-start gap-4 mt-5 pb-5'>
            <SectionTitle 
                title="Condizioni dell'oggetto"
                descrizione="Seleziona le condizioni in cui è il tuo oggetto"
                isMandatory
                isValid={Boolean(status)}
            />
            <div className='w-full'>
                <RadioGroup items={radioOptions} radioGroup="status" onChange={handleRadioChange} selectedItem={status} />
            </div>

        </div>
    )
}

export default StatoRegalo